// @ts-check
import React, { useMemo } from "react";
import { Typography } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import { getArtistsFeats, getTrackTitle } from "../business-objects";

import ExplicitIcon from "./ExplicitIcon";
import getArtistMain from "../business-objects/getArtistMain";

/** @typedef {import('../business-objects/album').Album} Album */
/** @typedef {import('../business-objects/album').Track} Track */

const TrackArtists = ({ track, album }) => {
  const [mainArtist, feats] = useMemo(() => {
    const _main = getArtistMain(album.artists);
    const _feats = getArtistsFeats(track.artists);
    return [_main, _feats];
  }, [album.artists, track.artists]);

  return (
    <>
      {mainArtist?.name}{" "}
      {feats?.length > 0 && (
        <span>feat. {feats.map((artist) => artist.name).join(", ")} </span>
      )}
    </>
  );
};

/**
 * Render the title of a Track
 * @param {{
 *  track: Track,
 *  album: Album
 * }} props
 */
export const ListItemTextTrack = ({ track, album, ...props }) => {
  return (
    <ListItemText
      primary={getTrackTitle(track, album)}
      secondary={
        <>
          {track?.explicit && <ExplicitIcon />}
          <TrackArtists track={track} album={album} />
        </>
      }
      {...props}
    />
  );
};

/**
 * Render the title of a Track
 * @param {{
 *  track: Track,
 *  album: Album
 * }} props
 */
export function TrackTitleHeader({ track, album }) {
  return (
    <div>
      <Typography variant="h6">{getTrackTitle(track, album)}</Typography>
      <Typography variant="subtitle2" color="textSecondary">
        {track?.explicit && <ExplicitIcon />}
        <TrackArtists track={track} album={album} />
      </Typography>
    </div>
  );
}
