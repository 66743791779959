// @ts-check
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import DeleteIcon from "@material-ui/icons/Delete";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import WarningIcon from "@material-ui/icons/Warning";

import wizardActions from "../../../../../reducers/wizardNewAlbum/wizardActions";

/** @typedef {import('../../../../../reducers/store').StateStore} StateStore */
/** @typedef {import('../../../../../reducers/wizardNewAlbum/wizardStore').NewAlbumTrack} NewAlbumTrack */

const useStylesTrackList = makeStyles((theme) => ({
  trackList: {},
  trackListItem: {
    border: "1px solid #CCC",
    borderRadius: "0.3rem",
    padding: "0.4rem",
    marginBottom: "0.6rem",
    backgroundColor: "#FFF",
    position: "relative",
    "& .MuiListItemIcon-root": {
      minWidth: "1.5rem",
    },
    "& .tracksList__trackOrderNum": {
      color: theme.palette.text.secondary,
      marginLeft: "0.5rem",
      marginRight: "0.5rem",
    },
    "& .tracksList__progress": {
      position: "absolute",
      bottom: "0",
      left: "0",
      width: "100%",
    },
    "& .uploading__message": {
      color: theme.palette.text.secondary,
    },
  },
  trackListItemSecondaryAction: {},
  brokenWarning: {
    display: "flex",
    alignItems: "center",
    "& .brokenWarning__message": {
      display: "flex",
      alignItems: "center",
      color: theme.palette.error.light,
      "& > *": {},
    },
  },
}));

/**
 *
 * @param {{track: NewAlbumTrack}} props
 * @returns
 */
const SecondaryActionTrack = ({ track }) => {
  const dispatch = useDispatch();
  const classes = useStylesTrackList();

  const handleOnDelete = () => {
    dispatch(wizardActions.audioTrackRemove(track));
  };

  let content = <></>;

  if (!track) {
    return content;
  }

  if (track.broken) {
    content = (
      <div className={classes.brokenWarning}>
        <div className="brokenWarning__message">
          <span>Archivo incompleto</span>
          <WarningIcon />
        </div>
        <IconButton edge="end" aria-label="delete" onClick={handleOnDelete}>
          <DeleteIcon />
        </IconButton>
      </div>
    );
  } else if (track.uploading) {
    content = (
      <div className="uploading__message">
        Subiendo...{Math.floor(track.progress)}%
      </div>
    );
  } else if (track.busy) {
    content = <CircularProgress size={30} aria-busy={true} />;
  } else {
    content = (
      <IconButton edge="end" aria-label="delete" onClick={handleOnDelete}>
        <DeleteIcon />
      </IconButton>
    );
  }

  return <ListItemSecondaryAction>{content}</ListItemSecondaryAction>;
};

const SortableItem = SortableElement(({ track, orderNum, className }) => (
  <ListItem className={className}>
    <ListItemIcon>
      <DragIndicatorIcon />
    </ListItemIcon>
    <div className="tracksList__trackOrderNum">{orderNum}.</div>
    <ListItemText primary={track.originalFilename} />
    <SecondaryActionTrack track={track} />
    {track.uploading && (
      <LinearProgress
        className="tracksList__progress"
        variant="determinate"
        value={track.progress}
      />
    )}
  </ListItem>
));

/**
 *
 * @param {{tracks: Array<NewAlbumTrack>}} param0
 * @returns
 */
const TracksList = SortableContainer(({ tracks }) => {
  const classes = useStylesTrackList();

  return (
    <List className={classes.trackList}>
      {tracks?.map((track, index) => {
        return (
          <SortableItem
            key={track.id}
            index={index}
            orderNum={index + 1}
            className={classes.trackListItem}
            track={track}
          />
        );
      })}
    </List>
  );
});

const StepTracksTracksList = () => {
  /** @type {Array<NewAlbumTrack>} */
  const tracks = useSelector(
    /**
     * @param {StateStore} state
     * @return {Array<NewAlbumTrack>}
     */
    (state) => state.wizardNewAlbumState.tracks
  );
  const dispatch = useDispatch();

  const handleOnSortEnd = ({ oldIndex, newIndex }) => {
    dispatch(wizardActions.trackOrderAction(oldIndex, newIndex));
  };

  return (
    <TracksList tracks={tracks} distance={1} onSortEnd={handleOnSortEnd} />
  );
};

export default StepTracksTracksList;
