import { fbroot } from "../firebase";
import { getCurrentUser } from "../firebase/auth";

const db = fbroot.database();

// const currentUser = getCurrentUser();

const createBulletin = async (payload) => {
  const { uid } = getCurrentUser();
  const sender = uid;
  const bulletin = { sender, ...payload };
  return db.ref("bulletins").push(bulletin);
};

const deleteBulletinById = async (buletinId) => {
  if (!buletinId) return false;
  return db.ref("bulletins").child(buletinId).remove();
};

const deleteBulletinsBySender = async (senderUid) => {
  if (!senderUid) return false;
  return db.ref("bulletins").orderByChild("sender").equalTo(senderUid);
};

const deleteBulletinByRecipient = async () => {
  const { uid } = getCurrentUser();
  const snap = await db
    .ref("bulletins")
    .orderByChild("recipient")
    .equalTo(uid)
    .once("value");
  const bulletins = snap.val();

  return Promise.all(
    Object.keys(bulletins).map((key) => db.ref("bulletins").child(key).remove())
  );
};

const suscribeAndHandle = async (handler) => {
  const { uid } = getCurrentUser();
  const refBulletin = db
    .ref("bulletins")
    .orderByChild("recipient")
    .equalTo(uid)
    .limitToLast(1);

  refBulletin.on("child_added", handler);

  // return unsuscribe function
  return () => refBulletin.off();
};

const getBulletin = async () => {
  try {
    const { uid } = getCurrentUser();
    const snap = await db
      .ref("bulletins")
      .orderByChild("recipient")
      .equalTo(uid)
      .limitToLast(1)
      .once("value");
    if (!snap.exists()) return null;

    const bulletin = snap.val();
    const [key] = Object.keys(bulletin);
    return { key, ...bulletin[key] };
  } catch (e) {
    console.error(e);
    return false;
  }
};

// const anyBulletins = async (authUser) => {
//   try {
//     const bulletins = getBulletins();
//     if (bulletins?.length) return true;
//   } catch (e) {
//     console.error(e);
//     return false;
//   }
// };

export {
  createBulletin,
  getBulletin,
  suscribeAndHandle,
  deleteBulletinsBySender,
  deleteBulletinById,
  deleteBulletinByRecipient,
};
