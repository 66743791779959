import * as React from "react";
import { useEffect, useState } from "react";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { functions } from "../firebase";
import translateError from "../utils/translateError";
import { doSignInInteractive, getCurrentUser } from "../firebase/auth";
import { CircularProgress } from "@material-ui/core";
import { Done as DoneIcon, Error as ErrorIcon } from "@material-ui/icons";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { createBulletin, deleteBulletinsBySender } from "../utils/bulletin";

const getUserAccountsInfo = functions.httpsCallable("userAccountsInfo");
const linkAccountSingle = functions.httpsCallable("linkAccountSingle");
const linkAccountGroup = functions.httpsCallable("linkAccountGroup");
const linkAccountBreakGroup = functions.httpsCallable("linkAccountBreakGroup");

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
  })
);

const Accs = ({ accs, checkAccount }) => {
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (accs.length >= 1) {
      setMessage("Esta cuenta ya está enlazada a otra(s) cuentas:");
    }
  }, [accs]);

  const ofus = (email) => {
    const [name, domain] = email.split("@");
    const [dom, com] = domain.split(".");
    return `${name[0] + name[1] + name[2]}${new Array(name.length - 3).join(
      "*"
    )}@${new Array(dom.length).join("*")}.${com}`;
  };

  if (!accs || !accs.length) return null;

  return (
    <>
      <Box>{message}</Box>
      <ul>
        {accs.map((acc) => (
          <li key={acc.uid}>{ofus(acc.email)}</li>
        ))}
      </ul>
      <Box>
        ¿Deseas enlazar TODAS o sólo esta: <strong>{checkAccount}</strong>?
      </Box>
    </>
  );
};

const Actions = ({
  account,
  other,
  handleLinkSingle,
  handleLinkGroup,
  handleLinkBreakGroup,
  abort,
  checking,
  modified,
  handleGetAccountInfo,
  checkAccount,
  error,
}) => {
  const classes = useStyles();

  if (!checkAccount || modified || error) {
    return (
      <Box className={classes.root}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleGetAccountInfo}
          disabled={checking}
        >
          Continuar
        </Button>
      </Box>
    );
  }

  if (!other.length) {
    return (
      <Box className={classes.root}>
        <Button onClick={abort} autoFocus>
          Cancelar
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleLinkSingle}
          disabled={checking}
        >
          Enlazar cuenta
        </Button>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Button
        color="primary"
        variant="contained"
        onClick={handleLinkGroup}
        disabled={checking}
      >
        Enlazar todas
      </Button>
      <Button
        color="primary"
        variant="contained"
        onClick={handleLinkBreakGroup}
        disabled={checking}
      >
        Sólo esta
      </Button>
      <Button onClick={abort} autoFocus>
        Cancelar
      </Button>
    </Box>
  );
};

//

function AccountLinkDialog(props) {
  const { open, closeDialog, linkedAccounts } = props;
  const [email, setEmail] = useState("");
  const [checkAccount, setCheckAccount] = useState(""); // the email of the account we want to check for
  const [error, setError] = useState("");
  const [checking, setChecking] = useState(false);
  const [targetAccountGroup, setTargetAccountGroup] = useState([]);
  const [accountToLink, setAccountToLink] = useState({});
  const [linkFromAccount, setLinkFromAccount] = useState({});
  const [modified, setModified] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    const currentUser = getCurrentUser();
    setLinkFromAccount(currentUser);
  }, []);

  useEffect(() => {
    setModified(email !== checkAccount);
  }, [email, checkAccount]);

  useEffect(() => {
    if (typeof checkAccount === "string" && checkAccount.includes("@")) {
      setChecking(true);
      setError("");

      getUserAccountsInfo(checkAccount).then(({ data }) => {
        if (data?.errorInfo) {
          const translatedError = translateError(data.errorInfo);
          setError(translatedError.message);
        }

        // there is some info...
        else if (data?.length) {
          const isLinkedAlready = Boolean(
            linkedAccounts.find((a) => a.email === checkAccount)
          );

          if (isLinkedAlready) {
            setError("Esa cuenta ya está enlazada ;)");
            setTargetAccountGroup([]);
          } else {
            // save to state the account we want to link
            setAccountToLink(
              data.filter((acc) => acc.email === checkAccount)[0]
            );

            // save to state all other accounts in the group (currently linked to the target account)
            setTargetAccountGroup(
              data.filter((acc) => acc.email !== checkAccount)
            );

            setError("");
          }
        }
        setChecking(false);
      });
    }
  }, [checkAccount, retryCount, linkedAccounts]);

  const handleChange = (event) => {
    const _e = event.target.value;
    setEmail(_e);

    if (checkAccount !== email) setError("");
  };

  const reset = () => {
    closeDialog();
    clear();
  };

  const clear = () => {
    setCheckAccount("");
    setTargetAccountGroup([]);
    setEmail("");
    setError("");
    setAccountToLink({});
  };

  const handleClose = () => {
    closeDialog();
  };

  const handleGetAccountInfo = () => {
    setCheckAccount(email);
    setRetryCount(retryCount + 1);
  };

  const handleLinkSingle = async () => {
    try {
      await createBulletin({
        title: "¡Cuenta enlazada!",
        body: `Has enlazado con éxito tu cuenta ${accountToLink.email}. Utiliza el menú de cuentas (arriba a la derecha) para moverte entre cuentas.`,
        recipient: accountToLink.uid,
      });

      const newCredentials = await doSignInInteractive(accountToLink.email);

      // error
      if (newCredentials && newCredentials instanceof Error) {
        throw newCredentials;
      }

      // sign in successful, comfirmed ownership, link it
      if (newCredentials.operationType) {
        setChecking(true);
        await linkAccountSingle({
          targetUid: accountToLink.uid,
          sourceUid: linkFromAccount.uid,
        });
        setChecking(false);
        reset();
      }
    } catch (error) {
      deleteBulletinsBySender(getCurrentUser().uid);

      const translatedError = translateError(error);
      setError(translatedError.message);
    } finally {
      window.location.reload();
    }
  };

  const handleLinkGroup = async () => {
    try {
      await createBulletin({
        title: "¡Cuentas enlazadas!",
        body: `Has enlazado con éxito las cuentas ${[
          accountToLink,
          ...targetAccountGroup,
        ]
          .map((a) => a.email)
          .join(", ")}. 
        Utiliza el menú de cuentas (arriba a la derecha) para moverte entre cuentas.`,
        recipient: accountToLink.uid,
      });

      const newCredentials = await doSignInInteractive(accountToLink.email);

      // error
      if (newCredentials && newCredentials instanceof Error) {
        throw newCredentials;
      }

      // sign in successful, comfirmed ownership, link it
      if (newCredentials.operationType) {
        setChecking(true);
        await linkAccountGroup({
          targetUid: accountToLink.uid,
          sourceUid: linkFromAccount.uid,
        });
        setChecking(false);
        reset();
      }
    } catch (error) {
      deleteBulletinsBySender(getCurrentUser().uid);

      const translatedError = translateError(error);
      setError(translatedError.message);
    } finally {
      window.location.reload();
    }
  };

  const handleLinkBreakGroup = async () => {
    try {
      await createBulletin({
        title: "¡Cuentas enlazadas!",
        body: `Has enlazado con éxito tu cuenta ${accountToLink.email}. Utiliza el menú de cuentas (arriba a la derecha) para moverte entre cuentas.`,
        recipient: accountToLink.uid,
      });

      const newCredentials = await doSignInInteractive(accountToLink.email);

      // error
      if (newCredentials && newCredentials instanceof Error) {
        throw newCredentials;
      }

      // sign in successful, comfirmed ownership, link it
      if (newCredentials.operationType) {
        setChecking(true);
        await linkAccountBreakGroup({
          targetUid: accountToLink.uid,
          sourceUid: linkFromAccount.uid,
        });
        setChecking(false);
        reset();
      }
    } catch (error) {
      deleteBulletinsBySender(getCurrentUser().uid);
      const translatedError = translateError(error);
      setError(translatedError.message);
    } finally {
      window.location.reload();
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle>Enlazar cuenta</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>
          Introduce el email de la cuenta que quieres enlazar.
        </DialogContentText>
        <Box sx={{ marginY: 2, display: "flex", alignItems: "center" }}>
          <TextField
            id="email"
            label="Email"
            variant="outlined"
            value={email}
            onChange={handleChange}
          />
          <Box marginLeft={1}>
            {!modified && !checking && !error && accountToLink?.uid && (
              <DoneIcon fontSize="large" color="primary" />
            )}
            {!modified && !checking && error && (
              <ErrorIcon fontSize="large" color="error" />
            )}
            {checking && <CircularProgress variant="indeterminate" />}
          </Box>
        </Box>
        <DialogContentText color="error">
          {!modified && error}
        </DialogContentText>
        {!modified && !checking && (
          <Accs accs={targetAccountGroup} checkAccount={checkAccount} />
        )}
      </DialogContent>
      <DialogActions>
        <Actions
          account={accountToLink}
          other={targetAccountGroup}
          abort={reset}
          handleLinkSingle={handleLinkSingle}
          handleLinkGroup={handleLinkGroup}
          handleLinkBreakGroup={handleLinkBreakGroup}
          checking={checking}
          modified={modified}
          handleGetAccountInfo={handleGetAccountInfo}
          checkAccount={checkAccount}
          error={error}
        />
      </DialogActions>
    </Dialog>
  );
}

export default AccountLinkDialog;
