// @ts-check
import { db } from "../../../firebase";
import { openAlbumField } from "../../albumActions";
import { processAlbumChange } from "../../cart/cartActions";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album").ArtistRole} ArtistRole */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldActionType} FieldActionType */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */
/** @typedef {import("../../../reducers/store").ThunkActionFunction} ThunkActionFunction */

/**
 * Add an Artist to the album
 * @param {string} upc
 * @param {ArtistRole} role
 * @return {Function}
 */
export const addArtist = (upc, role) => {
  /**
   * @param {function} dispatch
   */
  return async function thunk(dispatch) {
    const idArtist = await db.addAlbumArtist(upc, role.label);
    const path = `artists/${idArtist}`;

    dispatch(openAlbumField(`${path}/name`));

    /** @type {FieldEditable} */
    const field = {
      label: role.label,
      path: path,
      level: "album",
      type: "artist",
      artistRole: role,
    };

    // send to cart process
    dispatch(processAlbumChange(upc, field, "add", role));

    return idArtist;
  };
};
