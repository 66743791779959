import React from "react";
import { useFlashMessages, removeFlashMessage } from "@42.nl/react-flash-messages";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { addFlashMessage } from "@42.nl/react-flash-messages";
import { addError } from "@42.nl/react-flash-messages";

const addNotice = ({ text }) => {
  return addFlashMessage({ type: "NOTICE", duration: 20000, text });
};

const DisplayFlashMessages = () => {
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (flashMessage) => {
    removeFlashMessage(flashMessage);
  };

  const flashMessages = useFlashMessages();

  return (
    <div>
      {flashMessages.map((flashMessage) => (
        <div key={flashMessage.id} onClick={() => handleClose(flashMessage)}>
          <Snackbar
            key={"ahwtaowidjfpoiasdf"}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={true}
            onClose={() => handleClose(flashMessage)}
          >
            <Alert onClose={() => handleClose(flashMessage)} severity="success">
              {flashMessage.text}
            </Alert>
          </Snackbar>
        </div>
      ))}
    </div>
  );
};

export { DisplayFlashMessages, addNotice, addError };
