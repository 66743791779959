// @ts-check
import newAlbumService from "../../../services/newAlbumService";
import wizardActions from "../wizardActions";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/user").User} User */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../wizardStore").WizardNewAlbumState} WizardNewAlbumState */
/** @typedef {import("../wizardStore").NewAlbumPayload} NewAlbumPayload*/

/**
 *
 * @return {Function}
 */
export function discardNewAlbum() {
  /**
   * @param {function} dispatch
   * @param {StateGetter} getState
   */
  return async function thunk(dispatch, getState) {
    /** @type {StateStore} */
    const state = getState();

    const uploadingKey = state.wizardNewAlbumState.uploadingKey;
    if (uploadingKey) {
      await newAlbumService.discardNewAlbum(uploadingKey);
      dispatch(wizardActions.clearAction());
    }
  };
}

export default discardNewAlbum;
