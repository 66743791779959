import { firebase } from "../firebase";
import { functions } from "../firebase";

/** @typedef {import('../reducers/wizardNewAlbum/wizardStore').WizardNewAlbumState} WizardNewAlbumState */
/** @typedef {import('../reducers/wizardNewAlbum/wizardStore').NewAlbumPayload} NewAlbumPayload */

const _getUploadingRefPath = () => {
  const uid = firebase.auth.currentUser.uid;
  return `uploading/${uid}`;
};

const newAlbumKey = async () => {
  return firebase.db.ref("albums").push().key;
};

/**
 * @param {NewAlbumPayload} payloadAlbum
 * @return {Promise<any>}
 */
const saveTemporally = async (payloadAlbum) => {
  const uploadingRefPath = _getUploadingRefPath();
  const uploadingRef = firebase.db.ref(uploadingRefPath);
  return uploadingRef.update(payloadAlbum);
};

/** @return {Promise<NewAlbumPayload>} */
const fetchAlbumTemporally = async () => {
  const uploadingRefPath = _getUploadingRefPath();
  const snap = await firebase.db.ref(uploadingRefPath).once("value");
  return snap.exists() ? snap.val() : null;
};

/**
 * @param {object} payload
 * @param {string} uploadingKey
 * @return {Promise<Album>}
 */
export const submitNewAlbum = (payload, uploadingKey) => {
  const rpc = functions.httpsCallable("albumReceiveNewFromWizard");
  return rpc({ payload, uploadingKey }).then((resp) => resp.data);
};

/**
 * @param {string} uploadingKey
 * @return {Promise<any>}
 */
export const discardNewAlbum = (uploadingKey) => {
  const uid = firebase.auth.currentUser.uid;
  const rpc = functions.httpsCallable("newAlbumDiscard");
  return rpc({ uid, uploadingKey }).then((resp) => resp.data);
};

export default {
  newAlbumKey,
  fetchAlbumTemporally,
  saveTemporally,
  submitNewAlbum,
  discardNewAlbum,
};
