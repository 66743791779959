//@ts-check
import { storage } from "../firebase";

/** @typedef {import("./storageServiceTypes").UploadProgressCallback} UploadProgressCallback */
/** @typedef {import("./storageServiceTypes").UploadFileResponse} UploadFileResponse */

const _makeRefFromUrl = (customBucket, pathFolder) => {
  if (!customBucket || !pathFolder) {
    return "";
  }

  let ret;

  if (customBucket.indexOf("gs://") === -1) {
    ret = `gs://${customBucket}/${pathFolder}`;
  } else {
    ret = `${customBucket}/${pathFolder}`;
  }

  return ret;
};

/**
 * @param {string} pathFolder
 * @param {string} fileName
 * @param {File} file
 * @param {UploadProgressCallback?} progressCallback
 * @param {string?} customBucket - the bucket name
 * @return {Promise<UploadFileResponse>}
 */
export const uploadFile = async (
  pathFolder,
  fileName,
  file,
  progressCallback = null,
  customBucket = ""
) => {
  return new Promise((resolve, reject) => {
    const albumStorageRef = !!customBucket
      ? storage.refFromURL(_makeRefFromUrl(customBucket, pathFolder))
      : storage.ref(pathFolder);
    const itemStorageRef = albumStorageRef.child(fileName);
    const storageLocation = itemStorageRef.fullPath;

    // upload
    const uploadTask = itemStorageRef.put(file);

    // progress handler
    uploadTask.on(
      "state_changed",
      (snap) => {
        if (progressCallback) {
          progressCallback(snap);
        }
      },

      // error
      (error) => {
        reject(error);
      },

      // set download url on complete callback
      () => {
        itemStorageRef.getDownloadURL().then((url) => {
          resolve({
            url: url,
            storageLocation,
          });
        });
      }
    );
  });
};

/**
 * @param {string} storageLocation
 * @param {string?} customBucket - the bucket name
 * @return {Promise<object>}
 */
export const removeFile = async (storageLocation, customBucket = null) => {
  const storageRef = !!customBucket
    ? storage.refFromURL(_makeRefFromUrl(customBucket, storageLocation))
    : storage.ref(storageLocation);
  return storageRef.delete();
};

/**
 * @param {string} storageLocation
 * @param {string?} customBucket - the bucket name
 */
export const getDownloadURL = async (storageLocation, customBucket = null) => {
  const storageRef = !!customBucket
    ? storage.refFromURL(_makeRefFromUrl(customBucket, storageLocation))
    : storage.ref(storageLocation);
  return storageRef.getDownloadURL();
};

export default {
  uploadFile,
  removeFile,
  getDownloadURL,
};
