// @ts-check
import storageService from "../../../services/storageService.js";
import { trackBusyAction } from "../actions/trackBusy";
import { trackRemoveAction } from "../actions/trackRemove";
import saveTemporally from "./saveTemporally";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */
/** @typedef {import('../wizardStore').NewAlbumTrack} NewAlbumTrack */

/**
 * Recive un file para persistir y setear en el album
 *
 * @param {NewAlbumTrack} track
 * @return {Function}
 */
export function audioTrackRemove(track) {
  /**
   * @param {function} dispatch
   * @param {StateGetter} getState
   */
  return async function thunk(dispatch, getState) {
    // CHECK IF TRACK IS UPLODADED

    dispatch(trackBusyAction(track));

    // REMOVE STORAGE FILE
    if (track.storageLocation) {
      try {
        await storageService.removeFile(track.storageLocation);
      } catch (e) {}
    }

    // REMOVE TRACK OBJECT ON STORE
    dispatch(trackRemoveAction(track));

    // SAVE STATE OF THE NEW ALBUM
    await dispatch(saveTemporally());
  };
}

export default audioTrackRemove;
