// @ts-check
import { db } from "../../../firebase";
import {
  albumSetErrorAction,
  getAlbum,
  openAlbumField,
} from "../../albumActions";
import { processAlbumChange } from "../../cart/cartActions";

import _sideEffectCommon from "./_sideEffectCommon";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldActionType} FieldActionType */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */

/**
 * Delete an Album field or an Track field
 * @param {string} upc
 * @param {FieldEditable} field
 * @return {Function}
 */
export const deleteField = (upc, field) => {
  /**
   * @param {function} dispatch
   */
  return async function deleteFieldThunk(dispatch) {
    const { path, type } = field;
    /** @type {Album} */
    const album = await dispatch(getAlbum(upc));

    // clear album error asociated to the field
    dispatch(albumSetErrorAction(path, null));

    // remove & close field
    const _path = type === "artist" ? path.replace("/name", "") : path;
    let updates = { [_path]: null };

    updates = _sideEffectCommon(updates, album, field, null);

    await db.updateAlbumFields(upc, updates);
    dispatch(openAlbumField(null));

    // send to cart process
    await dispatch(processAlbumChange(upc, field, "delete"));
  };
};
