// @ts-check
import produce from "immer";
import findTrackByOrder from "../utils/findTrackByOrder";
import getTrackOpened from "../utils/getTrackOpened";

/** @typedef {import('../../store').AlbumState} AlbumState */

const TYPE = "PREV_ALBUM_TRACK";

/**
 * @return {Object}
 */
export const trackPrevAction = () => {
  return {
    type: TYPE,
  };
};

/**
 * @param {AlbumState} state
 * @param {object} action
 * @return {object}
 */
export const trackPrevReducer = (state, action) => {
  const currentTrack = getTrackOpened(state);

  if (!currentTrack) {
    return state;
  }

  const album = state.albums[state.openAlbum];

  if (currentTrack.order > 1) {
    const prevTrack = findTrackByOrder(album.tracks, currentTrack.order - 1);

    if (!prevTrack) {
      return state;
    }

    return produce(state, (draft) => {
      draft.openTrack = prevTrack.isrc;
    });
  }
  return state;
};

export default {
  action: trackPrevAction,
  reducer: trackPrevReducer,
  TYPE,
};
