import React from "react";
import Chip from "@material-ui/core/Chip";
import useConfig from "../../../hooks/useConfig";

const AlbumStatus = ({ statusKey, className }) => {
  const config = useConfig();

  if (!statusKey) {
    return null;
  }

  const statusKeyCrear = statusKey.toLowerCase().trim();
  const status = config.statuses[statusKeyCrear];

  if (!status) {
    return null;
  }

  return (
    <Chip
      label={status.label}
      style={{ backgroundColor: status.color, fontSize: ".8em" }}
      className={className}
      color="primary"
      size="small"
    />
  );
};

export default AlbumStatus;
