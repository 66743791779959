// @ts-check
import { db } from "../../../firebase";
import storageService from "../../../services/storageService.js";
import getFileExtension from "../../../utils/getFileExtension";
import getTitleFromFilename from "../../../utils/getTitleFromFilename";
import { processAlbumChange } from "../../cart/cartActions";
import { padStart } from "lodash";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album").Track} Track */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldActionType} FieldActionType */
/** @typedef {import("../../store").StateStore} StateStore */
/** @typedef {import("../../store").StateGetter} StateGetter */
/**import { padStart } from 'lodash';

 * @typedef {{
 *  storageLocation: string;
 *  filename: string;
 *  extension: string;
 *  originalFilename: string
 * }} NewAudioMeta
 */

/**
 *
 * @param {Track} track
 * @param {NewAudioMeta} newTrackMeta
 * @param {boolean} isUpdatingAlbum
 * @returns {{ [key: string]: any }}
 */
const makeUpdatesTrackMetadata = (track, newTrackMeta, isUpdatingAlbum) => {
  const { isrc } = track;
  // update track metadata
  /**
   * @type {{ [key: string]: any }}
   */

  console.log("track", track);
  console.log("newAudio", newTrackMeta);

  const { extension, originalFilename } = newTrackMeta;

  const updates = {
    [`tracks/${isrc}/title`]: getTitleFromFilename(originalFilename),
    [`tracks/${isrc}/storageLocation`]: newTrackMeta.storageLocation,
    [`tracks/${isrc}/filename`]: newTrackMeta.filename,
    [`tracks/${isrc}/extension`]: newTrackMeta.extension,
    [`tracks/${isrc}/originalFilename`]: newTrackMeta.originalFilename,
  };

  if (isUpdatingAlbum) {
    updates[`tracks/${isrc}/fileChanged`] = true;
  }

  return updates;
};

/**
 *
 * @param {Album} album
 * @param {Track} track
 * @param {File} file
 * @param {Object} config
 * @param {Function?} onProgress
 * @return {Promise<NewAudioMeta>}
 */
const uploadNewAudio = async (album, track, file, config, onProgress) => {
  const bucketName =
    album.status === "draft"
      ? config.buckets.uploaded.name
      : config.buckets.uploading.name;

  const pathFolder = `${album.subaccount_user}/${album.upc}`; // TODO also defined on functions > receiveNewAlbum
  const filename = `${padStart(track.order.toString(), 2, "0")}-${file.name}`;
  const extension = getFileExtension(file);

  // UPLOAD
  const result = await storageService.uploadFile(
    pathFolder,
    filename,
    file,
    (snap) => {
      if (onProgress) {
        onProgress(snap.bytesTransferred, snap.totalBytes);
      }
    },
    bucketName
  );

  return {
    storageLocation: result.storageLocation,
    extension,
    filename,
    originalFilename: file.name,
  };
};

/**
 * Add a field to track
 * @param {Album} album
 * @param {Track} track
 * @param {File} file
 * @param {Function?} onProgress
 * @return {Function}
 */
export const trackUploadAudio = (album, track, file, onProgress = null) => {
  /**
   * @param {function} dispatch
   */
  return async function thunk(dispatch, getState) {
    /** @type {StateStore} */
    const state = getState();
    const config = state.configState.config;

    // UPLOAD FILE
    const newTrackMeta = await uploadNewAudio(
      album,
      track,
      file,
      config,
      onProgress
    );

    const isUpdating = album.status === "live_updating";

    // UPDATE FIELD VALUE
    const { upc } = album;
    const updates = makeUpdatesTrackMetadata(track, newTrackMeta, isUpdating);
    await db.updateAlbumFields(upc, updates);

    /** @type {FieldEditable} */
    const field = {
      label: "Archivo de audio",
      path: `tracks/${track.isrc}/originalFilename`,
      type: "audioTrack",
      level: "track",
      isrcTrack: track.isrc,
    };

    // SEND TO CART CHANGES
    await dispatch(
      processAlbumChange(upc, field, "update", newTrackMeta.originalFilename)
    );
  };
};
