const getTitleFromFilename = (filename) => {
  const betterFilename = filename
    .replace(/\..{0,5}$/, "") // remove extension
    .replaceAll(/[_\-.]+/g, " ") // replace underscores, dashes, and dots with spaces
    .replaceAll(/^\d+/g, "")
    .trim(); // remove leading numbers

  return betterFilename.charAt(0).toUpperCase() + betterFilename.slice(1);
};

export default getTitleFromFilename;
