import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect } from "react";
import { sortBy } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  formControl: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
}));

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          if (context.datasetIndex === 0)
            return `${context.formattedValue} ${context.dataset.label}`;
          else return `${context.formattedValue}% del máximo tolerable`;
        },
      },
    },
  },
  stacked: false,
  scales: {
    x: {
      display: true,
      title: {
        text: "Período",
      },
    },
    y: {
      title: {
        display: true,
        text: "Reproducciones",
      },
    },
    y1: {
      title: {
        display: true,
        text: "Repr. fraud. (% del total permitido)",
      },
      min: 0,
      max: 100,
      position: "right",
    },
  },
};

const FraudByTrack = ({ data }) => {
  const [selectedTrack, setSelectedTrack] = useState("");
  const [tracks, setTracks] = useState([]);
  const [streamsByTrackAndPeriod, setStreamsByTrackAndPeriod] = useState([]);
  const [faudulentByPeriod, setFraudulentByPeriod] = useState({});
  const [fraudulentByTrackAndPeriod, setFraudulentByTrackAndPeriod] = useState(
    {}
  );
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (data) {
      // tracks
      setTracks(data.fraudulentTracks);

      // select first track
      if (data.fraudulentTracks.length > 0) {
        setSelectedTrack(data.fraudulentTracks[0].isrc);
      }

      // streamsByTrackAndPeriod
      setStreamsByTrackAndPeriod(data.streamsByTrackAndPeriod);

      // fraudulentByTrackAndPeriod
      setFraudulentByTrackAndPeriod(data.fraudulentByTrackAndPeriod);

      // fraudulentByPeriod
      setFraudulentByPeriod(data.fraudulentByPeriod);
    }
  }, [data]);

  useEffect(() => {
    if (
      selectedTrack &&
      streamsByTrackAndPeriod &&
      fraudulentByTrackAndPeriod
    ) {
      const trackStreamsByPeriodArr = streamsByTrackAndPeriod.filter(
        (track) => track.isrc === selectedTrack
      );

      const trackStreamsByPeriodObj = trackStreamsByPeriodArr.reduce(
        (acc, curr) => {
          acc[curr.period] = curr.cantidad;
          return acc;
        },
        {}
      );

      const trackFraudulentByPeriodArr = fraudulentByTrackAndPeriod.filter(
        (track) => track.isrc === selectedTrack
      );

      const chartData = {
        labels: trackStreamsByPeriodArr.map((track) => track.periodHuman),
        datasets: [
          {
            label: "Reproducciones",
            data: trackStreamsByPeriodArr.map((track) => track.cantidad),
            backgroundColor: "rgba(0, 120, 0, 1)",
            yAxisID: "y",
          },
          {
            label: "Reproducciones fraudulentas del período",
            data: trackFraudulentByPeriodArr.map((x) => {
              const totGood = trackStreamsByPeriodObj[x.period];
              const maxBad = totGood / 100; // 1% of total streams is the max allowed
              return Math.round(x.cantidad / maxBad);
            }),
            backgroundColor: "rgba(250, 0, 0, 1)",
            yAxisID: "y1",
          },
        ],
      };

      setChartData(chartData);
    }
  }, [
    selectedTrack,
    streamsByTrackAndPeriod,
    faudulentByPeriod,
    fraudulentByTrackAndPeriod,
  ]);

  const handleChange = (event) => {
    setSelectedTrack(event.target.value);
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h4" gutterBottom>
        Reproducciones fraudulentas por track
      </Typography>

      <FormControl className={classes.formControl}>
        <InputLabel id="track-selector-label">Elige track</InputLabel>

        <Select
          labelId="track-selection"
          id="track-selector"
          value={selectedTrack}
          onChange={handleChange}
        >
          {sortBy(tracks, ["title"]).map((track) => {
            return (
              <MenuItem key={track.isrc} value={track.isrc}>
                {track.title}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      {chartData && <Bar data={chartData} options={options} />}
    </div>
  );
};

export default FraudByTrack;
