import { functions } from "../firebase";

/**
 *
 * @param {string} text
 * @return {Promise<string | null>}
 */
export const languageDetector = (text) => {
  const service = functions.httpsCallable("languageDetector");

  return service(text).then((result) => result?.data || null);
};

export default {
  languageDetector,
};
