import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useEffect } from "react";
import useConfig from "../hooks/useConfig";

const MessageToAllUsers = () => {
  const config = useConfig();

  useEffect(() => {

    if(config) {
      const { messageAll } = config;

      if(!messageAll) {
        return;
      }

      const { message, active } = messageAll;

      if(active) {
        toast(
          message,
          {
            position: "top-center",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          }
        );
      }
    }
  }, [config]);

  return null;
};

export default MessageToAllUsers;
