// @ts-check
import { db } from "../../../firebase";
import { setAlbumUneditedAction } from "../actions/setAlbumUnedited";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldActionType} FieldActionType */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */

/**
 * Find the album on the local store or fetch from server
 * @param {string} upc
 * @return {Function}
 */
export const loadAlbumUnedited = (upc) => {
  /**
   * @param {function} dispatch
   * @param {StateGetter} getState
   */
  return async function thunk(dispatch, getState) {
    /** @type {StateStore} */
    const state = getState();

    let albumUnedited = state.cartState.albumUnedited;
    if (!albumUnedited || albumUnedited.upc !== upc) {
      albumUnedited = await db.fetchAlbumUnedited(upc);
      if (albumUnedited) {
        dispatch(setAlbumUneditedAction(upc, albumUnedited));
      }
    }

    return albumUnedited;
  };
};
