import React, { useState, useEffect } from "react";
import { auth, db } from "../../firebase";
import * as routes from "../../constants/routes";
import { TextField, Button, Typography, makeStyles } from "@material-ui/core";
import translateError from "../../utils/translateError";
import packageData from "../../../package.json";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(5),
  },
  buttonbox: {
    [theme.breakpoints.up("sm")]: {
      textAlign: "right",
    },
  },
  button: {
    marginTop: theme.spacing(5),
  },
  error: {
    marginTop: theme.spacing(2),
  },
}));

const SignInForm = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    setIsValid(
      password !== "" &&
        email.includes("@") &&
        email.includes(".") &&
        email.length > 5
    );
  }, [password, email]);

  const onSubmit = async (event) => {
    auth
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        // make sure the UI version is up to date or reload! <- Version update!
        db.getUIversion().then((version) => {
          if (version !== packageData.version) {
            window.location.reload(true);
          } else {
            console.log("UI version is up to date:", version);
          }
        });
      })
      .then(() => {
        history.push(routes.ALBUMS);
      })
      .catch((error) => {
        const _error = translateError(error);
        setError(_error);
      });

    event.preventDefault();
    return false;
  };

  return (
    <form onSubmit={onSubmit}>
      <Typography variant="h5" className={classes.title}>
        Entrar
      </Typography>

      <TextField
        id="email-input"
        label="Email"
        onChange={(event) => setEmail(event.target.value)}
        margin="normal"
        autoComplete=""
        value={email}
        variant="outlined"
        fullWidth
      />

      <br />

      <TextField
        id="password-input"
        label="Contraseña"
        type="password"
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        margin="normal"
        variant="outlined"
        fullWidth
      />

      {error && (
        <Typography className={classes.error} color="error">
          {error.message}
        </Typography>
      )}

      <Typography variant="h5" className={classes.buttonbox}>
        <Button
          color="primary"
          variant="contained"
          disabled={!isValid}
          type="submit"
          align="left"
          className={classes.button}
        >
          Entrar
        </Button>
      </Typography>
    </form>
  );
};
export default SignInForm;
