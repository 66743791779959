// @ts-check
import produce from "immer";

/** @typedef {import('../wizardStore').WizardNewAlbumState} WizardNewAlbumState */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */

const TYPE = "WIZARD_SET_SHOW_UPC_FIELD";

/**
 * @param {boolean} show
 * @return {Object}
 */
export const setShowUpcFieldAction = (show) => {
  return {
    type: TYPE,
    show,
  };
};

/**
 * @param {WizardNewAlbumState} state
 * @param {object} action
 * @return {object}
 */
export const setShowUpcFieldReducer = (state, action) => {
  return produce(state, (draft) => {
    draft.showUpcField = action.show;
    draft.album.upc = "";
  });
};

export default {
  action: setShowUpcFieldAction,
  reducer: setShowUpcFieldReducer,
  TYPE,
};
