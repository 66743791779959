// @ts-check
import produce from "immer";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import('../cartStore').CartState} CartState */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */

/**
 * @typedef {{
 *  type: "SET_ALBUM_UNEDITED",
 *  upc: string,
 *  album: Album
 * }} setAlbumUneditedAction
 */

const TYPE = "SET_ALBUM_UNEDITED";

/**
 *
 * @param {string} upc
 * @param {Album} album
 * @return {setAlbumUneditedAction}
 */
export const setAlbumUneditedAction = (upc, album) => {
  return {
    type: TYPE,
    upc,
    album,
  };
};

/**
 * @param {CartState} state
 * @param {setAlbumUneditedAction} action
 * @return {object}
 */
export const setAlbumUneditedReducer = (state, action) => {
  return produce(state, (draft) => {
    draft.albumUnedited = action.album;
    draft.upc = action.upc;
  });
};

export default {
  action: setAlbumUneditedAction,
  reducer: setAlbumUneditedReducer,
  TYPE,
};
