// @ts-check
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import { useHistory } from "react-router";
import wizardActions from "../../../../../reducers/wizardNewAlbum/wizardActions";
import * as routes from "../../../../../constants/routes";
import WizardStepHeading from "./WizardStepHeading";
import WizardBotContext from "./WizardBotContext";
import ConfirmationDialog from "../../Edit/components/ConfirmationDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

/** @typedef {import('../../../../../reducers/wizardNewAlbum/wizardStore').WizardNewAlbumState} WizardNewAlbumState */
/** @typedef {import('../../../../../reducers/store').StateStore} StateStore */

// Based on https://github.com/formium/formik/blob/master/examples/MultistepWizard.js

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1100,
    margin: "auto",
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  buttonLabelBig: {
    display: "inline-block",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  buttonLabelSmall: {
    display: "inline-block",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepperContainer: {
    background: "transparent",
    padding: theme.spacing(2),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    justifyContent: "center",
    "& .MuiStepConnector-root": {
      flex: "initial",
      width: "40%",
    },
  },
  notActiveLabel: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  snackbar: {
    margin: theme.spacing(1),
  },
  snackbarError: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.error.dark,
  },
  closeIcon: {
    opacity: 0.9,
    fontSize: 20,
  },
  formPaper: {
    padding: theme.spacing(5),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
    borderRadius: 10,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(5, 2),
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(7),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    "& .toolbar__discardButton": {
      color: theme.palette.text.secondary,
    },
    "& .toolbar__left": {
      flex: "1 1 50%",
    },
    "& .toolbar__right": {
      flex: "1 1 50%",
      textAlign: "right",
      whiteSpace: "nowrap",
      "& > *": {
        marginLeft: "1rem",
      },
    },
  },
  errors: {
    display: "flex",
    width: "100%",
    marginTop: theme.spacing(5),
  },
  uploadingAnimationContainer: {
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    "& > *": {
      position: "absolute",
      top: "50%",
      left: "50%",
    },
  },
}));

const Wizard = ({ steps }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showConfirmDiscard, setShowConfirmDiscard] = useState(false);
  const [isDiscarting, setIsDiscarting] = useState(false);

  const album = useSelector(
    /**
     * @param {StateStore} state;
     * @return {Object}
     */
    (state) => state.wizardNewAlbumState.album
  );
  let activeStepNumber = useSelector(
    /**
     * @param {StateStore} state;
     * @return {Number}
     */
    (state) => state.wizardNewAlbumState.activeStep
  );
  const submiting = useSelector(
    /**
     * @param {StateStore} state;
     * @return {boolean}
     */
    (state) => state.wizardNewAlbumState.submiting || false
  );
  const isUploadingTracks = useSelector(
    /**
     * @param {StateStore} state;
     * @return {boolean}
     */
    (state) => state.wizardNewAlbumState.isUploadingTracks || false
  );

  // TODO: esta linea se puede borrar despues de borrar el wizard viejo
  activeStepNumber = Math.min(activeStepNumber, steps.length - 1);

  const StepComp = steps[activeStepNumber];

  const totalSteps = steps.length;
  const isLastStep = activeStepNumber === totalSteps - 1;

  const next = (values) => {
    const nextStepNumber = Math.min(activeStepNumber + 1, totalSteps - 1);
    dispatch(wizardActions.setAlbumAction(values, nextStepNumber));
    dispatch(wizardActions.saveTemporally());
  };

  const previous = (values) => {
    const nextStepNumber = Math.max(activeStepNumber - 1, 0);
    dispatch(wizardActions.setAlbumAction(values, nextStepNumber));
    dispatch(wizardActions.saveTemporally());
  };

  const handleSubmit = async (values, actions) => {
    if (isLastStep) {
      dispatch(wizardActions.setAlbumAction(values, activeStepNumber));
      await dispatch(wizardActions.saveTemporally());

      try {
        await dispatch(wizardActions.submitNewAlbum());
      } catch (error) {
        let msg = "Ops, no se pudo enviar el album";
        if (error.message && error.details?.isMessageUserFriendly) {
          msg = error.message;
        }
        toast.error(msg);
      }
    } else {
      actions.setTouched({});
      next(values);
    }
  };

  const doDiscardNewAlbum = async () => {
    try {
      setIsDiscarting(true);
      await dispatch(wizardActions.discardNewAlbum());
      history.push(routes.ALBUMS);
    } catch (e) {
      toast.error("Ops! no se pudo descartar el album");
      console.error(e);
    } finally {
      setShowConfirmDiscard(false);
      setIsDiscarting(false);
    }
  };

  const handleOnDiscard = () => {
    setShowConfirmDiscard(true);
  };

  return (
    <Formik
      initialValues={album}
      onSubmit={handleSubmit}
      validationSchema={StepComp.validationSchema}
      validateOnChange
    >
      {({ values, isSubmitting, isValid }) => {
        return (
          <Form noValidate>
            <WizardBotContext />

            {showConfirmDiscard && (
              <ConfirmationDialog
                onOk={doDiscardNewAlbum}
                okLabel="Descartar album"
                onCancel={() => setShowConfirmDiscard(false)}
                title="Confirmar"
                message="¿Está seguro que desea descartar? Se borran los datos y archivos subidos"
                loading={isDiscarting}
              />
            )}

            <div className={classes.formPaper}>
              {submiting && (
                <div className={classes.uploadingAnimationContainer}>
                  <div>
                    <CircularProgress />
                  </div>
                </div>
              )}

              <StepComp />

              <div className={classes.toolbar}>
                <div className="toolbar__left">
                  <Button
                    className="toolbar__discardButton"
                    variant="text"
                    size="small"
                    onClick={handleOnDiscard}
                  >
                    Descartar
                  </Button>
                </div>

                <div className="toolbar__right">
                  {activeStepNumber > 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        !isValid ||
                        isSubmitting ||
                        submiting ||
                        isUploadingTracks
                      }
                      onClick={() => previous(values)}
                    >
                      <span className={classes.buttonLabelBig}>Anterior</span>
                      <ArrowBackIcon className={classes.buttonLabelSmall} />
                    </Button>
                  )}

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={
                      !isValid || isSubmitting || submiting || isUploadingTracks
                    }
                  >
                    <span className={classes.buttonLabelBig}>
                      {isLastStep ? "Continuar" : "Siguiente"}
                    </span>
                    <ArrowForwardIcon className={classes.buttonLabelSmall} />
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const WizardStep = ({ children }) => children;

Wizard.Step = WizardStep;
Wizard.Heading = WizardStepHeading;

export default Wizard;
