import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { firebase, fbroot } from "../firebase";
import CircularProgress from "@material-ui/core/CircularProgress";
import DoneIcon from "@material-ui/icons/CloudDone";
import indigo from "@material-ui/core/colors/indigo";
import teal from "@material-ui/core/colors/teal";
import UploadIcon from "@material-ui/icons/CloudUpload";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Avatar from "@material-ui/core/Avatar";
import upperCase from "lodash/upperCase";
import { Typography } from "@material-ui/core";

const photoSquareHeight = "30vh";

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alginItems: "center",
  },
  iconContent: {
    textAlign: "center",
    alignSelf: "center",
  },
  dropzone: {
    borderWidth: 1,
    borderStyle: "dashed",
    borderColor: indigo[100],
    marginTop: "3vh",
    position: "absolute",
    textAlign: "center",
    width: photoSquareHeight,
    height: photoSquareHeight,
    borderRadius: "50%",
    [theme.breakpoints.down("sm")]: { width: "25vh", height: "25vh", bottom: "-25px" },
  },
  input: {
    opacity: "-1",
    cursor: "pointer",
    width: photoSquareHeight,
    height: photoSquareHeight,
    borderRadius: "50%",
  },
  imageBox: {
    position: "relative",
    margin: "auto",
  },
  uploadIcon: {
    align: "center",
    fontSize: 40,
    color: theme.palette.primary.main,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  progress: {
    position: "relative",
    zIndex: 1,
  },
  doneIcon: {
    align: "center",
    color: teal[100],
    fontSize: 40,
  },
  deleteButton: {
    position: "absolute",
    right: theme.spacing(1) * -2,
    color: theme.palette.primary.main,
  },

  image: {
    width: "100%",
    height: "100%",
  },
  75: {
    width: 75,
    height: 75,
  },
  90: {
    width: 90,
    height: 90,
  },
  150: {
    width: 150,
    height: 150,
  },
  250: {
    width: 250,
    height: 250,
  },
  avatarNoPhoto: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
    color: "#fff",
    fontWeight: 900,
    backgroundColor: theme.palette.secondary.main,
    fontSize: "3rem",
  },
});

class UserAvatar extends React.Component {
  constructor() {
    super();

    this.state = {
      uploading: false,
      progress: 0,
      error: false,
    };
  }

  componentDidMount() {
    const user = firebase.auth.currentUser;
    if (!user) return;
    this.setState({
      url: user.photoURL,
    });
  }

  saveAvatar = () => {
    const { url } = this.state;
    const user = firebase.auth.currentUser;
    user
      .updateProfile({
        photoURL: url,
      })
      .then(function () {
        console.log("yes updated");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  resetAvatar = () => {
    const user = firebase.auth.currentUser;

    this.setState({ url: null });

    user
      .updateProfile({
        photoURL: null,
      })
      .then(function () {
        console.log("yes removed");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getExtension = (file) => {
    switch (file.type) {
      case "image/jpeg":
        return "jpg";
      case "image/gif":
        return "gif";
      case "image/png":
        return "png";
      default:
        const extension = file.name.split(".").pop();
        return extension ? extension : "";
    }
  };

  handlePhotoRemove = () => {
    // we should remove storage object... but... no time.
    this.resetAvatar();
  };

  handlePhotoDrop = (event) => {
    if (!event) {
      return false;
    }

    // set local state quick to show feedback
    this.setState({ uploading: true });

    const { config } = this.props;
    const uid = firebase.auth.currentUser.uid;
    const avatarsBucket = fbroot.app().storage(config.buckets.avatars.url);
    const file = event.target.files[0];
    const extension = this.getExtension(file);
    const filename = `${uid}.${extension}`;
    const itemStorageRef = avatarsBucket.ref(filename);

    // upload
    const uploadTask = itemStorageRef.put(file);

    // progress handler
    uploadTask.on(
      "state_changed",
      (taskSnap) => {
        this.setState({
          progress: (taskSnap.bytesTransferred / taskSnap.totalBytes) * 100,
        });
      },

      (error) => {
        this.setState({ error: true });
        console.log(error);
      },

      // set download url on complete callback
      () => {
        itemStorageRef.getDownloadURL().then((url) => {
          this.setState({ url }, this.saveAvatar);
        });
      }
    );

    // no preview
    window.URL.revokeObjectURL(file.preview); // avoid memmory leaks
  };

  getSizeClass = () => {
    const { size, classes } = this.props;

    if (!size || !classes[size]) {
      return classes[90];
    }

    return classes[size];
  };

  render() {
    const { classes } = this.props;
    const { uploading, url, progress } = this.state;
    const { displayName, email } = firebase.auth.currentUser;

    return (
      <div className={classes.root}>
        {url ? null : (
          <>
            <div className={classes.dropzone}>
              <input className={classes.input} type="file" onChange={(e) => this.handlePhotoDrop(e)} accept={"image/*"} />
            </div>
            <div className={classes.iconContent}>
              {!uploading && <UploadIcon className={classes.uploadIcon} />}
              {progress === 100 && <DoneIcon className={classes.doneIcon} />}
              {uploading && progress === 0 && <CircularProgress variant="indeterminate" className={classes.progress} />}
              {uploading && progress > 0 && progress < 100 && (
                <CircularProgress variant="determinate" value={progress} className={classes.progress} />
              )}
              <Typography variant="subtitle1" paragraph={true} color="textSecondary" align="center">
                Arrastra o haz click
                <br />
                (pdf, jpg, png o gif)
              </Typography>
            </div>
          </>
        )}

        {!url ? null : (
          <div className={classes.imageBox}>
            {url ? (
              <Avatar src={url} className={this.getSizeClass()} />
            ) : (
              <Avatar className={classes.avatarNoPhoto}>{upperCase(displayName ? displayName.charAt(0) : email.charAt(0))}</Avatar>
            )}

            <IconButton
              className={classes.deleteButton}
              onClick={() => {
                this.setState({ uploading: false });
                this.handlePhotoRemove();
              }}
              aria-label="Delete"
            >
              <DeleteIcon />
            </IconButton>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(UserAvatar);
