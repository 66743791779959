//@ŧs-check

/**
 *
 * @param {import('./album').Artist} artist
 * @returns
 */
export const isPrimaryArtist = (artist) => {
  if (!artist) {
    return false;
  }
  return artist.country || artist.primary;
};

export default isPrimaryArtist;
