//@ts-check
import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import getTrackTitle from "../../../../../business-objects/getTrackTitle";
import mergeAndGroupArtists from "../../../../../business-objects/mergeAndGroupArtists";

/** @typedef {import('../../../../../business-objects/album').Album} Album */
/** @typedef {import('../../../../../business-objects/album').Artist} Artist */
/** @typedef {import('../../../../../business-objects/album').Track} Track */

const useStyles = makeStyles((theme) => ({
  rootCredits: {
    "& .MuiPaper-root": {
      minWidth: "400px",
    },
    "& .credits__title": {
      borderBottom: "1px solid #CCC",
    },
    "& .credits__titleLabel": {
      fontSize: "1.3rem",
    },
    "& .credits__close": {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    "& .credits__content": {
      padding: theme.spacing(2),
    },
    "& .artist": {
      marginTop: "1rem",
      marginBottom: "1rem",
      "& .artist__role": {},
      "& .artist__name": {
        color: theme.palette.text.secondary,
      },
    },
    "& .artistsList__empty": {
      padding: "2rem",
      color: theme.palette.text.secondary,
      textAlign: "center",
    },
  },
  "@media (max-width: 600px)": {
    rootCredits: {
      "& .MuiPaper-root": {
        minWidth: "initial",
        width: "100%",
        margin: "1rem",
      },
    },
  },
}));

/**
 *
 * @param {{
 *  groupRoles: Array<{
 *   role: string,
 *   artists: Array<Artist>
 * }>
 * }} props
 */
const ArtistsList = ({ groupRoles }) => {
  return (
    <>
      {groupRoles.map((group, index) => {
        return (
          <div className="artist" key={index}>
            <div className="artist__role">{group.role}</div>
            <div className="artist__name">
              {group.artists.map((a) => a.name).join(", ")}
            </div>
          </div>
        );
      })}
    </>
  );
};

/**
 *
 * @param {{
 *  track: Track,
 *  album: Album,
 *  onClose: Function
 * }} props
 * @returns
 */
const TrackCreditsDialog = ({ track, album, onClose }) => {
  const classes = useStyles();

  const artistsAlbum = album?.artists;
  const artistsTrack = track?.artists;

  const artistsGrouped = useMemo(() => {
    return mergeAndGroupArtists(artistsAlbum, artistsTrack);
  }, [artistsAlbum, artistsTrack]);

  if (!track) {
    return <></>;
  }

  return (
    <Dialog
      className={classes.rootCredits}
      open={true}
      aria-labelledby="Creditos"
      // @ts-ignore
      onClose={onClose}
    >
      <DialogTitle className="credits__title">
        <Typography className="credits__titleLabel">Créditos</Typography>
        <IconButton
          className="credits__close"
          aria-label="cerrar"
          onClick={() => onClose()}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <div className="credits__content">
        <div className="credits__trackName">{getTrackTitle(track, album)}</div>

        <div className="artist">
          <div className="artist__role">ISRC</div>
          <div className="artist__name">{track.isrc}</div>
        </div>

        <div className="credits__artistList">
          <ArtistsList groupRoles={artistsGrouped} />
        </div>
      </div>
    </Dialog>
  );
};

export default TrackCreditsDialog;
