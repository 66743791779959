import _clear from "./actions/clear";
import _setAlbum from "./actions/setAlbum";
import _setPhoto from "./actions/setPhoto";
import _setShowVersionField from "./actions/setShowVersionField";
import _setShowUpcField from "./actions/setShowUpcField";
import _setArtistRolesAvailables from "./actions/setArtistRolesAvailables";
import _trackOrder from "./actions/trackOrder";

import createNewArtist from "./actionsAsync/createNewArtist";
import initNewAlbum from "./actionsAsync/initNewAlbum";
import audioTrackRemove from "./actionsAsync/audioTrackRemove";
import audioTrackUpload from "./actionsAsync/audioTrackUpload";
import uploadPhoto from "./actionsAsync/uploadPhoto";
import removePhoto from "./actionsAsync/removePhoto";
import saveTemporally from "./actionsAsync/saveTemporally";
import submitNewAlbum from "./actionsAsync/submitNewAlbum";
import discardNewAlbum from "./actionsAsync/discardNewAlbum";

export const clearAction = _clear.action;
export const setAlbumAction = _setAlbum.action;
export const setPhotoAction = _setPhoto.action;
export const setArtistRolesAvailablesAction = _setArtistRolesAvailables.action;
export const setShowVersionFieldAction = _setShowVersionField.action;
export const setShowUpcFieldAction = _setShowUpcField.action;
export const trackOrderAction = _trackOrder.action;

export default {
  audioTrackRemove,
  audioTrackUpload,
  clearAction,
  setAlbumAction,
  setPhotoAction,
  setShowVersionFieldAction,
  setShowUpcFieldAction,
  setArtistRolesAvailablesAction,
  createNewArtist,
  uploadPhoto,
  removePhoto,
  saveTemporally,
  trackOrderAction,
  initNewAlbum,
  submitNewAlbum,
  discardNewAlbum,
};
