// @ts-check
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import cartActions from "../../../../../../reducers/cart/cartActions";
import MessageModal from "../MessageModal";
import ChangeValueRender from "./ChangeValueRender";

/** @typedef {import('react').ReactElement} ReactElement*/
/** @typedef {import("../../../../../../business-objects/cart").CartItem} CartItem*/
/** @typedef {import("../../../../../../business-objects/album.fieldEditable").FieldActionType} FieldActionType */

const useStyles = makeStyles({
  cartItem: {
    backgroundColor: "#FFFFFF",
    marginTop: "0.7rem",
    marginBottom: "0.7rem",
    padding: "0.5rem",
    paddingLeft: "0.75rem",
    paddingRight: "0.75rem",
    borderRadius: "2px",
    "& .cartItem-labelField": {
      fontWeight: "bold",
    },
  },
});

/**
 *
 * @param {FieldActionType} action
 * @return {string}
 */
const getLabelAction = (action) => {
  switch (action) {
    case "add":
      return "añadido";
    case "update":
      return "modificado";
    case "delete":
      return "eliminado";
    default:
      return "";
  }
};

/**
 *
 * @param {{change: CartItem}} param0
 * @return {ReactElement}
 */
const ChangeDetail = ({ change }) => {
  return (
    <div>
      {change.level === "track" && (
        <span> En track {change.numberTrack}, </span>
      )}
      <span className="cartItem-labelField">{change.labelField}</span>{" "}
      <span>{getLabelAction(change.action)}</span>
      <div>
        <ChangeValueRender change={change} variant="unedited" />
        <ChangeValueRender change={change} variant="new" />
      </div>
    </div>
  );
};

const ConfirmModal = ({ onConfirm, setOpenModal }) => {
  return (
    <MessageModal
      openModal
      setOpenModal={setOpenModal}
      title="Confirmar deshacer el cambio"
      description="¿Estas seguro de deshacer el cambio?"
      action={onConfirm}
    />
  );
};

/**
 *
 * @param {{change: CartItem, upc: string}} param0
 * @return {ReactElement}
 */
const ChangeItem = ({ upc, change, ...props }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const proceedDelete = async () => {
    setShowConfirm(false);
    await dispatch(cartActions.discardCartItem(upc, change));
  };

  const handleOnDelete = async () => {
    setShowConfirm(true);
  };

  return (
    <ListItem className={classes.cartItem} disabled={change.loading}>
      <ListItemText
        primary={<ChangeDetail change={change} />}
        secondary={`${change.creditsCost} credito(s)`}
      />
      <ListItemSecondaryAction>
        {change.loading ? (
          <CircularProgress />
        ) : (
          <IconButton
            edge="end"
            aria-label="delete"
            color="secondary"
            onClick={handleOnDelete}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </ListItemSecondaryAction>
      {showConfirm && (
        <ConfirmModal onConfirm={proceedDelete} setOpenModal={setShowConfirm} />
      )}
    </ListItem>
  );
};

export default ChangeItem;
