// @ts-check
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MUITextField from "@material-ui/core/TextField";
import { useFormikContext, ErrorMessage } from "formik";
import { TextField } from "formik-material-ui";
import { Autocomplete } from "formik-material-ui-lab";
import { Field, globalProps } from "../../../../../components-cross/Field";
import AutocompleteGenreField from "../../../../../components-cross/AutocompleteGenreField";
import useConfig from "../../../../../hooks/useConfig";

import Wizard from "./Wizard";
// import VersionField from "./StepAlbum.VersionField";
// import UpcField from "./StepAlbum.UpcField";
import ArtistsButtons from "./StepAlbum.ArtistsButtons";
import { ArtistPrimaryField, ArtistsFields } from "./StepAlbum.ArtistsFields";
import { setArtistRolesAvailablesAction } from "../../../../../reducers/wizardNewAlbum/actions/setArtistRolesAvailables";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  titleBlock: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
}));

export const StepAlbumArtists = () => {
  const config = useConfig();
  const [countries, setCountries] = useState([]);
  const dispatch = useDispatch();
  const { errors, isValid } = useFormikContext();

  const classes = useStyles();

  useEffect(() => {
    setCountries(config.countries?.map((country) => country.label));
  }, [config]);

  const onChangeGenre = (value) => {
    if (value) {
      dispatch(setArtistRolesAvailablesAction(value.genre, value.subgenre));
    }
  };

  return (
    <Wizard.Step>
      <Wizard.Heading
        title="3. Artistas"
        description="Aquellos que participan en todos los tracks."
      />

      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <ArtistPrimaryField />
        </Grid>

        <Grid item xs={12} md={6}>
          <Field
            name="country"
            component={Autocomplete}
            options={countries}
            renderInput={(params) => (
              <MUITextField
                {...params}
                error={!!errors["country"]}
                helperText={errors["country"] || "País del artista principal"}
                label="Pais"
                name="country"
                required
                fullWidth
                {...globalProps}
              />
            )}
          />
        </Grid>

        <ArtistsFields />

        <Grid item xs={12}>
          <ArtistsButtons />
        </Grid>

        {/* <Grid item xs={12}>
          <UpcField />
          <VersionField />
        </Grid> */}
      </Grid>
    </Wizard.Step>
  );
};

StepAlbumArtists.label = "Album";

StepAlbumArtists.validationSchema = yup.object({
  // title: yup.string().required(),
  // genreSubgenre: yup.mixed().required(),
  country: yup.mixed().required(),
  // photos: yup.object({
  //   cover: yup.mixed().required(),
  // }),
  // upc: yup.string().matches(/^\d{13}$/, "Debé ser un número de 13 cifras"),
  artists: yup.array().of(
    yup.object().shape({
      name: yup.string().required(),
    })
  ),
});

export default StepAlbumArtists;
