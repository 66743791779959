// @ts-check
import { newArtist } from "../wizardUtils";

/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */
/** @typedef {import("../../../business-objects/album").ArtistRole} ArtistRole */
/** @typedef {import('../wizardStore').NewAlbumArtist} NewAlbumArtist */

/**
 * @param {NewAlbumArtist} artist
 * @param {ArtistRole} role
 * @param {Array<NewAlbumArtist>} artists
 * @return {boolean}
 */
const setAsPrimary = (artist, role, artists) => {
  if (artist.primary) {
    return true;
  }

  const hasAPrimary = artists?.find((a) => a.primary);

  return !hasAPrimary && role.value === "primary_artist";
};

/**
 * Busca y retorna la config del sistema
 * @param {ArtistRole} role
 * @param {object} contextFormik
 * @return {Function}
 */
export function createNewArtist(role, contextFormik) {
  /**
   * @param {function} dispatch
   * @param {StateGetter} getState
   */
  return async function thunk(dispatch, getState) {
    const { values, setFieldValue } = contextFormik;

    const index = values.artists?.length || 0;
    const artist = newArtist(role);

    if (setAsPrimary(artist, role, values.artists)) {
      artist.country = values.country || "";
      artist.primary = true;
    }

    if (!values.artists) {
      setFieldValue("artists", []);
    }
    setFieldValue(`artists.${index}`, artist);

    return artist;
  };
}

export default createNewArtist;
