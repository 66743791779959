import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { firebase } from "../firebase";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import withAuthorization from "./withAuthorization";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    left: 0,
    top: 0,
    zIndex: 1101,
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.primary.main,
    overflow: "hidden",
  },
  verifyCard: {
    minWidth: 275,
    maxWidth: "90vw",
    margin: "auto",
    padding: "1rem",
    "& strong": {
      color: theme.palette.secondary.main,
    },
  },
}));

const EmailVerification = () => {
  const [snack, setSnack] = useState({});
  const [verified, setVerified] = useState(false);
  // const [user, setUser] = useState(null);

  useEffect(() => {
    if (firebase.auth.currentUser) setVerified(firebase.auth.currentUser.emailVerified);
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     user && setVerified(user.emailVerified);
  //   })();
  // }, [user]);

  const classes = useStyles();

  const handleVerifyEmail = async () => {
    // await auth.doSendVerificationEmail();
    setSnack({
      open: true,
      message: "Te hemos reenviado el email de verificación.",
      action: (
        <Button color="secondary" size="small" onClick={handleCloseSnack}>
          OK
        </Button>
      ),
    });
  };

  const handleCloseSnack = () => {
    setSnack({ open: false });
  };

  if (verified) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Card className={classes.verifyCard}>
        <CardContent>
          <Typography variant="h5" paragraph>
            Verificación de email
          </Typography>
          <Typography variant="subtitle1">
            Te hemos enviado un email a <strong>{firebase.auth.currentUser.email}</strong> para que verifiques tu email.
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" color="primary" onClick={handleVerifyEmail}>
            Reenviar correo
          </Button>
        </CardActions>
      </Card>

      <Snackbar
        {...snack}
        onClose={handleCloseSnack}
        ContentProps={{ "aria-describedby": "message-id" }}
        message={<span id="message-id">{snack.message}</span>}
      />
    </div>
  );
};

const authCondition = (authUser) => !!authUser;
export default withAuthorization(authCondition)(EmailVerification);
