// @ts-check
import { addDays } from "date-fns";

/**
 *
 * @param {import("./album").Album} album
 * @param {object} config
 * @return {{minDate?: Date, maxDate?: Date, minDateMessage?: string, maxDateMessage?: String}}
 */
export const getRangeReleaseDigital = (album, config) => {
  if (!album) {
    return {};
  }

  const configNextDays = config.album_release_digital_days || 3;

  return {
    minDate: addDays(new Date(), configNextDays),
    maxDate: undefined,
    minDateMessage: `Al menos ${configNextDays} dí­as a partir de hoy`,
    maxDateMessage: "",
  };
};

export default getRangeReleaseDigital;
