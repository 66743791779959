import { makeStyles } from "@material-ui/core";
import React from "react";
import ArtistsButtons from "./FormTrack.ArtistsButtons";
import FormTrackForm from "./FormTrack.Form";
import FormTrackArtists from "./FormTrack.Artists";

const useStyles = makeStyles((theme) => ({
  formTrack: {
    maxWidth: 1100,
    margin: "auto",
    position: "relative",
    paddingBottom: theme.spacing(5),

    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },

    "& .formTrack__formContainer": {
      width: "100%",
      paddingTop: "2rem",
      // paddingLeft: "2rem",
      // paddingRight: "2rem",
    },
    "& .formTrack__artistsContainer": {
      width: "100%",
      // paddingLeft: "2rem",
      // paddingRight: "2rem",
      marginBottom: "2rem",
    },
    "& .formTrack__rolesButtons": {
      width: "100%",
      display: "flex",
      padding: 0,
      // justifyContent: "center",
      // paddingBottom: theme.spacing(2),
    },
    "& .formTrack__creditsLine": {
      marginTop: "1rem",
      marginBottom: "1rem",
      // paddingLeft: "2rem",
      // paddingRight: "2rem",
      position: "sticky",
      top: "194px",
      zIndex: 2,
      width: "100%",
      height: "2rem",
      color: "#C4C4C4",
      backgroundColor: theme.palette.background.paper,

      "&-label": {
        backgroundColor: theme.palette.background.paper,
        display: "inline-block",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
      },
      "&-line": {
        display: "inline-block",
        width: "100%",
        borderTop: `1px solid #eee`,
        transform: "translateY(50%)",
      },
      [theme.breakpoints.down("sm")]: {
        top: "69px",
      },
    },
  },
}));

const FormTrack = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.formTrack}>
      <div className="formTrack__formContainer">
        <FormTrackForm />
      </div>

      <div className="formTrack__creditsLine">
        <hr className="formTrack__creditsLine-line" size="1" />
        <span className="formTrack__creditsLine-label">CRÉDITOS</span>
      </div>

      <div className="formTrack__artistsContainer">
        <FormTrackArtists />
      </div>

      <div className="formTrack__rolesButtons">
        <ArtistsButtons />
      </div>
    </div>
  );
};

export default FormTrack;
