import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import withAuthorization from "./withAuthorization";
import { withStyles } from "@material-ui/core/styles";
import { firebase, auth } from "../firebase";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "./Autocomplete";
import ScrollToTop from "./ScrollToTop";
import Button from "@material-ui/core/Button";
import UserAvatar from "./UserAvatar";
import VerifyAccount from "./VerifyAccount";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import { keys, get } from "lodash";

const styles = (theme) => ({
  root: {
    maxWidth: 1100,
    margin: "auto",
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(5),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    borderRadius: 10,
  },
  loading: {
    display: "flex",
  },
  avatarBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textField: {
    marginBottom: theme.spacing(4),
  },
  formPaper: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    borderRadius: 30,
    lineHeight: "1.5em",
  },
  paperInner: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  heading: {
    color: "black",
    marginTop: theme.spacing(5),
  },
  divider: {
    marginTop: theme.spacing(5),
  },
  topDivider: {
    marginBottom: theme.spacing(5),
  },
  verified: {
    backgroundColor: green[500],
    color: "white",
    ":hover": {
      backgroundColor: green[500],
    },
  },
  verifyPending: {
    backgroundColor: amber[500],
    color: "white",
    ":hover": {
      backgroundColor: amber[500],
    },
  },
  check: {
    marginBottom: theme.spacing(5),
    "& strong": {
      color: "black",
    },
    "& label > *": {
      fontSize: "1em",
      color: theme.palette.primary.main,
    },
  },
  toolbar: {
    marginTop: theme.spacing(5),
  },
});

const REQUIRED = [
  "displayName",
  "firstnames",
  "lastnames",
  "profile",
  "country",
  "phone",
];

class AccountPage extends Component {
  constructor(props) {
    super(props);
    this.verifyBlockRef = React.createRef();

    this.state = {
      firstnames: "",
      lastnames: "",
      photoID: "",
      photoURL: "",
      displayName: "",
      profile: "",
      profile_other: "",
      profileOtherId: "",
      country: "",
      phone: "",
      whatsapp: "",
      paypal: "",
      facebook: "",
      youtube: "",
      soundcloud: "",
      twitter: "",
      instagram: "",
      bio: "",
      verified: "",
      verified_when: "",
      current_password: "",
      new_password: "",
      new_password2: "",
      snack: {
        open: false,
        message: "",
        action: null,
      },
      changed: false,
      ready: false,
      loading: true,
    };
  }

  componentDidMount() {
    const uid = firebase.auth.currentUser.uid;

    // user
    firebase.db.ref(`/users/${uid}`).on("value", (snap) => {
      const state = {
        ...snap.val(),
        displayName: firebase.auth.currentUser.displayName,
        uid: firebase.auth.currentUser.uid,
        loading: false,
      };

      state.verified =
        typeof state.verified !== "undefined" ? state.verified : null;
      state.verified_when = state.verified_when || null;
      state.photoID = state.photoID || null;

      this.setState(state, this.validateAll);
    });
  }

  componentWillUnmount() {
    const uid = get(firebase, "auth.currentUser.uid", false);
    firebase.db.ref(`/users/${uid}`).off();
  }

  setFieldErrorState = (field, errorMsg) => {
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [field]: errorMsg,
      },
      ready: false,
    }));
  };

  clearFieldErrorState = (fields) => {
    const errorsToClear = fields.reduce((acc, field) => {
      acc[field] = null;
      return acc;
    }, {});

    this.setState(
      (prevState) => ({
        errors: {
          ...prevState.errors,
          ...errorsToClear,
        },
      }),
      this.updateIsReady
    );
  };

  validateByName = (name) => {
    // only work on required fields
    if (REQUIRED.indexOf(name) === -1) return true;

    const val = this.state[name];

    if (!val) {
      this.setFieldErrorState(name, "El campo no puede estar vacío");
      return false;
    } else if (val.trim && !val.trim()) {
      this.setFieldErrorState(name, "El campo no puede estar vacío");
      return false;
    } else if (name === "phone") {
      const phoneRule = /^[\d.()\-\s+]{9,20}$/;

      if (phoneRule.test(val)) {
        this.clearFieldErrorState([name]);
        return true;
      } else {
        this.setFieldErrorState(name, "El formato parece incorrecto");
        return false;
      }
    } else if (this.isSocial(name)) {
      console.log("validate social link");
    } else {
      this.clearFieldErrorState([name]);
      return true;
    }

    // // 1. Required fields should not be empty
    // if (value === "") {
    //   this.setFieldErrorState(name, "El campo no puede estar vacío");
    //   return false;
    // }

    // // 2. other, more specific problems
    // if (name === "phone") {
    //   const phoneRule = /^[\d.()\-\s]{8,20}$/;
    //   const isValid = phoneRule.test(value);

    //   if (!isValid) {
    //     this.setFieldErrorState("phone", "El formato parece incorrecto");
    //     return false;
    //   }
    // }

    // return true;
  };

  validatePassword = () => {
    const { new_password, new_password2 } = this.state;

    if (new_password && new_password2 && new_password !== new_password2) {
      this.setFieldErrorState("new_password", "Las contraseñas no coinciden");
      this.setFieldErrorState("new_password2", "Las contraseñas no coinciden");
    } else {
      this.clearFieldErrorState(["new_password", "new_password2"]);
    }
  };

  reset = () => {
    const uid = firebase.auth.currentUser.uid;

    // user
    firebase.db.ref(`users/${uid}`).once("value", (snap) =>
      this.setState({
        ...snap.val(),
        changed: false,
      })
    );
  };

  save = () => {
    const uid = firebase.auth.currentUser.uid;
    const { changed, displayName, photoURL, phone, banking, ...profile } =
      this.state;

    const profile_data = {
      ...profile,
      firstnames: profile.firstnames.trim(),
      lastnames: profile.lastnames.trim(),
      current_password: null,
      loading: null,
      new_password: null,
      new_password2: null,
      // photoID: null,
      // profile,
      // profile_other,
      ready: null,
      snack: null,
      banking: banking || null,
    };

    // db profile
    firebase.db.ref(`users/${uid}`).update(profile_data, () => {
      this.setState({ changed: false, ready: true });
    });

    // firebase user
    firebase.auth.currentUser.updateProfile({ displayName });
  };

  saveNewPassword = async () => {
    const { new_password, current_password } = this.state;

    try {
      const result = await auth.doPasswordUpdateLoggedIn(
        current_password,
        new_password
      );

      if (result.code === "auth/weak-password") {
        this.setFieldErrorState(
          "new_password",
          "La contraseña debe tener 6 caracteres como mínimo."
        );
      } else if (result.code === "auth/wrong-password") {
        this.setFieldErrorState(
          "current_password",
          "La contraseña actual no es correcta."
        );
      }
    } catch (error) {
      console.log(error.toString());
    }

    // all good
    this.setState(
      {
        snack: {
          open: true,
          message: "Contraseña actualizada",
        },
      },
      () => {
        this.setState({
          current_password: null,
          new_password: null,
          new_password2: null,
        });

        this.clearFieldErrorState([
          "current_passowrd",
          "new_password",
          "new_password2",
        ]);
      }
    );
  };

  isSocial = (name) => {
    return (
      name === "facebook" ||
      name === "youtube" ||
      name === "soundcloud" ||
      name === "twitter" ||
      name === "instagram"
    );
  };

  getPropPath = (propName) =>
    this.isSocial(propName) ? `socialNetworks/${propName}` : propName;

  handleChange = (event) => {
    const target = event.target;
    const propName = target.name;
    const value = !!target.checked ? target.checked : target.value;

    let propPath = propName;

    if (this.isSocial(propName)) {
      this.setState(
        {
          socialNetworks: { ...this.state.socialNetworks, [propName]: value },
          changed: true,
        },
        () => {
          this.validateByName(propName);
        }
      );
    } else {
      this.setState(
        {
          [propPath]: value,
          changed: true,
        },
        () => {
          this.validateByName(propName);
        }
      );
    }
  };

  handleChangePassword = ({ target }) => {
    this.setState({
      [target.name]: target.value,
    });

    this.validatePassword(target);
  };

  handleChangePaypal = (event) => {
    this.setState({
      banking: { ...this.state.banking, paypalEmail: event.target.value },
      changed: true,
    });
  };

  validateAll = () => {
    const errors = REQUIRED.filter((name) => this.validateByName(name));
    this.setState({ ready: errors.length === 0 });
  };

  updateIsReady = () => {
    const ready =
      keys(this.state.errors).filter((name) => {
        return Boolean(this.state.errors[name]); // true if there is error
      }).length === 0;

    this.setState({ ready });
  };

  handleCloseSnack = () => {
    this.setState({ snack: { open: false } });
  };

  handleScrollToVerifyblock = () => {
    this.verifyBlockRef.current.scrollIntoView();
  };

  render() {
    const { classes, config } = this.props;
    const {
      firstnames,
      lastnames,
      photoID,
      displayName = "",
      profile,
      profile_other,
      country,
      phone,
      whatsapp,
      // facebook,
      // youtube,
      // soundcloud,
      // twitter,
      // instagram,
      bio,
      changed,
      ready,
      verified,
      verified_when,
      current_password,
      new_password,
      new_password2,
      banking,
      // error_displayName,
      // error_firstnames,
      // error_lastnames,
      // error_profile,
      // error_profile_other,
      // error_country,
      // error_phone,
      // error_paypal,
      // error_facebook,
      // error_youtube,
      // error_soundcloud,
      // error_twitter,
      // error_instagram,
      // error_current_password,
      // error_new_password,
      // error_new_password2,
      errors = {},
      snack,
      loading,
      socialNetworks,
    } = this.state;

    const { facebook, youtube, soundcloud, twitter, instagram } =
      socialNetworks || {};

    if (loading) {
      return (
        <div className={classes.root}>
          <Paper elevation={2} className={classes.formPaper}>
            <Grid container spacing={3} className={classes.paperInner}>
              <Grid item xs={12}>
                <Typography variant="h4" className={classes.title}>
                  TU CUENTA
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.loading}>
                <CircularProgress variant="indeterminate" />
              </Grid>
            </Grid>
          </Paper>
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <Paper elevation={2} className={classes.formPaper}>
          <Fragment>
            <ScrollToTop />

            <Grid container spacing={5} className={classes.paperInner}>
              <Grid item xs={6}>
                <Typography variant="h4" className={classes.title}>
                  TU CUENTA
                </Typography>
              </Grid>
              <Grid item xs={6} align="right">
                {!verified && (
                  <Chip
                    icon={<Icon style={{ color: "white" }}>verified_user</Icon>}
                    label="sin verificar"
                    className={classes.notVerified}
                    onClick={this.handleScrollToVerifyblock}
                  />
                )}
                {verified === true && (
                  <Chip
                    icon={<Icon style={{ color: "white" }}>verified_user</Icon>}
                    label="verificada"
                    className={classes.verified}
                    onClick={this.handleScrollToVerifyblock}
                  />
                )}
                {verified === "pending" && (
                  <Chip
                    icon={<Icon style={{ color: "white" }}>timelapse</Icon>}
                    label="verificación en curso"
                    className={classes.verifyPending}
                    onClick={this.handleScrollToVerifyblock}
                  />
                )}
              </Grid>
            </Grid>

            <Divider className={classes.topDivider} />

            <Grid container spacing={5} className={classes.paperInner}>
              <Grid item xs={12} sm={6} className={classes.avatarBox}>
                <UserAvatar
                  size={250}
                  config={config} // <-- this should be gotten from UserAVatar component... make  a HOC
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  id="displayName"
                  name="displayName"
                  label="Nombre de usuario"
                  value={displayName || ""}
                  required={true}
                  onChange={this.handleChange}
                  error={!!errors.displayName}
                  helperText={errors.displayName}
                  className={classes.textField}
                  inputProps={{ "data-hj-whitelist": true }}
                  fullWidth
                />

                <TextField
                  id="firstnames"
                  name="firstnames"
                  label="Nombres"
                  value={firstnames}
                  required={true}
                  onChange={this.handleChange}
                  error={!!errors.firstnames}
                  helperText={errors.firstnames}
                  inputProps={{ "data-hj-whitelist": true }}
                  className={classes.textField}
                  fullWidth
                />

                <TextField
                  id="lastnames"
                  name="lastnames"
                  label="Apellidos"
                  value={lastnames}
                  required={true}
                  onChange={this.handleChange}
                  error={!!errors.lastnames}
                  helperText={errors.lastnames}
                  inputProps={{ "data-hj-whitelist": true }}
                  className={classes.textField}
                  fullWidth
                />

                <TextField
                  select
                  id="profile"
                  name="profile"
                  label="Perfil"
                  className={classes.textField}
                  onChange={this.handleChange}
                  fullWidth
                  value={profile}
                  required={true}
                  inputProps={{ "data-hj-whitelist": true }}
                  error={!!errors.profile}
                  helperText={errors.profile}
                >
                  {config.profiles.map((option) => (
                    <MenuItem key={option.label} value={option.label}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                {profile !== "Otro" ? (
                  <Grid item xs={12} sm={6}></Grid>
                ) : (
                  <TextField
                    id="profile-other"
                    name="profile_other"
                    label="Por favor, especifica..."
                    value={profile_other}
                    onChange={this.handleChange}
                    className="TextField"
                    required={true}
                    error={!!errors.profile_other}
                    helperText={errors.profile_other}
                    fullWidth
                    inputProps={{ "data-hj-whitelist": true }}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                <TextField
                  multiline
                  id="bio"
                  name="bio"
                  label="Bio"
                  onChange={this.handleChange}
                  fullWidth
                  value={bio}
                  inputProps={{ "data-hj-whitelist": true }}
                  helperText="Cuéntale al mundo algo sobre tí..."
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <Autocomplete
                  id="country"
                  name="country"
                  label="País"
                  allopts={config.countries}
                  value={country}
                  onChange={this.handleChange}
                  required={true}
                  error={!!errors.country}
                  helperText={errors.country}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="phone"
                  name="phone"
                  type="phone"
                  label="Teléfono"
                  value={phone}
                  onChange={this.handleChange}
                  // required={true}
                  error={!!errors.phone}
                  helperText={errors.phone}
                  inputProps={{ "data-hj-whitelist": true }}
                  disabled={true}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <FormControlLabel
                  label={`WhatsApp OK${whatsapp ? "!" : "?"}`}
                  control={
                    <Switch
                      name="whatsapp"
                      onChange={this.handleChange}
                      checked={!!whatsapp}
                      color="primary"
                      inputProps={{ "data-hj-whitelist": true }}
                    />
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="paypal"
                  name="paypal"
                  type="email"
                  label="Email de PayPal"
                  value={banking?.paypalEmail}
                  onChange={this.handleChangePaypal}
                  inputProps={{ "data-hj-whitelist": true }}
                  error={!!errors.paypal}
                  helperText={errors.paypal}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Divider className={classes.divider} />

            <Grid container spacing={5} className={classes.paperInner}>
              <Grid item xs={12}>
                <Typography variant="h4" className={classes.heading}>
                  Perfiles sociales
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="facebook"
                  name="facebook"
                  label="Facebook"
                  value={facebook}
                  onChange={this.handleChange}
                  error={!!errors.facebook}
                  helperText={errors.facebook}
                  fullWidth
                  inputProps={{ "data-hj-whitelist": true }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="youtube"
                  name="youtube"
                  label="YouTube"
                  value={youtube}
                  onChange={this.handleChange}
                  error={!!errors.youtube}
                  helperText={errors.youtube}
                  fullWidth
                  inputProps={{ "data-hj-whitelist": true }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="soundcloud"
                  name="soundcloud"
                  label="SoundCloud"
                  value={soundcloud}
                  onChange={this.handleChange}
                  error={!!errors.soundcloud}
                  helperText={errors.soundcloud}
                  fullWidth
                  inputProps={{ "data-hj-whitelist": true }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="twitter"
                  name="twitter"
                  label="Twitter"
                  value={twitter}
                  onChange={this.handleChange}
                  error={!!errors.twitter}
                  helperText={errors.twitter}
                  fullWidth
                  inputProps={{ "data-hj-whitelist": true }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="instagram"
                  name="instagram"
                  label="Instagram"
                  value={instagram}
                  onChange={this.handleChange}
                  error={!!errors.instagram}
                  helperText={errors.instagram}
                  fullWidth
                  inputProps={{ "data-hj-whitelist": true }}
                />
              </Grid>

              <Grid item xs={12} align="right" className={classes.toolbar}>
                <Button
                  disabled={!changed}
                  onClick={this.reset}
                  className={classes.button}
                >
                  Cancelar
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.save}
                  className={classes.button}
                  disabled={!ready || !changed}
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>

            <div ref={this.verifyBlockRef} />
            <Divider className={classes.divider} />

            <Grid container spacing={5} className={classes.paperInner}>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  className={classes.heading}
                  gutterBottom
                >
                  Verificar cuenta
                </Typography>

                <br />

                <VerifyAccount
                  url={photoID}
                  config={config} // <-- this should be gotten from UserAVatar component... make  a HOC
                  verified={verified}
                  verified_when={verified_when}
                  allFieldsOK={ready && !changed}
                />
              </Grid>
            </Grid>

            <Divider className={classes.divider} />

            <Grid container spacing={5} className={classes.paperInner}>
              <Grid item xs={12}>
                <Typography variant="h4" className={classes.heading}>
                  Cambiar contraseña
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="current_password"
                  name="current_password"
                  label="Contraseña actual"
                  value={current_password}
                  type="password"
                  onChange={this.handleChangePassword}
                  onBlur={this.validatePassword}
                  required={true}
                  error={!!errors.current_password}
                  helperText={errors.current_password}
                  autoComplete="off"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="new_password"
                  name="new_password"
                  label="Nueva contraseña"
                  value={new_password}
                  type="password"
                  onChange={this.handleChangePassword}
                  onBlur={this.validatePassword}
                  required={true}
                  error={!!errors.new_password}
                  helperText={errors.new_password}
                  autoComplete="off"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}></Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="new_password2"
                  name="new_password2"
                  label="Repite la contraseña"
                  value={new_password2}
                  type="password"
                  onChange={this.handleChangePassword}
                  onBlur={this.validatePassword}
                  required={true}
                  error={!!errors.new_password2}
                  helperText={errors.new_password2}
                  autoComplete="off"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} align="right" className={classes.toolbar}>
                <Button
                  disabled={
                    !new_password ||
                    !new_password2 ||
                    new_password !== new_password2
                  }
                  onClick={() =>
                    this.setState({ new_password: "", new_password2: "" })
                  }
                  className={classes.button}
                >
                  Cancelar
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.saveNewPassword}
                  className={classes.button}
                  disabled={
                    !new_password ||
                    !new_password2 ||
                    new_password !== new_password2
                  }
                >
                  Cambiar contraseña
                </Button>
              </Grid>
            </Grid>
          </Fragment>
        </Paper>

        <Snackbar
          {...snack}
          onClose={this.handleCloseSnack}
          ContentProps={{ "aria-describedby": "message-id" }}
          message={<span id="message-id">{snack.message}</span>}
        />
      </div>
    );
  }
}

/**
 * @param {StateStore} state
 * @return {object}
 */
const mapStateToProps = (state) => ({
  config: state.configState.config,
});

const authCondition = (authUser) => !!authUser;

export default compose(
  withAuthorization(authCondition),
  withStyles(styles),
  connect(mapStateToProps)
)(AccountPage);
