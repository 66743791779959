import React, { useCallback, useState } from "react";
import { get, debounce } from "lodash";
import MUITextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Field, useFormikContext } from "formik";
import { Autocomplete as AutocompleteFormik } from "formik-material-ui-lab";
import CircularProgress from "@material-ui/core/CircularProgress";

import useConfig from "../hooks/useConfig";

const LIMIT_SEARCH_ITEMS = 50;

const calcPoints = (option, words, entirePhrase) => {
  const { subgenre, genre } = option;

  const totalPoints = words.reduce((points, word) => {
    const contains = new RegExp(word, "i");

    if (subgenre === entirePhrase) {
      points += 2;
    }

    if (word === subgenre) {
      points += 1;
    } else if (contains.test(subgenre)) {
      points += 0.75;
    }

    if (word === genre) {
      points += 0.75;
    } else if (contains.test(genre)) {
      points += 0.5;
    }
    return points;
  }, 0);
  return totalPoints;
};

const getOptionSelected = (option, queryString) => {
  const { subgenre, genre } = option;
  const contains = new RegExp(queryString, "i");
  const matched = contains.test(subgenre) || contains.test(genre);
  return matched;
};

/**
 * @param {Array<any>} rows;
 * @param {Array<any>} rows;
 */
const limit = (rows) => {
  if (!rows) {
    return rows;
  }

  return rows.slice(0, LIMIT_SEARCH_ITEMS);
};

function matchSortByPoints(rows, queryString) {
  if (!queryString || !queryString.length) {
    return limit(rows);
  }

  const terms = queryString.split(" ");
  if (!terms) {
    return rows;
  }

  let matches = [];
  rows.forEach((option) => {
    const points = calcPoints(option, terms, queryString);
    if (points > 0) {
      matches.push({ points, option });
    }
  });
  matches = matches.sort((a, b) => b.points - a.points);
  matches = limit(matches);
  const selected = matches.map((item) => item.option);

  return selected;
}

const getOptionLabel = (option, genre, subgenre) => {
  let obj = option;
  if (!obj.subgenre && genre && subgenre) {
    obj = {
      genre,
      subgenre,
    };
  }

  if (obj.subgenre) {
    return `${obj.genre} / ${obj.subgenre}`;
  } else {
    return obj;
  }
};

const renderOption = (option) => {
  return (
    <div>
      <Typography variant="caption" color="textSecondary">
        {option.genre}
      </Typography>
      <Typography color="textPrimary">{option.subgenre}</Typography>
    </div>
  );
};

export const AutocompleteGenre = ({
  label,
  name,
  value,
  error = false,
  helperText = "",
  InputProps = {},
  onChange = null,
  required = false,
  isFormikContext = false,
  ...restProps
}) => {
  const config = useConfig();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const genre = value?.genre || "";
  const subgenre = value?.subgenre || "";

  const subgenres = config?.subgenres || [];

  const searchOptions = useCallback(
    debounce((valueText) => {
      const matched = matchSortByPoints(subgenres, valueText);
      setOptions(matched);
      setLoading(false);
    }, 500),
    []
  );

  const handleChangeQuery = (e) => {
    const valueText = e.target.value;
    setLoading(true);
    setOpen(true);
    searchOptions(valueText);
  };

  const handleOnChange = (e, selected, reason) => {
    if (onChange) {
      onChange(e, selected, reason);
    }
  };

  // TODO: Is there a better way to resolve this?
  const AutocompleteComp = isFormikContext ? AutocompleteFormik : Autocomplete;

  return (
    <AutocompleteComp
      {...restProps}
      value={value}
      options={options}
      freeSolo={false}
      open={open}
      loading={loading}
      onOpen={() => {
        if (options?.length > 0) {
          setOpen(true);
        }
      }}
      onClose={() => setOpen(false)}
      handleHomeEndKeys={false}
      filterOptions={(opts) => opts}
      getOptionLabel={(option) => getOptionLabel(option, genre, subgenre)}
      getOptionSelected={getOptionSelected}
      onChange={handleOnChange}
      renderOption={renderOption}
      renderInput={(params) => (
        <MUITextField
          {...params}
          variant="outlined"
          required={required}
          fullWidth
          error={error}
          helperText={helperText}
          onChange={handleChangeQuery}
          label={label}
          name={name}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress color="inherit" size={18} />}
                {params.InputProps?.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export const AutocompleteGenreField = ({ label, name, onChange }) => {
  const { touched, values, errors, setFieldValue } = useFormikContext();
  const touch = get(touched, name);
  const error = get(errors, name);

  const handleOnChange = (e, newValue, reason) => {
    setFieldValue(name, newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const value = values[name];

  return (
    <Field
      component={AutocompleteGenre}
      label={label}
      name={name}
      fullWidth
      required
      error={touch && !!error}
      helperText={touch && error}
      onChange={handleOnChange}
      isFormikContext={true}
      value={value}
    />
  );
};

export default AutocompleteGenreField;
