import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { firebase, db, functions } from "../firebase";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import upperCase from "lodash/upperCase";
import { withRouter } from "react-router-dom";
import * as routes from "../constants/routes";
import isAdmin from "../utils/isAdmin";
import isSafeAdmin from "../utils/isSafeAdmin";
import canAccess from "../utils/canAccess";
import { MANAGE_ROYALTIES } from "../constants/routes";

const styles = (theme) => ({
  userBox: {
    padding: theme.spacing(5),
    minWidth: 300,
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    width: 90,
    height: 90,
    marginBottom: theme.spacing(1),
    // marginRight: theme.spacing(2),
  },
  avatarNoPhoto: {
    width: 90,
    height: 90,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
    color: "#fff",
    fontWeight: 900,
    backgroundColor: theme.palette.secondary.main,
    fontSize: "3rem",
  },
  menuButton: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(1.5),
  },
  email: {
    fontSize: ".85rem",
  },
  list: {
    // minWidth: 300,
    // maxWidth: '100%',
  },
  exit: {
    alignSelf: "flex-end",
  },
});

class UserMenu extends React.Component {
  state = {
    open: false,
    submitedTracksData: [],
    updatingTracksData: [],
    paymentData: [],
  };

  openDrawer = () => this.setState({ open: true });
  closeDrawer = () => this.setState({ open: false });

  navigate = (event) => {
    const { history } = this.props;
    const item = event.currentTarget.getAttribute("name");
    history.push(item);
    this.closeDrawer();
  };

  goToHelpSite = () => {
    window.open("https://subidalibre.tawk.help/");
    this.closeDrawer();
  };

  async componentDidMount() {
    const siteName = await db.getAccountName();
    this.setState({ siteName });
  }

  render() {
    const { classes, config } = this.props;

    if (!firebase.auth.currentUser) return null;

    const { email, displayName, photoURL } = firebase.auth.currentUser;

    return (
      <div>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          aria-label="Menu"
          onClick={this.openDrawer}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          open={this.state.open}
          onClose={this.closeDrawer}
          className={classes.drawer}
        >
          <div tabIndex={0} role="button" onKeyDown={this.closeDrawer}>
            <List component="nav" className={classes.list}>
              <ListItem
                button
                name={routes.ACCOUNT}
                className={classes.userBox}
                onClick={this.navigate}
              >
                {photoURL ? (
                  <Avatar src={photoURL} className={classes.avatar} />
                ) : (
                  <Avatar className={classes.avatarNoPhoto}>
                    {upperCase(
                      displayName ? displayName.charAt(0) : email.charAt(0)
                    )}
                  </Avatar>
                )}
                <Typography variant="h6">
                  {displayName ? displayName : email}
                </Typography>
                <Typography className={classes.email}>{email}</Typography>
              </ListItem>

              <Divider />

              <ListItem button name={routes.ALBUMS} onClick={this.navigate}>
                <ListItemIcon>
                  <Icon>apps</Icon>
                </ListItemIcon>
                <ListItemText primary="Música" />
              </ListItem>

              <ListItem button name={routes.UPLOAD} onClick={this.navigate}>
                <ListItemIcon>
                  <Icon>publish</Icon>
                </ListItemIcon>
                <ListItemText primary="Subir música" />
              </ListItem>

              {/* <ListItem button name={routes.REPORTING} onClick={this.navigate}>
                <ListItemIcon>
                  <Icon>show_chart</Icon>
                </ListItemIcon>
                <ListItemText primary="Regalías" />
              </ListItem>

              <ListItem button name={routes.PAYMENTS} onClick={this.navigate}>
                <ListItemIcon>
                  <Icon>attach_money</Icon>
                </ListItemIcon>
                <ListItemText primary="Pagos" />
              </ListItem> */}

              <ListItem button name={routes.ROYALTIES} onClick={this.navigate}>
                <ListItemIcon>
                  <Icon>attach_money</Icon>
                </ListItemIcon>
                <ListItemText primary="Ingresos" />
              </ListItem>

              {/* <ListItem
                button
                name={routes.ROYALTIES}
                onClick={this.navigate}
                disabled
              >
                <ListItemIcon>
                  <Icon>show_chart</Icon>
                </ListItemIcon>
                <ListItemText
                  primary="Lanzamiento destacado"
                  secondary="¡Próximamente!"
                />
              </ListItem> */}

              <ListItem button name={routes.ACCOUNT} onClick={this.navigate}>
                <ListItemIcon>
                  <Icon>account_circle</Icon>
                </ListItemIcon>
                <ListItemText primary="Tu cuenta" />
              </ListItem>

              {this.state.siteName === "Subidalibre" && (
                <ListItem
                  button
                  name={routes.ACCOUNT}
                  onClick={this.goToHelpSite}
                >
                  <ListItemIcon>
                    <Icon>help</Icon>
                  </ListItemIcon>
                  <ListItemText primary="Ayuda" />
                </ListItem>
              )}

              {(isAdmin() || isSafeAdmin()) && (
                <>
                  <Divider />
                  <ListItem
                    button
                    name={routes.MANAGE_USERS}
                    onClick={this.navigate}
                  >
                    <ListItemIcon>
                      <Icon>lock</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Admin users" />
                  </ListItem>

                  <ListItem
                    button
                    name={routes.MANAGE_ACCOUNTS}
                    onClick={this.navigate}
                  >
                    <ListItemIcon>
                      <Icon>business</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Admin accounts" />
                  </ListItem>

                  <ListItem
                    button
                    name={routes.MANAGE_ROYALTIES}
                    onClick={this.navigate}
                  >
                    <ListItemIcon>
                      <Icon>attach_money</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Admin ingresos" />
                  </ListItem>

                  <ListItem
                    button
                    name={routes.MANAGE_ARTISTS}
                    onClick={this.navigate}
                  >
                    <ListItemIcon>
                      <Icon>color_lens</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Admin artists" />
                  </ListItem>

                  <ListItem
                    button
                    name={routes.BLACKLIST}
                    onClick={this.navigate}
                  >
                    <ListItemIcon>
                      <Icon>block</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Blacklist" />
                  </ListItem>
                </>
              )}
            </List>
          </div>
        </Drawer>
      </div>
    );
  }
}

/**
 * @param {StateStore} state
 * @return {object}
 */
const mapStateToProps = (state) => ({
  config: state.configState.config,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(withRouter(UserMenu));
