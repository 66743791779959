// @ts-check
import produce from "immer";

/** @typedef {import('../wizardStore').WizardNewAlbumState} WizardNewAlbumState */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */

const TYPE = "WIZARD_SET_NEW_ALBUM";

/**
 * @param {Object} album
 * @param {Number} nextStep
 * @return {Object}
 */
export const setAlbumAction = (album, nextStep) => {
  return {
    type: TYPE,
    album,
    nextStep,
  };
};

/**
 * @param {WizardNewAlbumState} state
 * @param {object} action
 * @return {object}
 */
export const setAlbumReducer = (state, action) => {
  return produce(state, (draft) => {
    draft.album = action.album;
    draft.activeStep = action.nextStep;
  });
};

export default {
  action: setAlbumAction,
  reducer: setAlbumReducer,
  TYPE,
};
