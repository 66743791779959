// @ts-check
import { db } from "../../../firebase";
import storageService from "../../../services/storageService.js";
import { processAlbumChange } from "../../cart/cartActions";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album").Track} Track */
/** @typedef {import("../../../business-objects/album").Photo} Photo */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldActionType} FieldActionType */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */

/**
 * @param {Photo} newCover
 * @param {boolean} isUpdatingAlbum*
 * @returns
 */
const makeUpdatesCoverMetadata = (newCover, isUpdatingAlbum) => {
  // update track metadata
  const updates = {
    [`photos/cover/storageLocation`]: newCover.storageLocation,
    [`photos/cover/url`]: newCover.url,
  };

  if (isUpdatingAlbum) {
    updates[`photos/cover/fileChanged`] = true;
  }

  return updates;
};

/**
 * Add a field to track
 * @param {Album} album
 * @param {File} file
 * @param {Function?} onProgress
 * @return {Function}
 */
export const coverReplaceUpload = (album, file, onProgress = null) => {
  /**
   * @param {function} dispatch
   */
  return async function thunk(dispatch, getState) {
    /** @type {StateStore} */
    const state = getState();
    const config = state.configState.config;
    let pathFolder;
    let bucket;
    let fileName;

    switch (album.status) {
      case "live_updating":
        bucket = config.buckets.uploading.url;
        pathFolder = `${album.subaccount_user}/${album.upc}`;
        fileName = "art-cover.jpg";
        break;

      case "draft":
        bucket = config.buckets.uploaded.url;
        pathFolder = `${album.subaccount_user}/${album.upc}`;
        fileName = "art-cover.jpg";
        break;

      default:
        bucket = config.buckets.uploading.url;
        pathFolder = "";
        fileName = album.photos?.cover?.storageLocation || "";
        break;
    }

    const newFileCover = await storageService.uploadFile(
      pathFolder,
      fileName,
      file,
      (snap) => {
        if (onProgress) {
          onProgress(snap.bytesTransferred, snap.totalBytes);
        }
      },
      bucket
    );

    // UPDATE FIELD VALUE
    const { upc } = album;

    const isUpdating = album.status === "live_updating";
    const updates = makeUpdatesCoverMetadata(
      {
        ...newFileCover,
        extension: "jpg",
      },
      isUpdating
    );
    await db.updateAlbumFields(upc, updates);

    // SEND TO CART CHANGES
    /** @type {FieldEditable} */
    const coverField = {
      path: "photos/cover/url",
      label: "Foto de Portada",
      type: "cover",
      level: "album",
    };
    await dispatch(
      processAlbumChange(upc, coverField, "update", newFileCover.url)
    );
  };
};
