//@ts-check
import React, { useMemo, useState } from "react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { format, parse, isDate } from "date-fns";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from "@date-io/date-fns";
import useEditableField from "../hooks/useEditableField";

/** @typedef {import("../../../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable*/
/** @typedef {import("../../../../../business-objects/album.fieldEditable").FieldLevelType} FieldLevelType*/
/** @typedef {import("../../../../../business-objects/album.fieldEditable").FieldTypes} FieldTypes*/

/**
 * @param {{
 *  label: string,
 *  name: string,
 *  type: FieldTypes,
 *  level: FieldLevelType,
 *  minDate?: Date,
 *  minDateMessage?: String,
 *  maxDate?: Date,
 *  required?: boolean;
 *  maxDateMessage?: String,
 *  defaultValue?: Date
 * }} props
 */
export const EditableDate = (props) => {
  const [open, setOpen] = useState(false);
  const { label, value, setValue, error, setError, required } =
    useEditableField({ ...props, skipInteralValidation: true }); // the validation is done by the DatePicker

  const { minDate, maxDate, minDateMessage, maxDateMessage } = props;

  /** @type {Date | null} */
  //@ts-ignore
  const valueDate = useMemo(() => {
    if (!value) {
      return null;
    }
    if (isDate(value)) {
      return value;
    }

    const refDate = new Date();

    // @ts-ignore
    let date = parse(value, "yyyy-MM-dd", refDate);
    if (isNaN(date?.getTime())) {
      // @ts-ignore
      date = parse(value, "yyyy/MM/dd", refDate);
    }

    return date;
  }, [value]);

  const handleClose = () => {
    setOpen(!open);
  };

  const handleOnError = (e) => {
    const strError = e.toString();
    if (strError && strError !== error) {
      setError(strError);
    }
  };

  const handleDateChange = (date) => {
    if (date) {
      try {
        const newValue = format(date, "yyyy-MM-dd");

        setError("");
        setValue(newValue);
      } catch (e) {
        setError("La fecha no es válida");
      }
    } else {
      setValue("");
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
      <KeyboardDatePicker
        allowKeyboardControl={false}
        inputVariant="outlined"
        okLabel="Aceptar"
        clearLabel="Limpiar"
        cancelLabel="Cancelar"
        todayLabel="Hoy"
        autoOk
        open={open}
        name="date"
        format="dd/MM/yyyy"
        invalidDateMessage="La fecha no es válida"
        invalidLabel="La fecha no es válida"
        error={!!error}
        helperText={error}
        label={label}
        value={valueDate}
        minDate={minDate}
        minDateMessage={minDateMessage}
        maxDate={maxDate}
        maxDateMessage={maxDateMessage}
        required={required}
        fullWidth={true}
        onChange={handleDateChange}
        onClose={handleClose}
        onOpen={handleClose}
        onAccept={() => setError("")}
        onError={handleOnError}
      />
    </MuiPickersUtilsProvider>
  );
};

export default EditableDate;
