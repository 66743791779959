//@ts-check
import { useMemo, useState, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, ButtonGroup, makeStyles } from "@material-ui/core";
import { Link as RouterLink, useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import cartActions from "../../../../../reducers/cart/cartActions";
import ConfirmationDialog from "../../Edit/components/ConfirmationDialog";
import albumActions from "../../../../../reducers/albumActions";
import keys from "lodash/keys";
import ExtendedUserContext from "../../../../../contexts/ExtendedUserContext";
import * as routes from "../../../../../constants/routes";
import SocialInsufficiencyDialog from "./SocialInsufficiencyDialog";
import Smartlink from "./Smartlink";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "sticky",
    zIndex: 3,
  },
  editButton: {
    height: "36px",
  },
  "@media (max-width: 600px)": {
    root: {
      // top: theme.spacing(7),
      // marginTop: theme.spacing(7),
    },
    editButton: {
      "& .MuiButton-startIcon": {
        marginLeft: "0",
        marginRight: "0",
      },
    },
  },
  inner: {
    margin: "auto",
    maxWidth: 1100,
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  statusPublish: {
    display: "flex",
    alignItems: "center",
    "& > * ": {
      marginLeft: "1rem",
    },
  },
  status: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  button: {
    borderRadius: "16px",
  },
  // descartar: {
  //   color: red[700],
  // },
}));

const ToolbarAlbumPreview = ({ album, vertical }) => {
  const [showConfirmDiscard, setShowConfirmDiscard] = useState(false);
  const [isDiscarting, setIsDiscarting] = useState(false);
  const [showSocialInsufficiency, setShowSocialInsufficiency] = useState(false);
  const [socialNetworks, setSocialNetworks] = useState({});

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useContext(ExtendedUserContext);

  useEffect(() => {
    if (user) {
      if (user.socialNetworks) {
        setSocialNetworks(user.socialNetworks);
      } else {
        const legacySupported = [
          "facebook",
          "instagram",
          "twitter",
          "youtube",
          "soundcloud",
        ];

        const legacySocial = {};

        legacySupported.forEach((key) => {
          if (user[key]) {
            legacySocial[key] = user[key];
          }
        });

        setSocialNetworks(legacySocial);
      }
    }
  }, [user]);

  const isDraft = useMemo(
    () => album.status === "draft" || album.status === "live_updating",
    [album.status]
  );

  const isSmartlinkReady = useMemo(
    () =>
      ["live", "scheduled", "processing", "delay", "delay2"].includes(
        album.status.trim()
      ),
    [album.status]
  );

  const handleOnPublish = () => {
    if (album.status === "live_updating") {
      dispatch(cartActions.setShowDetailPanel(true));
    } else if (album.status === "draft") {
      // Validar que haya al menos 1 track
      if (keys(album.tracks).length === 0) {
        toast.error(`Añade tracks a tu album para poder publicarlo. (EDITAR)`);
        return;
      }

      // Validar que todos los tracks tengan audio
      const sinAudio = keys(album.tracks).filter(
        (isrc) => !album.tracks[isrc]?.storageLocation
      );

      if (sinAudio.length) {
        sinAudio.forEach((isrc) => {
          toast.error(
            `El track ${album.tracks[isrc].title} no tiene archivo de audio`
          );
        });
        return;
      }

      // Validar que el usuario tenga redes sociales
      const MIN_REDES = 5;

      if (user) {
        if (keys(socialNetworks).length >= MIN_REDES) {
          setShowSocialInsufficiency(false);

          history.push(
            routes.ALBUM_VIEW_PUBLISHLEGAL.replace(":upc", album.upc)
          );
        } else {
          setShowSocialInsufficiency(true);
        }
      }
    }
  };

  // const

  const doDiscardNewAlbum = async () => {
    try {
      setIsDiscarting(true);
      await dispatch(albumActions.discardAlbum());
      history.push(routes.ALBUMS);
    } catch (e) {
      toast.error("Ops! no se pudo descartar el album");
      console.error(e);
      setShowConfirmDiscard(false);
    } finally {
      setIsDiscarting(false);
    }
  };

  const handleOnDiscard = () => {
    setShowConfirmDiscard(true);
  };

  return (
    <Box className={classes.root}>
      <div className={classes.inner}>
        {showConfirmDiscard && (
          // @ts-ignore
          <ConfirmationDialog
            onOk={doDiscardNewAlbum}
            okLabel="Descartar album"
            onCancel={() => setShowConfirmDiscard(false)}
            title="Confirmar"
            message="¿Está seguro que desea descartar? Se borran los datos y archivos subidos"
            loading={isDiscarting}
          />
        )}

        <SocialInsufficiencyDialog
          user={user}
          open={showSocialInsufficiency}
          handleClose={() => setShowSocialInsufficiency(false)}
          socialNetworks={socialNetworks}
          nextStep={routes.ALBUM_VIEW_PUBLISHLEGAL.replace(":upc", album.upc)}
        />

        <div className={classes.statusPublish}>
          <Box
            style={{
              display: "flex",
              gap: "1em",
              flexDirection: vertical ? "column" : "row",
            }}
          >
            {isDraft && (
              <Button
                color="primary"
                className={classes.descartar}
                onClick={handleOnDiscard}
              >
                DESCARTAR
              </Button>
            )}

            <ButtonGroup
              variant="contained"
              orientation={vertical ? "vertical" : "horizontal"}
              aria-label="contained primary button group"
            >
              {isDraft && (
                <Button
                  color="primary"
                  // variant="contained"
                  // className={classes.editButton}
                  component={RouterLink}
                  to={routes.ALBUM_EDIT.replace(":upc", album.upc)}
                >
                  EDITAR
                </Button>
              )}

              {isDraft && (
                <Button
                  color="primary"
                  // variant="contained"
                  onClick={handleOnPublish}
                >
                  PUBLICAR
                </Button>
              )}

              {isSmartlinkReady && <Smartlink album={album} />}
            </ButtonGroup>
          </Box>
        </div>
      </div>
    </Box>
  );
};

export default ToolbarAlbumPreview;
