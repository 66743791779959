//@ts-check
import { makeStyles, Slide } from "@material-ui/core";
import React, { useEffect } from "react";
import FormTrack from "./components/FormTrack";
import HeaderTrack from "./components/HeaderTrack";
import TrackNav from "./components/TrackNav";
import TrackAudioDropzone from "./components/TrackAudioDropzone";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    // maxWidth: 1100,
    margin: theme.spacing(8, "auto", 5, "auto"),
    // marginTop: theme.spacing(8),
    // marginBottom: theme.spacing(5),
    backgroundColor: theme.palette.background.paper,
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(7),
    },
  },
  headerTrackBackground: {
    background:
      "linear-gradient(180deg, #F6C358 0%, rgba(246, 195, 88, 0.38) 100%)",
    // width: "100%",
    // height: "100px",
  },
}));

const TrackEditor = ({ album, track }) => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div className={classes.root}>
      <Slide direction="up" in={true} mountOnEnter unmountOnExit>
        <div>
          <div className={classes.headerTrackBackground}>
            <TrackNav />
            <HeaderTrack
              // @ts-ignore
              album={album}
              // @ts-ignore
              track={track}
            />
          </div>
          {track.storageLocation ? (
            <FormTrack />
          ) : (
            <TrackAudioDropzone album={album} track={track} />
          )}
        </div>
      </Slide>
    </div>
  );
};

export default TrackEditor;
