// @ts-check
import produce from "immer";

/** @typedef {import('../cartStore').CartState} CartState */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */

const TYPE = "SET_SAVING_CART";

/**
 *
 * @param {boolean} saving
 * @return {object}
 */
export const setSavingAction = (saving) => {
  return {
    type: TYPE,
    saving,
  };
};

/**
 * @param {CartState} state
 * @param {object} action
 * @return {object}
 */
export const setSavingReducer = (state, action) => {
  return produce(state, (draft) => {
    draft.saving = action.saving;
  });
};

export default {
  action: setSavingAction,
  reducer: setSavingReducer,
  TYPE,
};
