export default (file) => {
  let ext = "";

  switch (file.type) {
    case "audio/mpeg":
      ext = "mp3";
      break;
    case "video/mp4":
    case "audio/mp4":
      ext = "mp4";
      break;
    case "audio/x-aiff":
      ext = "aiff";
      break;
    case "audio/flac":
      ext = "flac";
      break;
    case "audio/wav":
    case "audio/x-wav":
    case "audio/wave":
      ext = "wav";
      break;
    case "audio/ogg":
      ext = "ogg";
      break;
    case "video/webm":
    case "audio/webm":
      ext = "webm";
      break;
    case "audio/x-ms-wma":
      ext = "wma";
      break;
    case "image/jpeg":
      ext = "jpg";
      break;
    case "image/tif":
      ext = "tiff";
      break;
    case "image/gif":
      ext = "gif";
      break;
    case "image/png":
      ext = "png";
      break;
    case "application/pdf":
      ext = "pdf";
      break;
    case "video/3gpp":
      ext = "3gp";
      break;
    case "audio/aac":
      ext = "aac";
      break;
    case "video/x-ms-asf":
      ext = "asf";
      break;
    case "image/heic":
      ext = "heic";
      break;
    case "image/heif":
      ext = "heif";
      break;
    case "video/mpeg":
      ext = "mpg";
      break;
    case "video/quicktime":
      ext = "mov";
      break;
    case "audio/midi":
      ext = "mid";
      break;
    case "image/webp":
      ext = "webp";
      break;
    default:
      break;
  }

  if (!ext) {
    const parts = file.name.split(".");
    ext = parts.pop().toLowerCase();

    const good = [
      "wav",
      "mp3",
      "ogg",
      "gsm",
      "dct",
      "flac",
      "au",
      "aiff",
      "vox",
      "raw",
      "wma",
      "aac",
      "m4a",
      "midi",
      "mid",
      "jpg",
      "png",
      "jpeg",
      "gif",
      "pdf",
      "tiff",
      "webm",
      "3gp",
      "asf",
      "heic",
      "heif",
      "mpg",
      "mp4",
      "mpeg",
      "mov",
      "webp",
    ];

    ext = good.find((x) => ext === x);
  }

  return ext || "";
};
