// @ts-check
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import * as routes from "../../../../../constants/routes";
import IconDeleteConfirmButton from "../../../../../components-cross/IconDeleteConfirmButton";
import { ListItemTextTrack } from "../../../../../components-cross/TrackTitle";
import { getTracksSorted } from "../../../../../business-objects";

import {
  trackOpenAction,
  trackRemove,
} from "../../../../../reducers/albumActions";

/** @typedef {import('../../../../../business-objects/album').Album} Album */
/** @typedef {import('../../../../../business-objects/album').Track} Track */

const useStyles = makeStyles((theme) => ({
  tracksList: {
    paddingTop: 0,
    marginBottom: "1rem",
    "& .MuiListItemIcon-root": {
      minWidth: "1.5rem",
    },
    "& .trackList__listHeader": {
      paddingTop: 0,
      color: theme.palette.text.secondary,
      fontSize: "0.8rem",
      "& .MuiListItemText-primary": {
        fontSize: "0.8rem",
      },
    },
  },
}));

/**
 * @param {{album: Album, showDelete?:boolean}} props
 * @returns
 */
const TracksList = ({ album, showDelete }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const tracks = getTracksSorted(album);

  const handleOnClickTrack = (track) => {
    dispatch(trackOpenAction(track.isrc));
    history.push(routes.ALBUM_EDIT_TRACKS.replace(":upc", album.upc), {
      fromAlbumEditor: true,
    });
  };

  const handleOnClickRemove = (track) => {
    dispatch(trackRemove(album.upc, track));
  };

  return (
    <List className={classes.tracksList}>
      {tracks.map((track, index) => {
        return (
          <ListItem
            button
            key={track.isrc}
            onClick={() => handleOnClickTrack(track)}
          >
            <ListItemIcon>{track.order}.</ListItemIcon>
            <ListItemTextTrack track={track} album={album} />
            {showDelete && (
              <ListItemIcon>
                <IconDeleteConfirmButton
                  onClick={() => handleOnClickRemove(track)}
                />
              </ListItemIcon>
            )}
          </ListItem>
        );
      })}
    </List>
  );
};

export default TracksList;
