// @ts-check
import { auth } from "../../../firebase";
import { setPhotoAction } from "../actions/setPhoto";

import getExtension from "../../../utils/getFileExtension";
import storageService from "../../../services/storageService.js";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */

const handlePhotoUploadProgress = (dispatch, taskSnap, type) => {
  dispatch(
    setPhotoAction(type, {
      uploading: true,
      progress: (taskSnap.bytesTransferred / taskSnap.totalBytes) * 100,
    })
  );
};

/**
 * Recive un file para persistir y setear en el album
 *
 * @param {'cover'|'back'}  type
 * @param {Array<File>} files
 * @param {Function?} onProgress
 * @return {Function}
 */
export function uploadPhoto(type, files, onProgress) {
  /**
   * @param {function} dispatch
   * @param {StateGetter} getState
   */
  return async function thunk(dispatch, getState) {
    /** @type {StateStore} */
    const state = getState();

    const file = files.pop();
    if (!file) {
      return false;
    }

    const albumKey = state.wizardNewAlbumState.uploadingKey;

    // const uid = firebase.auth.currentUser.uid
    const email = auth.getCurrentUser()?.email;
    const extension = getExtension(file);
    const fileName = `art-${type}.${extension}`; // storage bucket filename for track (without path)
    const pathFolder = `${email}-${albumKey}`;

    dispatch(
      setPhotoAction(type, {
        uploading: true,
        progress: 0,
      })
    );

    const result = await storageService.uploadFile(
      pathFolder,
      fileName,
      file,
      (snap) => {
        handlePhotoUploadProgress(dispatch, snap, type);
        if (onProgress) {
          onProgress(snap.bytesTransferred, snap.totalBytes);
        }
      }
    );

    dispatch(
      setPhotoAction(type, {
        url: result.url,
        storageLocation: result.storageLocation,
        name: fileName,
        extension,
        uploading: false,
        progress: 100,
      })
    );

    return result;
  };
}

export default uploadPhoto;
