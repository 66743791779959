import { firebase } from "../firebase";
import { get } from "lodash";

export default () => {
  const uid = get(firebase, "auth.currentUser.uid", false);

  // PROD
  const gerardo = "9y0eG2zv5LW244xUfrytNJU1pK43"; // gerardo@therecordingconsort.com
  const agucho = "7nOTHZvg36f97Whx3HfUE3IXfQ52"; // agucho@gmail.com
  const production = "7ij6Ktb00WX1WWoSCgcfw03rEvA3"; // production@therecordingconsort.com
  const distribution = "RiBkqzzsodTYMNl9E0PoD7lde4p2"; // distribution@therecordingconsort.com

  const x = `${gerardo}${production}${distribution}${agucho}`;

  return uid && x.indexOf(uid) > -1;
};
