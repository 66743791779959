import React, { Fragment, PureComponent } from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@material-ui/core/Icon";
import Divider from "@material-ui/core/Divider";
import dateFormat from "dateformat";
import { range, reverse, concat } from "lodash";

const HISTORY_YEAR_START = 2020;

const styles = (theme) => ({
  periodInfo: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  infoButton: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
});

class PeriodRangeMenu extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      open: false,
      fromDate: "",
      untilDate: "",
      info: "---",
    };
  }

  componentDidMount() {
    this.setDatePeriods();
    this.setMenuOptions();
  }

  openMenu = (event) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  makeSelection = (period) => {
    const { setPeriod } = this.props;
    setPeriod(period);
    this.setState({ open: false });
  };

  periodToDate = (period) => {
    const periodStr = String(period);
    const year = periodStr.substr(0, 4);
    const month = periodStr.substr(4, 2);
    return new Date(`${year}-${month}`);
  };

  setDatePeriods = () => {
    const { from, until } = this.props;

    this.setState({
      fromDate: this.periodToDate(from),
      untilDate: this.periodToDate(until),
    });
  };

  setMenuOptions = () => {
    const now = new Date();
    const yearEnd = now.getFullYear();
    const monthEnd = now.getMonth();

    // shortcuts
    const last12months = new Date(
      now.getFullYear() - 1,
      now.getMonth() - 2,
      now.getDate()
    );
    const ingresosTotales = new Date(HISTORY_YEAR_START);
    const currentYear = new Date(now.getFullYear(), 0 - 3, 1);
    const ultimoMes = new Date(now.getFullYear(), now.getMonth() - 3);
    const ultimos3meses = new Date(now.getFullYear(), now.getMonth() - 5);
    const ultimos6meses = new Date(now.getFullYear(), now.getMonth() - 8);

    const shortcuts = [
      {
        label: "Desde el inicio",
        from: dateFormat(ingresosTotales, "yyyymm"),
        until: dateFormat(now, "yyyymm"),
      },
      {
        label: `Últimos 12 meses`,
        from: dateFormat(last12months, "yyyymm"),
        until: dateFormat("yyyymm"),
      },
      {
        label: `Año ${yearEnd}`,
        from: dateFormat(currentYear, "yyyymm"),
        until: dateFormat("yyyymm"),
      },
      {
        label: `Último mes`,
        from: dateFormat(ultimoMes, "yyyymm"),
        until: dateFormat(ultimoMes, "yyyymm"),
      },
      {
        label: `Últimos 3 meses`,
        from: dateFormat(ultimos3meses, "yyyymm"),
        until: dateFormat("yyyymm"),
      },
      {
        label: `Últimos 6 meses`,
        from: dateFormat(ultimos6meses, "yyyymm"),
        until: dateFormat("yyyymm"),
      },
    ];

    const singles = [];

    range(HISTORY_YEAR_START, yearEnd + 1).forEach((year) => {
      const _monthStart = year === HISTORY_YEAR_START ? 3 : 0;
      const _monthEnd = year === yearEnd ? monthEnd : 12;

      range(_monthStart, _monthEnd + 1).forEach((month) => {
        const date = new Date(year, month - 3);
        const dateLabel = new Date(year, month);
        singles.push({
          label: dateFormat(dateLabel, "mmm yyyy"),
          from: dateFormat(date, "yyyymm"),
          until: dateFormat(date, "yyyymm"),
        });
      });
    });

    const periods = concat(shortcuts, null, reverse(singles));

    this.setState({ periods });
  };

  render() {
    const { classes, info = "" } = this.props;
    const { anchorEl, open, periods = [] } = this.state;

    return (
      <Fragment>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            disableRipple={true}
            aria-label="More"
            aria-owns={open ? "long-menu" : undefined}
            aria-haspopup="true"
            onClick={this.openMenu}
            className={classes.infoButton}
          >
            <Icon>date_range</Icon>{" "}
            <div className={classes.periodInfo}>{info}</div>
          </Button>
        </div>

        <Menu
          id="period-filter"
          variant="menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
        >
          {periods.map((period, i) =>
            period ? (
              <MenuItem
                key={i}
                selected={period.label === info}
                onClick={() => this.makeSelection(period)}
                onMouseOver={this.mouseOver}
                onMouseOut={this.mouseOut}
              >
                {period.label}
              </MenuItem>
            ) : (
              <Divider key={i} />
            )
          )}
        </Menu>
      </Fragment>
    );
  }
}

export default withStyles(styles)(PeriodRangeMenu);
