const translateError = (error) => {
  switch (error.code) {
    /* AUTH */

    case "auth/user-not-found":
      return {
        ...error,
        message: "No hay ningún usuario con ese correo electrónico.",
      };
    case "auth/invalid-email":
      return { ...error, message: "El email no es válido." };
    case "auth/user-disabled":
      return {
        ...error,
        message:
          "🚫 Cuenta Suspendida por Equipo Anti-Fraude. Más información en tu email.",
      };
    case "auth/wrong-password":
      return { ...error, message: "La contraseña no es correcta." };
    case "auth/too-many-requests":
      return {
        ...error,
        message: "Demasiados intentos fallidos, inténtalo más tarde.",
      };
    case "auth/captcha-check-failed":
      return { ...error, message: "Fallo en reCAPTCHA, vuelve a intentarlo." };
    case "auth/invalid-phone-number":
      return { ...error, message: "Número de teléfono inválido." };
    case "auth/missing-phone-number":
      return { ...error, message: "Falta el número de teléfono." };
    case "auth/missing-code":
      return { ...error, message: "Falta el código que te hemos enviado." };
    case "auth/unverified-email":
      return { ...error, message: "Email sin verificar." };
    case "auth/email-already-in-use":
      return {
        ...error,
        message: "El Email corresponde a una cuenta ya existente.",
      };
    case "auth/quota-exceeded":
      return error;
    case "auth/maximum-second-factor-count-exceeded":
      return error;
    case "auth/second-factor-already-in-use":
      return error;
    case "auth/unsupported-first-factor":
      return error;

    /* CATCH ALL */
    default:
      // return error;
      console.log(error.code);
      console.log(error);
      return {
        ...error,
        message: "Algo ha fallado :/. Inténtalo de nuevo más tarde.",
      };
  }
};

export default translateError;
