// messages
export const REQUIRED_EMAIL = "⚠ Debe ingresar un email";
export const INVALID_EMAIL = "⚠ Debe ingresar un email valido";
export const REQUIRED_FULL_NAME = "⚠ Debe ingresar su nombre completo";
export const REQUIRED_TAX_ID = "⚠ Debe ingresar su identificacion tributaria";
export const REQUIRED_ID = "⚠ Debe ingresar su documento de identidad";
export const REQUIRED_BIRTHDAY = "⚠ Debe ingresar su fecha de nacimiento";
export const REQUIRED_PROFESSION = "⚠ Debe ingresar su profesión";
export const REQUIRED_NATIONALITY = "⚠ Debe ingresar su nacionalidad";
export const REQUIRED_COUNTRY = "⚠ Debe ingresar su pais de origen";
export const REQUIRED_RESIDENCE_COUNTRY =
  "⚠ Debe ingresar su lugar de residencia";
export const REQUIRED_POSTAL_CODE = "⚠ Debe ingresar su codigo postal";
export const REQUIRED_CITY = "⚠ Debe ingresar su ciudad";
export const REQUIRED_ADDRESS = "⚠ Debe ingresar su domicilio";
export const REQUIRED_PHONE = "⚠ Debe ingresar su numero telefonico";
export const REQUIRED_AGREEMENT =
  "⚠ Debes confirmar la veracidad de los datos.";
export const REQUIRED_AGREEMENT2 =
  "⚠ Debes aceptar el Acuerdo de Autofacturación";
export const REQUIRED_BANK_NAME = "⚠ Debe ingresar el nombre del banco";
export const REQUIRED_ACCOUNT_NUMBER = "⚠ Debe ingresar el numero de cuenta";
export const REQUIRED_ACCOUNT_SWIFT = "⚠ Debe ingresar el código SWIFT";
export const REQUIRED_ACCOUNT_TYPE = "⚠ Debe ingresar el tipo de cuenta";
export const REQUIRED_PATH_NUMBER = "⚠ Debe ingresar el número de ruta";

//custom data
export const PAYPAL = "paypal";
export const BANK = "bank";
export const DEFAULT_DATA = {
  tax: "Identificación Fiscal o Tributaria",
  identification: "Documento de identidad",
  payment: {
    options: [{ id: "paypal", label: "Paypal" }],
  },
};
const paymentData = {
  options: [
    { id: "paypal", label: "Paypal" },
    { id: "bank", label: "Transferencia  Bancaria" },
  ],
  data: {
    bankName: { label: "Nombre del banco" },
    accountNumber: { label: "Número de cuenta" },
    accountSwift: { label: "Código SWIFT" },
    accountType: {
      label: "Tipo de cuenta",
      options: ["Cuenta Corriente", "Cuenta de Ahorro", "Cuenta Vista"],
    },
  },
};
const paymentChile = {
  options: paymentData.options,
  data: {
    ...paymentData.data,
    ...{
      bankName: {
        label: paymentData.data.bankName.label,
        options: [
          "Banco Bice [028]",
          "Banco Consorcio [055]",
          "Banco Credito e Inversiones(BCI) [016]",
          "Banco Falabella [051]",
          "Banco HSBC [031]",
          "Banco Internacional [009]",
          "Banco Itau Chile [039]",
          "Banco Penta [056]",
          "Banco Rabobank [054]",
          "Banco Ripley [053]",
          "Banco Santander [037]",
          "Banco Security [049]",
          "Banco de Chile [001]",
          "Banco del Estado de Chile [012]",
          "Scotiabank Chile [014]",
        ],
      },
    },
  },
};
const paymentArgentina = {
  options: paymentData.options.filter((option) => option.id !== "bank"),
  data: {
    ...paymentData.data,
    ...{
      accountNumber: { label: "Número de cuenta(CBU)" },
    },
  },
};
const paymentCostaRica = {
  options: paymentData.options,
  data: {
    ...paymentData.data,
    ...{
      accountNumber: { label: "IBAN" },
    },
  },
};
const paymentUsa = {
  options: paymentData.options,
  data: {
    ...paymentData.data,
    ...{
      pathNumber: { label: "Número de ruta" },
      accountType: {
        ...paymentData.data.accountType,
        ...{
          options: ["Checking", "Savings"],
        },
      },
    },
  },
};
const paymentEuropa = {
  options: paymentData.options,
  data: {
    ...paymentData.data,
    ...{
      accountNumber: { label: "IBAN" },
    },
  },
};
const paymentMexico = {
  options: paymentData.options,
  data: {
    ...paymentData.data,
    ...{
      accountNumber: { label: "CLABE (Clave Bancaria Estandarizada)" },
    },
  },
};
const paymentPeru = {
  options: paymentData.options,
  data: {
    ...paymentData.data,
    ...{
      bankName: {
        label: paymentData.data.bankName.label,
        options: [
          "BANCO AZTECA DEL PERU, S.A. - AZTK",
          "BANCO BBVA CONTINENTAL - BCON",
          "BANCO DE COMERCIO - BDCM",
          "BANCO DE CRÉDITO DEL PERU - BCPL",
          "BANCO DEUTSCHE BANK DEL PERU  S.A. - DEUT",
          "BANCO GNB SA - HBPE",
          "BANCO INTERAMERICANO DE FINANZAS (BANBIF) - BIFS",
          "BANCO INTERNACIONAL DEL PERU (INTERBANK) - BINP",
          "BANCO PICHINCHA - FINA",
          "BANCO SANTANDER PERU S.A. - BSAP",
          "BANCO SCOTIABANK PERU S.A.A. - BSUD",
          "CITIBANK DEL PERU S.A. - CITI",
        ],
      },
    },
  },
};
const paymentUruguay = {
  options: paymentData.options,
  data: {
    ...paymentData.data,
    ...{
      bankName: {
        label: paymentData.data.bankName.label,
        options: [
          "AFINIDAD AFAP FONDOS DE AHORRO PREVISIONAL",
          "AKUMAL S.A. - CASA DE CAMBIO CASAS DE CAMBIO",
          "ALMAR S.A. - MONTEVIDEO CASAS DE CAMBIO",
          "ALTERCAMBIO S.A. CASAS DE CAMBIO",
          "BANCO BILBAO VIZCAYA ARGENTARIA URUGUAYS.A.",
          "BANCO CENTRAL DEL URUGUAY",
          "BANCO ITAU",
          "BANCO ITAU-BBA S.A. SUCURSAL URUGUAY",
          "BANCO SANTANDER",
          "BANDES URUGUAY S.A.",
          "BANQUE HERITAGE (URUGUAY) S.A.",
          "BNL DE URUGUAY S.A.",
          "BOLSA DE VALORES DE MONTEVIDEO",
          "DISCOUNT BANK (LATIN AMERICA) BANCOS PRIVADOS",
          "FORTIGOLD S.A. CASAS DE CAMBIO",
          "HSBC BANK (URUGUAY) S.A.",
          "NUEVO BANCO COMERCIAL - SCOTIABANK URUGUAYS.A. -",
          "REPUBLICA AFAP FONDOS DE AHORRO PREVISIONAL",
          "SCOTIATRUST (URUGUAY) I.F.E., S.A.",
          "TOTAL DE BANCOS OFICIALES",
          "TOTAL DE BANCOS PRIVADOS",
          "URUCHANGE CASAS DE CAMBIO",
          "VALDIG S.A. - MONTEVIDEO CASAS DE CAMBIO",
          "VANTRO S.A. CASAS DE CAMBIO",
        ],
      },
    },
  },
};
const paymentColombia = {
  options: paymentData.options,
  data: {
    ...paymentData.data,
    ...{
      bankName: {
        label: paymentData.data.bankName.label,
        options: [
          "BANCO CAJA SOCIAL [CASOCOBBXXX]",
          "BANCO COOMEVA S.A. [61]",
          "BANCO DAVIVIENDA S.A.(FORMERLY GRANBANCO BANCAFE S.A.) [CAFECOBB]",
          "BANCOLOMBIA S.A. [COLOCOBMMD1]",
          "BBVA [FBBVCOBB]",
          "Banco AV Villas [BAVICOBB]",
          "Banco Agrario [CCAICOBB]",
          "Banco CorpBanca [BSCHCOBB]",
          "Banco GNB Sudameris [BSUDCOBB]",
          "Banco Popular [BPOPCOBB]",
          "Banco Scotiabank Colpatria [COLPCOBB]",
          "Banco de Bogota [BBOGCOBB]",
          "Banco de Occidente [OCCICOBC]",
          "Bancolombia [COLOCOBM]",
          "Caja Social [CCSFCOBB]",
          "Citibank Colombia [CITICOBB]",
          "Davivienda [CAVDCOBB]",
          "HSBC [BISTCOBB]",
          "Helm Bank S.A. [x]",
          "ITAU CORPBANCA COLOMBIA S.A. [BCTOCOBB]",
        ],
      },
    },
  },
};
export const COUNTRIES_DATA = {
  AR: { tax: "CUIL/CUIT", identification: "DNI", payment: paymentArgentina },
  BO: { tax: "NIT", identification: "CI" },
  BR: { tax: "CPF", identification: "RG" },
  CL: { tax: "RUT", identification: "CI", payment: paymentChile },
  CO: { tax: "NIT", identification: "CC", payment: paymentColombia },
  CR: { tax: "NITE", identification: "CI", payment: paymentCostaRica },
  EC: { tax: "RUC", identification: "CC" },
  US: { tax: "TIN", identification: "SSN", payment: paymentUsa },
  SV: { tax: "NIT", identification: "DUI" },
  ES: { tax: "NIF", identification: "DNI", payment: paymentEuropa },
  GT: { tax: "RTU", identification: "DPI" },
  HN: { tax: "RTN", identification: "TDI" },
  IT: {
    tax: "Codice fiscale/Partita IVA",
    identification: 'Carta d"Identita',
    payment: paymentEuropa,
  },
  MX: { tax: "RFC", identification: "CURP", payment: paymentMexico },
  NI: { tax: "RUC", identification: "CI" },
  PA: { tax: "RUC", identification: "CIP" },
  PY: { tax: "RUC", identification: "CIC/CI" },
  PE: { tax: "RUC", identification: "DNI", payment: paymentPeru },
  PR: { tax: "TIN", identification: "ID" },
  DO: { tax: "RNC", identification: "CIE" },
  UY: { tax: "RUT", identification: "CI", payment: paymentUruguay },
  VE: { tax: "RIF", identification: "CI" },
};
export const DEFAULT_DATA_FORM = {
  email: "",
  fullName: "",
  residenceCountry: null,
  nationality: null,
  taxIdentification: "",
  identification: "",
  birthday: new Date(),
  profession: "",
  postalCode: "",
  phone: "",
  city: "",
  address: "",
};
