import React from "react";
import { TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";

import IconDeleteConfirmButton from "../../../../../components-cross/IconDeleteConfirmButton";
import useEditableField from "../hooks/useEditableField";

/** @typedef {import("../../../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable*/
/** @typedef {import("../../../../../business-objects/album.fieldEditable").FieldLevelType} FieldLevelType*/
/** @typedef {import("../../../../../business-objects/album.fieldEditable").FieldTypes} FieldTypes*/
/** @typedef {import("react").ReactNode} ReactNode */

/**
 * @param {{
 *  label: string,
 *  name: string,
 *  type: FieldTypes,
 *  level: FieldLevelType,
 *  required?: boolean,
 *  canDelete?: boolean,
 *  disabled?:boolean,
 *  endAdornment?: ReactNode
 * }} props
 */
const EditableInput = (props) => {
  const {
    path,
    label,
    value,
    setValue,
    canDelete,
    deleteField,
    required,
    error,
  } = useEditableField({ ...props, debounceTimeToSave: 1000 });
  const { disabled } = props;
  let { endAdornment } = props;

  const handleOnDelete = (e) => {
    deleteField();
  };

  const handleOnChnage = (e) => {
    setValue(e.target.value);
  };

  if (canDelete) {
    endAdornment = (
      <InputAdornment position="end">
        <IconDeleteConfirmButton onClick={handleOnDelete} />
      </InputAdornment>
    );
  }

  return (
    <TextField
      name={path}
      label={label}
      value={value}
      onChange={handleOnChnage}
      variant="outlined"
      fullWidth
      required={required}
      error={!!error}
      helperText={error}
      disabled={disabled}
      InputProps={
        endAdornment
          ? {
              endAdornment,
            }
          : null
      }
    />
  );
};

export default EditableInput;
