import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import AuthUserContext from "./AuthUserContext";
import UserMenu from "./UserMenu";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import AccountMenu from "./AccountMenu";
import packageData from "../../package.json";
import { db } from "../firebase";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  appBar: {
    color: "rgb(204, 170, 102)",
    background: "black",
    borderBottom: "5px solid rgb(204, 170, 102)",
  },
  appBarNonAuth: {
    padding: theme.spacing(2),
    color: "black",
    background: "transparent",
    marginBottom: theme.spacing(5),
  },
  navbarUserEmail: {
    marginRight: "1em",
  },
  list: {
    width: "30vw",
    minWidth: 300,
    maxWidth: "100%",
  },
  logo: {
    color: "rgb(204, 170, 102)",
  },
  version: {
    fontSize: "0.6em",
  },
});

const Navigation = (props) => (
  <AuthUserContext.Consumer>
    {(authUser) => authUser && <NavigationAuth {...props} user={authUser} />}
  </AuthUserContext.Consumer>
);

const Logo = ({ classes, siteName }) => {
  if (!siteName) {
    return null;
  }

  if (siteName === "Subidalibre") {
    return (
      <a
        href="https://subidalibre.com/"
        style={{ textDecoration: "none" }}
        className={classes.logo}
      >
        {siteName}
      </a>
    );
  }

  return siteName;
};

const NavigationAuth = ({ classes, user, history }) => {
  const [siteName, setSiteName] = useState("");

  useEffect(() => {
    (async () => {
      const account = await db.getAccountName();
      setSiteName(account);
    })();
  }, []);

  return (
    <div className={classes.root}>
      <AppBar elevation={2} className={classes.appBar}>
        <Toolbar>
          <UserMenu />

          <Typography variant="h6" color="inherit" className={classes.flex}>
            <Logo siteName={siteName} classes={classes} />{" "}
            <sup className={classes.version}>
              v.{packageData.version.split(".").join(".")}
            </sup>
          </Typography>

          <AccountMenu distributor={siteName} user={user} history={history} />
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default withRouter(withStyles(styles)(Navigation));
