// @ts-check

import cartService from "../../../services/cartService";
import { cartSetItemsAction } from "../cartActions";
import calculateChangeCost from "../bo/calculateChangeCost";
import { loadAlbumUnedited } from "./loadAlbumUnedited";
import { getAlbum } from "../../albumActions";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */

// eslint-disable-next-line valid-jsdoc
/**
 * @param {{[key:string]: CartItem}} cartItemsObj
 * @param {{[key:string]: number}} pricesChanges
 * @return {Array<CartItem>}
 */
export const receiveCartChanges = (cartItemsObj, pricesChanges) => {
  if (!cartItemsObj) {
    return [];
  }

  let items = Object.values(cartItemsObj);
  if (items) {
    items = items.map(
      /**
       * @param {CartItem} cartItem
       * @return {cartItem}
       */
      (cartItem) => {
        return {
          ...cartItem,
          creditsCost: calculateChangeCost(cartItem, pricesChanges),
        };
      }
    );
  }

  // sorting, first album level changes
  items.sort((a, b) => {
    if (a.level !== b.level) {
      return a.level === "album" ? -1 : 1;
    } else if (a.level === "track") {
      if (a.numberTrack && b.numberTrack && a.numberTrack !== b.numberTrack) {
        return a.numberTrack < b.numberTrack ? -1 : 1;
      }
    }

    return a.labelField < b.labelField ? -1 : 1;
  });
  return items;
};

/**
 * Busca y retorna si existe el carrito para un album
 *
 * @param {string} upc;
 * @param {boolean=} force;
 * @return {Function}
 */
export function loadCart(upc, force = false) {
  /**
   * @param {function} dispatch
   * @param {StateGetter} getState
   */
  return async function thunk(dispatch, getState) {
    /** @type {StateStore} */
    const state = getState();

    /** @type {Album} */
    const album = await dispatch(getAlbum(upc));

    const config = state.configState.config;
    const configPricesChanges = config?.cart?.pricesChanges || {};

    if (!album || album.status !== "live_updating") {
      dispatch(cartSetItemsAction(null, null));
      return;
    }

    let cartItems = state.cartState.cartItems;

    if (!cartItems || state.cartState.upc !== upc || force) {
      const cartItemsObj = await cartService.fetchCartItems(upc);

      cartItems = receiveCartChanges(cartItemsObj, configPricesChanges);

      await dispatch(loadAlbumUnedited(upc));

      dispatch(cartSetItemsAction(upc, cartItems));
    }

    return cartItems;
  };
}

export default loadCart;
