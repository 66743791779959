// @ts-check

/**
 *
 * @param {import("./album").Album} album
 * @return {Array<import("./album").Track>}
 */
export const getTracksSorted = (album) => {
  if (!album || !album.tracks) {
    return [];
  }
  return Object.values(album.tracks).sort((a, b) => a.order - b.order);
}

export default getTracksSorted;