import { loadConfig } from "./actionsAsync/loadConfig";

/**
 *
 * @param {Object} config
 * @return {Object}
 */
export const setConfigAction = (config) => {
  return {
    type: "CONFIG_SET",
    config,
  };
};

export default {
  loadConfig,
  setConfigAction,
};
