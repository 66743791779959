// @ts-check
import produce from "immer";

/** @typedef {import('../wizardStore').WizardNewAlbumState} WizardNewAlbumState */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */

const TYPE = "WIZARD_SET_SHOW_VERSION_FIELD";

/**
 * @param {boolean} show
 * @return {Object}
 */
export const setShowVersionFieldAction = (show) => {
  return {
    type: TYPE,
    show,
  };
};

/**
 * @param {WizardNewAlbumState} state
 * @param {object} action
 * @return {object}
 */
export const setShowVersionFieldReducer = (state, action) => {
  return produce(state, (draft) => {
    draft.showVersionField = action.show;
  });
};

export default {
  action: setShowVersionFieldAction,
  reducer: setShowVersionFieldReducer,
  TYPE,
};
