import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { firebase } from "../../../../firebase";

import * as routes from "../../../../constants/routes";
import useConfig from "../../../../hooks/useConfig";
import { canAddRemoveTracks } from "../../../../business-objects";
import useTrackOpened from "../../../../reducers/album/hooks/useTrackOpened";
import useAlbumErrors from "../../../../reducers/album/hooks/useAlbumErrors";
import albumActions from "../../../../reducers/albumActions";
import cartActions from "../../../../reducers/cart/cartActions";

export const useEditor = (upc) => {
  const location = useLocation();
  const [informed, setInformed] = useState(false);
  const [suscription, setSuscription] = useState(false);
  const config = useConfig();
  const history = useHistory();

  // redux selectors
  const album = useSelector((state) => (state.albumState.albums || {})[upc]);
  const mode = useSelector((state) => state.albumState.mode);
  const trackOpened = useTrackOpened();
  const { errors, hasErrors, clearErrors } = useAlbumErrors();
  const loading = useSelector((state) => state.albumState.openAlbumLoading);
  const saving = useSelector((state) => state.cartState.saving);
  const showLegales = useSelector((state) => state.albumState.showLegales);

  // action dispatcher functions
  const dispatch = useDispatch();

  const status = album?.status;

  const blockedit = useMemo(() => {
    if (!status || !config || !config.statuses) {
      return false;
    }
    const isBlocked =
      !(status in config.statuses) || config.statuses[status.trim()].blockedit;
    return isBlocked;
  }, [status, config]);

  useEffect(() => {
    return () => {
      // ON finish edition (deatached the useEditor Hook) => clear album errors
      clearErrors();
    };
  }, [upc, clearErrors]);

  // LOAD ALBUM FOR EDITION
  useEffect(() => {
    const load = async () => {
      try {
        await dispatch(albumActions.loadAlbumToEdit(upc));
        await dispatch(cartActions.loadCart(upc, true));
      } catch (e) {
        //TODO: check code error to redirect to an 404 or 500 screen
        history.replace(routes.ALBUM_NOTEXISTS);
      }

      // this location.state is sendind by the wizard
      if (location.state && "track" in location.state && location.state.track) {
        await dispatch(albumActions.trackOpenAction(location.state.track.isrc));
        history.replace(routes.ALBUM_EDIT_TRACKS.replace(":upc", upc), {
          fromWizard: true,
        });
      }
    };
    load();
  }, [dispatch, upc, location.state, history]);

  // LISTENING BACKEND CHANGES FOR ALBUM
  useEffect(() => {
    const handleAlbumFieldChange = (snap) => {
      const path = snap.key;
      const value = snap.val();
      dispatch(albumActions.albumFieldChange(upc, path, value));
    };

    const handleAlbumFieldAdded = (snap) => {
      if (!album) {
        return;
      }
      const path = snap.key;
      const value = snap.val();
      const hasChange = album[path] !== value;
      if (hasChange) {
        dispatch(albumActions.albumFieldChange(upc, path, value));
      }
    };

    const handleAlbumFieldRemoved = (snap) => {
      const path = snap.key;
      dispatch(albumActions.albumFieldChange(upc, path, null));
    };

    // listen for further changes in the album
    if (!suscription && album) {
      console.log(`suscribe to ${upc}`);
      const _suscription = firebase.db.ref(`catalog/${upc}`);
      _suscription.on("child_changed", handleAlbumFieldChange);
      _suscription.on("child_added", handleAlbumFieldAdded);
      _suscription.on("child_removed", handleAlbumFieldRemoved);
      setSuscription(_suscription);
    }

    return () => {
      if (suscription) {
        suscription.off("child_changed", handleAlbumFieldChange);
        suscription.off("child_added", handleAlbumFieldAdded);
        suscription.off("child_removed", handleAlbumFieldRemoved);
      }
    };
  }, [dispatch, suscription, upc, album]);

  // MODAL WARNING EDITION
  useEffect(() => {
    async function setDialogPref() {
      const fromSessionDontShow = sessionStorage.getItem(
        "dontShowAlbumEditWarning"
      );

      if (fromSessionDontShow) {
        setInformed(true);
      } else {
        const snap = await firebase.db
          .ref(
            `users/${firebase.auth.currentUser.uid}/settings/optouts/albumEditWarning`
          )
          .once("value");

        if (snap.exists) {
          const fromDbDontShow = snap.val();

          if (fromDbDontShow) {
            setInformed(true);
            sessionStorage.setItem("dontShowAlbumEditWarning", true);
          }
        }
      }
    }

    if (firebase.auth.currentUser) setDialogPref();
  }, []);

  return {
    album,
    errors,
    hasErrors,
    canAddRemoveTracks: canAddRemoveTracks(album),
    trackOpened,
    informed,
    loading,
    mode,
    blockedit,
    setInformed,
    saving,
    showLegales,
  };
};

export default useEditor;
