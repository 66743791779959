// @ts-check
import produce from "immer";

/** @typedef {import('../wizardStore').WizardNewAlbumState} WizardNewAlbumState */
/** @typedef {import('../wizardStore').NewAlbumTrack} NewAlbumTrack */

/** @typedef {{
 *  type: "WIZARD_TRACK_SET_BUSY"
 *  track: NewAlbumTrack,
 *  busy: boolean
 * }} trackBusyAction */

const TYPE = "WIZARD_TRACK_SET_BUSY";

/**
 * @param {NewAlbumTrack} track
 * @param {boolean=} busy
 * @return {trackBusyAction}
 */
export const trackBusyAction = (track, busy=true) => {
  return {
    type: TYPE,
    track,
    busy
  }
};

/**
 * @param {WizardNewAlbumState} state
 * @param {trackBusyAction} action
 * @return {object}
 */
export const trackBusyReducer = (state, {track, busy}) => {
  return produce(state, (draft) => {
    const index = draft.tracks.findIndex(t => t.id === track.id);
    if (index !== -1) {
      draft.tracks[index].busy = busy;
    }
  })
};

export default {
  action: trackBusyAction,
  reducer: trackBusyReducer,
  TYPE,
};
