import React, { useEffect, useState } from "react";
import { makeStyles, LinearProgress, Typography } from "@material-ui/core";
import { Button, ButtonGroup } from "@material-ui/core";
// import UserSearch from "../components/userSearch";
import { DataGrid } from "@mui/x-data-grid";
import { fbroot } from "../../../firebase";
import { keys } from "lodash";
import { GridColDef } from "@mui/x-data-grid";

const db = fbroot.database();

const TABLE_ARTISTS = "artists";
const TABLE_SMARTLINKS = "smartlinks2";
const DEFAULT_COL_WIDTH = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(5),
    maxWidth: "90vw",
    height: "80vh",
  },
  container: {
    display: "flex",
    height: "100%",
  },
  tabsBox: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
  },
  toolbar: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(3),
  },
  botonera: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
  },
}));

const renderImage = (params) => {
  const { value } = params;
  return <img src={value} alt="artist" style={{ width: 75 }} />;
};

const renderLink = (params) => {
  const { value } = params;
  return (
    <a target="_blank" href={value}>
      Smartlink
    </a>
  );
};

const artistsCols = [
  { field: "name", headerName: "Artista" },
  { field: "country", headerName: "País" },
  { field: "id", headerName: "ID Artista TRC", width: 250 },
  { field: "metaID", headerName: "ID META" },
  { field: "fmID", headerName: "FFM ID Artista", width: 250 },
  { field: "uid", headerName: "UID" },
];

const smartlinksCols = [
  { field: "artistId", headerName: "FFM ID Artista" },
  { field: "id", headerName: "FFM ID Smartlink" },
  { field: "url", headerName: "URL", renderCell: renderLink },
  {
    field: "image",
    headerName: "Imagen",
    width: 85,
    renderCell: renderImage,
  },
  { field: "upc", headerName: "UPC" },
  { field: "createdAt", headerName: "Created at" },
];

const getCols = (table) => {
  switch (table) {
    case TABLE_ARTISTS:
      return artistsCols.map((col) => ({
        ...col,
        width: col.width || DEFAULT_COL_WIDTH,
      }));
    case TABLE_SMARTLINKS:
      return smartlinksCols.map((col) => ({
        ...col,
        width: col.width || DEFAULT_COL_WIDTH,
      }));

    default:
      return [];
  }
};

const AdminArtists = () => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState(artistsCols);
  const [loading, setLoading] = useState(false);
  const [table, setTable] = useState(TABLE_ARTISTS);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      const snap = await db.ref(table).once("value");
      const data = snap.val();

      let rows;
      let cols;

      // format each row
      switch (table) {
        case TABLE_ARTISTS:
          rows = keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));

          cols = getCols(table);

          break;

        case TABLE_SMARTLINKS:
          rows = keys(data).map((key) => {
            const {
              artistId = "",
              createdAt = "",
              domain = "",
              id = "",
              image = "",
              shortId = "",
              upc = "",
            } = data[key];

            return {
              artistId,
              id,
              url: `${domain}/${shortId}`,
              image,
              upc,
              createdAt: new Date(createdAt).toLocaleString(),
            };
          });

          cols = getCols(table);

          break;
      }

      setRows(rows);
      setColumns(cols);
      setLoading(false);
    };

    loadData();
  }, [table]);

  const switchToTab = (newTable) => {
    setTable(newTable);
  };

  const deleteRows = async () => {
    console.log(selected);
    const updates = {};
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.botonera}>
        <div className={classes.tabsBox}>
          <ButtonGroup variant="contained" disableElevation>
            <Button
              color={table === TABLE_ARTISTS ? "primary" : "default"}
              onClick={() => switchToTab(TABLE_ARTISTS)}
            >
              Artistas
            </Button>
            <Button
              color={table === TABLE_SMARTLINKS ? "primary" : "default"}
              onClick={() => switchToTab(TABLE_SMARTLINKS)}
            >
              Smartlinks
            </Button>
          </ButtonGroup>
        </div>

        <div className={classes.toolbar}>
          <ButtonGroup variant="contained" disableElevation>
            <Button onClick={deleteRows}>Eliminar</Button>
          </ButtonGroup>
        </div>
      </div>

      {loading && <LinearProgress />}

      <div className={classes.container}>
        <DataGrid
          // density="compact"
          rows={rows}
          onSelectionModelChange={setSelected}
          columns={columns}
          pagination
          loading={loading}
          checkboxSelection
          autoPageSize
          disableDensitySelector={false}
        />
      </div>
    </div>
  );
};

export default AdminArtists;
