import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";

const styles = (theme) => ({
  box: {
    marginBottom: theme.spacing(4),
  },
  heading: {
    color: "black",
    margin: 0,
  },
  subheading: {
    margin: 0,
    marginBottom: "1.5rem",
  },
});

const StepHeadings = ({ classes, title, description, noGutter = false }) => (
  <Box className={classes.box}>
    <Typography
      variant="h4"
      gutterBottom
      className={classes.heading}
      align="center"
    >
      {title}
    </Typography>
    <Typography
      variant="body2"
      gutterBottom={!noGutter}
      color="primary"
      className={classes.subheading}
      align="center"
    >
      {description}
    </Typography>
  </Box>
);

export default withStyles(styles)(StepHeadings);
