import React from "react";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  base: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
  },
}));

const AdminUserSearch = (props) => {
  const { handleSearch, handleChange, clearSearch, q } = props;

  const classes = useStyles();

  return (
    <div className={classes.base}>
      <TextField
        id="query"
        name="q"
        value={q}
        label="UID o Email"
        onChange={handleChange}
        onKeyUp={(e) => (e.key === "Enter" ? handleSearch() : false)}
        fullWidth
        variant="outlined"
        size="small"
      />
      {q !== "" && (
        <IconButton size="small" onClick={clearSearch}>
          <Icon>clear</Icon>
        </IconButton>
      )}
    </div>
  );
};

export default AdminUserSearch;
