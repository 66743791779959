/** @typedef {import("../business-objects/album").Album} Album */
/** @typedef {import("../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../business-objects/album.fieldEditable").FieldActionType} FieldActionType */

import trackClose from "./album/actions/trackClose";
import trackOpen from "./album/actions/trackOpen";
import albumClearErrors from "./album/actions/albumClearErrors";
import albumSetError from "./album/actions/albumSetError";
import albumSetShowLegales from "./album/actions/albumSetShowLegales";

import { albumPrice } from "./album/actionsAsync/albumPrice";
import { albumDiscardChanges } from "./album/actionsAsync/albumDiscardChanges";
import { albumStartEdition } from "./album/actionsAsync/albumStartEdition";
import { loadAlbumToEdit } from "./album/actionsAsync/loadAlbumToEdit";
import { saveFieldChange } from "./album/actionsAsync/saveFieldChange";
import { restoreFieldChange } from "./album/actionsAsync/restoreFieldChange";
import { addArtist } from "./album/actionsAsync/addArtist";
import { addTrackArtist } from "./album/actionsAsync/addTrackArtist";
import { addTrackField } from "./album/actionsAsync/addTrackField";
import { coverReplaceUpload } from "./album/actionsAsync/coverReplaceUpload";
import { deleteField } from "./album/actionsAsync/deleteField";
import { getAlbum } from "./album/actionsAsync/getAlbum";
import { publishAlbum } from "./album/actionsAsync/publishAlbum";
import { trackAdd } from "./album/actionsAsync/trackAdd";
import { trackOrder } from "./album/actionsAsync/trackOrder";
import { trackRemove } from "./album/actionsAsync/trackRemove";
import { discardAlbum } from "./album/actionsAsync/discardAlbum";

export {
  albumDiscardChanges,
  albumStartEdition,
  restoreFieldChange,
  getAlbum,
  trackAdd,
  trackOrder,
  trackRemove,
  coverReplaceUpload,
};

export const trackCloseAction = trackClose.action;
export const trackOpenAction = trackOpen.action;
export const albumClearErrorsAction = albumClearErrors.action;
export const albumSetErrorAction = albumSetError.action;
export const albumSetShowLegalesAction = albumSetShowLegales.action;

/**
 *
 * @param {string} upc
 * @return {Object}
 */
export const openAlbum = (upc) => {
  return {
    type: "OPEN_ALBUM",
    upc,
  };
};

/**
 *
 * @param {boolean} loading
 * @return {Object}
 */
export const openAlbumLoading = (loading) => {
  return {
    type: "OPEN_ALBUM_LOADING",
    loading,
  };
};

/**
 *
 * @param {string} upc
 * @param {Album} album
 * @return {Object}
 */
export const setAlbum = (upc, album) => {
  return {
    type: "ALBUM_SET",
    payload: {
      upc,
      album,
    },
  };
};
/**
 *
 * @param {string} upc
 * @param {Object} cartItems
 * @return {Object}
 */
export const setAlbumCart = (upc, cartItems) => {
  return {
    type: "ALBUM_CART_SET",
    payload: {
      upc,
      cartItems,
    },
  };
};

/**
 *
 * @param {object} cart
 * @return {Object}
 */
export const setActivedCart = (cart) => {
  return {
    type: "ALBUM_ACTIVE_CART_SET",
    payload: cart,
  };
};

/**
 * @param {string} upc
 * @param {string} path
 * @param {any} value
 * @return {Object}
 */
export const albumFieldChange = (upc, path, value) => {
  return {
    type: "ALBUM_FIELD_CHANGE",
    payload: {
      upc,
      path,
      value,
    },
  };
};

/**
 * @param {string | null} path
 * @return {Object}
 */
export const openAlbumField = (path) => {
  return {
    type: "OPEN_ALBUM_FIELD",
    path,
  };
};

export default {
  albumDiscardChanges,
  albumClearErrorsAction,
  albumSetError,
  albumSetShowLegalesAction,
  albumStartEdition,
  albumPrice,
  getAlbum,
  openAlbum,
  openAlbumLoading,
  addArtist,
  addTrackArtist,
  addTrackField,
  coverReplaceUpload,
  trackCloseAction,
  deleteField,
  saveFieldChange,
  restoreFieldChange,
  albumFieldChange,
  loadAlbumToEdit,
  openAlbumField,
  setAlbum,
  setAlbumCart,
  setActivedCart,
  publishAlbum,
  trackOpenAction,
  trackAdd,
  trackOrder,
  trackRemove,
  discardAlbum,
};
