// @ts-check
import { payloadToState } from "../wizardUtils";

/** @typedef {import('../wizardStore').WizardNewAlbumState} WizardNewAlbumState */
/** @typedef {import('../wizardStore').NewAlbumPayload} NewAlbumPayload */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */

const TYPE = "WIZARD_SET_NEW_ALBUM_PAYLOAD";

/**
 * Used to set entire payload when
 *
 * @param {NewAlbumPayload} newAlbumPayload
 * @return {object}
 */
export const setNewAlbumPayloadAction = (newAlbumPayload) => {
  return {
    type: TYPE,
    newAlbumPayload,
  };
};

/**
 * @param {NewAlbumPayload} albumPayload
 * @return {NewAlbumPayload}
 */
const _resetUploadingStatusTracks = (albumPayload) => {
  if (!albumPayload) {
    return albumPayload;
  }

  albumPayload.tracks = albumPayload.tracks?.map((track) => {
    if (track.uploading) {
      track.uploading = false;
      if (track.progress < 100 || track.busy) {
        track.broken = true;
      }
    }
    track.busy = false;
    return track;
  });

  return albumPayload;
};

/**
 * @param {WizardNewAlbumState} state
 * @param {object} action
 * @return {WizardNewAlbumState}
 */
export const setNewAlbumPayloadReducer = (state, action) => {
  /** @type {NewAlbumPayload} */
  const newAlbumPayload = _resetUploadingStatusTracks(action.newAlbumPayload);

  const newState = {
    ...state,
    ...payloadToState(newAlbumPayload),
    isUploadingTracks: false,
  };
  return newState;
};

export default {
  action: setNewAlbumPayloadAction,
  reducer: setNewAlbumPayloadReducer,
  TYPE,
};
