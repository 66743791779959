import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { firebase } from "../firebase";
import LinearProgress from "@material-ui/core/LinearProgress";

import configActions from "../reducers/config/configActions";
import Result500 from "../components-cross/Result500";

export const AppConfig = (props) => {
  const dispatch = useDispatch();
  const configLoaded = useSelector((state) => state.configState.loaded);
  const [error, setError] = useState("");

  useEffect(() => {
    const onValue = (snap) => {
      const newConfig = snap.val();
      dispatch(configActions.setConfigAction(newConfig));
    };

    const onError = (e) => {
      console.error("config.onValue", e.message);
      setError(e.message);
    };

    firebase.db.ref("config").on("value", onValue, onError);

    return () => {
      firebase.db.ref("config").off("value", onValue);
    };
  }, [dispatch]);

  if (error) {
    return <Result500 />;
  }

  return configLoaded ? props.children : <LinearProgress color="primary" />;
};

export default AppConfig;
