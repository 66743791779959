//@ts-check
import React from "react";

import { AutocompleteGenre } from "../../../../../components-cross/AutocompleteGenreField";
import useEditableField from "../hooks/useEditableField";

/** @typedef {import("../../../../../business-objects/album.fieldEditable").FieldLevelType} FieldLevelType*/
/** @typedef {import("../../../../../business-objects/album.fieldEditable").FieldTypes} FieldTypes*/

/**
 * @param {{label: string, name: string, type: FieldTypes, level: FieldLevelType, required?: boolean}} props
 */
const EditableGenre = (props) => {
  const { label, name, value, setValue, required, error } = useEditableField(
    props
  );

  const onChangeGenre = (e, newValue, reason) => {
    // TODO verifcar que no rompa y termnar de borrar este codigo
    // if (!newValue) {
    //   newValue = {
    //     genre: "",
    //     subgenre: "",
    //   };
    // }
    setValue(newValue);
  };

  return (
    <AutocompleteGenre
      label={label}
      name={name}
      value={value}
      onChange={onChangeGenre}
      required={required}
      error={!!error}
      helperText={error}
    />
  );
};

export default EditableGenre;
