import React from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles((theme) => ({
  rootResult: {
    padding: "3rem",
    textAlign: "center",
    "& .IconContainer > svg": {
      fontSize: "3.5rem",
    },
    "&.--success .IconContainer": {
      color: theme.palette.success.dark,
    },
    "&.--info .IconContainer": {
      color: theme.palette.info.dark,
    },
    "&.--warning .IconContainer": {
      color: theme.palette.error.main,
    },
  },
  title: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  buttonsContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const mapIconStatus = {
  success: CheckCircleIcon,
  info: InfoIcon,
  warning: WarningIcon,
};

/**
 *
 * @param {{
 *  status: "success" | "info" | "warning";
 *  title: string;
 *  subTitle?: string;
 *  backLabel?: string
 *  onBack?: Function | null
 * }} props
 */
const Result = ({
  status,
  title,
  subTitle,
  backLabel = "volver",
  onBack = null,
}) => {
  const classes = useStyles();

  let Icon = mapIconStatus[status] || InfoIcon;

  return (
    <div className={classes.rootResult + " --" + status}>
      <div className="IconContainer">{<Icon />}</div>
      <Typography className={classes.title} variant="h5">
        {title}
      </Typography>
      {subTitle && (
        <Typography variant="h6" color="textSecondary">
          {subTitle}
        </Typography>
      )}
      {onBack && (
        <div className={classes.buttonsContainer}>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={onBack}
          >
            {backLabel}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Result;
