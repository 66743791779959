// @ts-check
/** @typedef {import('../wizardStore').WizardNewAlbumState} WizardNewAlbumState */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */

const TYPE = "WIZARD_CLEAR";

/**
 * @return {Object}
 */
export const clearAction = () => {
  return {
    type: TYPE,
  };
};

/**
 * @param {WizardNewAlbumState} state
 * @param {object} action
 * @return {object}
 */
export const clearReducer = (state, action) => {
  return state;
};

export default {
  action: clearAction,
  reducer: clearReducer,
  TYPE,
};
