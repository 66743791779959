// @ts-check
import produce from "immer";

/** @typedef {import('../cartStore').CartState} CartState */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */

const TYPE = "SET_SHOW_CONFIRMCHECKOUT";

/**
 *
 * @param {boolean} show
 * @return {object}
 */
export const setShowConfirmCheckoutAction = (show) => {
  return {
    type: TYPE,
    show,
  };
};

/**
 * @param {CartState} state
 * @param {object} action
 * @return {object}
 */
export const setShowConfirmCheckoutReducer = (state, action) => {
  return produce(state, (draft) => {
    draft.isShowConfirmCheckout = action.show;
  });
};

export default {
  action: setShowConfirmCheckoutAction,
  reducer: setShowConfirmCheckoutReducer,
  TYPE,
};
