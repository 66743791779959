// @ts-check
import { useMemo } from "react";

import useAlbumErrors from "./useAlbumErrors";
import useTrackOpened from "./useTrackOpened";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../store").StateStore} StateStore */

/**
 * @return {{
 *  hasErrors: boolean,
 * }}
 */
export const useTrackErrors = () => {
  const { errors, hasErrors } = useAlbumErrors();
  const track = useTrackOpened();

  const hasErrorsTrack = useMemo(() => {
    if (!hasErrors || !errors || !track) {
      return false;
    }

    const part = `tracks/${track.isrc}/`;

    return Object.keys(errors).some((key) => key.indexOf(part) > -1);
  }, [hasErrors, errors, track]);

  return {
    hasErrors: hasErrorsTrack,
  };
};

export default useTrackErrors;
