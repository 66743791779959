import React from "react";
import { Paper, Typography, Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Countdown from "react-countdown";
import DialogVideo from "./DisabledVideo";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(3),
  },
  quedan: {
    textAlign: "center",
    maxWidth: "500px",
    margin: "auto",
  },
  updating: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "1rem",
    backgroundColor: "white",
    maxWidth: "650px",
    margin: "auto",
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  timer: {
    textAlign: "center",
  },
  horadiaminseg: {
    textAlign: "center",
    color: "green",
    fontSize: "1rem",
    "& span": {
      backgroundColor: "black",
      color: "white",
      margin: "0 5px",
      padding: "1px 7px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    updating: {
      maxWidth: "500px",
    },
    timer: {
      fontSize: "3rem",
    },
    horadiaminseg: {
      fontSize: ".7rem",
    },
  },
}));

const Disabled = () => {
  const [showVideo, setShowVideo] = React.useState(false);

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography variant="h4" className={classes.quedan} color="primary">
        Nos vamos a retrasar un pelín, te pedimos mil disculpas. 🙏
      </Typography>

      <Typography variant="body1" className={classes.quedan} color="black">
        Estamos trabajando sin descanso -literalmente 😵- para adaptarnos a los
        cambios que, como sabes, operan en nuestra industria. No tengas dudas,
        nuestro único objetivo es seguir ofreciéndote el mejor servicio posible.
        Sólo necesitamos unas pocas horas más.
      </Typography>

      <Typography variant="h5" className={classes.quedan} color="primary">
        El sistema de regalías estará disponible en...
      </Typography>

      <Paper className={classes.updating}>
        <Typography variant="h1" className={classes.timer}>
          <Countdown date={"2024-03-08T23:59:59"} />
        </Typography>

        <Typography variant="button" className={classes.horadiaminseg}>
          <span>Días</span>
          <span>Horas</span>
          <span>Min</span>
          <span>Seg</span>
        </Typography>
      </Paper>

      <Typography variant="h4" className={classes.quedan} color="primary">
        ❤️ Gracias por la espera ❤️
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={() => setShowVideo(true)}
        style={{ marginTop: "1rem" }}
      >
        ¿Por qué hacemos este cambio?
      </Button>

      <DialogVideo open={showVideo} onClose={() => setShowVideo(false)} />
    </Box>
  );
};

export default Disabled;
