import React, { useEffect, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import ExtendedUserContext from "../contexts/ExtendedUserContext";
import { doSignOut } from "../firebase/auth";
import { db } from "../firebase";

const getDomain = () => {
  const { host } = window.location;

  switch (true) {
    case host.includes("localhost"):
      return "localhost";
    case host.includes("musik.digital"):
      return "musik.digital";
    case host.endsWith("musik-digital.web.app"):
    case host.endsWith("musik-digital.firebaseapp.com"):
      return "musik-digital.web.app";
    default:
      return host;
  }
};

const getSubdomain = (account) => {
  if (!account || !account.name) return null;

  return (
    account.subdomain || account.name.replace(/[\s\.]+/g, "").toLowerCase()
  );
};

const SubdomainEnforcer = () => {
  const [subdomain, setSubdomain] = useState(null);
  const [account, setAccount] = useState(null);
  const { pathname } = useLocation();
  const user = useContext(ExtendedUserContext);

  useEffect(() => {
    if (user) {
      const fetchAccount = async () => {
        const account = await db.getAccount({ name: user.account });
        const subdo = getSubdomain(account);

        console.log(subdo);

        setSubdomain(subdo);
        setAccount(account);
      };

      fetchAccount();
    }
  }, [user]);

  // useEffect(() => {
  //   if (account) {
  //     const subdo = account.subdomain || getSubdomain(account.name);
  //     console.log(subdo);
  //     setSubdomain(subdo);
  //   }
  // }, [account]);

  useEffect(() => {
    const { host, protocol, port } = window.location;

    if (subdomain && !host.startsWith(subdomain)) {
      doSignOut();
      const domain = getDomain(host);
      const portString = port ? `:${port}` : "";
      const correctUrl = `${protocol}//${subdomain}.${domain}${portString}${pathname}`;
      window.location.href = correctUrl;
    }
  }, [subdomain, pathname]);

  return null;
};

export default SubdomainEnforcer;
