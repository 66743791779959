import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyle = makeStyles({
  explicitIcon: {
    color: "#FFF",
    width: "20px",
    height: "22px",
    margin: "0.2rem",
    display: "inline-block",
    padding: "0.1rem",
    background: "#C4C4C4",
    fontSize: ".9em",
    textAlign: "center",
  },
});

const ExplicitIcon = () => {
  const classes = useStyle();
  return <span className={classes.explicitIcon}>E</span>;
};

export default ExplicitIcon;
