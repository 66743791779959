import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useFormikContext } from "formik";

import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import wizardActions from "../../../../../reducers/wizardNewAlbum/wizardActions";
import useStorageFile from "../../../../../hooks/useStorageFile";
import MessageModal from "../../Edit/components/MessageModal";

/** @typedef {import('../../../../../reducers/store').StateStore} StateStore */

const DROPZONE_SIZE = "310px";

registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginImageTransform,
  FilePondPluginImageCrop,
  FilePondPluginFileValidateType,
  FilePondPluginImageValidateSize
);

const labelsEs = {
  labelIdle:
    "<div class='PreviewEmpty-title'>Portada</div> " +
    " <div class='PreviewEmpty-description'>" +
    "   Arrastra o haz click" +
    " </div>",
  labelFileProcessing: "Subiendo",
  labelTapToRetry: "",
  labelTapToUndo: "",
  labelFileProcessingComplete: "",
  labelTapToCancel: "",
  labelFileTypeNotAllowed: "Tipo de archivo no valido",
  fileValidateTypeLabelExpectedTypes: "Suba archivos de imagenes",
  imageValidateSizeLabelImageSizeTooSmall: "La imagen es muy chica",
  imageValidateSizeLabelExpectedMinSize:
    "Tamaño minimo {minWidth} × {minHeight} px",
};

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: DROPZONE_SIZE,
    minHeight: DROPZONE_SIZE,
    height: DROPZONE_SIZE,
    position: "relative",

    "&.--error .filepond--drop-label ": {
      borderColor: theme.palette.error.dark,
    },
    "& .filepond--drop-label": {
      bottom: 0,
      height: "inherit",
      color: "inherit",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "rgba(0, 0, 0, 0.23)",
      cursor: "pointer",
      "& label": {
        cursor: "pointer",
      },
    },
    "& .filepond--panel-root": {
      backgroundColor: theme.palette.action.hover,
    },
    "& .PreviewEmpty-title": {
      width: "100%",
      fontSize: "1.3rem",
    },
    "& .PreviewEmpty-description": {
      width: "100%",
      color: theme.palette.text.secondary,
      textAlign: "center",
    },
    "& .filepond--file-info": {
      transform: "inherit !important",
    },
    "& .filepond--file-status": {
      transform: "translate(-50%, -50%) !important",
      position: "absolute",
      top: "50%",
      left: "50%",
      alignItems: "center",
      willChange: "inherit",
      "& > .filepond--file-status-main": {
        opacity: "inherit",
        fontSize: "0.9rem",
      },
      "& > .filepond--file-status-sub": {
        opacity: "inherit",
        fontSize: "0.8rem",
      },
    },
    [theme.breakpoints.up("sm")]: {
      width: DROPZONE_SIZE,
      height: DROPZONE_SIZE,
    },
    // [theme.breakpoints.up("lg")]: {
    //   width: "22vw",
    //   height: "22vw",
    // },
    // [theme.breakpoints.up("xl")]: {
    //   width: "15vw",
    //   height: "15vw",
    // },
  },

  previewContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 2,
    width: "100%",
    height: DROPZONE_SIZE,
    minWidth: DROPZONE_SIZE,
    minHeight: DROPZONE_SIZE,
    "& img": {
      borderRadius: "0.1rem",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "rgba(0, 0, 0, 0.23)",
      width: "100%",
      // height: "30vh",
      minWidth: DROPZONE_SIZE,
      minHeight: DROPZONE_SIZE,
    },
    "& .PreviewContainer-deleteButton": {
      position: "absolute",
      right: "0.5rem",
      bottom: "0.5rem",
      color: "white",
      backgroundColor: "rgba(0,0,0,0.25)",
    },
    "& .PreviewContainer-loading": {
      transform: "translate(-50%, -50%) !important",
      position: "absolute",
      top: "50%",
      left: "50%",
      color: "white",
      backgroundColor: "rgba(0,0,0,0.25)",
      padding: "1rem",
      borderRadius: "0.5rem",
      "& .MuiCircularProgress-svg": {
        color: "white",
      },
    },
    [theme.breakpoints.up("sm")]: {
      width: DROPZONE_SIZE,
      height: DROPZONE_SIZE,
    },
    // [theme.breakpoints.up("lg")]: {
    //   width: "22vw",
    //   height: "22vw",
    // },
    // [theme.breakpoints.up("xl")]: {
    //   width: "15vw",
    //   height: "15vw",
    // },
  },
  errorMessage: {
    color: "#f44336",
    position: "absolute",
    bottom: "-20px",
    fontSize: "12px",
  },
}));

/** Ref: https://pqina.nl/filepond/docs/patterns/api/server/ */
const serverProcess = async (
  dispatch,
  fieldName,
  file,
  metadata,
  load,
  error,
  progress,
  abort,
  transfer,
  options
) => {
  const result = await dispatch(
    wizardActions.uploadPhoto("cover", [file], (loadedBytes, totalBytes) => {
      progress(true, loadedBytes, totalBytes);
    })
  );

  await dispatch(wizardActions.saveTemporally());

  progress(true, 100, 100);

  load(result);
};

export const StepAlbumCover = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [previewDone, setPreviewDone] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const { errors, setFieldValue } = useFormikContext();

  const error = get(errors, "photos.cover");
  const hasError = !!error;

  const cover = useSelector(
    /**
     * @param {StateStore} state
     * @return {Object}
     */
    (state) => state.wizardNewAlbumState.album.photos?.cover
  );

  const [coverUrl, loadingUrl] = useStorageFile(cover?.storageLocation);

  useEffect(() => {
    if (hasError && coverUrl) {
      setFieldValue("photos.cover", cover);
    }
  }, [coverUrl, cover, hasError, setFieldValue]);

  const handleDoRemove = async () => {
    setPreviewDone(false);
    setShowConfirmDelete(false);
    await dispatch(wizardActions.removePhoto("cover"));
    await dispatch(wizardActions.saveTemporally());
    setFieldValue("photos.cover", null);
  };

  return (
    <div className={classnames(classes.container, { "--error": hasError })}>
      {!previewDone && (
        <FilePond
          name="cover"
          {...labelsEs}
          server={{
            process: serverProcess.bind(null, dispatch),
          }}
          status={3}
          credits={null}
          allowRevert={false}
          allowMultiple={false}
          allowImageCrop
          imageResizeTargetWidth={600}
          imageCropAspectRatio="1:1"
          stylePanelAspectRatio="1:1"
          stylePanelLayout="compact"
          allowImageValidateSize
          imageValidateSizeMinWidth="500"
          imageValidateSizeMinHeight="500"
          allowFileTypeValidation
          acceptedFileTypes={["image/*"]}
          styleButtonRemoveItemPosition="center bottom"
          styleButtonProcessItemPosition="right bottom"
          styleLoadIndicatorPosition="right bottom"
          styleProgressIndicatorPosition="right bottom"
        />
      )}

      {(loadingUrl || coverUrl) && (
        <div className={classes.previewContainer}>
          {(loadingUrl || !previewDone) && (
            <div className="PreviewContainer-loading">
              <CircularProgress />
            </div>
          )}
          {coverUrl && (
            <img
              src={coverUrl}
              alt="cover"
              onLoad={() => setPreviewDone(true)}
            />
          )}
          {previewDone && (
            <IconButton
              className="PreviewContainer-deleteButton"
              onClick={() => setShowConfirmDelete(true)}
              aria-label="Borrar Imagen"
            >
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      )}

      <div className={classes.errorMessage}>{error}</div>

      {showConfirmDelete && (
        <MessageModal
          openModal={true}
          setOpenModal={setShowConfirmDelete}
          isCustom
          customAction={() => setShowConfirmDelete(false)}
          action={handleDoRemove}
          title="Borrar"
          description="¿Está seguro de borrar la imagen de portada?"
          acceptButton="Borrar"
          cancelButton="Cancelar"
          dangerAction
        />
      )}
    </div>
  );
};

export default StepAlbumCover;
