import React from "react";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import stringSimilarity from "string-similarity";

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </strong>
          );
        })}
      </div>
    </MenuItem>
  );
}

function renderSuggestionsContainer(options) {
  const { containerProps, children } = options;

  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
}

function getSuggestionValue(suggestion) {
  return suggestion.label;
}

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: "relative"
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    height: 250,
    overflow: "scroll",
    backgroundColor: "white"
  },
  suggestion: {
    display: "block"
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  }
});

class Autocomplete extends React.Component {
  state = {
    value: "",
    lastGoodValue: "",
    suggestions: []
  };

  renderInput(inputProps) {
    const { classes, ref, error, helperText, ...other } = inputProps;

    return (
      <TextField
        fullWidth
        label={inputProps.label}
        error={error}
        helperText={helperText}
        inputProps={{ "data-hj-whitelist": true }}
        InputProps={{
          inputRef: ref,
          classes: {
            input: classes.input
          },
          ...other
        }}
      />
    );
  }

  getSuggestions(value) {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    const suggestions = this.props.allopts;

    return inputLength === 0
      ? []
      : suggestions.filter(suggestion => {
          const keep =
            (!inputValue ||
              suggestion.label
                .toLowerCase()
                .indexOf(inputValue.toLowerCase()) !== -1) &&
            count < 50;

          if (keep) {
            count += 1;
          }

          return keep;
        });
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  handleSuggestionHighlighted = ({ suggestion }) => {
    if (!!suggestion) {
      this.setState({
        lastGoodValue: suggestion
      });
    }
  };

  handleChange = (event, { newValue }) => {
    const { name, onChange } = this.props;

    onChange({
      target: {
        name: name,
        value: newValue
      }
    });
  };

  handleBlur = (event, { highlightedSuggestion }) => {
    const { name, value, allopts, onChange } = this.props;

    if (highlightedSuggestion) {
      onChange({
        target: {
          name: name,
          value: highlightedSuggestion.label
        }
      });
    }

    // can we guess it?
    else {
      const allOptions = allopts.map(x => x.label);
      const matches = stringSimilarity.findBestMatch(value, allOptions);

      if (matches.bestMatch.rating > 0.5) {
        // fraction represents the strictness of the guessing
        onChange({
          target: {
            name: name,
            value: matches.bestMatch.target
          }
        });
      } else {
        onChange({
          target: {
            name: name,
            value: ""
          }
        });
      }
    }
  };

  // <input autocomplete="off" aria-invalid="false" aria-required="false" class="MuiInput-input-356" name="genre" value="Arabic|Khaleeji" type="text">

  // <input autocomplete="off" role="combobox" aria-autocomplete="list" aria-owns="react-autowhatever-1" aria-expanded="false" classes="[object Object]" name="genre" label="Género y subgénero *" value="World Music|Khaleeji" type="text">

  render() {
    const {
      classes,
      onChange,
      required,
      error = false,
      helperText,
      disabled = false,
      ...props
    } = this.props;

    return (
      <Autosuggest
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion
        }}
        renderInputComponent={this.renderInput}
        suggestions={this.state.suggestions}
        onSuggestionHighlighted={this.handleSuggestionHighlighted}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        renderSuggestionsContainer={renderSuggestionsContainer}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        highlightFirstSuggestion={true}
        inputProps={{
          classes,
          onChange: this.handleChange,
          onBlur: this.handleBlur,
          name: props.name,
          label: `${props.label}${required ? " *" : ""}`,
          placeholder: props.placeholder,
          value: props.value,
          error: error,
          helperText: helperText,
          disabled: disabled
        }}
      />
    );
  }
}

Autocomplete.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Autocomplete);
