import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import CssBaseline from "@material-ui/core/CssBaseline";
import Navigation from "./Navigation";
import Footer from "./Footer";
import SignInPage from "./authentication";
import ForgotPasswordPage from "./authentication/ForgotPassword";
import AlbumsPage from "../scenes/Albums";
import AlbumNewWizard from "../scenes/Albums/scenes/wizard";
import AlbumPreview from "../scenes/Albums/scenes/AlbumPreview";
import AlbumEditor from "../scenes/Albums/scenes/AlbumEditor";
import Album404 from "../scenes/Albums/components/Album404";
import UploadedPage from "./UploadedPage";
import AccountPage from "./AccountPage";
import ReportingApp from "./reporting";
import Page from "./Page";
import HistoryPage from "./HistoryPage";
import {
  MuiThemeProvider,
  createTheme,
  withStyles,
} from "@material-ui/core/styles";
import * as routes from "../constants/routes";
import withAuthentication from "./withAuthentication";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "typeface-roboto";
import EmailVerification from "./EmailVerification";
import Bulletin from "./Bulletin";
import SubdomainEnforcer from "./SubdomainEnforcer";
import PhoneVerification from "./PhoneVerification";
import Hotjar from "../utils/components/Hotjar";
import AppConfig from "./AppConfig";
import ManageUsersPage from "../scenes/admin/users";
import ManageAccounts from "../scenes/admin/accounts";
import Fingerprint from "./fingerprinting";
import ManageBlacklist from "../scenes/admin/blacklist/index";
import Royalties from "./royalties";
import AdminRoyalties from "../scenes/admin/royalties";
import AdminArtists from "../scenes/admin/artists";
import MessageToAllUsers from "./MessageToAllUsers";

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  // palette: {
  //   primary: grey,
  //   secondary: amber,
});

const styles = (theme) => ({
  root: {
    overflowX: "hidden",
    // position: "relative",
    // paddingBottom: "5vh",
  },
});

const App = ({ authUser, classes }) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Hotjar uid={authUser ? authUser.uid : ""} />
      <Router>
        <MuiThemeProvider theme={theme}>
          <ToastContainer />

          <div className={classes.root} style={{ minHeight: "100vh" }}>
            <CssBaseline />

            <AppConfig>
              <PhoneVerification />
              <EmailVerification />

              <Navigation />

              <Switch>
                <Route
                  exact
                  path={routes.SIGN_IN}
                  render={() =>
                    authUser ? (
                      <Redirect to={routes.ALBUMS} />
                    ) : (
                      <SignInPage isSignIn />
                    )
                  }
                />
                <Route
                  exact
                  path={routes.SIGN_UP}
                  render={() =>
                    authUser ? <Redirect to={routes.ALBUMS} /> : <SignInPage />
                  }
                />
                <Route
                  exact
                  path={routes.REPORTING}
                  render={() => <Redirect to={routes.ROYALTIES} />}
                />
                <Route
                  exact
                  path={routes.ROYALTIES}
                  render={() => <Royalties />}
                />
                <Route
                  exact
                  path={routes.PAYMENTS}
                  render={() => <ReportingApp tab={1} />}
                />
                <Route
                  exact
                  path={routes.ALBUMS}
                  children={() => <AlbumsPage />}
                />
                <Route
                  exact
                  path={routes.ALBUM_NOTEXISTS}
                  component={Album404}
                />
                <Route exact path={routes.UPLOAD} component={AlbumNewWizard} />
                <Route exact path={routes.HISTORY} component={HistoryPage} />
                <Route exact path={routes.ACCOUNT} component={AccountPage} />
                <Route
                  exact
                  path={routes.PASSWORD_FORGET}
                  render={() => <ForgotPasswordPage />}
                />
                <Route
                  exact
                  path={routes.UPLOADED}
                  render={() => <UploadedPage />}
                />
                <Route
                  exact
                  path={routes.EDITED}
                  render={() =>
                    authUser ? (
                      <UploadedPage editing={true} />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />
                <Route
                  exact
                  path={routes.LEGAL_TERMS}
                  render={() => (
                    <Page
                      slug={routes.LEGAL_TERMS}
                      account={window.location.hostname}
                    />
                  )}
                />
                <Route
                  exact
                  path={routes.ACUERDO_AUTOFACTURACION}
                  render={() => (
                    <Page
                      slug={routes.ACUERDO_AUTOFACTURACION}
                      account={window.location.hostname}
                    />
                  )}
                />
                <Route path={routes.ALBUM_VIEW} component={AlbumPreview} />
                <Route path={routes.ALBUM_EDIT} component={AlbumEditor} />
                <Route path={routes.MANAGE_USERS} component={ManageUsersPage} />
                <Route
                  path={routes.MANAGE_ACCOUNTS}
                  component={ManageAccounts}
                />
                <Route
                  path={routes.MANAGE_ROYALTIES}
                  render={() => <AdminRoyalties />}
                />
                <Route
                  path={routes.MANAGE_ARTISTS}
                  render={() => <AdminArtists />}
                />
                <Route path={routes.BLACKLIST} component={ManageBlacklist} />
                <Redirect to="/" />
              </Switch>
            </AppConfig>
            <MessageToAllUsers />
            {/* {authUser && <MessageToAllUsers />} */}
            <Bulletin />
            <SubdomainEnforcer />
            <Fingerprint />
          </div>

          <Footer />
        </MuiThemeProvider>
      </Router>
    </MuiPickersUtilsProvider>
  );
};

export default withAuthentication(withStyles(styles)(App));
