import React, { useEffect, useState } from "react";
import { makeStyles, Box, LinearProgress } from "@material-ui/core";
import UserSearch from "../components/userSearch";
import { functions, fbroot } from "../../../firebase";

const db = fbroot.database();

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(5),
    maxWidth: "90vw",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(5),
  },
}));

const searchBlacklist = functions.httpsCallable("searchBlacklist");

const ManageBlacklist = () => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);

  const classes = useStyles();

  const handleSearch = async () => {
    setLoading(true);
    const { data } = await searchBlacklist({ keyword: query });
    setResults(data);
    setLoading(false);
  };

  const handleChange = ({ target }) => {
    setQuery(target.value);
  };

  const clearSearch = () => {
    setQuery("");
    setLoading(false);
  };

  return (
    <Box className={classes.root}>
      <div className={classes.toolbar}>
        <UserSearch
          handleChange={handleChange}
          handleSearch={handleSearch}
          clearSearch={clearSearch}
        />
      </div>

      <Box mt={5}>
        {loading ? (
          <LinearProgress variant="indeterminate" />
        ) : (
          <div>
            {results.map((r) => {
              return (
                <div key={r.uid}>
                  {r.value} ({r.label}) <a href="">{r.uid}</a>]
                </div>
              );
            })}
          </div>
        )}
      </Box>
    </Box>
  );
};

export default ManageBlacklist;
