import React, { useEffect, useState } from "react";
import Lottie from "lottie-react-web";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { Redirect } from "react-router-dom";
import * as routes from "../../../../../constants/routes";

const chooseAnimation = (genre) => {
  switch (genre) {
    case "Jazz":
      return "trumpet";

    case "Classical":
      return "violin";

    case "Electronic":
    case "Latin":
    case "Hip-hop/Rap":
      return "speakers";

    // case "Children":
    //   return "celebration";

    case "Country":
    case "Folk":
      return "lady-guitar";

    case "Rock":
    case "Metal":
    case "Punk":
    case "Pop":
    case "R&B":
    case "Reggae":
      return "electric-guitar";

    default:
      return "speakers";
  }
};

const PreviewAnimation = ({ genre }) => {
  const [animationData, setAnimationData] = useState(false);

  useEffect(() => {
    const animation = chooseAnimation(genre);
    import(`../animations/${animation}.json`).then((animData) => {
      setAnimationData(animData);
    });
  }, [genre]);

  if (!animationData) {
    return null;
  }

  return (
    <div
      style={{
        height: "220px",
        width: "470px",
        overflow: "hidden",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "100%",
        }}
      >
        <Lottie speed={0.7} options={{ animationData }} />
      </div>
    </div>
  );
};

export default PreviewAnimation;
