import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";

const MessageModal = (props) => {
  const {
    title,
    openModal,
    setOpenModal,
    action,
    description,
    isCustom,
    customAction,
    acceptButton,
    cancelButton,
    dangerAction = false,
  } = props;

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {isCustom ? (
          <>
            <Button onClick={customAction} color="default">
              {cancelButton}
            </Button>
            <Button
              onClick={action}
              color={dangerAction ? "Secondary" : "primary"}
            >
              {acceptButton}
            </Button>
          </>
        ) : (
          <>
            <Button onClick={handleCloseModal} color="default">
              Cancelar
            </Button>
            <Button
              onClick={action}
              color={dangerAction ? "Secondary" : "primary"}
            >
              Aceptar
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default MessageModal;
