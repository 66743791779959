import React from "react";
import { makeStyles } from "@material-ui/core";
import { amber, red, green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 12,
    height: 12,
    borderRadius: "50%",
    marginLeft: 12,
  },
}));

const Bola = ({ status, CID }) => {
  const classes = useStyles();

  const color =
    status === "disabled" ? red[500] : !CID ? green[500] : amber[500];

  return (
    <div className={classes.root} style={{ background: color }}>
      {" "}
    </div>
  );
};

export default Bola;
