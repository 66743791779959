import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";

const DonutChart = ({ title, data }) => {
  const [chartData, setChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    if (title) {
      setChartOptions({
        pieHole: 0.4,
        is3D: false,
        title,
        tooltip: { isHtml: true },
      });
    }
  }, [title]);

  useEffect(() => {
    if (data) {
      setChartData(data);
    }
  }, [data]);

  return (
    <div>
      <Chart
        chartType="PieChart"
        width="100%"
        height="400px"
        data={[
          ["Metric", "Value", { type: "string", role: "tooltip" }],
          ...chartData,
        ]}
        options={chartOptions}
      />
    </div>
  );
};

export default DonutChart;
