import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import useEditableField from "../hooks/useEditableField";

/** @typedef {import("../../../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable*/
/** @typedef {import("../../../../../business-objects/album.fieldEditable").FieldLevelType} FieldLevelType*/
/** @typedef {import("../../../../../business-objects/album.fieldEditable").FieldTypes} FieldTypes*/

/**
 * @param {{
 *  label: string,
 *  name: string,
 *  type: FieldTypes,
 *  level: FieldLevelType,
 *  options: Array<Any>
 *  required?: boolean
 * }} props
 */
const EditableAutocomplete = (props) => {
  const { path, label, value, setValue, required, error } = useEditableField(
    props
  );
  const { options } = props;

  const handleOnChange = (event, newValue) => {
    setValue(newValue || "");
  };

  return (
    <Autocomplete
      name={path}
      label={label}
      options={options}
      onChange={handleOnChange}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          error={!!error}
          helperText={error}
          required={required}
        />
      )}
    />
  );
};

export default EditableAutocomplete;
