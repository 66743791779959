// @ts-check
import produce from "immer";
import findTrackByOrder from "../utils/findTrackByOrder";
import getTrackOpened from "../utils/getTrackOpened";

/** @typedef {import('../../store').AlbumState} AlbumState */

const TYPE = "NEXT_ALBUM_TRACK";

/**
 * @return {Object}
 */
export const trackNextAction = () => {
  return {
    type: TYPE,
  };
};

/**
 * @param {AlbumState} state
 * @param {object} action
 * @return {object}
 */
export const trackNextReducer = (state, action) => {
  const currentTrack = getTrackOpened(state);

  if (!currentTrack) {
    return state;
  }

  const album = state.albums[state.openAlbum];

  const len = Object.keys(album.tracks).length;

  if (currentTrack.order < len) {
    const nextTrack = findTrackByOrder(album.tracks, currentTrack.order + 1);

    if (!nextTrack) {
      return state;
    }

    return produce(state, (draft) => {
      draft.openTrack = nextTrack.isrc;
    });
  }
  return state;
};

export default {
  action: trackNextAction,
  reducer: trackNextReducer,
  TYPE,
};
