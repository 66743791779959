// @ts-check
import normalizeTitle from "./normalizeTitle";

/**
 * Generate the title of a Track
 *
 * @param {import('./album').Track} track
 * @param {import('./album').Album} album
 * @return {string}
 */
export const getTrackTitle = (track, album) => {
  let title = "track sin título";
  if (!track || !album) {
    return title;
  }

  if (track.title) {
    title = track.title;
  }

  const parts = [title];

  if (album.live || track.live) {
    parts.push("En Vivo");
  }

  title = normalizeTitle(parts, album.lyrics_lang);

  return title;
};

export default getTrackTitle;
