import React from "react";
import Icon from "@material-ui/core/Icon";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import Box from "@material-ui/core/Box";

const VerificationStatus = ({ user }) => {
  const { verified } = user;

  let icon;

  if (!verified) {
    icon = <Icon style={{ color: "#eee" }}>verified_user</Icon>;
  }
  if (verified === true) {
    icon = <Icon style={{ color: green[500] }}>verified_user</Icon>;
  }
  if (verified === "pending") {
    icon = <Icon style={{ color: amber[500] }}>timelapse</Icon>;
  }

  return <Box style={{ display: "flex", alignItems: "center" }}>{icon}</Box>;
};

export default VerificationStatus;
