// @ts-check
import { db } from "../../../firebase";
import { openAlbumField } from "../../albumActions";
import { processAlbumChange } from "../../cart/cartActions";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album").ArtistRole} ArtistRole */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldActionType} FieldActionType */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */
/** @typedef {import("../../../reducers/store").ThunkActionFunction} ThunkActionFunction */

/**
 * Add an Artist to a track
 * @param {string} upc
 * @param {string} isrc
 * @param {ArtistRole} role
 * @return {Function}
 */
export const addTrackArtist = (upc, isrc, role) => {
  /**
   * @param {function} dispatch
   */
  return async function addTrackArtistThunk(dispatch) {
    const idArtist = await db.addTrackArtist(upc, isrc, role.label);
    const path = `tracks/${isrc}/artists/${idArtist}`;
    dispatch(openAlbumField(`${path}/name`));

    /** @type {FieldEditable} */
    const field = {
      label: role.label,
      path: path,
      level: "track",
      type: "artist",
      artistRole: role,
      isrcTrack: isrc,
    };

    // send to cart process
    await dispatch(processAlbumChange(upc, field, "add"));

    return idArtist;
  };
};
