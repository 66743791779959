//@ts-check
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckIcon from "@material-ui/icons/Check";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import HLine from "../../../../../components-cross/HLine";

/**
 * @typedef {import("react").MouseEvent} MouseEvent
 */

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  thanks: {
    maxWidth: "650px",
    textAlign: "center",
    "& .Thanks-header": {
      "& svg": {
        color: theme.palette.success.dark,
        fontSize: "5rem",
      },
      "& .Thanks-headerLabel": {
        fontSize: "1.4rem",
        marginTop: 0,
      },
    },

    "& .ItemHeader": {
      marginTop: "2rem",
      color: "#CCAA66",
      fontSize: "0.85rem",
      marginBottom: 0,
      textAlign: "left",
      fontWeight: "normal",
    },

    "& .Item": {
      fontSize: "0.85rem",
      display: "flex",
      alignItems: "center",
      "& svg": {
        fontSize: "1rem",
        marginRight: "0.4rem",
      },
      "& svg.check": {
        color: theme.palette.success.dark,
      },
      "& svg.arrow": {
        color: theme.palette.primary.dark,
      },
    },

    "& .Thanks-line": {
      marginTop: "2rem",
      marginBottom: "2rem",
    },

    "& .Thanks-footer": {
      marginBottom: "2rem",
    },
  },
}));

/**
 *
 * @param {{
 *  label: import("react").ReactNode,
 *  variant?: "check" | "arrow"
 * }} props
 * @returns
 */
const Item = ({ label, variant = "check" }) => {
  const Icon = variant === "check" ? CheckIcon : ArrowForwardIcon;

  return (
    <div className="Item">
      <Icon className={variant} />
      <label>{label}</label>
    </div>
  );
};

const ItemHeader = ({ label }) => {
  return <h5 className="ItemHeader">{label}</h5>;
};

/**
 *
 * @param {{
 *  upc: string,
 *  onClickContinue: Function
 * }} props
 * @returns
 */
const Thanks = ({ upc, onClickContinue }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.thanks}>
        <div className="Thanks-header">
          <CheckCircleIcon />
          <h2 className="Thanks-headerLabel">¡Va tomando forma!</h2>
        </div>

        <ItemHeader label="Ya Tenemos..." />
        <Item label="Título e información del álbum" />
        <Item label="Portada y archivos de audio" />
        <Item label="Artistas y otros créditos del álbum (serán visibles en todos los tracks)" />
        <Item label={`UPC asignado: ${upc}`} />

        <ItemHeader
          label={
            <>
              <b>Siguiente paso:</b> info específica de track
            </>
          }
        />
        <Item
          variant="arrow"
          label="Título e información adicional de cada track"
        />
        <Item
          variant="arrow"
          label="Feats, colaboraciones y otros créditos de cada track (serán visibles sólo en ese track)"
        />

        <HLine className="Thanks-line" />

        <div className="Thanks-footer">
          <Button
            type="button"
            variant="contained"
            color="primary"
            size="small"
            onClick={() => onClickContinue()}
          >
            continuar con info de tracks
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Thanks;
