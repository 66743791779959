// @ts-check
import produce from "immer";

/** @typedef {import('../wizardStore').WizardNewAlbumState} WizardNewAlbumState */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */

const TYPE = "WIZARD_SET_IS_SINGLE";

/**
 * @param {boolean} isSingle
 * @return {Object}
 */
export const setIsSingleAction = (isSingle) => {
  return {
    type: TYPE,
    isSingle,
  };
};

/**
 * @param {WizardNewAlbumState} state
 * @param {object} action
 * @return {object}
 */
export const setIsSingleReducer = (state, action) => {
  // console.log("setIsSingleReducer", state, action);
  return produce(state, (draft) => {
    draft.isSingle = action.isSingle;
  });
};

export default {
  action: setIsSingleAction,
  reducer: setIsSingleReducer,
  TYPE,
};
