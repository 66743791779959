import React from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
// import SoundcloudIcon from "@material-ui/icons/Soundcloud";

const SocialNetwork = ({ name }) => {
  if (!name) return null;

  switch (name) {
    case "facebook":
      return <FacebookIcon />;
    case "instagram":
      return <InstagramIcon />;
    case "twitter":
      return <TwitterIcon />;
    case "youtube":
      return <YouTubeIcon />;
    // case "soundcloud":
    //   return <SoundcloudIcon />;
    default:
      return null;
  }
};

export default SocialNetwork;
