// @ts-check
import clear from "./actions/clear";
import setAlbum from "./actions/setAlbum";
import setArtistRolesAvailablesAction from "./actions/setArtistRolesAvailables";
import setUploadKey from "./actions/setUploadKey";
import setPhoto from "./actions/setPhoto";
import setSavedAlbum from "./actions/setSavedAlbum";
import setShowVersionField from "./actions/setShowVersionField";
import setShowUpcField from "./actions/setShowUpcField";
import setInitNewAlbum from "./actions/setInitNewAlbum";
import setNewAlbumPayload from "./actions/setNewAlbumPayload";
import setSubmiting from "./actions/setSubmiting";
import setIsSingle from "./actions/setIsSingle";
import trackAdd from "./actions/trackAdd";
import trackBusy from "./actions/trackBusy";
import trackOrder from "./actions/trackOrder";
import trackProgress from "./actions/trackProgress";
import trackRemove from "./actions/trackRemove";
import trackUploadDone from "./actions/trackUploadDone";
import updateIsUploadingTracks from "./actions/updateIsUploadingTracks";
import { ARTIST_ROLE_PRIMARY } from "../../business-objects";
import { newArtist } from "./wizardUtils";

/** @typedef {import('./wizardStore').WizardNewAlbumState} WizardNewAlbumState */
/** @typedef {import('./wizardStore').NewAlbumPayload} NewAlbumPayload */

const initialAlbum = {
  title: "",
  titleLabel: "",
  genreSubgenre: null,
  lyrics_lang: "",
  price: "",
  release: "",
  release_digital: "",
  live: false,
  country: "",
  upc: "",
  artists: [newArtist(ARTIST_ROLE_PRIMARY, true)],
  photos: {},
};

/**
 * @type {WizardNewAlbumState}
 */
const INITIAL_STATE = {
  account: {},
  activeStep: 0,
  activeTrak: 0,
  uploadingKey: "",
  album: { ...initialAlbum },
  tracks: [],
  artistRolesAvailables: [],
  submiting: false,
  showVersionField: false,
  showUpcField: false,
  showThanks: false,
  // @ts-ignore
  savedAlbum: null,
  isUploadingTracks: false,
};

function wizardReducer(state, action) {
  if (!state) {
    state = INITIAL_STATE;
  }
  switch (action.type) {
    case clear.TYPE: {
      return INITIAL_STATE;
    }
    case setAlbum.TYPE: {
      return setAlbum.reducer(state, action);
    }
    case setArtistRolesAvailablesAction.TYPE: {
      return setArtistRolesAvailablesAction.reducer(state, action);
    }
    case setUploadKey.TYPE: {
      return setUploadKey.reducer(state, action);
    }
    case setPhoto.TYPE: {
      return setPhoto.reducer(state, action);
    }
    case setSavedAlbum.TYPE: {
      return setSavedAlbum.reducer(state, action);
    }
    case setShowVersionField.TYPE: {
      return setShowVersionField.reducer(state, action);
    }
    case setShowUpcField.TYPE: {
      return setShowUpcField.reducer(state, action);
    }
    case setInitNewAlbum.TYPE: {
      return setInitNewAlbum.reducer(state, action);
    }
    case setNewAlbumPayload.TYPE: {
      return setNewAlbumPayload.reducer(state, action);
    }
    case setSubmiting.TYPE: {
      return setSubmiting.reducer(state, action);
    }
    case setIsSingle.TYPE: {
      return setIsSingle.reducer(state, action);
    }
    case trackAdd.TYPE: {
      return trackAdd.reducer(state, action);
    }
    case trackBusy.TYPE: {
      return trackBusy.reducer(state, action);
    }
    case trackOrder.TYPE: {
      return trackOrder.reducer(state, action);
    }
    case trackProgress.TYPE: {
      return trackProgress.reducer(state, action);
    }
    case trackRemove.TYPE: {
      return trackRemove.reducer(state, action);
    }
    case trackUploadDone.TYPE: {
      return trackUploadDone.reducer(state, action);
    }
    case updateIsUploadingTracks.TYPE: {
      return updateIsUploadingTracks.reducer(state, action);
    }
    default:
      return state;
  }
}

export default wizardReducer;
