import { setLocale } from "yup";

setLocale({
  string: {
    required: "El campo no puede estar vacío",
  },
  mixed: {
    required: "El campo no puede estar vacío",
  },
});
