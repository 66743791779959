// @ts-check
import { db } from "../../../firebase";

/**
 * Add a track to an album
 * @param {string} upc
 * @param {number} oldIndex
 * @param {number} newIndex
 * @return {Function}
 */
export const trackOrder = (upc, oldIndex, newIndex) => {
  /**
   * @param {function} dispatch
   */
  return async function thunk(dispatch) {
    await db.orderAlbumTrack(upc, oldIndex, newIndex);
  };
};
