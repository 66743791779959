// @ts-check
import { db } from "../../../firebase";
import { getAlbum } from "../../albumActions";
import { keys } from "lodash";

/**
 * @typedef {import("../../../../src/business-objects/album").Album} Album
 */

/**
 * Set album price
 * @param {string} upc
 * @return {Function}
 */
export const albumPrice = (upc) => {
  /**
   * @param {function} dispatch
   */
  return async function thunk(dispatch) {
    /** @type {Album} */
    const album = await dispatch(getAlbum(upc));

    const tracksInAlbum = keys(album.tracks).length;

    let price = "";

    switch (true) {
      case tracksInAlbum <= 4:
        price = "Digital 45 (4 Tracks Max)";
        break;
      case tracksInAlbum <= 8:
        price = "EP 10 Track Max";
        break;
      case tracksInAlbum <= 10:
        price = "Budget Two";
        break;
      default:
        price = "Mid";
    }

    await db.updateAlbumFields(upc, { price });
  };
};
