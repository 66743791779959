// @ts-check
import React, { useEffect, useState, useMemo } from "react";
import { Button, Grid, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import InfoIcon from "@material-ui/icons/Info";
import InputAdornment from "@material-ui/core/InputAdornment";

import canEditTrackVersion from "../../../../../business-objects/canEditTrackVersion";

import { EditableInput } from "../../../components/EditableField";
import useTrackOpened from "../../../../../reducers/album/hooks/useTrackOpened";
import useAlbumOpened from "../../../../../reducers/album/hooks/useAlbumOpened";

const FormTrackVersionField = () => {
  const [showField, setShowField] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const track = useTrackOpened();
  const album = useAlbumOpened();

  const canEdit = useMemo(() => {
    return canEditTrackVersion(track, album);
  }, [track, album]);

  useEffect(() => {
    if (!track) {
      return;
    }

    if (track.live) {
      setShowField(false);
      setShowButton(false);
    } else if (!!track.version) {
      setShowField(true);
      setShowButton(false);
    } else {
      setShowField(false);
      setShowButton(true);
    }
  }, [track]);

  const handleOnAddField = () => {
    setShowField(true);
    setShowButton(false);
  };

  return (
    <Grid item xs={12} sm={6} md={6}>
      {showButton && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<AddIcon />}
          onClick={handleOnAddField}
        >
          Versión
        </Button>
      )}
      {showField && (
        <EditableInput
          label="Versión"
          name="version"
          type="input"
          level="track"
          canDelete={false}
          disabled={!canEdit}
          endAdornment={
            <>
              {!canEdit && (
                <InputAdornment position="end">
                  <Tooltip title="No se puede editar para Albums Single, que no sean 'En Vivo'. La versión del track debe ser la misma que la versión del album">
                    <InfoIcon color="primary" />
                  </Tooltip>
                </InputAdornment>
              )}
            </>
          }
        />
      )}
    </Grid>
  );
};

export default FormTrackVersionField;
