// @ts-check
import React from "react";
import useAlbumOpened from "../../../../../reducers/album/hooks/useAlbumOpened";
import AlbumPhotoEditor from "../../../../../components-cross/AlbumPhotoEditor";
import { useDispatch } from "react-redux";
import albumActions from "../../../../../reducers/albumActions";

const AlbumCoverField = () => {
  const album = useAlbumOpened();
  const dispatch = useDispatch();

  /**
   * Ref: https://pqina.nl/filepond/docs/patterns/api/server/#advanced
   */
  const handleServerProcess = async (
    fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort,
    transfer,
    options
  ) => {
    try {
      const result = await dispatch(
        albumActions.coverReplaceUpload(
          album,
          file,
          (loadedBytes, totalBytes) => {
            progress(true, loadedBytes, totalBytes);
          }
        )
      );
      progress(true, 100, 100);
      load(result);
    } catch (e) {
      error("No se pudo subir");
    }
  };

  return (
    <div>
      <AlbumPhotoEditor
        photo={album?.photos?.cover}
        serverProcess={handleServerProcess}
      />
    </div>
  );
};

export default AlbumCoverField;
