import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "600px",
  },
  [theme.breakpoints.down("sm")]: {
    root: {
      width: "100vw",
    },
  },
}));

export default function DialogVideo({ open, onClose }) {
  const classes = useStyles();

  return (
    <Dialog open={open} keepMounted onClose={onClose}>
      <div className={classes.root}>
        <div
          style={{
            paddingBottom: "56.25%",
            position: "relative",
          }}
        >
          <iframe
            src="https://player.vimeo.com/video/913966162?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameBorder="0"
            allow="autoplay; fullscreen;"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            title="Cambios en el modelo de regalías y multas en plataformas de música"
          ></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
    </Dialog>
  );
}
