// @ts-check
import produce from "immer";

/** @typedef {import('../wizardStore').WizardNewAlbumState} WizardNewAlbumState */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */

const TYPE = "WIZARD_SET_NEWALBUM_SUBMITING";

/**
 * @param {boolean} value
 * @return {Object}
 */
export const setSubmitingAction = (value) => {
  return {
    type: TYPE,
    value
  };
};

/**
 * @param {WizardNewAlbumState} state
 * @param {object} action
 * @return {object}
 */
export const setSubmitingReducer = (state, action) => {
  return produce(state, (draft) => {
    draft.submiting = action.value;
  });
};

export default {
  action: setSubmitingAction,
  reducer: setSubmitingReducer,
  TYPE,
};
