// @ts-check
import produce from "immer";

/** @typedef {import('../cartStore').CartState} CartState */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */

/**
 *
 * @param {Array<CartItem>} changes
 * @return {number}
 */
const _calculateTotalChanges = (changes) => {
  return 0; //for now is free, waiting for the stripe feature
  // eslint-disable-next-line
  return (
    changes?.reduce((sum, item) => {
      return sum + item.creditsCost;
    }, 0) || 0
  );
};

const TYPE = "CART_SET_ITEMS";

export const cartSetItemsAction = (upc, cartItems) => {
  return {
    type: TYPE,
    upc,
    cartItems,
  };
};

/**
 * @param {CartState} state
 * @param {object} action
 * @return {object}
 */
export const cartSetItemsReducer = (state, action) => {
  const { upc, cartItems } = action;

  return produce(state, (draft) => {
    draft.cartItems = cartItems || [];
    draft.upc = upc;
    draft.totalCostCredit = _calculateTotalChanges(cartItems);
  });
};

export default {
  action: cartSetItemsAction,
  reducer: cartSetItemsReducer,
  TYPE,
};
