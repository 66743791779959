// @ts-check
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import albumActions from "../../../../../reducers/albumActions";
import useAlbumOpened from "../../../../../reducers/album/hooks/useAlbumOpened";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    gap: theme.spacing(2),
    flexWrap: "wrap",
  },
}));

const ArtistsButtons = () => {
  const dispatch = useDispatch();
  const album = useAlbumOpened();
  const roles = useSelector(
    /** @param {import('../../../../../reducers/store').StateStore} state */
    (state) => state.albumState.rolesArtistAlbum
  );

  const classes = useStyles();

  const onAdd = (role) => {
    dispatch(albumActions.addArtist(album.upc, role));
  };

  return (
    <Box className={classes.root}>
      {roles?.map((role) => (
        <Button
          key={role.value}
          startIcon={<AddIcon />}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => onAdd(role)}
        >
          {role.label}
        </Button>
      ))}
    </Box>
  );
};

export default ArtistsButtons;
