// @ts-check

import cartService from "../../../services/cartService";
import loadCart from "./loadCart";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldTypes} FieldTypes */

/**
 *
 * @param {FieldTypes} fieldType
 * @param {string} path1
 * @param {string} path2
 * @return {boolean}
 */
const areEqualPath = (fieldType, path1, path2) => {
  if (!path1 || !path2) {
    return false;
  }
  path1 = cartService.fieldPathToCartItemPath(fieldType, path1);
  path2 = cartService.fieldPathToCartItemPath(fieldType, path2);
  return path1 === path2;
};

/**
 * Busca y retorna si existe en el carrito un cambio para un campo
 *
 * @param {string} upc;
 * @param {FieldEditable} field
 * @return {Function}
 */
export function getCartFieldChange(upc, field) {
  /**
   * @param {function} dispatch
   * @param {StateGetter} getState
   */
  return async function thunk(dispatch, getState) {
    const cartItems = await dispatch(loadCart(upc));
    if (!cartItems) {
      return null;
    }
    const fieldType = field.type || "input";
    const fieldPath = field.path;
    const keys = Object.keys(cartItems);
    const key = keys.find((k) =>
      areEqualPath(fieldType, fieldPath, cartItems[k].path)
    );

    return key ? cartItems[key] : null;
  };
}

export default getCartFieldChange;
