// @ts-check
import produce from "immer";
import { getArtistRolesForGenre } from "../../../business-objects";

/** @typedef {import('../wizardStore').WizardNewAlbumState} WizardNewAlbumState */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */

const TYPE = "WIZARD_NEW_ALBUM_SET_ROLES";

/**
 * @param {string} genre
 * @param {string} subgenre
 * @return {Object}
 */
export const setArtistRolesAvailablesAction = (genre, subgenre) => {
  return {
    type: TYPE,
    genre,
    subgenre,
  };
};

/**
 * @param {WizardNewAlbumState} state
 * @param {object} action
 * @return {object}
 */
export const setArtistRolesAvailablesReducer = (state, action) => {
  return produce(state, (draft) => {
    draft.artistRolesAvailables = getArtistRolesForGenre(
      action.genre,
      action.subgenre
    );
  });
};

export default {
  action: setArtistRolesAvailablesAction,
  reducer: setArtistRolesAvailablesReducer,
  TYPE,
};
