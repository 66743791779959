// @ts-check
import albumService from "../../../services/albumService";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */

/**
 * Descarta los cambios del album y borra el carrito
 *
 * @param {string} upc;
 * @return {Function}
 */
export function albumDiscardChanges(upc) {
  /**
   * @param {function} dispatch
   */
  return async function thunk(dispatch) {
    return albumService.albumDiscardChanges(upc);
  };
}

export default albumDiscardChanges;
