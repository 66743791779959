import produce from "immer";
import { getArtistRolesForGenre } from "../business-objects";

/** @typedef {import('./store').StateStore} StateStore */

import trackClose from "./album/actions/trackClose";
import trackNext from "./album/actions/trackNext";
import trackOpen from "./album/actions/trackOpen";
import trackPrev from "./album/actions/trackPrev";
import albumClearErrors from "./album/actions/albumClearErrors";
import albumSetError from "./album/actions/albumSetError";
import albumSetShowLegales from "./album/actions/albumSetShowLegales";

/**
 * @type {StateStore}
 */
const INITIAL_STATE = {
  albums: {},
  carts: {},
  openTrack: null,
  openField: null,
  openAlbum: null,
  openAlbumLoading: false,
  mode: null,
  showBalanceModal: false,
  showLegales: false,
  accountBalance: 0,
};

const getRolesForAlbum = (album) => {
  if (!album || (!album.genre && !album.genreSubgenre)) {
    return {
      rolesArtistAlbum: [],
      rolesArtistTrack: [],
    };
  }

  const genre = album.genreSubgenre ? album.genreSubgenre.genre : album.genre;
  const subgenre = album.genreSubgenre
    ? album.genreSubgenre.subgenre
    : album.subgenre;

  const rolesArtistAlbum = getArtistRolesForGenre(genre, subgenre, "album");

  const rolesArtistTrack = getArtistRolesForGenre(genre, subgenre, "track");

  return {
    rolesArtistAlbum,
    rolesArtistTrack,
  };
};

const applySetAlbum = (state, action) => {
  const { upc, album } = action.payload;

  const { rolesArtistTrack, rolesArtistAlbum } = getRolesForAlbum(album);

  if (!album.genreSubgenre) {
    album.genreSubgenre = {
      genre: album.genre,
      subgenre: album.subgenre,
    };
  }

  return produce(state, (draft) => {
    draft.albums[upc] = album;
    draft.showLegales = false;
    draft.showBalanceModal = false;
    draft.rolesArtistTrack = rolesArtistTrack;
    draft.rolesArtistAlbum = rolesArtistAlbum;
  });
};

const applySetAlbumCart = (state, action) => {
  const { upc, cartItems } = action.payload;

  return produce(state, (draft) => {
    draft.carts[upc] = cartItems;
  });
};

const resetAlbumState = (state, action) => {
  return produce(state, (draft) => {
    draft.albums = {};
  });
};

const applyAlbumFieldChange = (state, action) => {
  const { upc, path, value } = action.payload;

  return produce(state, (draft) => {
    draft.albums[upc][path] = value;
    if (path === "genreSubgenre" && value?.subgenre) {
      const { rolesArtistTrack, rolesArtistAlbum } = getRolesForAlbum(
        draft.albums[upc]
      );
      draft.rolesArtistTrack = rolesArtistTrack;
      draft.rolesArtistAlbum = rolesArtistAlbum;
    }
  });
};

const applyOpenField = (state, action) => {
  return produce(state, (draft) => {
    draft.openField = action.path;
  });
};

const applyOpenAlbum = (state, action) => {
  return produce(state, (draft) => {
    draft.openAlbum = action.upc;
  });
};

const applyOpenAlbumLoading = (state, action) => {
  return produce(state, (draft) => {
    draft.applyOpenAlbumLoading = action.loading;
  });
};

const applySetMode = (state, action) => {
  return produce(state, (draft) => {
    draft.mode = action.mode;
  });
};

const openBalance = (state, action) => {
  return produce(state, (draft) => {
    draft.showBalanceModal = true;
  });
};

const closeBalance = (state, action) => {
  return produce(state, (draft) => {
    draft.showBalanceModal = false;
  });
};

const setAccountBalance = (state, action) => {
  return produce(state, (draft) => {
    draft.accountBalance = action.balance;
  });
};

function albumReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ALBUM_SET": {
      return applySetAlbum(state, action);
    }
    case "ALBUM_CART_SET": {
      return applySetAlbumCart(state, action);
    }
    case "ALBUM_FILTERED": {
      return resetAlbumState(state, action);
    }
    case "ALBUM_FIELD_CHANGE": {
      return applyAlbumFieldChange(state, action);
    }
    case "OPEN_ALBUM": {
      return applyOpenAlbum(state, action);
    }
    case "OPEN_ALBUM_LOADING": {
      return applyOpenAlbumLoading(state, action);
    }
    case "OPEN_ALBUM_FIELD": {
      return applyOpenField(state, action);
    }
    case albumClearErrors.TYPE: {
      return albumClearErrors.reducer(state, action);
    }
    case albumSetError.TYPE: {
      return albumSetError.reducer(state, action);
    }
    case albumSetShowLegales.TYPE: {
      return albumSetShowLegales.reducer(state, action);
    }
    case trackOpen.TYPE: {
      return trackOpen.reducer(state, action);
    }
    case trackClose.TYPE: {
      return trackClose.reducer(state, action);
    }
    case trackNext.TYPE: {
      return trackNext.reducer(state, action);
    }
    case trackPrev.TYPE: {
      return trackPrev.reducer(state, action);
    }
    case "ALBUM_SET_MODE": {
      return applySetMode(state, action);
    }
    case "OPEN_BALANCE": {
      return openBalance(state, action);
    }
    case "CLOSE_BALANCE": {
      return closeBalance(state, action);
    }
    case "SET_ACCOUNT_BALANCE": {
      return setAccountBalance(state, action);
    }
    default:
      return state;
  }
}

export default albumReducer;
