// @ts-check
/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldActionType} FieldActionType */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */

/**
 *
 * @param {Album} album
 * @param {FieldEditable} field
 * @param {*} action
 * @param {*} uneditedValue
 * @param {*} newValue
 * @return {CartItem}
 */
export const makeCartItem = (album, field, action, uneditedValue, newValue) => {
  /** @type {CartItem} */
  const cartItem = {
    labelField: field.label,
    path: field.path,
    level: field.level,
    typeField: field.type || "input",
    action,
    uneditedValue,
    newValue,
    creditsCost: 0,
  };

  if (field.level === "track") {
    cartItem.numberTrack = determineNumberTrack(album, field.isrcTrack);
    cartItem.isrcTrack = field.isrcTrack;
  }

  return cartItem;
};

/**
 * Rebuild a FieldEditable from a CartItem change
 *
 * @param {CartItem} cartItem
 * @return {FieldEditable | null}
 */
export const makeFieldEditable = (cartItem) => {
  if (!cartItem) {
    return null;
  }

  const field = {
    label: cartItem.labelField,
    level: cartItem.level,
    path: cartItem.path,
    type: cartItem.typeField,
    isrcTrack: cartItem.isrcTrack,
  };

  if (cartItem.isrcTrack) {
    field.isrcTrack = cartItem.isrcTrack;
  }

  return field;
};

/**
 *
 * @param {Album} album
 * @param {string=} isrcTrack
 * @return {number} // start on 1, -1 if the isrcTrack doesn't exists
 */
export const determineNumberTrack = (album, isrcTrack) => {
  if (!isrcTrack || !(isrcTrack in album?.tracks)) {
    return -1;
  }

  return album.tracks[isrcTrack].order || -1;
};

export default {
  makeCartItem,
  makeFieldEditable,
  determineNumberTrack,
};
