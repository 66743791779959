import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  Hline: {
    marginTop: "1rem",
    marginBottom: "1rem",
    paddingTop: "0.25rem",
    zIndex: 2,
    width: "100%",
    height: "2rem",
    color: "#C4C4C4",
    position: "relative",

    "& .HLine-label": {
      backgroundColor: theme.palette.background.paper,
      display: "inline-block",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      position: "absolute",
      top: "0.5rem",
      left: "50%",
      transform: "translateX(-50%)",
    },
    "& .HLine-line": {
      display: "inline-block",
      width: "100%",
      borderTop: "1px solid rgba(204, 170, 102, 0.4)",
      transform: "translateY(50%)",
    },
  },
}));

/**
 *
 * @param {{label?: string, className?: string}} props
 * @returns
 */
const HLine = ({ label = "", className = null }) => {
  const classes = useStyles();
  return (
    <div className={classnames(classes.Hline, className)}>
      <div className="HLine-line" />
      {label && <span className="HLine-label">{label}</span>}
    </div>
  );
};

export default HLine;
