import * as yup from "yup";
import {
  REQUIRED_EMAIL,
  INVALID_EMAIL,
  REQUIRED_FULL_NAME,
  REQUIRED_TAX_ID,
  REQUIRED_ID,
  REQUIRED_BIRTHDAY,
  REQUIRED_PROFESSION,
  REQUIRED_NATIONALITY,
  REQUIRED_RESIDENCE_COUNTRY,
  REQUIRED_POSTAL_CODE,
  REQUIRED_CITY,
  REQUIRED_ADDRESS,
  REQUIRED_PHONE,
  REQUIRED_AGREEMENT,
  REQUIRED_AGREEMENT2,
  REQUIRED_BANK_NAME,
  REQUIRED_ACCOUNT_NUMBER,
  REQUIRED_ACCOUNT_SWIFT,
  REQUIRED_ACCOUNT_TYPE,
  REQUIRED_PATH_NUMBER,
  PAYPAL,
  BANK,
  COUNTRIES_DATA,
} from "./constants";

const paymentPaypal = {
  is: (paymentType) => paymentType === PAYPAL,
  then: yup.string().email(INVALID_EMAIL).required(REQUIRED_EMAIL),
};

const paymentBankName = {
  is: (paymentType, residenceCountry) => {
    if (!residenceCountry) return false;
    const country = COUNTRIES_DATA[residenceCountry.code];
    return (
      paymentType === BANK && country.payment && country.payment.data.bankName
    );
  },
  then: yup.string().required(REQUIRED_BANK_NAME),
};
const paymentAccountNumber = {
  is: (paymentType, residenceCountry) => {
    if (!residenceCountry) return false;
    const country = COUNTRIES_DATA[residenceCountry.code];
    return (
      paymentType === BANK &&
      country.payment &&
      country.payment.data.accountNumber
    );
  },
  then: yup.string().required(REQUIRED_ACCOUNT_NUMBER),
};
const paymentAccountSwift = {
  is: (paymentType, residenceCountry) => {
    if (!residenceCountry) return false;
    const country = COUNTRIES_DATA[residenceCountry.code];
    return (
      paymentType === BANK &&
      country.payment &&
      country.payment.data.accountSwift
    );
  },
  then: yup.string().required(REQUIRED_ACCOUNT_SWIFT),
};
const paymentAccountType = {
  is: (paymentType, residenceCountry) => {
    if (!residenceCountry) return false;
    const country = COUNTRIES_DATA[residenceCountry.code];
    return (
      paymentType === BANK &&
      country.payment &&
      country.payment.data.accountType
    );
  },
  then: yup.string().required(REQUIRED_ACCOUNT_TYPE),
};
const paymentPathNumber = {
  is: (paymentType, residenceCountry) => {
    if (!residenceCountry) return false;
    const country = COUNTRIES_DATA[residenceCountry.code];
    return (
      paymentType === BANK && country.payment && country.payment.data.pathNumber
    );
  },
  then: yup.string().required(REQUIRED_PATH_NUMBER),
};

import { object, string, number, date, InferType } from "yup";

const PaymentSchema = object({
  email: string().email(INVALID_EMAIL).required(REQUIRED_EMAIL),
  residenceCountry: object()
    .typeError(REQUIRED_RESIDENCE_COUNTRY)
    .required(REQUIRED_RESIDENCE_COUNTRY),
  fullName: string().required(REQUIRED_FULL_NAME),
  taxIdentification: yup
    .string()
    .typeError(REQUIRED_TAX_ID)
    .required(REQUIRED_TAX_ID),
  identification: yup.string().typeError(REQUIRED_ID).required(REQUIRED_ID),
  birthday: yup.date().required(REQUIRED_BIRTHDAY),
  profession: yup.string().required(REQUIRED_PROFESSION),
  nationality: yup
    .object()
    .typeError(REQUIRED_NATIONALITY)
    .required(REQUIRED_NATIONALITY),
  postalCode: yup.string().required(REQUIRED_POSTAL_CODE),
  city: yup.string().required(REQUIRED_CITY),
  address: yup.string().required(REQUIRED_ADDRESS),
  phone: yup.number().typeError(REQUIRED_PHONE).required(REQUIRED_PHONE),
  paymentType: yup.string(),
  paypalEmail: yup.string().when(["paymentType"], paymentPaypal),
  bankName: yup
    .string()
    .when(["paymentType", "residenceCountry"], paymentBankName),
  accountNumber: yup
    .string()
    .when(["paymentType", "residenceCountry"], paymentAccountNumber),
  accountSwift: yup
    .string()
    .when(["paymentType", "residenceCountry"], paymentAccountSwift),
  accountType: yup
    .string()
    .when(["paymentType", "residenceCountry"], paymentAccountType),
  pathNumber: yup
    .string()
    .when(["paymentType", "residenceCountry"], paymentPathNumber),
  paypalEmail: yup.string().when("paymentType", paymentPaypal),
  bankName: yup
    .string()
    .when(["paymentType", "residenceCountry"], paymentBankName),
  accountNumber: yup
    .string()
    .when(["paymentType", "residenceCountry"], paymentAccountNumber),
  accountSwift: yup
    .string()
    .when(["paymentType", "residenceCountry"], paymentAccountSwift),
  accountType: yup
    .string()
    .when(["paymentType", "residenceCountry"], paymentAccountType),
  pathNumber: yup
    .string()
    .when(["paymentType", "residenceCountry"], paymentPathNumber),
  agreement: yup
    .boolean()
    .required(REQUIRED_AGREEMENT)
    .oneOf([true], REQUIRED_AGREEMENT),
  agreement2: yup
    .boolean()
    .required(REQUIRED_AGREEMENT2)
    .oneOf([true], REQUIRED_AGREEMENT2),
});

export { PaymentSchema };
