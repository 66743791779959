// @ts-check
import React from "react";
import * as yup from "yup";

import Wizard from "./Wizard";
import StepTracksUploader from "./StepTracks.Uploader";

export const StepTracks = () => {
  return (
    <Wizard.Step>
      <Wizard.Heading
        title="1. Audios"
        description="Sube y ordena los audios."
      />
      <StepTracksUploader />
    </Wizard.Step>
  );
};

StepTracks.label = "Tracks";

StepTracks.validationSchema = yup.object({
  tracks: yup.array().min(1),
});

export default StepTracks;
