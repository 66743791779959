// @ts-check
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { LinearProgress, makeStyles, Typography } from "@material-ui/core";
import BackupIcon from "@material-ui/icons/Backup";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

import getTrackOriginalFilename from "../../../../../business-objects/getTrackOriginalFilename";
import { trackReplaceAudio } from "../../../../../reducers/album/actionsAsync/trackReplaceAudio";

/** @typedef {import('../../../../../business-objects/album').Album} Album */
/** @typedef {import('../../../../../business-objects/album').Track} Track */

const useStyles = makeStyles((theme) => ({
  trackAudio: {
    "& .trackAudio__descriptionContainer": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      textTransform: "lowercase",
      width: "100%",
      padding: 0,
      "& .trackAudio__fileType": {
        color: "white",
        fontSize: "0.8rem",
        padding: "0 1em",
        backgroundColor: "rgb(204, 170, 102)",
        fontWeight: "bold",
        textTransform: "lowercase",
        borderRadius: "4px",
        marginRight: "0.5rem",
      },
      "& .trackAudio__fileName": {
        opacity: 0.8,
      },
    },
    "& .trackAudio__actionContainer": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      cursor: "pointer",
      marginTop: "0.25rem",
      opacity: 0.8,
      "&:hover": {
        opacity: 1,
      },
      "& .trackAudio__uploadIcon": {
        marginRight: "0.5rem",
      },
      "&--uploading": {
        flexDirection: "column",
        alignItems: "initial",
        opacity: 0.8,
        cursor: "initial",
      },

      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start",
      },
    },
  },
}));

/**
 *
 * @param {{track: Track, album: Album}} props
 * @returns
 */
const HeaderTrackTrackAudio = ({ album, track }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0); // 0 - 100

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles?.length <= 0) {
        return;
      }
      setProgress(0);
      setUploading(true);
      await dispatch(
        trackReplaceAudio(
          album,
          track,
          acceptedFiles[0],
          (bytesProgress, bytesTotal) => {
            setProgress((bytesProgress / bytesTotal) * 100);
          }
        )
      );
      setUploading(false);
    },
    [album, track, dispatch]
  );

  const onDropRejected = (files) => {
    toast.error("Archivos no validos");
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept: ["audio/*", ".ogg", ".mp3", ".wav"],
  });

  if (!track.storageLocation) {
    return null;
  }

  return (
    <div className={classes.trackAudio}>
      <div className="trackAudio__descriptionContainer">
        <Typography className="trackAudio__fileType">
          {track.extension}
        </Typography>
        <Typography className="trackAudio__fileName">
          {getTrackOriginalFilename(track)}
        </Typography>
      </div>
      {uploading ? (
        <div className="trackAudio__actionContainer trackAudio__actionContainer--uploading">
          <LinearProgress variant="determinate" value={progress} />
          Subiendo...
        </div>
      ) : (
        <div {...getRootProps({ className: "trackAudio__actionContainer" })}>
          <input {...getInputProps()} />
          <BackupIcon className="trackAudio__uploadIcon" />
          Sustituir archivo de audio
        </div>
      )}
    </div>
  );
};

export default HeaderTrackTrackAudio;
