import React, { useEffect } from "react";
import { NavLink, Link, withRouter } from "react-router-dom";
import { ForgotPasswordLink } from "./ForgotPassword";
import * as routes from "../../constants/routes";
import { Button, Paper, Typography, makeStyles } from "@material-ui/core";
import AppConfig from "../AppConfig";
import SignInForm from "./SigninForm";
import RegistrationForm from "./RegistrationForm";
import { auth } from "../../firebase";

const useStyles = makeStyles((theme) => ({
  portada: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(2,2,2,1)",
    width: "100vw",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(5),
    },
  },
  topbar: {
    color: "rgb(204, 170, 102)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    alignItems: "center",
    textAlign: "center !important",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  barTitle: {
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  registerButton: {
    backgroundColor: "rgb(204, 170, 102)",
    color: "#222",
    [theme.breakpoints.down("xs")]: {
      order: -1,
    },
  },
  loginButton: {
    backgroundColor: "rgb(204, 170, 102)",
    color: "#222",
    [theme.breakpoints.down("xs")]: {
      order: -1,
    },
  },
  centeredPanel: {
    margin: "0 auto",
    "& a": {
      color: "rgb(204, 170, 102)",
      textDecoration: "none",
    },
  },
  paper: {
    padding: theme.spacing(5),
    borderRadius: 10,
    marginBottom: theme.spacing(3),
    width: "90vw",
    maxWidth: 700,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3),
    },
  },
}));

const SignInPage = ({ history, isSignIn }) => {
  const classes = useStyles();

  useEffect(() => {
    if (auth.getCurrentUser()) {
      history.push(routes.ALBUMS);
    }
  }, [history]);

  return (
    <AppConfig>
      <div className={classes.portada}>
        <div className={classes.topbar}>
          <Typography variant="h6" color="inherit" className={classes.barTitle}>
            Tu música en Spotify, Apple Music, Amazon y más...
          </Typography>

          <Button
            className={classes.registerButton}
            color="inherit"
            variant="contained"
            component={NavLink}
            to={routes.SIGN_UP}
            exact
            activeStyle={{
              display: "none",
            }}
          >
            REGÍSTRATE
          </Button>

          <Button
            className={classes.loginButton}
            color="inherit"
            variant="contained"
            component={NavLink}
            exact
            to={routes.SIGN_IN}
            activeStyle={{
              display: "none",
            }}
          >
            ENTRA
          </Button>
        </div>

        <div className={classes.centeredPanel}>
          <Paper elevation={1} className={classes.paper}>
            {isSignIn ? (
              <SignInForm
                classes={classes}
                history={history}
                linkAccountTo={window.linkSignInFor}
              />
            ) : (
              <RegistrationForm classes={classes} />
            )}
          </Paper>

          {isSignIn ? (
            <ForgotPasswordLink />
          ) : (
            <Typography align="center">
              <Link to="/">Ya tengo una cuenta</Link>
            </Typography>
          )}
        </div>
      </div>
    </AppConfig>
  );
};

export default withRouter(SignInPage);
