import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  inputError: {
    display: "inline",
    color: "red",
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  labelStyle: {
    root: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      right: "22px",
      bottom: "0px",
    },
    shrink: {
      right: "unset",
    },
  },
  contract: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(4),
  },
}));

export { useStyles };
