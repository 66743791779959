import * as React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  DialogActions,
} from "@material-ui/core";

export default function HelpFraudChartDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <React.Fragment>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        ¿Cómo puedo reducirla?
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth="true"
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">
          Reproducciones fraudulentas en plataformas
        </DialogTitle>
        <DialogContent dividers="true">
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <p>
              En caso de que tu música esté siendo reproducida artificialmente
              porque contrataste un servicio que incluye un número garantizado
              de reproducciones de tu música en las plataformas, debes
              contactarlos inmediatamente para finalizar el servicio contratado
              y pedir que eliminen tu música de todas las listas de reproducción
              a las que la hayan añadido.
            </p>

            <p>
              <strong>
                ¿Tu música está en una Lista de Reproducción o Playlist que
                incrementa el número de reproducciones a través de Bots, y no
                contrataste ningún servicio de promoción que incluyera este tipo
                de servicio?
              </strong>
            </p>

            <p>
              El tráfico artificial y de Listas de Reproducción (Playlists)
              reproducidas artificialmente por bots representa un riesgo muy
              importante para los artistas independientes. Anteriormente este
              tipo de prácticas se convertían en, únicamente, una advertencia de
              parte de tu distribuidor a o Sello Discográfico, sin embargo, hoy
              en día puede provocar una suspensión de tu cuenta por parte de tu
              distribuidor, y un bloqueo de tu nombre de Artista y/o Sello
              Discográfico por parte de algunas plataformas musicales.
            </p>
            <p>
              <strong>
                ¿Por qué tu distribuidora puede suspender tu cuenta si tu musica
                está en una Lista de Reproducción, o Playlist, reproducida
                artificialmente por bots?
              </strong>
            </p>
            <p>
              Este tipo de prácticas va contra de las políticas de uso de
              Spotify y la mayoría de las plataformas musicales. Por este
              motivo, Spotify penaliza a las Distribuidoras y Sellos
              Discográficos que publican música que es reproducida
              artificialmente por cualquier proceso de automatización (Bots,
              Scripts, etc.).
            </p>
            <p>
              <strong>
                ¿Cómo puedes evitar que tu Distribuidora pueda suspender tu
                cuenta porque las plataformas detectan actividad sospechosa
                relacionada con tu música?
              </strong>
            </p>
            <p>
              Lo primero y más importante que debes comprobar, es que ni tú, ni
              alguno de tus colaboradores (Otros artistas que colaboran en tus
              grabaciones, Managers, Sello Discográfico, etc.) haya contratado
              ningún servicio de promoción que garantice un número de streams
              (reproducciones) en Spotify mediante el pago por adelantado de una
              cuota.
            </p>
            <p>
              <strong>
                ¿Qué puedes hacer si no has contratado ningún servicio de
                promoción que garantice un número de Streams o Reproducciones y
                tu música está incluida en una Lista de Reproducción, o Playlist
                reproducida artificialmente por bots?
              </strong>
            </p>
            <p>
              Ante todo es conveniente que desarrolles el hábito de revisar, al
              menos dos o tres veces a la semana tus analíticas de Spotify for
              Artists.
            </p>
            <p>
              Como parte de esta rutina deberás revisar si tu música ha sido
              añadida a nuevas Listas de Reproducción, o Playlists durante la
              última semana.
            </p>
            <p>
              Si son Playlists Editoriales o Algorítmicas tu música está siendo
              promovida de forma segura y no hay ningún peligro. Si son Playlist
              creadas por usuarios particulares para uso personal o para
              compartir con amigos, suelen tener muy pocos seguidores y tampoco
              hay ningún problema.
            </p>
            <p>
              Sin embargo, si detectas que tu música ha sido añadida a Paylists
              o Listas de Reproducción con un número algo de seguidores,
              entonces tendrás que hacer algunas comprobaciones adicionales:
            </p>
            <ol>
              <li>
                Comprobar que no haya patrones de incremento abrupto en el
                número de reproducciones de la Lista de Reproducción o Playlist.
                Cuando una Lista de Reproducción tiene un aumento repentino o
                descenso masivo de seguidores y/o de reproducciones durante un
                periodo de tiempo corto, puede ser una señal de alerta. Las
                Playlists auténticas crecen de manera constante, regular, con
                picos ocasionales debido a canciones que se incrementan su
                popularidad debido a menciones hechas por influencers, medios de
                comunicación u organizaciones con una masa importante de
                seguidores en redes sociales. Sin embargo, cuando el crecimiento
                es abrupto, puede ser un crecimiento no-orgánico y es
                conveniente que compruebes que la lista de reproducción a la que
                ha sido añadida tu música no está relacionada con actividad
                fraudulenta. Para comprobarlo puedes utilizar la herramienta
                gratuita Bot Checker, de Artist Tools
                (https://www.artist.tools).
              </li>
              <li>
                Valorar el “engagement” de la Lista de Reproducción. Los bots
                pueden reproducir canciones de forma automatizada, pero no
                normalmente están programados para interactuar. Si la canción de
                una Playlist tiene un alto número de reproducciones, pero un
                bajo número en las veces que ha sido guardada o compartida, y un
                bajo número de seguidores, es un posible indicador de actividad
                fraudulenta, por reproducciones realizadas mediante procesos
                automatizados. Si Playlist tiene un bajo número de oyentes y un
                altísimo número de reproducciones también es una señal de
                alerta.
              </li>
              <li>
                Revisar que los seguidores del perfil del curador sean usuarios
                reales. Los bots operan a menudo bajo perfiles de usuario
                genéricos, con nombres generados aleatoriamente con caracteres y
                números que no son el nombre de una persona y frecuentemente sin
                foto de perfil de usuario. Los perfiles creadores de Playlists
                fraudulentas suelen tener un alto número de seguidores genéricos
                y es conveniente que compruebes que tu música no haya sido
                añadida a una Lista de Reproducción con estas características,
                pues pueden ser Playlists relacionadas con reproducciones
                artificiales generadas por bots u otros procesos de reproducción
                automatizada.
              </li>
              <li>
                Examinar la ubicación, género y edad de los oyentes Si la
                mayoría de los oyentes de la Playlist son de regiones que no
                tienen relación con tu audiencia principal es una señal de
                alarma. Igualmente, otro indicio de que la Lista de Reproducción
                puede estar vinculada a actividad fraudulenta, puede ser una
                anormal distribución de la edad o del género de los usuarios que
                siguen esa Playlist. Con frecuencia las reproducciones
                artificiales provienen de países que no tienen relación con el
                artista, como Estonia, Ucrania, Pakistán, India, aunque también
                podrían provenir de Los Ángeles, Chicago, Nueva York, donde hay
                una gran cantidad de “granjas de streaming” como se conoce a
                estos servicios de bots masivos.
              </li>
              <li>
                Por último, desconfía de Playlists con nombres extraños o no
                relacionados con el género o “mood” de la música que incluye. Si
                la Lista de Reproducción incluye canciones de géneros y estilos
                que no tienen relación entre sí, puede ser otra señal de alerta.
                Si después de seguir los pasos descritos anteriormente,
                confirmas que tu música está en una Playlist reproducida
                artificialmente por bots, lo más importante actuar con rapidez.
                Si no actúas rápidamente es probable que Spotify reporte el
                problema a tu distribuidora y tu distribuidora suspenda tu
                cuenta por estar relacionada con actividad fraudulenta.
              </li>
            </ol>
            <p>
              <strong>
                Para evitar esta desagradable situación debes seguir únicamente
                estos dos pasos:
              </strong>
            </p>
            <ol>
              <li>
                Reportar la Playlist infractora utilizando la App de Spotify.
                Cualquier usuario de Spotify puede reportar contenido que
                considere ofensivo, fraudulento o que viola las políticas de
                contenido. Antes de reportar el problema es conveniente que te
                informes sobre las Reglas de la Plataforma de Spotify
                (https://support.spotify.com/mx/article/platform-rules). Si
                después de consultar la información sobre las reglas de la
                Plataforma aún quieres reportar la Lista de Reproducción a la
                que han añadido tu música, puedes hacerlo a través de la App de
                Spotify. Para reportar una Lista de Reproducción es necesario
                seguir los siguientes pasos:
                <ol type="A">
                  <li>
                    Acceder a la Aplicación de Escritorio de Spotify (desde una
                    computadora).
                  </li>
                  <li>
                    Hacer clic derecho en el título de la Lista de Reproducción,
                  </li>
                  <li>
                    Seleccionar la opción: “Reportar”, que abrirá un formulario
                    en tu navegador.
                  </li>
                  <li>Responder el formulario y enviarlo.</li>
                </ol>
              </li>
              <li>
                Reportar la Lista de Reproducción a través del servicio de
                Soporte de Spotify for Artsits Para hacerlo sólo tienes que
                acceder al servicio de soporte de Spotify for Artsits, en
                https://support.spotify.com/mx/artists/contact-spotify-anonymous.
                <p>
                  Tu objetivo es informarlos de que tu música ha sido añadida a
                  una Playlist que sospechas que está relacionada con
                  reproducciones artificiales, generadas por bots. Es importante
                  que menciones que tú no contrataste ningún servicio de
                  promoción y que quieres denunciarla. Por último es necesario
                  que solicites que tu música sea eliminada de la Lista de
                  Reproducción que estás reportando, para que tu actividad de
                  Artista no se vea afectada.
                </p>
                <p>
                  Ten en cuenta que el servicio de Soporte te pedirá el enlace
                  de esa playlist. Es conveniente que acompañes tu reporte con
                  la mayor cantidad de detalles posibles (URI de la Playlist,
                  códigos ISRC y UPC de tu música, pantallazos, etc.) y que
                  guardes toda la comunicación que tengas con el servicio de
                  Soporte de Spotify for Artists.
                </p>
              </li>
            </ol>

            <p>
              <strong>
                ¿Qué hacer si Spotify ya nos ha reportado sobre actividad
                fraudulenta relacionada con tu música y hemos suspendido tu
                cuenta?
              </strong>
            </p>
            <p>
              En este caso lo más probable es que tu distribuidora haya dado de
              baja tu música y que tu música música ya no esté disponible en
              Spotify y demás plataformas. Si estás en esta situación lo más
              importante es que no intentes discutir con nuestro servicio de
              soporte argumentando que tú no has contratado ningún servicio que
              incluya reproducciones artificiales generadas por bots. Nuestras
              políticas son intolerantes ante cualquier actividad que vulnere
              las políticas de Spotify o de cualquier otra plataforma musical.
              Por lo que si tu cuenta ha sido suspendida la única alternativa
              que tienes es probar volver a subir tu música a través de otra
              distribuidora.
            </p>
            <p>
              Recuerda que es muy importante que no contrates servicios de
              promoción que garantizan un número de reproducciones al día,
              semana o mes, aún si el servicio asegura que las reproducciones
              son orgánicas. Si vas a usar servicios de promoción, usa servicios
              legales como Submit Hub, Groover, Playlist Push, Song Tools.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Entendido</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
