// @ts-check
import setConfig from "./actions/setConfig";

/** @typedef {import('./configStore').ConfigState} ConfigState */

/** @type {ConfigState} */
const INITIAL_STATE = {
  config: {},
  loaded: false,
};

function configReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case setConfig.TYPE: {
      return setConfig.reducer(state, action);
    }
    default:
      return state;
  }
}

export default configReducer;
