// @ts-check
import slugify from "slugify";
import { auth, db } from "../../../firebase";

import getExtension from "../../../utils/getFileExtension";
import storageService from "../../../services/storageService.js";
import { trackAddAction } from "../actions/trackAdd";
import { trackProgressAction } from "../actions/trackProgress";
import { trackUploadDoneAction } from "../actions/trackUploadDone";
import saveTemporally from "./saveTemporally";
import { updateIsUploadingTracksAction } from "../actions/updateIsUploadingTracks";
import getTitleFromFilename from "../../../utils/getTitleFromFilename";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */

const makeupId = () => {
  return (
    Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2)
  );
};

/**
 * Recive un file para persistir y setear en el album
 *
 * @param {Array<File>} files
 * @param {Function?} onProgress
 * @return {Function}
 */
export function uploadAudio(files, onProgress = null) {
  /**
   * @param {function} dispatch
   * @param {StateGetter} getState
   */
  return async function thunk(dispatch, getState) {
    /** @type {StateStore} */
    const state = getState();

    const file = files.pop();
    if (!file) {
      return false;
    }

    // SUBIR FILE
    const albumKey = state.wizardNewAlbumState.uploadingKey;
    const email = auth.getCurrentUser()?.email;
    const trackID = makeupId();
    const extension = getExtension(file);
    const nameNoExt = file.name ? file.name.split(".")[0] : "";
    const filename = `${trackID}-${slugify(nameNoExt)}.${extension}`;
    const pathFolder = `${email}-${albumKey}`;
    const [isrc] = await db.getISRCblock();

    const title = getTitleFromFilename(file.name);

    // CREATE track OBJ
    const track = {
      id: trackID,
      title: title,
      titleLabel: title,
      isrc,
      explicit: false,
      // orchestra: "",
      // ensamble: "",
      // director: "",
      // version: "",
      // lyrics_lang: "",
      // artists: [],
      originalFilename: file.name,
      filename,
      extension,
      mime: file.type,
      storageLocation: "",
      droppedAt: Date.now(),
      uploading: true,
      busy: true,
      progress: 0,
      order: 0,
    };

    // AGREGAR AL WIRZARD-ALBUM
    dispatch(trackAddAction(track));
    dispatch(updateIsUploadingTracksAction());

    // UPLOAD FILE
    const result = await storageService.uploadFile(
      pathFolder,
      filename,
      file,
      (snap) => {
        const progress = (snap.bytesTransferred / snap.totalBytes) * 100;
        dispatch(trackProgressAction(track, progress));
        if (onProgress) {
          onProgress(snap.bytesTransferred, snap.totalBytes);
        }
      }
    );

    // TODO HANDLE ERROR FILE

    // DONE FILE
    dispatch(trackUploadDoneAction(track, result.storageLocation));
    dispatch(updateIsUploadingTracksAction());

    await dispatch(saveTemporally());

    return track;
  };
}

export default uploadAudio;
