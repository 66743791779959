import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Box, Button, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "react-toastify/dist/ReactToastify.css";

import * as routes from "../../../../../constants/routes";
import { db } from "../../../../../firebase";

import BalanceModal from "./BalanceModal";
import WorkflowButtons from "./WorkflowButtons";
import AlbumStatusChip from "../../../components/AlbumStatusChip";

const styles = (theme) => ({
  root: {
    // marginTop: "69px", // spacing(8) + 5px del borderBottom del Navigation
    margin: 0,
    // marginBottom: theme.spacing(2),
    padding: 0,
    // paddingTop: theme.spacing(4),
    // paddingBottom: theme.spacing(1),

    // backgroundColor: "#f3f3f3",
    // borderBottom: "1px solid #ddd",
    // position: "sticky",
    // top: "69px", // spacing(8) + 5px del borderBottom del Navigation
    zIndex: 3,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 4),
    },
  },
  leftTools: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  inner: {
    margin: "auto",
    maxWidth: 1100,
    // padding: theme.spacing(2),
    // paddingLeft: theme.spacing(3),
    // paddingRight: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: theme.spacing(2),
  },
  statusPublish: {
    display: "flex",
    alignItems: "center",
  },
  status: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  button: {
    borderRadius: "16px",
  },
});

const AlbumToolbar = ({ album, classes, history }) => {
  const dispatch = useDispatch();
  const accountBalance = useSelector(
    (state) => state.albumState.accountBalance
  );

  const getBalance = async () => {
    const balance = await db.getUserBalance();
    dispatch({ type: "SET_ACCOUNT_BALANCE", balance: balance });
  };

  useEffect(() => {
    getBalance();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Box className={classes.root}>
        <div className={classes.inner}>
          <div className={classes.leftTools}>
            <Typography variant="body1">EDITOR DE ALBUM | Status:</Typography>
            <AlbumStatusChip statusKey={album.status} />
          </div>
          <div className={classes.statusPublish}>
            <WorkflowButtons album={album} />
          </div>
        </div>
      </Box>

      <BalanceModal accountBalance={accountBalance} getBalance={getBalance} />
    </>
  );
};

export default withRouter(withStyles(styles)(AlbumToolbar));
