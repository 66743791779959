// https://github.com/stackworx/formik-material-ui/pull/171

/**
 *
 * #### Example
 *
 * import { SwitchWithLabel } from 'formik-material-ui';
 *
 * <Field
 *     component={SwitchWithLabel}
 *     type="checkbox"
 *     label="A Switch"
 *     name="checked"
 * />;
 */

import * as React from "react";
import MuiSwitch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { fieldToSwitch } from "formik-material-ui";

export function SwitchWithLabel({ label, ...props }) {
  return (
    <FormControlLabel
      control={<MuiSwitch {...fieldToSwitch(props)} />}
      label={label}
    />
  );
}

SwitchWithLabel.displayName = "FormikMaterialUISwitchWithLabel";

export default SwitchWithLabel;
