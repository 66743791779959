// @ts-check

/**
 *
 * @param {import("./album").Album} album
 * @return {boolean}
 */
export const canAddRemoveTracks = (album) => {
  return album && album.status === "draft";
};

export default canAddRemoveTracks;
