// @ts-check
import produce from "immer";

/** @typedef {import('../wizardStore').WizardNewAlbumState} WizardNewAlbumState */
/** @typedef {import('../wizardStore').NewAlbumTrack} NewAlbumTrack */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */

const TYPE = "WIZARD_UPDATE_ISUPLOADINGTRACKS";

/**
  * @return {Object}
 */
export const updateIsUploadingTracksAction = () => {
  return {
    type: TYPE
  }
};

/**
 * @param {WizardNewAlbumState} state
 * @param {object} action
 * @return {object}
 */
export const updateIsUploadingTracksReducer = (state, action) => {
  const {tracks} = state;
  
  return produce(state, (draft) => {
    draft.isUploadingTracks = tracks?.some(t => t.uploading);
  })
};

export default {
  action: updateIsUploadingTracksAction,
  reducer: updateIsUploadingTracksReducer,
  TYPE,
};
