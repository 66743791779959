import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ScrollToTop from "./ScrollToTop";
import {
  Grid,
  ListItemText,
  Typography,
  ListItem,
  List,
} from "@material-ui/core";
import { db } from "../firebase";
// import MaterialTable from "material-table";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  title: {
    textAlign: "center",
    marginBottom: "8vh",
  },
  album: {
    width: "900px",
    margin: "auto",
    paddingTop: "20vh",
  },
});

const SimpleTable = () => {
  const classes = useStyles();

  const [changesByUser, setChanges] = useState([]);
  const fieldName = {
    artists: "Artista",
    subaccount: "Sello",
    genre: "Género",
    subgenre: "Sub-género",
    release: "Fecha de lanzamiento",
    release_digital: "Lanzamiento en plataformas digitales",
    lyrics_lang: "Idioma",
    country: "País",
    version: "Versión",
    live: "En vivo",
    price: "Precio de descarga",
    title: "Título",
    cover: "Portada",
    back: "Contratapa",
  };

  function* getHistoryGenerator() {
    let changes = db.getHistory();

    yield;
    console.log("sigio");
    setChanges(changes);
  }

  const getChanges = getHistoryGenerator();

  if (changesByUser.length === 0 || changesByUser === undefined) {
    setTimeout(() => {
      getChanges.next();
    }, 5000);
    setTimeout(() => {
      getChanges.next();
    }, 5000);
  }

  const generate = (change) => {
    delete change.upc;
    delete change.timestamp;
    let arrChange = [];
    for (let field in change) {
      let key = field;
      let value = change[field];
      arrChange.push({ key, value });
    }
    return arrChange.map((item) => {
      return (
        <ListItem>
          {typeof item.value === "string" ? (
            <ListItemText
              primary={fieldName[item.key]}
              secondary={item.value}
            />
          ) : (
            <ListItemText primary={fieldName[item.key]} />
          )}
        </ListItem>
      );
    });
  };

  const getChangesFromDb = () => {
    if (changesByUser.length)
      return changesByUser.map((change) => {
        let formateDate = new Date(change.timestamp * 1).toLocaleString();
        return { date: formateDate, upc: change.upc, changes: change };
        // <TableRow key={change.timestamp}>
        //     <TableCell component="th" scope="row">
        //         {new Date(change.timestamp*1000).toString()}
        //     </TableCell>
        //     <TableCell>{change.type}</TableCell>
        //     <TableCell>{change.upc? change.upc : ""}</TableCell>
        //     <TableCell>
        //         <div className={classes.demo}>
        //             <List dense={true}>
        //             {generate(change)}
        //             </List>
        //         </div>
        //     </TableCell>
        // </TableRow>
      });
  };

  return (
    <>
      <ScrollToTop />
      <Grid container spacing={5} className={classes.album}>
        <ListItemText secondary={true} className={classes.title}>
          <Typography variant={"h4"}>Historial de cambios</Typography>
        </ListItemText>
        {/* <MaterialTable
          style={{ margin: "auto", minWidth: "900px" }}
          title={"Cambios en album"}
          columns={[
            { title: "Fecha", field: "date" },
            { title: "UPC", field: "upc" },
            {
              title: "Cambios",
              sorting: false,
              render: (rowData) => (
                <List dense={true} style={{ minWidth: "50vh" }}>
                  {generate(rowData.changes)}
                </List>
              ),
            },
          ]}
          data={getChangesFromDb()}
          options={{
            filtering: true,
          }}
        /> */}
        {/* <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Fecha</TableCell>
                                                <TableCell>Tipo</TableCell>
                                                <TableCell>UPC</TableCell>
                                                <TableCell>Cambios</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {getChangesFromDb()}
                                        </TableBody>
                                    </Table>
                                </TableContainer> */}
      </Grid>
    </>
  );
};

export default SimpleTable;
