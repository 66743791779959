import { useEffect, useState } from "react";
import useConfig from "../../hooks/useConfig";
import { Typography } from "@material-ui/core";
import Relojito from "./Relojito";
import { Chart } from "react-google-charts";
import localizerFactory from "../../utils/localizerFactory";
import { makeStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

const localMoney = localizerFactory();

const useStyles = makeStyles((theme) => ({
  bigmoney: {
    fontWeight: "bold",
    color: "green",
  },
  topPanel: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: theme.spacing(1),
    width: "100%",
  },

  topPanelVertical: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
    width: "100%",
  },

  minitotal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  subtitle: {
    maxWidth: "300px",
    borderTop: "1px solid #ccc",
    paddingTop: theme.spacing(1),
    marginTop: theme.spacing(1),
    textAlign: "center",
  },
  exploreButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(-2),
  },
  button: {
    marginBottom: theme.spacing(2),
  },
}));

const UltimoPeriodo = ({ streams, rrss, total, fraud, other, width, messages }) => {
  const classes = useStyles();
  const config = useConfig();

  if (width === "lg" || width === "xl") {
    return (
      <div className={classes.topPanel}>
        <Relojito
          data={streams}
          label="Streams"
          big={width === "lg" || width === "xl"}
        />

        <Relojito data={rrss} label="Redes" />

        <div className={classes.minitotal}>
          <Typography variant="button">Último período</Typography>
          <Typography variant="h3" className={classes.bigmoney}>
            {localMoney.format(total)}
          </Typography>
          <Typography variant="body2" className={classes.subtitle}>
            Ingresos generados en {messages.generados}
            <br />
            publicados en {messages.publicados}
          </Typography>
        </div>
        <Chart
          chartType="Gauge"
          options={{
            width: 140,
            height: 140,
            redFrom: 5000,
            redTo: 7000,
            max: 7000,
            yellowFrom: 3500,
            yellowTo: 5000,
            minorTicks: 5,
          }}
          data={[
            ["Label", "Value"],
            ["Fraude", fraud],
          ]}
        />

        <Relojito
          data={other}
          label="Otros"
          big={width === "lg" || width === "xl"}
          infoLeft
        />
      </div>
    );
  }

  if (width === "md") {
    return (
      <div>
        <div className={classes.topPanel}>
          <Relojito data={streams} label="Streams" />

          <Relojito data={rrss} label="Redes" />

          <Chart
            chartType="Gauge"
            options={{
              width: 140,
              height: 140,
              redFrom: 5000,
              redTo: 7000,
              max: 7000,
              yellowFrom: 3500,
              yellowTo: 5000,
              minorTicks: 5,
            }}
            data={[
              ["Label", "Value"],
              ["Fraude", fraud],
            ]}
          />

          <Relojito data={other} label="Otros" infoLeft />
        </div>

        <div className={classes.minitotal}>
          <Typography variant="button">Último período</Typography>
          <Typography variant="h5" className={classes.bigmoney}>
            {localMoney.format(total)}
          </Typography>
          <Typography variant="body2" className={classes.subtitle}>
            Ingresos generados en {messages.generados}
            <br />
            publicados en {messages.publicados}
          </Typography>
        </div>
      </div>
    );
  }

  if (width === "sm") {
    return (
      <div>
        <div className={classes.topPanel}>
          <Relojito data={streams} label="Streams" />

          <Relojito data={rrss} label="Redes" />

          <Chart
            chartType="Gauge"
            options={{
              width: 140,
              height: 140,
              redFrom: 5000,
              redTo: 7000,
              max: 7000,
              yellowFrom: 3500,
              yellowTo: 5000,
              minorTicks: 5,
            }}
            data={[
              ["Label", "Value"],
              ["Fraude", fraud],
            ]}
          />
          <Relojito data={other} label="Otros" />
        </div>
        <div className={classes.minitotal}>
          <Typography variant="button">Último período</Typography>
          <Typography variant="h3" className={classes.bigmoney}>
            {localMoney.format(total)}
          </Typography>
          <Typography variant="body2" className={classes.subtitle}>
            Ingresos generados en {messages.generados}
            <br />
            publicados en {messages.publicados}
          </Typography>
        </div>
      </div>
    );
  }

  if (width === "xs") {
    return (
      <div>
        <div className={classes.minitotal}>
          <Typography variant="button">Último período</Typography>
          <Typography variant="h4" className={classes.bigmoney}>
            {localMoney.format(total)}
          </Typography>
          <Typography variant="body2" className={classes.subtitle}>
            Ingresos generados en {messages.generados}
            <br />
            publicados en {messages.publicados}
          </Typography>
        </div>
        <div className={classes.topPanelVertical}>
          <Relojito data={streams} label="Streams" big />
          <Relojito data={rrss} label="Redes" big />
          <Chart
            chartType="Gauge"
            options={{
              width: 175,
              height: 175,
              redFrom: 5000,
              redTo: 7000,
              max: 7000,
              yellowFrom: 3500,
              yellowTo: 5000,
              minorTicks: 5,
            }}
            data={[
              ["Label", "Value"],
              ["Fraude", fraud],
            ]}
          />
          <Relojito
            data={other}
            label="Otros"
            big={width === "lg" || width === "xl"}
            big
          />
        </div>
      </div>
    );
  }
};

export default withWidth()(UltimoPeriodo);
