//@ts-check
import { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty, debounce } from "lodash";
import useAlbumOpened from "../../../../../reducers/album/hooks/useAlbumOpened";
import useTrackOpened from "../../../../../reducers/album/hooks/useTrackOpened";
import albumActions from "../../../../../reducers/albumActions";
import useAlbumErrors from "../../../../../reducers/album/hooks/useAlbumErrors";

/** @typedef {import("../../../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable*/
/** @typedef {import("../../../../../business-objects/album.fieldEditable").FieldLevelType} FieldLevelType*/
/** @typedef {import("../../../../../business-objects/album.fieldEditable").FieldTypes} FieldTypes*/

/**
 *
 * @param {FieldTypes} type
 */
const makeAdefaultValue = (type) => {
  switch (type) {
    case "switch":
      return false;
    case "date":
      return "";
    default:
      return "";
  }
};

const validateRequired = (value) => {
  return isEmpty(value) ? "El campo no puede estar vacío" : null;
};

const validate = (value, props) => {
  let error = null;
  if (props.required) {
    error = validateRequired(value);
  }

  return error;
};

/**
 * @param {{
 * label: string,
 * name: string,
 * type: FieldTypes,
 * level: FieldLevelType,
 * required?:boolean,
 * canDelete?:boolean,
 * options?: any
 * debounceTimeToSave?: number,
 * skipInteralValidation?: boolean
 * defaultValue?: any
 * }} props
 */
const useEditableField = (props) => {
  const {
    label,
    name,
    level,
    type,
    canDelete,
    required,
    debounceTimeToSave = 0,
    skipInteralValidation = false,
    defaultValue,
  } = props;
  const [value, setValue] = useState("");
  const [localValue, setLocalValue] = useState(
    defaultValue || makeAdefaultValue(type)
  );
  const [hasChanged, setHasChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();
  const upc = useSelector((state) => state.albumState.openAlbum);
  const { errors, setError } = useAlbumErrors();
  const [localError, setLocalError] = useState("");
  const album = useAlbumOpened();
  const track = useTrackOpened();

  const path = useMemo(() => {
    if (!track) {
      return name;
    }
    return level === "track" ? `tracks/${track.isrc}/${name}` : name;
  }, [level, track, name]);

  const error = useMemo(() => {
    return localError || (errors && errors[path]) || "";
  }, [errors, path, localError]);

  /**
   * @return {FieldEditable}
   */
  const _getFieldEditable = () => {
    /** @type {FieldEditable} */
    const field = {
      label,
      type,
      path,
      value,
      level,
    };

    if (level === "track" && track) {
      field.isrcTrack = track.isrc;
    }

    return field;
  };

  useEffect(() => {
    if (!track && level === "track") {
      console.warn("no hay track");
      return;
    }
    if (!path) {
      return;
    }
    const pathObj = path.replace(/\//g, ".");
    const currentValue = get(album, pathObj);
    if (value !== currentValue) {
      setValue(currentValue);
      setLocalValue(currentValue);
    }
    if (!skipInteralValidation) {
      setError(path, validate(currentValue, props));
    }
  }, [
    name,
    level,
    album,
    track,
    path,
    setError,
    setValue,
    setLocalValue,
    props,
    value,
    skipInteralValidation,
  ]);

  useEffect(() => {
    setHasChanged(value !== localValue);
  }, [value, localValue]);

  useEffect(() => {
    setError(path, localError);
  }, [localError, path, setError]);

  const _saveNewValue = useCallback(
    debounce((newValue) => {
      const doSave = async () => {
        setIsSaving(true);
        await dispatch(
          albumActions.saveFieldChange(
            upc,
            _getFieldEditable(),
            "update",
            newValue
          )
        );
        setIsSaving(false);
      };
      doSave();
    }, debounceTimeToSave),
    [dispatch, albumActions, upc, path, debounceTimeToSave]
  );

  const setNewValue = (newValue) => {
    if (newValue === localValue) {
      return;
    }
    setLocalValue(newValue);
    setLocalError(validate(newValue, props) || "");
    _saveNewValue(newValue);
  };

  const deleteField = async () => {
    dispatch(albumActions.deleteField(upc, _getFieldEditable()));
  };

  /**
   * @param {string} errorStr
   */
  const setErrorPublic = (errorStr) => {
    setLocalError(errorStr);
  };

  return {
    upc,
    path,
    label,
    value: localValue,
    hasChanged,
    canDelete,
    isSaving,
    required,
    error,
    setError: setErrorPublic,
    setValue: setNewValue,
    deleteField: deleteField,
  };
};

export default useEditableField;
