const config_DEV_default = {
  apiKey: "AIzaSyD9z5kEElcbLKtnCiJCjJZc2fyX-qMNITA",
  authDomain: "musik-digital.firebaseapp.com",
  databaseURL: "https://musik-digital.firebaseio.com",
  projectId: "musik-digital",
  storageBucket: "musik-digital-track-uploading",
  messagingSenderId: "604933229283",
};

const config_DEV_local = {
  apiKey: "AIzaSyD9z5kEElcbLKtnCiJCjJZc2fyX-qMNITA",
  authDomain: "http://localhost:9099",
  databaseURL: "http://localhost:9000",
  projectId: "musik-digital",
  storageBucket: "musik-digital-track-uploading",
  messagingSenderId: "604933229283",
};

const config_PROD_default = {
  apiKey: "AIzaSyA_XAx7fNVbpC-OIdcJqmvD5tftvhfMZBU",
  authDomain: "the-recording-consort.firebaseapp.com",
  databaseURL: "https://the-recording-consort.firebaseio.com",
  projectId: "the-recording-consort",
  storageBucket: "tracks-uploading",
  messagingSenderId: "470361540391",
};

module.exports = {
  dev: {
    FIREBASE_CONFIG: config_PROD_default,
    USE_EMULATOR: true,
  },
  prod: {
    FIREBASE_CONFIG: config_PROD_default,
  },
};
