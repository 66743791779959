import { combineReducers } from "redux";
import configReducer from "./config/configReducer";
import albumReducer from "./albumReducer";
import cartReducer from "./cart/cartReducer";
import wizardNewAlbumReducer from "./wizardNewAlbum/wizardReducer";

const rootReducer = combineReducers({
  configState: configReducer,
  albumState: albumReducer,
  cartState: cartReducer,
  wizardNewAlbumState: wizardNewAlbumReducer,
});

export default rootReducer;
