//@ts-check
import React from "react";
import { Switch, FormControlLabel } from "@material-ui/core";

import useEditableField from "../hooks/useEditableField";

/** @typedef {import("../../../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable*/
/** @typedef {import("../../../../../business-objects/album.fieldEditable").FieldLevelType} FieldLevelType*/
/** @typedef {import("../../../../../business-objects/album.fieldEditable").FieldTypes} FieldTypes*/

/**
 * @param {{label: string, name: string, type: FieldTypes, level: FieldLevelType, forceCheck?:boolean, disabled?:boolean}} props
 */
const EditableSwitch = (props) => {
  const { path, label, value, setValue } = useEditableField(props);
  const { forceCheck, disabled } = props;

  const computedValue = value === true || forceCheck === true;

  return (
    <FormControlLabel
      label={label}
      control={
        <Switch
          checked={computedValue}
          onChange={() => setValue(!value)}
          name={path}
          color="primary"
          disabled={disabled}
        />
      }
    />
  );
};

export default EditableSwitch;
