// @ts-check
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import newAlbumService from "../../services/newAlbumService";
import { setNewAlbumPayloadAction } from "./actions/setNewAlbumPayload";
import wizardActions from "./wizardActions";

/** @typedef {import('../store').StateStore} StateStore */
/** @typedef {import("formik").FormikHelpers<any>} FormikHelpers */

export const useWizardBot =
  /** @param {FormikHelpers} actionsFormik */
  (actionsFormik) => {
    const dispatch = useDispatch();
    const uploadingKey = useSelector(
      /**
       * @param {StateStore} state
       * @return {Object}
       */
      (state) => state.wizardNewAlbumState.uploadingKey
    );

    useEffect(() => {
      const init = async () => {
        if (!uploadingKey) {
          const newAlbumPayload = await newAlbumService.fetchAlbumTemporally();

          if (newAlbumPayload) {
            dispatch(setNewAlbumPayloadAction(newAlbumPayload));
            if (newAlbumPayload.album) {
              actionsFormik.setValues(newAlbumPayload.album);
            }
          } else {
            dispatch(wizardActions.initNewAlbum());
          }
        }
      };
      init();
    }, [dispatch, uploadingKey, actionsFormik]);

    return {};
  };

export default useWizardBot;
