import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import localizerFactory from "../../utils/localizerFactory";

const ReportMiniDisplay = ({ title = "", data = [], numberFormat = "decimal" }) => {
  const formatter = localizerFactory({ style: numberFormat });

  const amount_formatted = formatter.format(data);

  return (
    <Paper style={{ padding: 20, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      <Typography variant="h4" align="center">
        {amount_formatted}
      </Typography>
      <Typography variant="subtitle2" align="center">
        {title}
      </Typography>
    </Paper>
  );
};

export default ReportMiniDisplay;
