const handleLoginError = (error) => {
  switch (error.code) {
    case "auth/wrong-password":
      alert("La contraseña es incorrecta.");
      break;
    default:
      alert("Algo ha fallado.");
  }
};

export default handleLoginError;
