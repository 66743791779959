// @ts-check
import { isArray } from "lodash";
import isPrimaryArtist from "./isPrimaryArtist";

/** @typedef {import("./album").Artist} Artist */
/** @typedef {import("./album").ArtistRole} ArtistRole */
/** @typedef {import("../reducers/wizardNewAlbum/wizardStore").NewAlbumArtist} NewAlbumArtist */

const _getPrimaryFromArray = (artists) => {
  return artists.find(isPrimaryArtist);
};

/**
 * Search and return the main primary artit
 *
 * @param {{[key:string]: Artist} | Array<NewAlbumArtist>} artists
 * @return {Artist | null}
 */
export const getArtistMain = (artists) => {
  if (!artists) {
    return null;
  }

  return isArray(artists)
    ? _getPrimaryFromArray(artists)
    : _getPrimaryFromArray(Object.values(artists));
};

export default getArtistMain;
