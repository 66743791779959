export default function getFlagEmoji(countryCode) {
  if (countryCode === undefined) return "";

  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map((char) => char.charCodeAt(0) + 127397);

  return String.fromCodePoint(...codePoints);
}
