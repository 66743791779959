// @ts-check
import produce from "immer";

/** @typedef {import('../wizardStore').WizardNewAlbumState} WizardNewAlbumState */
/** @typedef {import('../wizardStore').NewAlbumTrack} NewAlbumTrack */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */

/** @typedef {{
 *  type: "WIZARD_TRACK_PROGRESS"
 *  track: NewAlbumTrack
 *  progress: number
 * }} TrackProgressAction */

const TYPE = "WIZARD_TRACK_PROGRESS";

/**
 * @param {NewAlbumTrack} track
 * @param {number} progress 0-100 value
 * @return {TrackProgressAction}
 */
export const trackProgressAction = (track, progress) => {
  return {
    type: TYPE,
    track,
    progress,
  };
};

/**
 * @param {WizardNewAlbumState} state
 * @param {TrackProgressAction} action
 * @return {object}
 */
export const trackProgressReducer = (state, { track, progress }) => {
  return produce(state, (draft) => {
    const index = draft.tracks.findIndex((t) => t.id === track.id);
    if (index !== -1) {
      draft.tracks[index].progress = progress;
    }
  });
};

export default {
  action: trackProgressAction,
  reducer: trackProgressReducer,
  TYPE,
};
