import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";

const useStyles = makeStyles({
  root: {
    height: "100%",
    width: "100%",
    // maxWidth: 500,
  },
});

export default function SmartlinkEmbed({ smartlink }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea style={{ height: "100%" }}>
        <CardMedia
          component="iframe"
          height="100%"
          src={smartlink}
        />
      </CardActionArea>
    </Card>
  );
}
