import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import Button from "@material-ui/core/Button";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { firebase } from "../../../../../firebase";
import { useState } from "react";
import PreviewAnimation from "./PreviewAnimation";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: "470px",
  },
  dialogActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2),
  },
}));

const messages = [
  "Verificando metadata...",
  "Verificando audios...",
  "Verificando portada...",
  "Analizando integridad de los archivos...",
  "Verificando duración de los audios...",
  "Verificando tamaño de los audios...",
  "Verificando tamaño de la portada...",
];

const MAX_TIMES_SHOW_DIALOG = 3;

const PreviewInfoDialog = ({ genre }) => {
  const [informed, setInformed] = useState(true);
  const [message, setMessage] = useState("Verificando metadata...");
  const [indexMessage, setIndexMessage] = useState(0);
  const [canContinue, setCanContinue] = useState(false);
  const [page, setPage] = useState(0);

  useEffect(() => {
    (async () => {
      const snap = await firebase.db
        .ref(
          `users/${firebase.auth.currentUser.uid}/settings/optouts/previewInfoDialog`
        )
        .once("value");

      const dialogShowedCount = snap.val() || 0; // how many times the dialog was showed to the user

      if (dialogShowedCount >= MAX_TIMES_SHOW_DIALOG) {
        setInformed(true);
      } else {
        setInformed(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (indexMessage === messages.length - 1) {
      setCanContinue(true);
      setMessage("");
    } else {
      const wait = Math.floor(Math.random() * 1000) + 500;

      setTimeout(() => {
        setIndexMessage((indexMessage) => (indexMessage + 1) % messages.length);
        setMessage(messages[indexMessage]);
      }, wait);
    }
  }, [indexMessage]);

  const classes = useStyles();

  const handleShowNoMore = async () => {
    const database = firebase.db;
    const endpointPath = `users/${firebase.auth.currentUser.uid}/settings/optouts/previewInfoDialog`;

    database.ref(endpointPath).transaction(
      (currentData) => {
        if (currentData === null) {
          return 1;
        } else {
          currentData = (currentData || 0) + 1;
          return currentData;
        }
      },
      (error, committed, snapshot) => {
        if (error) {
          // Handle any error that occurred during the transaction
          console.error("Transaction failed abnormally!", error);
        } else if (!committed) {
          // Handle the case where the transaction was aborted
          console.log("Transaction aborted.");
        } else {
          // Handle the case where the transaction was successfully committed
          console.log("Transaction committed!", snapshot.val());
        }
      }
    );

    if (setInformed) {
      setInformed(true);
    }
  };

  if (!canContinue) {
    return (
      <Dialog
        open={!informed}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              gap: "1em",
              flexDirection: "column",
              justifyContent: "space-evenly",
              paddingTop: "2em",
              paddingBottom: "2em",
            }}
          >
            <PreviewAnimation genre={genre} />

            <Typography variant="body2" align="center">
              {message}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={!informed}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {page === 0 ? "¡Todo OK a nivel album!" : "Importante"}
        </Box>
      </DialogTitle>

      <Divider />

      <DialogContent>
        <div className={classes.dialog}>
          {page === 0 && (
            <div>
              <Typography variant="h1" align="center">
                🙌
              </Typography>
            </div>
          )}

          {page === 1 && (
            <div>
              <Typography variant="body1">
                Evita <strong>retrasos</strong> de publicación:
              </Typography>

              <ul>
                <li>
                  No incluyas <b>número de orden</b> en el título.
                </li>
                <li>
                  No incluyas <b>Feat.</b> ni otros artistas en el título.
                </li>
                <li>
                  No dejes el <b>nombre de archivo</b> como título.
                </li>
              </ul>
            </div>
          )}

          {/*               
              <div className="formTrack__creditsLine">
                <hr className="formTrack__creditsLine-line" size="1" />
                <span className="formTrack__creditsLine-label">
                  <ArrowDownwardIcon />
                </span>
              </div> */}
        </div>
      </DialogContent>
      <Divider />
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="contained"
          color="primary"
          className={classes.dialogButton}
          onClick={page === 0 ? () => setPage(1) : handleShowNoMore}
          disabled={!canContinue}
          autoFocus
          fullWidth
        >
          {page === 0 ? "Vamos con los tracks" : "Entendido"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PreviewInfoDialog;
