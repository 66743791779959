// @ts-check
import { db } from "../../../firebase";
import { openAlbumField } from "../../albumActions";
import { endsWith } from "lodash";
import { processAlbumChange } from "../../cart/cartActions";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldActionType} FieldActionType */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */

/**
 * Add a field to track
 * @param {string} upc
 * @param {string} isrc
 * @param {FieldEditable} field
 * @return {Function}
 */
export const addTrackField = (upc, isrc, field) => {
  /**
   * @param {function} dispatch
   */
  return async function addTrackFieldThunk(dispatch) {
    const { path } = field;
    const value = endsWith(path, "explicit") ? true : "";
    await db.updateAlbumFields(upc, { [path]: value });
    dispatch(openAlbumField(path));

    field.isrcTrack = isrc;

    // send to cart process
    await dispatch(processAlbumChange(upc, field, "add"));
  };
};
