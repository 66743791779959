// @ts-check
import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  albumClearErrorsAction,
  albumSetErrorAction,
} from "../../albumActions";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../store").StateStore} StateStore */

/**
 * @return {{
 *  errors: {[key:string]: string}
 *  hasErrors: boolean,
 *  setError: Function,
 *  clearErrors: Function
 * }}
 */
export const useAlbumErrors = () => {
  const dispatch = useDispatch();
  const errors = useSelector(
    /** @param {StateStore} state */
    (state) => state.albumState.openAlbumErrors
  );

  /**
   * @param {string} nameField
   * @param {string | null} error
   */
  const setError = useCallback(
    (nameField, error) => {
      dispatch(albumSetErrorAction(nameField, error));
    },
    [dispatch]
  );

  const clearErrors = useCallback(() => {
    dispatch(albumClearErrorsAction());
  }, [dispatch]);

  const hasErrors = errors && Object.values(errors).length > 0;

  return { errors, hasErrors, setError, clearErrors };
};

export default useAlbumErrors;
