export { makeupId } from "./makeupId";
export { makeReleaseDates } from "./makeReleaseDates";
export { makeCopyrightFields } from "./makeCopyrightFields";
export { getArtistRolesForGenre } from "./getArtistRolesForGenre";
export { getArtistsPrimaries } from "./getArtistsPrimaries";
export { getArtistsFeats } from "./getArtistsFeats";
export { getAlbumFormatName } from "./getAlbumFormatName";
export { getTrackOriginalFilename } from "./getTrackOriginalFilename";
export { getAlbumTitle } from "./getAlbumTitle";
export { getTrackTitle } from "./getTrackTitle";
export { getTracksSorted } from "./getTracksSorted";
export { isAlbumEditing } from "./isAlbumEditing";
export { isPrimaryArtist } from "./isPrimaryArtist";
export { canAddRemoveTracks } from "./canAddRemoveTracks";
export { normalizeTitle } from "./normalizeTitle";

/** @type {import("./album").ArtistRole} */
export const ARTIST_ROLE_PRIMARY = {
  label: "Artista principal",
  value: "primary_artist",
};
