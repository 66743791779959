import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import * as routes from "../constants/routes";

const useStyles = makeStyles((theme) => ({
  heading: {
    color: "black",
  },
  subheading: {
    paddingBottom: theme.spacing(1),
  },
  formPaper: {
    maxWidth: 1100,
    margin: "auto",
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(5),
    padding: theme.spacing(5),
    borderRadius: 10,
  },
  button: {
    marginTop: theme.spacing(5),
  },
}));

const UploadedPage = ({ editing }) => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Paper elevation={1} className={classes.formPaper}>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        className={classes.heading}
      >
        {editing ? "¡Cambios recibidos, gracias!" : "¡Album recibido, gracias!"}
      </Typography>

      <Typography
        variant="h5"
        color="inherit"
        align="center"
        className={classes.button}
      >
        <Button
          color="primary"
          variant="contained"
          component={NavLink}
          to={routes.ALBUMS}
        >
          Tus albums
        </Button>
      </Typography>
    </Paper>
  );
};

export default UploadedPage;
