//@ts-check
/**
 * @typedef {import("./album").Album} Album
 * @typedef {import("../reducers/wizardNewAlbum/wizardStore").PayloadToServer} PayloadToServer
 **/

/**
 * @param {PayloadToServer | Album} album
 * @return {{
 *  copyright: string;
    soundRecordingCopyright: string;
 * }}
 */
export const makeCopyrightFields = (album) => {
  if (!album || !album.release) {
    return {
      copyright: "",
      soundRecordingCopyright: "",
    };
  }

  const { release } = album;
  const year = release.split("-")[0];

  const copyright = year + " " + album.subaccount;
  const soundRecordingCopyright = copyright;

  const metaFields = {
    copyright,
    soundRecordingCopyright,
  };

  return metaFields;
};

export default makeCopyrightFields;
