// @ts-check
import produce from "immer";

/** @typedef {import('../wizardStore').WizardNewAlbumState} WizardNewAlbumState */
/** @typedef {import('../wizardStore').NewAlbumTrack} NewAlbumTrack */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */

/** @typedef {{
 *  type: "WIZARD_TRACK_REMOVE"
 *  track: NewAlbumTrack
 * }} trackRemoveAction */

const TYPE = "WIZARD_TRACK_REMOVE";

/**
 * @param {NewAlbumTrack} track
 * @return {trackRemoveAction}
 */
export const trackRemoveAction = (track) => {
  return {
    type: TYPE,
    track,
  };
};

/**
 * @param {WizardNewAlbumState} state
 * @param {trackRemoveAction} action
 * @return {object}
 */
export const trackRemoveReducer = (state, { track }) => {
  // console.log('trackRemoveReducer', track, state);

  return produce(state, (draft) => {
    const index = draft.tracks.findIndex((t) => t.id === track.id);
    if (index !== -1) {
      draft.tracks.splice(index, 1);
    }
  });
};

export default {
  action: trackRemoveAction,
  reducer: trackRemoveReducer,
  TYPE,
};
