import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  base: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
    marginRight: theme.spacing(5),
  },
  clear: {
    marginLeft: theme.spacing(1),
  },
}));

const Search = ({ q, setSpace }) => {
  const [query, setQuery] = useState(q);

  useEffect(() => {
    const _q = q === "all" ? "" : q;
    setQuery(_q);
  }, [q]);

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = () => {
    setSpace(query);
  };

  const clearSearch = () => {
    setQuery("");
    setSpace("all");
  };

  const classes = useStyles();

  return (
    <div className={classes.base}>
      <TextField
        id="query"
        name="q"
        value={query}
        label="Buscar"
        onChange={handleChange}
        onKeyUp={(e) => (e.key === "Enter" ? handleSearch() : false)}
        fullWidth
        variant="outlined"
      />
      {q !== "" && (
        <IconButton
          size="small"
          className={classes.clear}
          onClick={clearSearch}
        >
          <Icon>clear</Icon>
        </IconButton>
      )}
    </div>
  );
};

export default Search;
