// @ts-check
import { db } from "../../../firebase";
import storageService from "../../../services/storageService.js";
import { loadAlbumUnedited } from "../../cart/actionsAsync/loadAlbumUnedited";
import { getAlbum } from "./getAlbum";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album").Track} Track */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldActionType} FieldActionType */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */

/**
 * @param {Album} albumUnedited
 * @return {object}
 */
const makeUpdateToRestoreCover = (albumUnedited) => {
  const cover = albumUnedited.photos?.cover;
  const updates = {
    [`photos/cover/storageLocation`]: cover?.storageLocation || null,
    [`photos/cover/url`]: cover?.url || null,
    [`photos/cover/fileChanged`]: null,
  };
  return updates;
};

/**
 *
 * @param {function} dispatch
 * @param {string} upc
 * @param {FieldEditable} field
 */
const restoreMetadataCover = async (dispatch, upc, field) => {
  /** @type {Album} */
  const albumUnedited = await dispatch(loadAlbumUnedited(upc));

  const updates = makeUpdateToRestoreCover(albumUnedited);
  await db.updateAlbumFields(upc, updates);
};

/**
 *
 * @param {function} dispatch
 * @param {StateGetter} getState
 * @param {string} upc
 */
const removeUploadedCover = async (dispatch, getState, upc) => {
  const album = await dispatch(getAlbum(upc));

  /** @type {StateStore} */
  const reduxState = getState();
  const config = reduxState.configState.config;
  const bucketName = config.buckets.uploading.name;

  try {
    await storageService.removeFile(
      album.photos.cover.storageLocation,
      bucketName
    );
  } catch (e) {
    console.warn("Error on remove old cover file", e);
  }
};

/**
 * Restore an audio track
 * @param {string} upc
 * @param {FieldEditable} field
 * @return {Function}
 */
export const coverRestoreUpload = (upc, field) => {
  /**
   * @param {function} dispatch
   * @param {StateGetter} getState
   */
  return async function thunk(dispatch, getState) {
    if (field?.type !== "cover") {
      console.log(field);
      throw new Error("El campo a restaurar no es del tipo cover");
    }

    // REMOVE TEMPORAL UPLOADED COVER FILE
    await removeUploadedCover(dispatch, getState, upc);

    // RESTORE METADATA RELATED TO THE FILE
    await restoreMetadataCover(dispatch, upc, field);
  };
};

export default coverRestoreUpload;
