// @ts-check
import { db } from "../firebase/firebase";
import { functions } from "../firebase";

/** @typedef {import("../business-objects/cart").CartItem} CartItem */
/** @typedef {import("../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../business-objects/album.fieldEditable").FieldTypes} FieldTypes */

/**
 * @param {string} str
 * @return {string}
 */
const _keyCartItem = (str) => {
  return str.replace(/\//gi, "_");
};

/**
 * @param {string} upc
 * @return {string}
 */
const _makeCartPath = (upc) => {
  const cartPath = `carts/${upc}`;
  return cartPath;
};

/**
 * Transform the path of a FieldEditable to CartItem Path
 *
 * basically this is need for Artists field
 *
 * @param {FieldTypes} fieldType
 * @param {string} path
 * @return {string}
 */
export const fieldPathToCartItemPath = (fieldType, path) => {
  if (!fieldType || !path) {
    return path;
  }

  if (fieldType !== "artist") {
    return path;
  }

  return path.replace("/name", "");
};

/**
 * @param {CartItem} cartItem
 * @return {string}
 */
const _makeCartItemPath = (cartItem) => {
  const tmpPath = fieldPathToCartItemPath(cartItem.typeField, cartItem.path);
  return _keyCartItem(tmpPath);
};

/**
 * @param {string} upc
 * @param {CartItem} cartItem
 */
export const saveCartItem = async (upc, cartItem) => {
  const cartPath = _makeCartPath(upc);

  const cartItemPath = _makeCartItemPath(cartItem);

  const cartUpdates = {
    [cartItemPath]: cartItem,
  };

  // @ts-ignore
  return db.ref(cartPath).update(cartUpdates);
};

export const removeCartItem = async (upc, cartItem) => {
  const cartPath = _makeCartPath(upc);

  const cartItemPath = _makeCartItemPath(cartItem);
  const fullPath = cartPath + "/" + cartItemPath;

  return db.ref(fullPath).remove();
};

/**
 *
 * @param {string} upc
 * @return {Promise<Object>}
 */
export const fetchCartItems = async (upc) => {
  const cartPath = _makeCartPath(upc);

  return db
    .ref(cartPath)
    .once("value")
    .then((snap) => snap.val());
};

export const publish = async (upc) => {
  const publishCart = functions.httpsCallable("albumPublishCart");

  return publishCart(upc);
};

export default {
  fetchCartItems,
  saveCartItem,
  removeCartItem,
  fieldPathToCartItemPath,
  publish,
};
