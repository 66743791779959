import { useEffect, useState, forwardRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { functions } from "../../../../../firebase";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import CircularProgress from "@material-ui/core/CircularProgress";
import SmartlinkEmbed from "./SmartlinkEmbed";
import CopyIcon from "@material-ui/icons/FileCopy";
import VisitIcon from "@material-ui/icons/Launch";
import Typography from "@material-ui/core/Typography";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";

const getAlbumSmartlinks = functions.httpsCallable("getAlbumSmartlinks");
const createSmartlink = functions.httpsCallable("createSmartlinkForAlbum");

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "black",
    borderBottom: "4px solid rgb(204, 170, 102)",
    display: "flex",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "rgb(204, 170, 102)",
    opacity: 0,
  },
  dialogInner: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    height: "100%",
  },
  loadingCircle: {
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    // marginTop: -12,
    // marginLeft: -12,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2),
  },
  buttons: {
    display: "flex",
    gap: theme.spacing(2),
  },
  smartlinks: {
    background: "rgb(204, 170, 102)",
    padding: theme.spacing(1),
    borderRadius: "4px",
    display: "flex",
    gap: "5px",
    flexDirection: "column",
    alignItems: "center",
  },
  label: {
    fontSize: ".8rem",
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Smartlink({ album }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [smartlink, setSmartlink] = useState("");
  const [message, setMessage] = useState("");
  const [isFutureRelease, setIsFutureRelease] = useState(true);
  const [type, setType] = useState("post_release");

  const keepEmPosted = () => {
    setTimeout(() => {
      setMessage("Ten paciencia, puede tardar hasta 1 min... 🕒");
    }, 3000);

    setTimeout(() => {
      setMessage("Ya casi está listo... ⏳");
    }, 6000);
  };

  useEffect(() => {
    (async () => {
      if (open) {
        setLoading(true);
        setMessage("Buscando smartlink...");

        const { upc } = album;

        let smartlink;

        // get existing smartlink
        const result = await getAlbumSmartlinks({ upc, type });

        if (result.data) {
          smartlink = result.data;
        } else {
          setMessage("Generando smartlink...");

          keepEmPosted();

          const result = await createSmartlink({ upc, type });

          if (result.data) {
            smartlink = result.data;
          }
        }

        setSmartlink(smartlink);
        setMessage("");
        setLoading(false);
      } else {
        const thePresent = new Date();
        const releaseDate = new Date(album.release_digital);
        setIsFutureRelease(releaseDate > thePresent);
      }
    })();
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const visitLink = () => {
    window.open(smartlink, "_blank");
  };

  const copyLink = () => {
    navigator.clipboard.writeText(smartlink);
  };

  const openPreSave = () => {
    setType("pre_release");
    handleClickOpen();
  };

  const openFuture = () => {
    setType("future_release");
    handleClickOpen();
  };

  const openSmartlink = () => {
    setType("post_release");
    handleClickOpen();
  };

  return (
    <div>
      <FormControl className={classes.smartlinks}>
        <FormLabel className={classes.label}>PROMO</FormLabel>

        {isFutureRelease && (
          <ButtonGroup
            orientation="vertical"
            aria-label="Vertical button group"
            variant="contained"
          >
            <Button color="primary" size="small" onClick={openPreSave}>
              Pre-save
            </Button>
            <Button color="primary" size="small" onClick={openFuture}>
              Cuenta regresiva
            </Button>
          </ButtonGroup>
        )}
        {!isFutureRelease && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={openSmartlink}
          >
            Smartlink
          </Button>
        )}
      </FormControl>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar style={{ justifyContent: "space-between" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>

            <div className={classes.buttons}>
              <Button
                color="inherit"
                startIcon={<CopyIcon />}
                onClick={copyLink}
                style={{ whiteSpace: "nowrap" }}
              >
                Copiar
              </Button>
              <Button
                color="inherit"
                startIcon={<VisitIcon />}
                onClick={visitLink}
              >
                Visitar
              </Button>
            </div>
          </Toolbar>
        </AppBar>

        {loading && (
          <div className={classes.dialogInner}>
            <div className={classes.loadingCircle}>
              <CircularProgress variant="indeterminate" />
              <Typography variant="body1">{message}</Typography>
            </div>
          </div>
        )}

        {!loading && smartlink && <SmartlinkEmbed smartlink={smartlink} />}
      </Dialog>
    </div>
  );
}
