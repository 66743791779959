import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Paper, Button, ButtonGroup } from "@material-ui/core";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect } from "react";
import { keys, orderBy } from "lodash";
import { set } from "date-fns";
import { ChevronRight, ChevronLeft } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(8),
    borderRadius: theme.spacing(10),
    [theme.breakpoints.down("md")]: {
      borderRadius: theme.spacing(0),
    },
  },
  chartBox: {
    marginBottom: theme.spacing(5),
  },
  titleAndToolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },
  pagination: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  [theme.breakpoints.down("sm")]: {
    pagination: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: theme.spacing(2),
    },
  },
}));

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    tooltip: {
      callbacks: {
        title: function ([{ dataset, dataIndex }]) {
          const { total } = dataset.tooltips[dataIndex];
          return `${total}`;
        },
        afterTitle: function ([{ dataset }]) {
          return `Ingresos en ${dataset.label} correspondientes a:`;
        },
        label: function ({ dataset, dataIndex }) {
          const info = dataset.tooltips[dataIndex];
          return `- Reproducciones de ${info.period}`;
        },
        afterLabel: function ({ dataset, dataIndex }) {
          const info = dataset.tooltips[dataIndex];
          return `- Publicados en ${info.periodAccounting}`;
        },
      },
    },
  },
};

const getColor = (platform) => {
  switch (platform) {
    case "Spotify":
      return "rgba(29, 185, 84, 1)";
    case "Apple Music":
      return "rgba(255, 0, 0, 0.5)";
    case "Amazon":
      return "#febd69";
    case "Deezer":
      return "rgb(162, 56, 255)";
    case "Facebook/Instagram":
      return "#4267B2";
    case "YouTube":
      return "rgba(255, 0, 0, 1)";
    case "Tidal":
      return "rgba(0, 0, 0, 1)";
    case "Google Play":
      return "#4285F4";
    case "SoundCloud":
      return "#ff6300";
    case "TikTok":
      return "#28e9e2";
    case "Otros":
      return "rgba(0, 0, 0, 0.3)";

    default:
      return "rgba(0, 0, 0, 1)";
  }
};

const getStyleConfigurations = (platform) => {
  return {
    backgroundColor: getColor(platform),
    borderColor: getColor(platform),
    borderWidth: 2,
    pointRadius: 3,
    pointHoverRadius: 5,
    tension: 0.3,
  };
};

const BigChartRoyaltiesbyPlatform = ({ data }) => {
  const [totPages, setTotPages] = useState(0);
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);

  const [pageSize, setPageSize] = useState(6);
  const [page, setPage] = useState(1);

  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (data) {
      setLabels(data.labels);

      setDatasets(
        data.datasets.map((dataset) => {
          return {
            ...dataset,
            ...getStyleConfigurations(dataset.label),
          };
        })
      );
    }
  }, [data]);

  useEffect(() => {
    const numPages = Math.ceil(data.labels.length / pageSize);
    setTotPages(numPages);

    const paginate = (arr) => {
      const start = page * pageSize * -1;
      const end = labels.length - pageSize * (page - 1);
      return arr.slice(start, end);
    };

    if (labels.length && datasets.length) {
      const _datasets = datasets.map((dataset) => {
        return {
          ...dataset,
          data: paginate(dataset.data),
          tooltips: paginate(dataset.tooltips),
        };
      });

      const _chartData = {
        labels: paginate(labels),
        datasets: _datasets,
      };

      setChartData(_chartData);
    }
  }, [labels, datasets, page, pageSize]);

  const classes = useStyles();

  if (!chartData) return null;

  // pagination controls
  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const nextPage = () => {
    if (page < totPages) {
      setPage(page + 1);
    }
  };

  const changePageSize = (size) => {
    setPageSize(size);
    setPage(1);
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.titleAndToolbar}>
        <Typography variant="h5" gutterBottom>
          Reproducciones por plataforma
        </Typography>
        <div className={classes.pagination}>
          <ButtonGroup
            color="primary"
            aria-label="outlined primary button group"
            size="small"
          >
            <Button
              disabled={page >= totPages}
              variant="contained"
              color="primary"
              onClick={nextPage}
            >
              <ChevronLeft />
            </Button>
            <Button
              disabled={page <= 1}
              variant="contained"
              color="primary"
              onClick={prevPage}
            >
              <ChevronRight />
            </Button>
          </ButtonGroup>

          <ButtonGroup
            color="primary"
            aria-label="outlined primary button group"
            size="small"
          >
            <Button
              variant="contained"
              disabled={pageSize === 6}
              color="primary"
              onClick={() => changePageSize(6)}
            >
              6 meses
            </Button>
            <Button
              variant="contained"
              disabled={pageSize === 12}
              color="primary"
              onClick={() => changePageSize(12)}
            >
              12 meses
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <Line data={chartData} options={options} />
    </Paper>
  );
};

export default BigChartRoyaltiesbyPlatform;
