// @ts-check
import { getCurrentUser } from "../../../firebase/auth";
import newAlbumService from "../../../services/newAlbumService";
import { stateToPayload } from "../wizardUtils";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../wizardStore").WizardNewAlbumState} WizardNewAlbumState */
/** @typedef {import("../wizardStore").NewAlbumPayload} NewAlbumPayload*/

/**
 * Busca y retorna la config del sistema
 *
 * @return {Function}
 */
export function saveTemporally() {
  /**
   * @param {function} dispatch
   * @param {StateGetter} getState
   */
  return async function thunk(dispatch, getState) {
    /** @type {StateStore} */
    const state = getState();

    const user = getCurrentUser(); // TODO: mover esto de firebase a store de redux

    const payload = stateToPayload(state.wizardNewAlbumState, user);

    try {
      await newAlbumService.saveTemporally(payload);
    } catch (e) {
      console.error("payload faild save", payload);
      throw e;
    }
  };
}

export default saveTemporally;
