import { Bars } from "react-loader-spinner";
import { Typography } from "@material-ui/core";

const Loading = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Bars
        height="80"
        width="80"
        color="#4fa94d"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
      <Typography variant="button" style={{ marginTop: "10px" }}>
        Generando reportes
      </Typography>
    </div>
  );
};

export default Loading;
