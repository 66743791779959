import React, { useEffect, useState } from "react";
import { green, red } from "@material-ui/core/colors";
import {
  ButtonBase,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  Divider,
  Tooltip,
} from "@material-ui/core";
import useConfig from "../../../../hooks/useConfig";
import { functions } from "../../../../firebase";
import keys from "lodash/keys";
import amber from "@material-ui/core/colors/amber";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles } from "@material-ui/core/styles";

const sendUltimatum = functions.httpsCallable("sendUltimatum");

const goodStatuses = ["live", "live_video"];

const veryBadStatuses = ["abnormal_streaming", "infringement"];

const lessBadStatuses = [
  "fraudulent",
  "blocked_artist",
  "blocked_label",
  "blocked_user",
];

const infoStatuses = ["withdrawn"];

const badStatuses = [...veryBadStatuses, ...lessBadStatuses];

const usefullStatuses = [...badStatuses, ...goodStatuses, ...infoStatuses];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    minWidth: 500,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(4),
  },
  actions: {
    justifyContent: "space-between",
  },
  otrosEstados: {
    width: "100%",
    maxWidth: 300,
  },
  ultimatum: {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
  },
}));

const AlbumStatusesBar = ({ user }) => {
  const [open, setOpen] = useState(false);
  const [statusesCount, setStatusesCount] = useState({});
  const [statusShare, setStatusShare] = useState({});
  const [patience, setPatience] = useState(0);
  const [ultimatumSent, setUltimatumSent] = useState(
    user.restrictions?.ultimatum || false
  );
  const [totalFuckup, setTotalFuckup] = useState(0);

  const { catalog } = user;
  const { albums } = catalog;

  const { statuses } = useConfig();

  const classes = useStyles();

  // calculate share of each status
  const severity = (status) => {
    return statuses[status]?.severity || 0;
  };

  const getColor = (status) => {
    const darkness = statuses[status]?.severityColor || 0;
    return red[darkness];
  };

  // count sort album statuses
  useEffect(() => {
    // count different statuses
    const statusCount = albums.reduce((acc, album) => {
      if (acc[album.status]) {
        acc[album.status] += 1;
      } else {
        acc[album.status] = 1;
      }
      return acc;
    }, {});

    // sort statuses by count BY SEVERITY!! TODO
    const sorted = Object.keys(statusCount).sort((a, b) => {
      return severity(b) - severity(a);
    });

    // create object with all statuses and usefull statuses
    const final = sorted.reduce(
      (acc, status) => {
        // all
        acc.all[status] = statusCount[status];

        // bad
        if (severity(status) > 0) {
          acc.bad[status] = statusCount[status];
        }

        // good
        if (goodStatuses.includes(status)) {
          acc.good[status] = statusCount[status];
        }

        // informative (only withdrawn at the moment)
        if (infoStatuses.includes(status)) {
          acc.info[status] = statusCount[status];
        }

        // usefull
        if (usefullStatuses.includes(status)) {
          acc.usefull = acc.usefull + statusCount[status];
        }

        return acc;
      },
      { all: {}, bad: {}, info: {}, good: {}, usefull: 0 }
    );

    setStatusesCount(final);
  }, [albums]);

  // calculate patience
  useEffect(() => {
    const totGood = keys(statusesCount.good).reduce((acc, status) => {
      return acc + statusesCount.good[status];
    }, 0);

    setPatience(100 + (Math.max(totGood, 10) - 10) * 5);
  }, [statusesCount]);

  useEffect(() => {
    let _totalFuckup = 0;

    const statusShare = keys(statusesCount?.bad || []).reduce((acc, status) => {
      const count = statusesCount.bad[status];
      const statusFuckupAmmount = severity(status) * count;
      acc[status] = statusFuckupAmmount * (100 / patience);
      _totalFuckup += statusFuckupAmmount;
      return acc;
    }, {});

    setStatusShare(statusShare);
    setTotalFuckup(_totalFuckup);
  }, [statusesCount]);

  const handleClose = () => setOpen(false);

  const ultimatum = async () => {
    const result = await sendUltimatum({ uid: user.uid });

    if (result.data.success) {
      setUltimatumSent(true);
    }
  };

  const listStatuses = (list) => {
    return keys(list).map((status) => (
      <ListItem key={status}>
        <ListItemText
          primary={`${list[status]} ${status}`}
          secondary={`Penalización: ${totalFuckup}`}
        />
      </ListItem>
    ));
  };

  return (
    <>
      <ButtonBase
        style={{ width: "100%", height: "100%" }}
        onClick={() => setOpen(true)}
      >
        <>
          <Box
            style={{
              backgroundColor: green[50],
              width: "100%",
              height: "100%",
              display: "flex",
            }}
          >
            {Object.keys(statusShare).map((status) => (
              <Tooltip
                title={`${status} x ${statusesCount.all[status]} (-${
                  severity(status) * statusesCount.all[status]
                })`}
                key={`tooltip${status}`}
              >
                <Box
                  key={status}
                  width={`${statusShare[status]}%`}
                  style={{
                    backgroundColor: getColor(status),
                    color: "white",
                    height: 30,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="subtitle2">
                    {statusesCount.all[status]}
                  </Typography>
                </Box>
              </Tooltip>
            ))}
          </Box>
          {totalFuckup >= patience && (
            <WarningIcon
              fontSize="small"
              style={{ color: amber[500], marginLeft: 10 }}
            />
          )}
        </>
      </ButtonBase>

      {open && (
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth="md"
        >
          <DialogContent>
            <Box className={classes.root}>
              <Box>
                <Typography variant="h3" style={{ whiteSpace: "nowrap" }}>
                  {patience - totalFuckup}
                </Typography>
              </Box>
              <Box
                style={{
                  backgroundColor: green[50],
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {Object.keys(statusShare).map((status) => (
                  <Box
                    key={status}
                    width={`${statusShare[status]}%`}
                    style={{
                      backgroundColor: getColor(status),
                      borderBottom: "1px solid rgba(255, 255, 255, .3)",
                      color: "white",
                      display: "flex",
                      // alignItems: "center",
                      // justifyContent: "center",
                      gap: "1rem",
                      whiteSpace: "nowrap",
                      padding: "10px 20px",
                      width: "100%",
                    }}
                  >
                    <Typography
                      style={{
                        minWidth: "50px",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      - {severity(status) * statusesCount.all[status]}
                    </Typography>
                    <Typography variant="subtitle2">
                      {`${statusesCount.all[status]} x ${status}`}
                    </Typography>
                  </Box>
                ))}
              </Box>

              <div className={classes.otrosEstados}>
                <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                  Otros
                </Typography>

                {keys(statusesCount.good).map((status) => (
                  <div key={status}>
                    <Typography variant="subtitle1">
                      {`${statusesCount.good[status]} ${status}`}
                    </Typography>
                  </div>
                ))}

                {keys(statusesCount.info).map((status) => (
                  <Typography variant="subtitle1">
                    {`${statusesCount.info[status]} ${status}`}
                  </Typography>
                ))}
              </div>
            </Box>
          </DialogContent>
          <Divider />
          <DialogActions className={classes.actions}>
            <div className={classes.ultimatum}>
              <Button
                onClick={ultimatum}
                variant="contained"
                color="primary"
                disabled={ultimatumSent}
              >
                Ultimatum
              </Button>

              {ultimatumSent && (
                <Typography variant="body">
                  {user.restrictions?.ultimatum?.when}
                </Typography>
              )}
            </div>
            <Button autoFocus onClick={handleClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default AlbumStatusesBar;
