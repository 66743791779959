// @ts-check
import { isArray } from "lodash";

/** @typedef {import("./album").Artist} Artist */
/** @typedef {import("./album").ArtistRole} ArtistRole */
/** @typedef {import("../reducers/wizardNewAlbum/wizardStore").NewAlbumArtist} NewAlbumArtist */

const _getFeatFromArray = (artists) => {
  return artists.filter(
    (artist) => artist.role === "Feat. (Artista Colaborador)"
  );
};

/**
 * Search and return the feats artits from a Set of artists
 *
 * @param {{[key:string]: Artist} | Array<NewAlbumArtist>} artists
 * @return {Array<Artist>}
 */
export const getArtistsFeats = (artists) => {
  if (!artists) {
    return [];
  }

  return isArray(artists)
    ? _getFeatFromArray(artists)
    : _getFeatFromArray(Object.values(artists));
};

export default getArtistsFeats;
