// @ts-check
import { getAlbumTitle, getTrackTitle } from "../../../business-objects";
import { db } from "../../../firebase";
import { openAlbumField } from "../../albumActions";
import { getAlbum } from "./getAlbum";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album").ALBUM_STATUSES} ALBUM_STATUSES */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldActionType} FieldActionType */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */

const sideEffectUpdates = (album) => {
  const updates = {};

  updates.titleLabel = getAlbumTitle(album);

  if (album.tracks) {
    updates.tracks = {};
    Object.keys(album.tracks).forEach((keyTrack) => {
      const track = album.tracks[keyTrack];
      updates.tracks[keyTrack] = {
        ...track,
        titleLabel: getTrackTitle(track, album),
      };
    });
  }

  return updates;
};

/**
 * Publish the album with a UPC. Only for a draft album
 * @param {string} upc
 * @return {Function}
 */
export const publishAlbum = (upc) => {
  /**
   * @param {function} dispatch
   * @param {StateGetter} getState
   */
  return async function thunk(dispatch, getState) {
    /** @type {Album} */
    const album = await dispatch(getAlbum(upc));

    if (album?.status !== "draft") {
      return;
    }

    const othersUpdates = sideEffectUpdates(album);

    /** @type {ALBUM_STATUSES} */
    const newStatus = "submitted";
    await db.updateAlbumFields(upc, { ...othersUpdates, status: newStatus });
    dispatch(openAlbumField(null));
    return newStatus;
  };
};
