// @ts-check
import produce from "immer";

/** @typedef {import('../../store').AlbumState} AlbumState */

const TYPE = "CLOSE_ALBUM_TRACK";

/**
 * @return {Object}
 */
export const trackCloseAction = () => {
  return {
    type: TYPE,
  };
};

/**
 * @param {AlbumState} state
 * @param {object} action
 * @return {object}
 */
export const trackCloseReducer = (state, action) => {
  return produce(state, (draft) => {
    draft.openTrack = null;
  });
};

export default {
  action: trackCloseAction,
  reducer: trackCloseReducer,
  TYPE,
};
