//@ts-check

/**
 *
 * @param {import("./album").Track} track
 * @return {string}
 */
export const getTrackOriginalFilename = (track) => {
  if (!track) {
    return "";
  }

  if (track.originalFilename) {
    return track.originalFilename;
  }

  const firstSlashPosition = track.filename?.indexOf("-");

  if (firstSlashPosition > -1) {
    return track.filename.substring(firstSlashPosition + 1);
  }

  return "";
};

export default getTrackOriginalFilename;
