//@ts-check
import React, { useMemo } from "react";
import { parse, format } from "date-fns";
import esLocale from "date-fns/locale/es";

/**
 * @param {{value: string, pattern?: string}} props
 * @returns
 */
const DateRender = ({ value, pattern = "d MMMM yyyy" }) => {
  const valueFormatted = useMemo(() => {
    if (!value) {
      return "";
    }

    try {
      const defaultDate = new Date();

      let date = parse(value, "yyyy-MM-dd", defaultDate);
      if (isNaN(date?.getTime())) {
        date = parse(value, "yyyy/MM/dd", defaultDate);
      }
      if (!date || isNaN(date?.getTime())) {
        return "";
      }

      return format(date, pattern, { locale: esLocale });
    } catch (e) {
      console.error(e);
      return "?";
    }
  }, [value, pattern]);

  return <span>{valueFormatted}</span>;
};

export default DateRender;
