import React from "react";
import { Field as FieldFormik } from "formik";

export const globalProps = {
  variant: "outlined",
};

export const Field = (props) => {
  return <FieldFormik {...globalProps} {...props} />;
};

export default Field;
