import * as React from "react";
import { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { functions, auth } from "../firebase";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PersonIcon from "@material-ui/icons/Person";
import PersonAdd from "@material-ui/icons/PersonAdd";
import { blue } from "@material-ui/core/colors";
import handleLoginError from "../utils/handleLoginError";
import { LinearProgress } from "@material-ui/core";

const getUserAccountsInfo = functions.httpsCallable("userAccountsInfo");
const getKuid = functions.httpsCallable("diuk");

function AccountSwitchDialog(props) {
  const { open, closeDialog, openAccountLink, setLinkedAccounts, linkedAccounts, user } = props;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getUserAccountsInfo()
      .then(({ data }) => {
        if (data?.length) {
          const linkedAccounts = data.filter((acc) => acc.uid !== user.uid);
          setLinkedAccounts(linkedAccounts);
        }
        setLoading(false);
      })
      .catch((e) => console.log("shherror"))
      .finally(() => setLoading(false));
  }, [open, user, setLinkedAccounts]);

  const handleClose = () => {
    closeDialog();
  };

  const handleListItemClick = async (email) => {
    try {
      setLoading(true);
      const { uid } = linkedAccounts.find((a) => a.email === email);
      const result = await getKuid(uid);
      const savedPassword = result.data;

      let password;

      if (savedPassword) {
        password = savedPassword;
      } else {
        password = window.prompt("Introduce la contraseña para " + email);
      }

      await auth.doAutoSignIn(email, password);
      window.location.reload();
      closeDialog();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleLoginError(error);
    }
  };

  const handleAccountLink = () => {
    closeDialog();
    openAccountLink();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Cambiar a...</DialogTitle>

      {loading && <LinearProgress variant="indeterminate" />}

      <List sx={{ pt: 0 }}>
        {linkedAccounts.map(({ email }) => (
          <ListItem key={email} button onClick={() => handleListItemClick(email)}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={email} />
          </ListItem>
        ))}

        <ListItem button onClick={handleAccountLink}>
          <ListItemAvatar>
            <Avatar>
              <PersonAdd />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Añadir cuenta" />
        </ListItem>
      </List>
    </Dialog>
  );
}

export default AccountSwitchDialog;
