import React, { useEffect } from "react";
import { functions } from "../../../firebase";
import { Paper, makeStyles, Box, Typography, Divider } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import SoundcloudIcon from "@material-ui/icons/CloudQueue";
import { Link } from "react-router-dom";
import sortBy from "lodash/sortBy";
import getFlagEmoji from "../../../utils/getFlagEmoji";

const fetchAccountInfo = functions.httpsCallable("getAccountGroups");

const useStyles = makeStyles((theme) => ({
  root: {},
  detail: {
    flexDirection: "column",
    whiteSpace: "nowrap",
    display: "flex",
    // alignItems: "center",
    gap: theme.spacing(0),
    padding: theme.spacing(1),
  },
  pais: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  flag: {
    display: "flex",
    alignItems: "center",
    // justifyContent: "center",
    fontSize: "1.1rem",
  },
  redes: {
    display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
  },
  sn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "0.8em",
    color: theme.palette.grey[400],
  },
  selected: {
    // backgroundColor: theme.palette.grey[200],
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    paddingBottom: "0 !important",
  },
}));

const UploaderInfo = ({ email }) => {
  const [info, setInfo] = React.useState(null);

  useEffect(() => {
    const getInfo = async () => {
      const { data } = await fetchAccountInfo([email]);

      if (!data) return;

      const INITIAL = {
        accounts: [],
        artists: [],
        labels: [],
      };

      const shitsa = data[0].reduce((acc, curr) => {
        const {
          email,
          country,
          countryISOCode,
          youtube,
          facebook,
          instagram,
          twitter,
          soundcloud,
        } = curr;

        acc.accounts.push({
          email,
          country: countryISOCode,
          countryName: country,
          redes: [youtube, facebook, instagram, twitter, soundcloud],
        });

        acc.artists.push(...curr.catalog.artists);
        acc.labels.push(...curr.catalog.labels);

        return acc;
      }, INITIAL);

      setInfo(shitsa);
    };

    getInfo();
  }, [email]);

  const classes = useStyles();

  if (!info || !email) return null;

  const linkIt = (url) => {
    if (!url) return null;

    let fullUrl;

    // add https if missing
    if (!url.includes("http")) fullUrl = `https://${url}`;

    // icon
    let icon;
    if (url.includes("facebook")) icon = <FacebookIcon fontSize="0.5em" />;
    if (url.includes("instagram")) icon = <InstagramIcon />;
    if (url.includes("twitter")) icon = <TwitterIcon />;
    if (url.includes("youtube")) icon = <YouTubeIcon />;
    if (url.includes("soundcloud")) icon = <SoundcloudIcon />;

    return (
      <a className={classes.sn} key={url} target="_blank" href={fullUrl}>
        {icon}
      </a>
    );
  };

  const detailIt = (user) => {
    const { country, redes, countryName } = user;
    const userEmail = user.email;

    return (
      <Box className={userEmail === email ? classes.selected : {}}>
        <Box className={classes.detail}>
          <Link style={{ color: "blue" }} to={`/albums?q=${userEmail}`}>
            {userEmail}
          </Link>
          <div className={classes.pais}>
            <span className={classes.flag}>{getFlagEmoji(country)}</span>{" "}
            {countryName}
          </div>
          <div className={classes.redes}>{redes.map(linkIt)}</div>
        </Box>
      </Box>
    );
  };

  const getArtistsUnique = (artists) => {
    const unique = [...new Set(artists.map((a) => a.name))];
    const sorted = sortBy(unique);
    return sorted
      .map((name) => name.trim())
      .filter((name) => name)
      .join(", ");
  };

  const getLabelsUnique = (labels) => {
    const unique = [...new Set(labels)];
    const sorted = sortBy(unique);
    return sorted
      .map((label) => label.trim())
      .filter((label) => label)
      .join(", ");
  };

  return (
    <Paper className={classes.root}>
      <Box p={2}>
        <Typography variant="subtitle2" gutterBottom>
          Cuentas
        </Typography>

        {info.accounts.map(detailIt)}
      </Box>

      <Divider />

      <Box p={2}>
        <Typography variant="subtitle2" gutterBottom>
          Artistas
        </Typography>
        {getArtistsUnique(info.artists)}
      </Box>

      <Divider />

      <Box p={2}>
        <Typography variant="subtitle2" gutterBottom>
          Labels
        </Typography>
        {getLabelsUnique(info.labels)}
      </Box>
    </Paper>
  );
};

export default UploaderInfo;
