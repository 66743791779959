import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import svg from "../images/500.svg";

const useStyles = makeStyles({
  result: {
    position: "fixed",
    top: "50%",
    left: "50%",
    textAlign: "center",
    transform: " translate(-50%,-50%)",
    "& .result-img": {},
    "& .result-title": {
      paddingTop: "2rem",
      fontSize: "2rem",
    },
    "& .result-backButtonContainer": {
      marginTop: "2rem",
    },
  },
});

/**
 *
 * @param {{
 *  title: string,
 *  backLabel?: string
 *  onBack?: Function
 * }} props
 * @returns
 */
const Result500 = ({
  title = "Disculpe, algo salió mal",
  backLabel = "volver",
  onBack = null,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.result}>
      <img className="result-img" src={svg} alt={title} />
      <div className="result-title">{title}</div>

      {onBack && (
        <div className="result-backButtonContainer">
          <Button color="primary" variant="contained" onClick={onBack}>
            {backLabel}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Result500;
