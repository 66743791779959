import { Paper, Typography, Button, ButtonGroup } from "@material-ui/core";
import { ChevronRight, ChevronLeft } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import localizerFactory from "../../utils/localizerFactory";
import { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(8),
    borderRadius: theme.spacing(10),
    [theme.breakpoints.down("md")]: {
      borderRadius: theme.spacing(0),
    },
  },
  titleAndToolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },
  pagination: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  [theme.breakpoints.down("sm")]: {
    pagination: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: theme.spacing(2),
    },
  },
}));

const localMoney = localizerFactory();

const getTotal = (values) => {
  return values.reduce((acc, item) => {
    return isNaN(item) ? acc : acc + item;
  }, 0);
};

const PaymentChart = ({ data }) => {
  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);
  const [tooltips, setTooltips] = useState(null);
  const [totalRoyalties, setTotalRoyalties] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [pageSize, setPageSize] = useState(6);
  const [page, setPage] = useState(1);
  const [totPages, setTotPages] = useState(0);

  const classes = useStyles();

  useEffect(() => {
    if (data) {
      const paginate = (arr) => {
        const start = page * pageSize * -1;
        const end = data.values.length - pageSize * (page - 1);
        return arr.slice(start, end);
      };

      const numPages = Math.ceil(data.values.length / pageSize);
      setTotPages(numPages);

      // set labels and values for chart
      setLabels(paginate(data.labels));
      setValues(paginate(data.values));
      setTooltips(paginate(data.tooltips));

      // set total (sum of displayed royalties)
      const total = getTotal(data.values);
      setTotalRoyalties(total);

      // set loaded
      setLoaded(true);
    }
  }, [data, page, pageSize]);

  if (!loaded) {
    return <h1>No data...</h1>;
  }

  const options = {
    responsive: true,
    elements: {
      point: {
        radius: 5,
      },
      line: {
        fill: true,
      },
      fill: "start",
    },
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          title: function ([{ dataIndex }]) {
            const { total } = tooltips[dataIndex];
            return `${total}`;
          },
          afterTitle: function ([{ dataIndex }]) {
            return "Ingresos correspondientes a:";
          },
          label: function ({ dataIndex }) {
            const info = tooltips[dataIndex];

            return `- Reproducciones de ${info.period}`;
          },
          afterLabel: function ({ dataIndex }) {
            const info = tooltips[dataIndex];

            return `- Publicados en ${info.periodAccounting}`;
          },
        },
      },
    },
  };

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Ingresos",
        data: values,
        borderColor: "green",
        backgroundColor: "green",
        fill: "start",
      },
    ],
  };

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const nextPage = () => {
    if (page < totPages) {
      setPage(page + 1);
    }
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.titleAndToolbar}>
        <div className={classes.minitotal}>
          <Typography variant="h4">
            {localMoney.format(totalRoyalties)}
          </Typography>
          <Typography variant="h6">Ingresos totales</Typography>
        </div>
        <div className={classes.pagination}>
          <ButtonGroup
            color="primary"
            aria-label="outlined primary button group"
            size="small"
          >
            <Button
              disabled={page >= totPages}
              variant="contained"
              color="primary"
              onClick={nextPage}
            >
              <ChevronLeft />
            </Button>
            <Button
              disabled={page <= 1}
              variant="contained"
              color="primary"
              onClick={prevPage}
            >
              <ChevronRight />
            </Button>
          </ButtonGroup>

          <ButtonGroup
            color="primary"
            aria-label="outlined primary button group"
            size="small"
          >
            <Button
              variant="contained"
              disabled={pageSize === 6}
              color="primary"
              onClick={() => setPageSize(6)}
            >
              6 meses
            </Button>
            <Button
              variant="contained"
              disabled={pageSize === 12}
              color="primary"
              onClick={() => setPageSize(12)}
            >
              12 meses
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <Line options={options} data={chartData} />
    </Paper>
  );
};

export default PaymentChart;
