import { functions } from "../../firebase";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ScrollToTop from "../ScrollToTop";
import PaymentChart from "./PaymentChart2";
import FraudSection from "./FraudSection";
// import GeoChart from "./GeoChart";
import LatestPeriod from "./LatestPeriod";
import Payments from "./PaymentTable";
import Loading from "./Loading";
import { Divider, Typography } from "@material-ui/core";
import isAdmin from "../../utils/isAdmin";
import isSafeAdmin from "../../utils/isSafeAdmin";
import keys from "lodash/keys";
import BigChartRoyaltiesbyPlatform from "../BigChartRoyaltiesByPlatform";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    maxWidth: "1100px",
    margin: "auto",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(15),
    paddingLeft: 0,
    paddingRight: 0,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(8),
      gap: theme.spacing(4),
    },
  },
  noData: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100vw",
    height: "100vh",
    paddingTop: "150px",
  },
}));

const getReportingDashboard = functions.httpsCallable("getReportingDashboard");

const Royalties = ({ query }) => {
  const [loading, setLoading] = useState(true);
  const [bigChartData, setBigChartData] = useState([]);
  const [reporting, setReporting] = useState({});
  const [multas, setMultas] = useState([]);
  const [bigChartByPlatform, setBigChartByPlatform] = useState([]);
  const [streamsByTracksAndPeriod, setStreamsByTracksAndPeriod] = useState([]);
  const [fraudData, setFraudData] = useState([]);
  const [fraudChartData, setFraudChartData] = useState([]);
  const [geoChartData, setGeoChartData] = useState([]);
  const [fraudDonutData, setFraudDonutData] = useState([]);
  const [latest, setLatest] = useState({});
  const [merged, setMerged] = useState([]);
  const [noData, setNoData] = useState(false);
  const [reloadData, setReloadData] = useState(0);

  useEffect(() => {
    (async () => {
      setLoading(true);

      const searchText = isAdmin() || isSafeAdmin() ? query : null;

      let { data } = await getReportingDashboard(searchText);

      const {
        bigChart,
        bigChartByPlatform,
        fraudChart,
        geoChart,
        latest,
        merged,
        multas,
        fraudDonut,
        fraud,
        streamsByTracksAndPeriod,
        error,
      } = data;

      if (error || !data || !merged || keys(merged).length === 0) {
        setNoData(true);
        setLoading(false);
      } else {
        setBigChartData(bigChart);
        setReporting(reporting);
        setBigChartByPlatform(bigChartByPlatform);
        setStreamsByTracksAndPeriod(streamsByTracksAndPeriod);
        setFraudData(fraud);
        setFraudChartData(fraudChart);
        setFraudDonutData(fraudDonut);
        setGeoChartData(geoChart);
        setLatest(latest);
        setMerged(merged);
        setMultas(multas);
        setNoData(false);
        setLoading(false);
      }
    })();
  }, [reloadData]);

  useEffect(() => {
    if (query) {
      setReloadData(reloadData + 1);
    }
  }, [query]);

  const classes = useStyles();

  if (loading) {
    return <Loading />;
  }

  if (noData) {
    return (
      <div className={classes.noData}>
        <Typography variant="button">Aún no hay datos para mostrar</Typography>
      </div>
    );
  }

  return (
    <div
      className={classes.root}
      style={!query ? { marginTop: "120px" } : { marginTop: "40px" }}
    >
      <ScrollToTop />

      <LatestPeriod data={latest} />

      <PaymentChart data={bigChartData} />

      <BigChartRoyaltiesbyPlatform data={bigChartByPlatform} />

      <FraudSection data={fraudData} />

      {/* <GeoChart data={geoChartData} /> */}

      <Divider />

      <Payments
        data={merged}
        multas={multas}
        reload={() => setReloadData(reloadData + 1)}
      />
    </div>
  );
};

export default Royalties;
