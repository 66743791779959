import React from "react";
import Lottie from "lottie-react-web";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import { Redirect } from "react-router-dom";
import * as routes from "../constants/routes";

class UploadingAnimation extends React.Component {
  state = {
    animationData: false,
    goAlready: false,
  };

  chooseAnimation = (genre) => {
    switch (genre) {
      case "Jazz":
        return "trumpet";

      case "Classical":
        return "violin";

      case "Electronic":
      case "Latin":
      case "Hip-hop/Rap":
        return "speakers";

      // case "Children":
      //   return "celebration";

      case "Country":
      case "Folk":
        return "lady-guitar";

      case "Rock":
      case "Metal":
      case "Punk":
      case "Pop":
      case "R&B":
      case "Reggae":
        return "electric-guitar";

      default:
        return "speakers";
    }
  };

  componentDidMount() {
    const { genre } = this.props;
    const animation = this.chooseAnimation(genre);

    import(`./animations/${animation}.json`).then((animationData) => {
      this.setState({ animationData });
    });
  }

  gotoPreview = () => {
    this.setState({ goAlready: true });
  };

  render() {
    const { gotoPreview } = this.props;
    const { animationData, goAlready } = this.state;

    if (!animationData) {
      return null;
    }

    return (
      <>
        {!gotoPreview ? (
          <Typography
            variant="h4"
            align="center"
            style={{
              width: "300px",
              height: "80px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              align: "centers",
            }}
          >
            <LinearProgress />
          </Typography>
        ) : (
          <Redirect
            to={routes.ALBUM_VIEW.replace(":upc", gotoPreview, {
              fromWizard: true,
            })}
          />
        )}
      </>
    );
  }
}

export default UploadingAnimation;
