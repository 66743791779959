import isProd from "../utils/isProd";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/functions";

import { dev, prod } from "../trc.config";

// PROD or NOT
const trcConfig = isProd() ? prod : dev;
const { USE_EMULATOR, FIREBASE_CONFIG } = trcConfig;

// init firebase
if (!firebase.apps.length) {
  firebase.initializeApp(FIREBASE_CONFIG);
}

const db = firebase.database();
const auth = firebase.auth();
const storage = firebase.storage();
const fbroot = firebase; // this is very confusing shit, fix all this idiocy
const functions = firebase.functions();

// USE LOCAL CLOUD-FUNCTIONS FOR TESTING!!!
if (!isProd() && USE_EMULATOR) {
  functions.useEmulator("localhost", "5001");
  console.log("USING LOCAL CLOUD FUNCTIONS");
}

export { db, auth, storage, fbroot, functions };
