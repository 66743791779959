// @ts-check
import produce from "immer";
import arrayMove from "array-move";

/** @typedef {import('../wizardStore').WizardNewAlbumState} WizardNewAlbumState */
/** @typedef {import('../wizardStore').NewAlbumTrack} NewAlbumTrack */

/** @typedef {{
 *  type: "WIZARD_TRACK_ORDER"
 *  oldIndex: number
 *  newIndex: number
 * }} trackOrderAction */

const TYPE = "WIZARD_TRACK_ORDER";

/**
 * @param {number} oldIndex
 * @param {number} newIndex
 * @return {trackOrderAction}
 */
export const trackOrderAction = (oldIndex, newIndex) => {
  return {
    type: TYPE,
    oldIndex,
    newIndex,
  };
};

/**
 * @param {WizardNewAlbumState} state
 * @param {trackOrderAction} action
 * @return {object}
 */
export const trackOrderReducer = (state, { oldIndex, newIndex }) => {
  return produce(state, (draft) => {
    draft.tracks = arrayMove(draft.tracks, oldIndex, newIndex);
    draft.manualSortedByUser = true;
  });
};

export default {
  action: trackOrderAction,
  reducer: trackOrderReducer,
  TYPE,
};
