import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  heading: {
    color: 'black',
  },
  subheading: {
    paddingBottom: theme.spacing(1),
  }
})

const StepHeadings = ({ classes, title, description, noGutter = false }) =>
  <div>
    <Typography variant="h4" gutterBottom className={classes.heading}>
      {title}
    </Typography>
    <Typography variant="subtitle1" gutterBottom={!noGutter} color="primary" className={classes.subheading}>
      {description}
    </Typography>
  </div>

export default withStyles(styles)(StepHeadings)
