// @ts-check
import { db } from "../../../firebase";
import newAlbumService from "../../../services/newAlbumService";
import { setInitNewAlbumAction } from "../actions/setInitNewAlbum";

/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */

/**
 * Busca y retorna la config del sistema
 * @return {Function}
 */
export function initNewAlbum() {
  /**
   * @param {function} dispatch
   * @param {StateGetter} getState
   */
  return async function thunk(dispatch, getState) {
    /** @type {StateStore} */
    const state = getState();

    let uploadingKey = state.wizardNewAlbumState.uploadingKey;
    if (!uploadingKey) {
      uploadingKey = (await newAlbumService.newAlbumKey()) || "empty";
      const account = db.getAccountName();
      dispatch(setInitNewAlbumAction(uploadingKey, account));
    }
  };
}

export default initNewAlbum;
