import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";

import useConfig from "../../../../../hooks/useConfig";
import useAlbumOpened from "../../../../../reducers/album/hooks/useAlbumOpened";
import EditableField, {
  EditableInput,
} from "../../../components/EditableField";

import AlbumCoverField from "./AlbumCoverField";
import AlbumHeaderDetail from "./AlbumHeaderDetail";
import AutoFormatTitleDialog from "./AutoFormatTitleDialog";

const useStyles = makeStyles((theme) => ({
  titleFieldContainer: {
    position: "relative",
  },
  toogleAutoformatTitle: {
    position: "absolute",
    right: "20px",
    top: 0,
    fontSize: "0.8rem",
    color: "#CCAA66",
    cursor: "pointer",
  },
}));

const AlbumFieldsForm = () => {
  const album = useAlbumOpened();
  const config = useConfig();
  const classes = useStyles();
  const [openedAutoFormat, setOpenedAutoFormat] = useState(false);

  return (
    <Grid container spacing={5}>
      <Grid container item xs={12} spacing={2} direction="row">
        <Grid item xs={12} md={2}>
          <AlbumCoverField />
        </Grid>
        <Grid container item xs={12} md={10} direction="column">
          {album && <AlbumHeaderDetail album={album} />}
          <div>
            <EditableField
              name="live"
              label="En Vivo"
              type="switch"
              level="album"
            />
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} className={classes.titleFieldContainer}>
        {openedAutoFormat && (
          <AutoFormatTitleDialog onClose={() => setOpenedAutoFormat(false)} />
        )}
        <Link
          className={classes.toogleAutoformatTitle}
          onClick={() => setOpenedAutoFormat(true)}
        >
          {album?.disabledAutoTitle ? "Activar" : "Desactivar"} auto-formato
        </Link>
        <EditableInput
          label="Título"
          name="title"
          type="input"
          level="album"
          endAdornment={
            <>{album?.disabledAutoTitle ? <WarningIcon /> : null}</>
          }
          required
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <EditableField
          label="Genero"
          name="genreSubgenre"
          type="genre"
          level="album"
          required
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <EditableField
          label="Pais"
          name="country"
          type="autocomplete"
          level="album"
          required
          options={config?.countries?.map((c) => c.label)}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <EditableField
          label="Idioma"
          name="lyrics_lang"
          type="autocomplete"
          level="album"
          required
          options={config?.languages?.map((c) => c.label)}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <EditableField
          label="Sello"
          name="subaccount"
          type="input"
          level="album"
          required
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <EditableField
          label="Fecha de lanzamiento original"
          name="release"
          type="date"
          level="album"
          required
        />
      </Grid>

      {!album?.live && (
        <Grid item xs={12} md={4}>
          <EditableField
            label="Versión"
            name="version"
            type="input"
            level="album"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default AlbumFieldsForm;
