module.exports = () => {
  switch (window.location.hostname) {
    case "subidalibre.musik.digital":
    case "therecordingconsort.musik.digital":
    case "videophonic.musik.digital":
    case "itinerantrecords.musik.digital":
    case "itinerant.musik.digital":
    case "nitzuga.musik.digital":
    case "musik.digital":
    case "www.musik.digital":
      return true;

    case "musik-digital.firebaseapp.com":
    case "subidalibre.localhost":
    case "therecordingconsort.localhost":
    case "videophonic.localhost":
    case "itinerantrecords.localhost":
    case "itinerant.localhost":
    case "nitzuga.localhost":
    default:
      return false;
  }
};
