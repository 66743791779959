//@ts-check
import { isArray } from "lodash";

/**
 * @typedef {import("./album").Album} Album
 * @typedef {import("./album").Track} Track
 * @typedef {import("./album").Artist} Artist
 * @typedef {import("./album").ArtistRole} ArtistRole
 *
 * @typedef {{
 *   role: string,
 *   artists: Array<Artist>
 * }} GroupRoleArtists
 *
 **/

/**
 * @param {Array<Artist> | {[key: string]: Artist}} artists
 * @return {Array<Artist>}
 **/
const _toArray = (artists) => {
  if (!artists) {
    return [];
  }

  // @ts-ignore
  return isArray(artists) ? artists : Object.values(artists);
};

/**
 * @param {Artist} artist
 */
const _add = (map, artist) => {
  if (!(artist.role in map)) {
    map[artist.role] = [];
  }
  map[artist.role].push(artist);
};

/**
 * Merge and group by role the artist from an album and for a track
 *
 * @param {Array<Artist> | {[key: string]: Artist}} artists1
 * @param {Array<Artist> | {[key: string]: Artist}} artists2
 * @return {Array<GroupRoleArtists>}
 */
export const mergeAndGroupArtists = (artists1, artists2) => {
  /**
   * @type {{[key: string]: Array<Artist>}}
   */
  const map = {};

  _toArray(artists1).forEach((artist) => _add(map, artist));
  _toArray(artists2).forEach((artist) => _add(map, artist));

  const out = Object.keys(map).map((key) => {
    return {
      role: key,
      artists: map[key],
    };
  });

  return out;
};

export default mergeAndGroupArtists;
