// @ts-check
import { isArray } from "lodash";

/** @typedef {import("./album").Artist} Artist */
/** @typedef {import("./album").ArtistRole} ArtistRole */
/** @typedef {import("../reducers/wizardNewAlbum/wizardStore").NewAlbumArtist} NewAlbumArtist */

const _getPrimaryFromArray = (artists) => {
  return artists.filter((artist) => artist.role === "Artista principal");
};

/**
 * Search and return the primaries artits from a Set of artists
 *
 * @param {{[key:string]: Artist} | Array<NewAlbumArtist>} artists
 * @return {Array<Artist>}
 */
export const getArtistsPrimaries = (artists) => {
  if (!artists) {
    return [];
  }

  return isArray(artists)
    ? _getPrimaryFromArray(artists)
    : _getPrimaryFromArray(Object.values(artists));
};

export default getArtistsPrimaries;
