// @ts-check
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { useFormikContext } from "formik";
import wizardActions from "../../../../../reducers/wizardNewAlbum/wizardActions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
  },
}));

const ArtistsButtons = () => {
  const dispatch = useDispatch();
  const context = useFormikContext();
  const roles = useSelector(
    /** @param {import('../../../../../reducers/store').StateStore} state */
    (state) => state.wizardNewAlbumState.artistRolesAvailables
  );

  const classes = useStyles();

  const onAdd = (role) => {
    dispatch(wizardActions.createNewArtist(role, context));
  };

  return (
    <Box className={classes.root}>
      {roles?.map((role) => (
        <Button
          key={role.value}
          startIcon={<AddIcon />}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => onAdd(role)}
        >
          {role.label}
        </Button>
      ))}
    </Box>
  );
};

export default ArtistsButtons;
