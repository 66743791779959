import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@material-ui/core/Icon";
import Hidden from "@material-ui/core/Hidden";
import keys from "lodash/keys";
import SpacesSearch from "./SpacesSearch";
import Typography from "@material-ui/core/Typography";
import isAdmin from "../../utils/isAdmin";
import isSafeAdmin from "../../utils/isSafeAdmin";

const styles = (theme) => ({
  infoButton: {
    display: "flex",
    justifyContent: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  info: {
    marginLeft: theme.spacing(1),
  },
});

class SpacesMenu extends React.Component {
  state = {
    anchorEl: null,
    open: false,
  };

  handleClick = (key) => {
    const { setSpace } = this.props;

    this.setState({ open: false }, () => {
      setSpace(key);
    });
  };

  openMenu = (event) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, spaces, active, appendSpace } = this.props;
    const space = spaces[active];
    const { anchorEl, open } = this.state;

    // non admins
    if (!isAdmin() && !isSafeAdmin()) {
      return (
        <Hidden xsDown>
          <Typography variant="button" className={classes.infoButton}>
            <Icon>account_circle</Icon> &nbsp; {space.label}
          </Typography>
        </Hidden>
      );
    }

    // administrators
    return (
      <Fragment>
        <Button
          variant="contained"
          disableRipple={true}
          aria-label="More"
          aria-owns={open ? "long-menu" : undefined}
          aria-haspopup="true"
          onClick={this.openMenu}
          className={classes.infoButton}
        >
          <Icon>account_circle</Icon>
          <Hidden xsDown>
            <div className={classes.info}>{space.label}</div>
          </Hidden>
        </Button>

        <Menu id="spaces-menu" variant="menu" anchorEl={anchorEl} open={open} onClose={this.handleClose}>
          {keys(spaces).map((spKey) => (
            <MenuItem key={spKey} selected={spKey === active} onClick={() => this.handleClick(spKey)}>
              {spaces[spKey].label}
            </MenuItem>
          ))}
          <MenuItem key="SpacesSearch">
            <SpacesSearch spaces={spaces} active={active} appendSpace={appendSpace} />
          </MenuItem>
        </Menu>
      </Fragment>
    );
  }
}

export default withStyles(styles)(SpacesMenu);
