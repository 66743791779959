import React, { useEffect, useState } from "react";
import { makeStyles, Box, LinearProgress } from "@material-ui/core";
import UserSearch from "../components/userSearch";
import GroupsList from "./components/groupsList";
import { functions, fbroot } from "../../../firebase";
// import BatchAction from "./components/BatchAction";

const db = fbroot.database();

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(5),
    maxWidth: "90vw",
  },
  toolbar: {
    // display: "flex",
    // justifyContent: "space-between",
    // alignItems: "center",
    // gap: theme.spacing(5),
  },
}));

const fetchGroups = functions.httpsCallable("getAccountGroups");

const findIdentifieres = (query) => {
  if (query.length < 4) return [];

  if (query.includes(",") || query.includes(" ")) {
    return query.split(/[\s,]+/).filter((identfier) => identfier.length > 3);
  }

  return [query];
};

const ManageUsersPage = () => {
  const [query, setQuery] = useState("");
  const [identifiers, setIdentifiers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [CIDstatuses, setCIDstatuses] = useState({});
  const [accountStatuses, setAccountStatuses] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const processed = findIdentifieres(query);
    setIdentifiers(processed);
  }, [query]);

  // listen to CID state changes
  useEffect(() => {
    const updateSingleCIDstate = (snap, uid) => {
      const restrictions = snap.val();

      setCIDstatuses((prev) => ({
        ...prev,
        [uid]: restrictions?.contentId || false,
      }));
    };

    (() => {
      if (groups?.length > 0) {
        groups.forEach((group) =>
          group.forEach((user) => {
            db.ref(`users/${user.uid}/restrictions`).on("value", (snap) =>
              updateSingleCIDstate(snap, user.uid)
            );
          })
        );
      }
    })();

    return () => {
      db.ref(`users`).off();
    };
  }, [groups]);

  // populate accountStatuses state
  useEffect(() => {
    if (groups) {
      groups.forEach((group) =>
        group.forEach((user) => {
          setAccountStatuses((prev) => ({
            ...prev,
            [user.uid]: user.authUser.disabled ? "disabled" : "enabled",
          }));
        })
      );
    }
  }, [groups]);

  // update account enabled/disabled state
  const updateAccountStatus = async (uid, newStatus) => {
    console.log("newStatus", newStatus);

    setAccountStatuses((prev) => ({
      ...prev,
      [uid]: newStatus,
    }));
  };

  const classes = useStyles();

  const handleSearch = async () => {
    if (!identifiers || identifiers?.length === 0) return;

    setLoading(true);
    const { data } = await fetchGroups(identifiers);
    setGroups(data);
    setLoading(false);
  };

  const handleChange = ({ target }) => {
    setQuery(target.value);
  };

  const clearSearch = () => {
    setQuery("");
    setGroups([]);
    setIdentifiers([]);
    setCIDstatuses({});
    setAccountStatuses({});
    setLoading(false);
  };

  return (
    <Box className={classes.root}>
      <div className={classes.toolbar}>
        <UserSearch
          handleChange={handleChange}
          handleSearch={handleSearch}
          clearSearch={clearSearch}
        />

        {/* <BatchAction /> */}
      </div>

      <Box mt={5}>
        {loading ? (
          <LinearProgress variant="indeterminate" />
        ) : (
          <GroupsList
            groups={groups}
            CIDstatuses={CIDstatuses}
            accountStatuses={accountStatuses}
            updateAccountStatus={updateAccountStatus}
            reload={handleSearch}
          />
        )}
      </Box>
    </Box>
  );
};

export default ManageUsersPage;
