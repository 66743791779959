import { functions } from "../firebase";

export const search = async (q) => {
  const _search = functions.httpsCallable("searchArtists");

  return _search(q).then((r) => r.data);
};

export default {
  search,
};
