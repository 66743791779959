import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect } from "react";
import { keys } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  chartBox: {
    marginBottom: theme.spacing(5),
  },
}));

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const getColor = (platform) => {
  switch (platform) {
    case "Spotify":
      return "rgba(29, 185, 84, 1)";
    case "Apple Music":
      return "rgba(255, 0, 0, 0.5)";
    case "Amazon":
      return "#febd69";
    case "Deezer":
      return "rgb(162, 56, 255)";
    case "Facebook/Instagram":
      return "#4267B2";
    case "YouTube":
      return "rgba(255, 0, 0, 1)";
    case "Tidal":
      return "rgba(0, 0, 0, 1)";
    case "Google Play":
      return "#4285F4";
    case "SoundCloud":
      return "#ff6300";
    case "TikTok":
      return "#28e9e2";
    case "Otros":
      return "rgba(0, 0, 0, 0.3)";

    default:
      return "rgba(0, 0, 0, 1)";
  }
};

const FraudChart = ({ data }) => {
  const [chartData, setChartData] = useState(null);
  const [tooltips, setTooltips] = useState(null);

  useEffect(() => {
    if (data) {
      const { fraudulentByPeriodWithLabels, fraudulentByPlatformAndPeriod } =
        data;

      const _byPlatform = fraudulentByPlatformAndPeriod.reduce((acc, row) => {
        if (!acc[row.plataforma]) {
          acc[row.plataforma] = {};
        }

        acc[row.plataforma][row.period] = row.cantidad;
        return acc;
      }, {});

      // console.log(_byPlatform);

      const datasets = keys(_byPlatform).map((platform) => {
        return {
          label: platform,
          data: keys(_byPlatform[platform]).map(
            (period) => _byPlatform[platform][period]
          ),
          backgroundColor: getColor(platform),
        };
      });

      const chartData = {
        labels: fraudulentByPeriodWithLabels.map((row) => row.periodHuman),
        type: "bar",
        datasets: [...datasets],
      };

      setChartData(chartData);

      // tooltips
      const _tooltips = fraudulentByPlatformAndPeriod.map(
        (row) => row.totalStreams
      );
      setTooltips(_tooltips);
    }
  }, [data]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          title: function ([{ dataIndex }]) {
            return "Fraudulentas:";
          },
          // afterTitle: function () {
          //   return "Fraudulentas:";
          // },
          // label: function ({ dataIndex }) {
          //   const item = tooltips[dataIndex];
          //   return `Escuchas legítimas: ${item}`;
          // },
          // footer: function ({ dataIndex }) {
          //   const item = Math.round(tooltips[dataIndex]);
          //   return `Legítimas del período: ${item}`;
          // },
          footer: function ([{ dataIndex }]) {
            const item = Math.round(tooltips[dataIndex]);
            return `Legítimas del período: ${item}`;
          },
        },
      },
    },
  };

  const classes = useStyles();

  if (!chartData) return null;

  return (
    <div className={classes.root}>
      <Typography variant="h5" gutterBottom>
        Reproducciones fraudulentas detectadas por las plataformas
      </Typography>

      <Bar data={chartData} options={options} />
    </div>
  );
};

export default FraudChart;
