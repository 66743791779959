import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import StripeCheckout from "react-stripe-checkout";
import { toast } from "react-toastify";

import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ButtonGroup,
  Button,
  FormControl,
} from "@material-ui/core";
import { db } from "../../../../../firebase";

const styles = () => ({
  checkout: {
    display: "block",
    alignItems: "right",
  },
  bold: {
    textAlign: "right",
    fontSize: "xx-large",
  },
  formControl: {
    minWidth: 200,
  },
});

const BalanceModal = (props) => {
  const arrAmount = [5, 10, 20, 50];
  const [priceTopUp, setPrice] = useState(0);
  const [token, setToken] = useState({});
  const dispatch = useDispatch();
  const showBalanceModal = useSelector(
    (state) => state.albumState.showBalanceModal
  );
  const { classes, getBalance, accountBalance } = props;

  useEffect(() => {
    if (priceTopUp > 0) {
      db.paymentService(token, priceTopUp * 100);
      db.setUserBalance(
        priceTopUp,
        toast("Perfecto! Has añadido saldo a tu cartera", { type: "success" })
      ).then(getBalance);
    }
  }, [token, getBalance, priceTopUp]);

  const handleCloseModal = (price) => {
    setPrice(price);
    dispatch({ type: "CLOSE_BALANCE" });
  };

  const handleToken = (token) => {
    setToken(token);
  };

  return (
    <Dialog
      open={showBalanceModal}
      onClose={handleCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableScrollLock={true}
    >
      <DialogTitle id="alert-dialog-title">BALANCE</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Saldo disponible en su cuenta.
        </DialogContentText>
        <DialogContentText className={classes.bold}>
          {`$${accountBalance}`}
        </DialogContentText>
        <form className={classes.form} noValidate>
          <FormControl className={classes.formControl}>
            Seleccione el monto que desea recargar
            <ButtonGroup variant="outlined">
              {arrAmount.map((price) => {
                return (
                  <StripeCheckout
                    key={price}
                    name="Saldo"
                    description="Agrega saldo a tu billetera"
                    panelLabel="Pagar"
                    label="Recargar"
                    stripeKey="pk_test_51HFjOdED5bMus2zTpmrCI4z4lkFn5CSoOw7ynqVEhZBVDIIzldVRhcEmNAmDcVoL9X3XuTQdDm7fEs9twvBDLLeu00dfXghyX9"
                    token={handleToken}
                    amount={price * 100}
                    currency="USD"
                    billingAddress
                    shippingAddress
                    brand="Musik.Digital"
                  >
                    <Button
                      className={classes.checkout}
                      onClick={() => handleCloseModal(price)}
                      variant="contained"
                      style={{ backgroundColor: "green", margin: "2px" }}
                      color="primary"
                      data-space="artistas"
                    >
                      ${price}
                    </Button>
                  </StripeCheckout>
                );
              })}
            </ButtonGroup>
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(BalanceModal);
