// @ts-check
import { setPhotoAction } from "../actions/setPhoto";
import storageService from "../../../services/storageService.js";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */

/**
 * Recive un file para persistir y setear en el album
 *
 * @param {'cover'|'back'}  type
 * @return {Function}
 */
export function removePhoto(type) {
  /**
   * @param {function} dispatch
   * @param {StateGetter} getState
   */
  return async function thunk(dispatch, getState) {
    /** @type {StateStore} */
    const state = getState();

    const storageLocation =
      state.wizardNewAlbumState.album.photos?.[type]?.storageLocation;
    if (storageLocation) {
      try {
        await storageService.removeFile(storageLocation);
      } catch (e) {}
      dispatch(setPhotoAction(type, null));
    } else {
      console.log("removePhoto: no se encontro storageLocation", state);
    }
  };
}

export default removePhoto;
