import React from "react";
import { useHistory } from "react-router";

import Result404 from "../../../components-cross/Result404";
import * as routes from "../../../constants/routes";

export const Album404 = (prop) => {
  const history = useHistory();

  const handleOnBack = () => {
    history.replace(routes.ALBUMS);
  };

  return (
    <Result404
      title="Ops! el album no existe"
      backLabel="Volver a albums"
      onBack={handleOnBack}
    />
  );
};

export default Album404;
