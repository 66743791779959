// @ts-check
import cartSetItems from "./actions/cartSetItems";
import setShowDetailPanel from "./actions/setShowDetailPanel";
import setShowConfirmCheckout from "./actions/setShowConfirmCheckout";
import setAlbumUnedited from "./actions/setAlbumUnedited";
import setSaving from "./actions/setSaving";

/** @typedef {import('./cartStore').CartState} CartState */

/**
 * @type {CartState}
 */
const INITIAL_STATE = {
  cartItems: [],
  upc: "",
  totalCostCredit: 0,
  isShowDetailPanel: false,
  isShowConfirmCheckout: false,
  albumUnedited: null,
  saving: false,
};

function cartReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case cartSetItems.TYPE: {
      return cartSetItems.reducer(state, action);
    }
    case setShowDetailPanel.TYPE: {
      return setShowDetailPanel.reducer(state, action);
    }
    case setShowConfirmCheckout.TYPE: {
      return setShowConfirmCheckout.reducer(state, action);
    }
    case setAlbumUnedited.TYPE: {
      return setAlbumUnedited.reducer(state, action);
    }
    case setSaving.TYPE: {
      return setSaving.reducer(state, action);
    }
    default:
      return state;
  }
}

export default cartReducer;
