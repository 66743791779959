import { auth, db, fbroot, functions } from "./firebase";
import { keys, get } from "lodash";

export const getCurrentUser = () => {
  return get(auth, "currentUser", false);
};

// Sign Up
export const doCreateUserWithEmailAndPassword = async (email, password) => {
  const emailLower = email.toLowerCase();

  const userCredentials = await auth.createUserWithEmailAndPassword(
    emailLower,
    password
  );
  if (userCredentials) await saveKuid(password);
  return userCredentials;
};

// Sign In
const saveKuid = functions.httpsCallable("kuid");
const deleteKuid = functions.httpsCallable("deleteKuid");

export const doSignInWithEmailAndPassword = async (email, password) => {
  const userCred = await auth.signInWithEmailAndPassword(email, password);
  if (userCred) await saveKuid(password);
  return userCred;
};

export const doSignInInteractive = async (email) => {
  const password = window.prompt("Introduce la contraseña para " + email);
  if (!password) return false;
  return doSignInWithEmailAndPassword(email, password);
};

export const doAutoSignIn = async (email, password) => {
  try {
    const userCred = await auth.signInWithEmailAndPassword(email, password);
    if (userCred) await saveKuid(password); // verificar y quitar
    return userCred;
  } catch (error) {
    if (error.code === "auth/wrong-password") {
      await deleteKuid({ email });
      const newPassword = window.prompt("Introduce tu contraseña");
      doSignInWithEmailAndPassword(email, newPassword);
    }
    return error;
  }
};

export const doRecaptchaVerifier = () =>
  new fbroot.auth.RecaptchaVerifier("recaptcha-container", {
    size: "invisible",
  });

// Sign out
export const doSignOut = () => auth.signOut();

// Password Reset
export const doPasswordReset = (email) => auth.sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = (password) =>
  auth.currentUser.updatePassword(password);

// Password Change from my account
export const doPasswordUpdateLoggedIn = async (
  current_password,
  new_password
) => {
  try {
    const credentials = await fbroot.auth.EmailAuthProvider.credential(
      auth.currentUser.email,
      current_password
    );
    await auth.currentUser.reauthenticateAndRetrieveDataWithCredential(
      credentials
    );
    await auth.currentUser.updatePassword(new_password);
  } catch (error) {
    return error;
  }

  return "yeah";
};

export const doUpdateUserInfo = (changes) => {
  const user = get(auth, "currentUser", false);

  if (!user) {
    console.log("No current user, cannot save profile");
    return false;
  }

  const auth_fields = ["displayName", "email", "phoneNumber", "photoURL"];
  const db_fields = [
    "firstnames",
    "lastnames",
    "bio",
    "country",
    "email",
    "phone",
    "photoID",
    "facebook",
    "instagram",
    "paypal",
    "profile",
    "profile_other",
    "soundcloud",
    "twitter",
    "whatsapp",
    "youtube",
    "verified",
    "verified_when",
  ];

  const reducer = (acc, key) => {
    acc[key] = changes[key];
    return acc;
  };

  const auth_changes = keys(changes)
    .filter((key) => auth_fields.indexOf(key) > -1)
    .reduce(reducer, {});

  const db_changes = keys(changes)
    .filter((key) => db_fields.indexOf(key) > -1)
    .reduce(reducer, {});

  if (auth_changes) {
    user.updateProfile(auth_changes);
  }

  if (db_changes) {
    db.ref(`users/${user.uid}`).update(db_changes);
  }
};

export const doSendVerificationEmail = () => {
  // redirect-url after verification
  const port =
    window.location.port && window.location.port !== 80
      ? `:${window.location.port}`
      : "";
  const url = `${window.location.protocol}//${window.location.hostname}${port}`;

  // send email
  return auth.currentUser.sendEmailVerification({ url });
};
