// @ts-check
import produce from "immer";

/** @typedef {import('../wizardStore').WizardNewAlbumState} WizardNewAlbumState */
/** @typedef {import("../../../business-objects/album").Album} Album */

const TYPE = "WIZARD_SET_SAVED_ALBUM";

/**
 * @param {Album} album
 * @return {object}
 */
export const setSavedAlbumAction = (album) => {
  return {
    type: TYPE,
    album,
  };
};

/**
 * @param {WizardNewAlbumState} state
 * @param {object} action
 * @return {object}
 */
export const setSavedAlbumReducer = (state, action) => {
  return produce(state, (draft) => {
    draft.showThanks = true;
    draft.savedAlbum = action.album;
  });
};

export default {
  action: setSavedAlbumAction,
  reducer: setSavedAlbumReducer,
  TYPE,
};
