// @ts-check
import { useSelector } from "react-redux";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album").Track} Track */
/** @typedef {import("../../store").StateStore} StateStore */

import getTrackOpened from "../utils/getTrackOpened";

/**
 * @return {Track}
 */
export const useTrackOpened = () => {
  const track = useSelector(getTrackOpened);

  return track;
};

export default useTrackOpened;
