// @ts-check
import { db } from "../../../firebase";
import albumService from "../../../services/albumService";
import { loadAlbumUnedited } from "../../cart/actionsAsync/loadAlbumUnedited";

import coverRestoreUpload from "./coverRestoreUpload";
import trackRestoreAudio from "./trackRestoreAudio";
import _sideEffectCommon from "./_sideEffectCommon";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldActionType} FieldActionType */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */

/**
 * @param {object} updates
 * @param {Album} albumUnedited
 * @param {FieldEditable} field
 * @param {any=} originalValue
 * @return {object}
 */
const _sideEffectChanges = (updates, albumUnedited, field, originalValue) => {
  const path = field.path;
  /**
   * TODO:
   * restore side effect: tiene que contrarrestar con el del save effect
   *
   * ALbum en vivo > restore envivo de todos los tracks
   */

  if (path === "genreSubgenre" && originalValue?.genre) {
    updates.genre = originalValue.genre;
    updates.subgenre = originalValue.subgenre;
  }

  return updates;
};

/**
 * @param {Album} albumUnedited
 * @param {FieldEditable} field
 * @param {any=} originalValue
 * @return {object}
 */
const _prepareRestoreUpdates = (albumUnedited, field, originalValue) => {
  const path = field.path;

  let updates = {
    [path]: originalValue,
  };

  updates = _sideEffectChanges(updates, albumUnedited, field, originalValue);
  updates = _sideEffectCommon(updates, albumUnedited, field, originalValue);

  return updates;
};

/**
 * @param {Album} albumUnedited
 * @param {string} upc
 * @param {FieldEditable} field
 * @return {Promise<object>}
 */
const _fetchOriginalvalue = async (albumUnedited, upc, field) => {
  let originalValue = await albumService.fetchUneditedField(upc, field.path);

  if (!originalValue && field.path === "genreSubgenre") {
    originalValue = {
      genre: albumUnedited.genre,
      subgenre: albumUnedited.subgenre,
    };
  }

  return originalValue;
};

/**
 * @param {string} upc
 * @param {FieldEditable} field
 * @return {Function}
 */
export const restoreFieldChange = (upc, field) => {
  /**
   * @param {function} dispatch
   */
  return async function thunk(dispatch) {
    // specific restore field
    if (field.type === "audioTrack") {
      return dispatch(trackRestoreAudio(upc, field));
    } else if (field.type === "cover") {
      return dispatch(coverRestoreUpload(upc, field));
    }

    // restore default
    const albumUnedited = await dispatch(loadAlbumUnedited(upc));

    // traer valor original
    const originalValue = await _fetchOriginalvalue(albumUnedited, upc, field);

    const updates = _prepareRestoreUpdates(albumUnedited, field, originalValue);

    await db.updateAlbumFields(upc, updates);
  };
};
