// @ts-check
import produce from "immer";

/** @typedef {import('../wizardStore').WizardNewAlbumState} WizardNewAlbumState */
/** @typedef {import('../wizardStore').NewAlbumTrack} NewAlbumTrack */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */

const TYPE = "WIZARD_TRACK_ADD";

/**
 * @param {NewAlbumTrack} track
 * @return {Object}
 */
export const trackAddAction = (track) => {
  return {
    type: TYPE,
    track,
  };
};

/**
 * @param {WizardNewAlbumState} state
 * @param {object} action
 * @return {object}
 */
export const trackAddReducer = (state, action) => {
  return produce(state, (draft) => {
    let tracks = draft.tracks || [];
    tracks.push(action.track);

    if (!state.manualSortedByUser) {
      tracks = tracks.sort((a, b) => {
        if (a.originalFilename === b.originalFilename) {
          return 0;
        }
        return a.originalFilename < b.originalFilename ? -1 : 1;
      });
    }

    draft.tracks = tracks;
  });
};

export default {
  action: trackAddAction,
  reducer: trackAddReducer,
  TYPE,
};
