// @ts-check
import React, { useState } from "react";
import { Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

/** @typedef {import('../business-objects/album').Album} Album */

const useStyles = makeStyles((theme) => ({
  imageBig: { height: "100vh" },
  [theme.breakpoints.down("sm")]: {
    imageBig: { height: "auto" },
  },
}));

/**
 * Render the Title of an Album
 * @param {{album: Album, className?: string}} props
 */
const AlbumCover = ({ album, className = "" }) => {
  const [blownUp, setBlownUp] = useState(false);

  const classes = useStyles();

  const url = album?.photos?.cover?.url;

  const computedClassName = className;

  return (
    <>
      <div className={computedClassName} onClick={() => setBlownUp(true)}>
        <img src={url} alt="cover" width="100%" height="100%" />
      </div>

      {blownUp && (
        <Dialog
          aria-labelledby="big-cover"
          open={blownUp}
          onClose={() => setBlownUp(false)}
          maxWidth="xl"
        >
          <img src={url} alt="cover" className={classes.imageBig} />
        </Dialog>
      )}
    </>
  );
};

export default AlbumCover;
