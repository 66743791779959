//@ŧs-check
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { TextField } from "formik-material-ui";
import { Field } from "../../../../../components-cross/Field";
import wizardActions from "../../../../../reducers/wizardNewAlbum/wizardActions";

/** @typedef {import('../../../../../reducers/store').StateStore} StateStore */

const UpcField = (props) => {
  const dispatch = useDispatch();
  const showUpcField = useSelector(
    /**
     * @param {StateStore} state;
     * @return {boolean}
     */
    (state) => state.wizardNewAlbumState.showUpcField
  );

  const handleAdd = () => {
    dispatch(wizardActions.setShowUpcFieldAction(true));
  };

  const handleRemove = () => {
    dispatch(wizardActions.setShowUpcFieldAction(false));
  };

  return (
    <>
      {showUpcField ? (
        <Grid item xs={12} sm={8} md={6}>
          <Field
            component={TextField}
            label="UPC"
            name="upc"
            helperText="Si no tienes, borra el campo y te asignamos uno gratis."
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="borrar" onClick={handleRemove}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      ) : (
        <Grid item xs={12} sm={8} md={6}>
          <Button startIcon={<AddBoxIcon />} onClick={handleAdd}>
            UPC propio
          </Button>
        </Grid>
      )}
    </>
  );
};

export default UpcField;
