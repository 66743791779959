// @ts-check
import produce from "immer";

/** @typedef {import('../wizardStore').WizardNewAlbumState} WizardNewAlbumState */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */

const TYPE = "WIZARD_SET_PHOTO";

/**
 * @param {"cover" | "back"} typePhoto
 * @param {object} payload
 * @return {object}
 */
export const setPhotoAction = (typePhoto, payload) => {
  return {
    type: TYPE,
    typePhoto,
    payload,
  };
};

/**
 * @param {WizardNewAlbumState} state
 * @param {object} action
 * @return {object}
 */
export const setPhotoReducer = (state, action) => {
  const newState = produce(state, (draft) => {
    if (!draft.album.photos) {
      draft.album.photos = {};
    }
    draft.album.photos[action.typePhoto] = {
      ...draft?.[action.typePhoto],
      ...action.payload,
    };
  });
  return newState;
};

export default {
  action: setPhotoAction,
  reducer: setPhotoReducer,
  TYPE,
};
