//@ŧs-check
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { TextField } from "formik-material-ui";
import { Field } from "../../../../../components-cross/Field";
import { useFormikContext } from "formik";
import wizardActions from "../../../../../reducers/wizardNewAlbum/wizardActions";

/** @typedef {import('../../../../../reducers/store').StateStore} StateStore */

const VersionField = (props) => {
  const dispatch = useDispatch();
  const { values } = useFormikContext();
  const showVersionField = useSelector(
    /**
     * @param {StateStore} state;
     * @return {boolean}
     */
    (state) => state.wizardNewAlbumState.showVersionField
  );

  const { live } = values;

  const handleAddVersionField = () => {
    dispatch(wizardActions.setShowVersionFieldAction(true));
  };

  const handleRemoveVersionField = () => {
    dispatch(wizardActions.setShowVersionFieldAction(false));
  };

  if (live) {
    return <></>;
  }

  return (
    <Grid item xs={12} sm={8} md={6}>
      {showVersionField ? (
        <Field
          component={TextField}
          label="Versión"
          name="version"
          helperText="Opcional"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="borrar"
                  onClick={handleRemoveVersionField}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <Button startIcon={<AddBoxIcon />} onClick={handleAddVersionField}>
          Versión
        </Button>
      )}
    </Grid>
  );
};

export default VersionField;
