import React from "react";
import { firebase, functions } from "../../firebase";
import TextField from "@material-ui/core/TextField";

class SpacesSearch extends React.Component {
  state = {
    anchorEl: null,
    open: false,
  };

  handleChange = ({ target }) => {
    this.setState({ q: target.value });
  };

  async componentDidMount() {
    // USE LOCAL CLOUD-FUNCTIONS FOR TESTING!!! // <---------------------------------- DELETE!!!
    // functions.useFunctionsEmulator('http://localhost:5001')
  }

  requestGetUidFromEmail = functions.httpsCallable("getUidFromEmail");

  findSpace = async () => {
    const query = this.state.q.trim();
    const { appendSpace } = this.props;

    const space = {
      key: "",
      label: "",
      type: "",
    };

    if (query.indexOf("@") > -1) {
      const result = await this.requestGetUidFromEmail(query);

      space.key = result.data;
      space.label = query;
      space.type = "user";
    } else {
      const accountsSnap = await firebase.db.ref(`accounts`).orderByChild("name").equalTo(query).once("value");

      accountsSnap.forEach((snap) => {
        if (snap.exists()) {
          const account_key = snap.key;
          const account = snap.val();

          space.key = account_key;
          space.label = account.name;
          space.type = "account";
        }
      });
    }

    if (space.key) {
      appendSpace(space);
    }
  };

  render() {
    return (
      <TextField
        label="Add space"
        aria-label="Search"
        name="q"
        onChange={this.handleChange}
        onKeyPress={(e) => (e.key === "Enter" ? this.findSpace() : false)}
      />
    );
  }
}

export default SpacesSearch;
