import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { compose } from "recompose";
import { Bar, Line } from "react-chartjs-2";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import grey from "@material-ui/core/colors/grey";
import dateFormat from "dateformat";
import localizerFactory from "../../utils/localizerFactory";
import isAdmin from "../../utils/isAdmin";
import isSafeAdmin from "../../utils/isSafeAdmin";
import useConfig from "../../hooks/useConfig";

const localMoney = localizerFactory();
const localNumber = new Intl.NumberFormat();

const styles = (theme) => ({
  title: {
    color: grey[900],
    paddingBottom: 0,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  chartWrap: {
    padding: theme.spacing(2),
  },
  table: {
    width: "100%",
  },
  openButton: {
    width: "100%",
  },
  openButtonInner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loading: {
    backgroundColor: grey[100],
    padding: theme.spacing(10),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const ReportBigChart = (props) => {
  const [dataSets, setDataSets] = useState([]);
  const [periods, setPeriods] = useState([]);

  const { showArtificialStreams = false } = useConfig();

  // clean up data a bit
  useEffect(() => {
    const { datasets = [], labels = [] } = props.data;

    const dss = datasets
      .filter((ds) => {
        switch (true) {
          case isAdmin() || isSafeAdmin():
          case ds.id === "payments":
            return true;

          case ds.id === "countArtificialStreams":
            if (!showArtificialStreams) return false; // config says not to show this
            if (!ds.data) return false; // no data
            return ds.data.reduce((acc, x) => (acc = Boolean(x)), false); // any non-zero values?

          default:
            return false;
        }
      })
      .map((ds) => {
        let { id, data = [], data2 = [], label, borderColor } = ds;

        if (id === "payments") {
          label = "Total en USD";
        }

        if (id === "totalRoyalties") {
          label = "Reporting en USD";
        }

        if (id === "countArtificialStreams") {
          if (data2.length) {
            data2 = labels.map((label) => {
              const period = parseInt(label);
              const tick = { period, quantity: 0, total: 0 };

              const item = data2.find((d) => d?.period === period);

              if (item) {
                const { quantity = 0, total = 0 } = item;
                tick.quantity =
                  localNumber.format(quantity) + " reproducciones fraudulentas";
                tick.total = total;
              }

              return tick;
            });
          }
          if (data.length) {
            data = data.map((x) => x * -1);
          }
        }

        return {
          id,
          data,
          data2,
          label,
          borderColor,
          lineTension: 0.2,
          pointRadius: 5,
          pointBackgroundColor: borderColor,
          borderWidth: 3,
          fill: false,
        };
      });

    setDataSets(dss);
    setPeriods(labels);
  }, [props.data]);

  const getChartOptions = (width) => {
    return {
      legend: {
        display: width !== "xs",
      },
      maintainAspectRatio: width !== "xs",
      scales: {
        yAxes: [
          {
            ticks: {
              callback: function (value) {
                return localMoney.format(value);
              },
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              callback: function (value) {
                const v = new Date(
                  value.getFullYear(),
                  value.getMonth() + 3,
                  value.getDate()
                );
                return dateFormat(v, "mmm") + " " + dateFormat(v, "yyyy");
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          title: function (tooltipItem, data) {
            const { datasetIndex, index } = tooltipItem[0];

            const dataset = data.datasets[datasetIndex];

            // if it's the artificial streams dataset, show the label
            if (dataset.id === "countArtificialStreams") {
              return "Fraudulento";
            }

            // otherwise, show date
            const __d = tooltipItem[0].xLabel;
            const dateStr = dateFormat(__d, "mm/yyyy");

            return `Correspondientes al mes ${dateStr}`;
          },
          label: function (tooltipItem, data) {
            const { datasetIndex, index } = tooltipItem;

            const dataset = data.datasets[datasetIndex];

            const { id } = dataset;

            if (id === "countArtificialStreams") {
              const { quantity = 0 } = dataset.data2[index] || {};
              return quantity.toString();
            } else {
              const val = dataset.data[index] || 0;
              return localMoney.format(val);
            }
          },
        },
      },
    };
  };
  const { classes, title, data = {}, width, chartType = "line" } = props;

  const { labels } = data;

  // only render if data is available
  if (!labels) {
    return (
      <Paper elevation={0} className={classes.loading}>
        No hay datos
      </Paper>
    );
  }

  // better labels
  const prettyLabels = labels.map((l) => {
    const year = l.substr(0, 4);
    const month = l.substr(4, 2);

    return new Date(year, Number(month) - 1, 1);
  });

  // go and render it
  const chart_data = {
    datasets: dataSets,
    labels: prettyLabels,
  };

  const chart_options = getChartOptions(width);

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6" gutterBottom className={classes.title}>
        {title}
      </Typography>

      <div className={classes.chartWrap}>
        {chartType === "bar" ? (
          <Bar data={chart_data} options={chart_options} />
        ) : (
          <Line data={chart_data} options={chart_options} />
        )}
      </div>
    </Paper>
  );
};
export default compose(withWidth(), withStyles(styles))(ReportBigChart);
