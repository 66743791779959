// @ts-check
import cartService from "../../../services/cartService";
import utils from "./utils";
import loadCart from "./loadCart";
import { openAlbumField, restoreFieldChange } from "../../albumActions";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */

/**
 * Descarta un cambio  cambios y borra el carrito
 *
 * @param {string} upc;
 * @param {CartItem} cartItem;
 * @return {Function}
 */
export function discardCartItem(upc, cartItem) {
  /**
   * @param {function} dispatch
   */
  return async function thunk(dispatch) {
    const field = utils.makeFieldEditable(cartItem);
    if (!field) {
      return;
    }

    // restaurar el field en el album
    await dispatch(restoreFieldChange(upc, field));

    // eliminar cambio en el carrito
    await cartService.removeCartItem(upc, cartItem);

    await dispatch(loadCart(upc, true));
    dispatch(openAlbumField(null));
  };
}

export default discardCartItem;
