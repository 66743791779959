import albumService from "../../../services/albumService";

/**
 *
 * @return {Function}
 */
export function discardAlbum() {
  /**
   * @param {function} dispatch
   * @param {StateGetter} getState
   */
  return async function thunk(dispatch, getState) {
    /** @type {StateStore} */
    const state = getState();

    const upc = state.albumState.openAlbum;

    if (upc) {
      await albumService.discardAlbum(upc);
    }
  };
}
