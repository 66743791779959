import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import packageData from "../../package.json";

const styles = (theme) => ({
  footer: {
    background: "rgba(20, 20, 20,1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    fontSize: ".6rem",
    zIndex: -1,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  inner: {
    color: "rgb(100, 100, 100) !important",
  },
});

const Footer = ({ classes }) => {
  return (
    <footer className={classes.footer}>
      <Typography className={classes.inner}>
        &copy; 2018-{new Date().getFullYear()} Musik.digital
      </Typography>
      <Typography className={classes.inner}>
        App version {packageData.version}
      </Typography>
    </footer>
  );
};

export default withStyles(styles)(Footer);
