import { useEffect } from "react";
import { hotjar } from "react-hotjar";

const hjid = 1014892;
const hjsv = 6;

const Hotjar = ({ uid }) => {
  useEffect(() => {
    hotjar.initialize(hjid, hjsv);
    if (hotjar.initialized()) {
      hotjar.identify(uid);
    }
  }, [uid]);

  return null;
};

export default Hotjar;
