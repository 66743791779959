import React, { useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { List, IconButton, Drawer } from "@material-ui/core";
import { useHistory, withRouter } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { toast } from "react-toastify";
import * as routes from "../../../../../../constants/routes";
import CoinsIcon from "../../../../../../images/icons/coins.svg";
import MessageModal from "../MessageModal";
import cartActions from "../../../../../../reducers/cart/cartActions";
import ChangeItem from "./ChangeItem";
import CartDetailBuyCredits from "./CartDetailBuyCredits";
import toastAServiceError from "../../../../../../utils/toastServiceError";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 450,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    padding: "0.75rem 1.5rem 0.75rem 1.5rem",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F1F1F1",
  },
  headerTitle: {
    flex: "1 1 auto",
    display: "inline-block",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
  },
  headerBalanceCredits: {
    fontWeight: "bold",
    paddingRight: "0.25rem",
  },
  content: {
    flex: "1 1 auto",
    overflow: "auto",
    "& .content-description": {
      padding: "1.5rem",
      paddingBottom: 0,
      backgroundColor: "#F5F5F5",
    },
    "& .content-listContainer": {
      paddingLeft: "1.5rem",
      paddingRight: "1.5rem",
      backgroundColor: "#F5F5F5",
    },
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "1.57rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  list: {},
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  cardLabel: {
    position: "relative",
  },
  publishButton: {
    width: "100%",
    marginTop: "0.25rem",
  },
  "@media (max-width: 600px)": {
    paperDrawer: {
      left: 0,
    },
    root: {
      width: "100%",
    },
  },
}));

const ConfirmTitle = "Su album se sobreescribirá. ¿Desea continuar?";
const ConfirmDescription = ({ totalCostCredit }) => {
  const isPlural = totalCostCredit !== 1;
  return (
    <span>
      Al confirmar los cambios,{" "}
      <Typography color="secondary" component="span">
        se descontará{isPlural && "n"} {totalCostCredit} crédito
        {isPlural && "s"}
      </Typography>{" "}
      de su cartera.
    </span>
  );
};

const Header = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const accountBalance = useSelector(
    (state) => state.albumState.accountBalance
  );

  const closeDetailPanel = useCallback(() => {
    dispatch(cartActions.setShowDetailPanel(false));
  }, [dispatch]);

  return (
    <div className={classes.header}>
      <img src={CoinsIcon} alt="credits" width="20" height="20" />
      <div className={classes.headerTitle}>
        <span className={classes.headerBalanceCredits}>
          {accountBalance} créditos
        </span>
        en tu cuenta
      </div>
      <IconButton
        edge="end"
        aria-label="close"
        color="default"
        onClick={closeDetailPanel}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
};

const CartDetail = ({ album }) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const endScrollContainer = useRef(null);

  const accountBalance = useSelector(
    (state) => state.albumState.accountBalance
  );
  const isShowConfirmCheckout = useSelector(
    (state) => state.cartState.isShowConfirmCheckout
  );
  const totalCostCredit = useSelector(
    (state) => state.cartState.totalCostCredit
  );
  const cartItems = useSelector((state) => state.cartState.cartItems);
  const isShowDetailPanel = useSelector(
    (state) => state.cartState.isShowDetailPanel
  );

  const affordable = totalCostCredit <= accountBalance;

  const setShowConfirm = useCallback(
    (show) => {
      dispatch(cartActions.setShowConfirmCheckout(show));
    },
    [dispatch]
  );

  const setShowDetailPanel = useCallback(
    (show) => {
      dispatch(cartActions.setShowDetailPanel(show));
    },
    [dispatch]
  );

  const proceedSaveChanges = async (substractBalance) => {
    await dispatch(cartActions.publishCart(album.upc));
    toast.success("Perfecto! Su album ha sido modificado con éxito.");
    history.push(routes.EDITED);
  };

  const validateBalance = async () => {
    setShowConfirm(false);
    if (!affordable) {
      toast.error("Su saldo es insuficiente, realice una recarga por favor.");
      return;
    }

    try {
      await proceedSaveChanges(totalCostCredit);
    } catch (e) {
      toastAServiceError(
        e,
        "No se ha podido guardar los cambios. Intente más tarde"
      );
    }
    setShowDetailPanel(false);
  };

  const handleToBalance = useCallback(() => {
    dispatch({ type: "OPEN_BALANCE" });
    setShowDetailPanel(false);
  }, [dispatch, setShowDetailPanel]);

  const goToRecharge = useCallback(() => {
    if (endScrollContainer && endScrollContainer.current) {
      endScrollContainer.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [endScrollContainer]);

  const handleOnContinue = () => {
    history.push(routes.ALBUM_VIEW_PUBLISHLEGAL.replace(":upc", album.upc));
  };

  const countChanges = cartItems?.length || 0;

  return (
    <Drawer
      open={isShowDetailPanel}
      onClose={() => setShowDetailPanel(false)}
      anchor="right"
    >
      <div className={classes.root}>
        <Header />
        <div className={classes.content}>
          <div className="content-description">
            <Typography variant="h5" component="span">
              {countChanges > 0 && cartItems.length} CAMBIO
              {countChanges !== 1 && "S"}
            </Typography>
            <Typography variant="h6" component="span">
              {" "}
              · {totalCostCredit} crédito{totalCostCredit !== 1 && "s"}
            </Typography>
            <Typography variant="caption" component="p">
              Tómate un momento para repasar los cambios antes de publicar
            </Typography>
          </div>
          <div className="content-listContainer">
            <List className={classes.list} dense={true}>
              {cartItems.map((change) => {
                return (
                  <ChangeItem
                    key={change.path}
                    upc={album.upc}
                    change={change}
                  />
                );
              })}
            </List>
          </div>
          <CartDetailBuyCredits />
          <div ref={endScrollContainer}></div>
        </div>
        <div className={classes.actions}>
          {!affordable ? (
            <>
              <Typography color="textSecondary" className={classes.cardLabel}>
                Saldo insuficiente
              </Typography>
              <Button
                size="small"
                variant="contained"
                className={classes.publishButton}
                color="primary"
                onClick={() => {
                  goToRecharge();
                  handleToBalance();
                }}
              >
                Recarga para continuar
              </Button>
            </>
          ) : (
            <Button
              size="small"
              variant="contained"
              className={classes.publishButton}
              color="primary"
              onClick={handleOnContinue}
              disabled={!affordable}
            >
              Continuar
            </Button>
          )}
        </div>
      </div>

      {isShowConfirmCheckout && (
        <MessageModal
          openModal
          setOpenModal={setShowConfirm}
          title={ConfirmTitle}
          description={<ConfirmDescription totalCostCredit={totalCostCredit} />}
          action={validateBalance}
        />
      )}
    </Drawer>
  );
};

// @ts-ignore
export default withRouter(CartDetail);
