// @ts-check
import { openAlbum, openAlbumLoading, getAlbum } from "../../albumActions";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */

/**
 *
 * @param {string} upc
 * @return {Function}
 */
export function loadAlbumToEdit(upc) {
  /**
   * @param {function} dispatch
   * @param {function} getState
   */
  return async function thunk(dispatch, getState) {
    dispatch(openAlbumLoading(true));
    const album = await dispatch(getAlbum(upc));
    dispatch(openAlbum(upc));
    dispatch(openAlbumLoading(false));
    return album;
  };
}
