// @ts-check
import { firebase } from "../../../firebase";
import { setConfigAction } from "../configActions";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */

/**
 * Busca y retorna la config del sistema
 *
 * @param {boolean=} force;
 * @return {Function}
 */
export function loadConfig(force = false) {
  /**
   * @param {function} dispatch
   * @param {StateGetter} getState
   */
  return async function thunk(dispatch, getState) {
    /** @type {StateStore} */
    const state = getState();

    const configLoaded = state.configState.loaded;

    if (!configLoaded) {
      const snap = await firebase.db.ref("config").once("value");
      dispatch(setConfigAction(snap.val()));
    }
  };
}

export default loadConfig;
