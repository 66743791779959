import React from "react";
import { useFormikContext } from "formik";
import useWizardBot from "../../../../../reducers/wizardNewAlbum/useWizardBot";

export const WizardBotContext = () => {
  const context = useFormikContext();
  useWizardBot(context);

  return <></>;
};

export default WizardBotContext;
