import React, { Component } from "react";
import localizerFactory from "../utils/localizerFactory";
import Tooltip from "@material-ui/core/Tooltip";

const formattedMoney = localizerFactory();
const longFormat = localizerFactory({ maximumFractionDigits: 10 });

class Money extends Component {
  state = {
    open: false,
  };

  toggleHover = () => {
    this.setState((prev) => ({ open: !prev.open }));
  };

  render() {
    const { amount, expandable = true } = this.props;

    const formatted = formattedMoney.format(amount);
    const long = longFormat.format(amount);

    return (
      <Tooltip title={long} placement={"top"}>
        <span onMouseEnter={expandable ? this.toggleHover : () => {}} onMouseLeave={expandable ? this.toggleHover : () => {}}>
          {formatted}
        </span>
      </Tooltip>
    );
  }
}

export default Money;
