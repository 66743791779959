// @ts-check
import React, { useState } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { Button } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { makeStyles } from "@material-ui/core/styles";

import ArrowCircleDown from "../../../../../../images/icons/arrow-circle-down.svg";
import useConfig from "../../../../../../hooks/useConfig";

/** @typedef {import('react').ReactElement} ReactElement*/
/** @typedef {import("../../../../../../business-objects/cart").CartItem} CartItem*/
/** @typedef {import("../../../../../../business-objects/album.fieldEditable").FieldActionType} FieldActionType */

const useStyle = makeStyles((theme) => ({
  totalContainer: {
    padding: "1.5rem",
    backgroundColor: "#FFF",
    marginBottom: "0.3rem",
    "& .totalContainer-countDetail": {
      fontSize: "1.15rem",
    },
    "& .totalContainer-description": {
      fontSize: "0.8rem",
      color: theme.palette.error.main,
    },
    "&.--affordable": {
      backgroundColor: "#e0ffe4",
    },
    "&.--notAffordable": {
      backgroundColor: "#fff4f7",
    },
  },
  buyerContainer: {
    padding: "1.5rem",
    paddingTop: "2rem",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f9f2b0",
    justifyContent: "center",
    position: "relative",
    "& .buyerContainer-iconArrow": {
      position: "absolute",
      width: "40px",
      height: "40px",
      top: 0,
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    "& .buyerContainer-title": {
      textTransform: "uppercase",
      textAlign: "center",
      fontSize: "0.9rem",
      fontWeight: "bold",
      marginBottom: "0.5rem",
    },
    "& .buyerContainer-list": {
      display: "flex",
      justifyContent: "space-around",
      marginBottom: "0.5rem",
    },
    "& .buyerContainer-listItem": {
      padding: "0.3rem 0.5rem 0.3rem 0.5rem",
      fontSize: "1.2rem",
      fontWeight: "bold",
      cursor: "pointer",
      marginBottom: "0.5rem",
      "&.--selected": {
        backgroundColor: "#F3E35B",
      },
    },
    "& .buyerContainer-footer": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& > *": {
        paddingLeft: "0.5rem",
        paddingRight: "0.5rem",
      },
      "& .buyerContainer-footerMoneyCount": {
        color: theme.palette.success.dark,
        fontSize: "0.8rem",
        fontWeight: "bold",
      },
    },
  },
}));

/**
 *
 * @param {object} props
 * @return {ReactElement}
 */
export const CartDetailBuyCredits = (props) => {
  const [selectedBuyOption, setSeletedBuyOption] = useState({
    creditsValue: 0,
    moneyValue: 0,
  });
  const accountBalance = useSelector(
    (state) => state.albumState.accountBalance
  );

  const totalCostCredit = useSelector(
    (state) => state.cartState.totalCostCredit
  );

  const config = useConfig();

  const classes = useStyle();

  const affordable = totalCostCredit <= accountBalance;

  return (
    <div>
      <div
        className={classnames(classes.totalContainer, {
          "--affordable": affordable,
          "--notAffordable": !affordable,
        })}
      >
        <div className="totalContainer-countDetail">
          Total: {totalCostCredit} crédito{totalCostCredit !== 1 && "s"} (Por ahora gratis!)
        </div>
        {!affordable && (
          <div className="totalContainer-description">
            No tienes suficientes créditos para publicar los cambios.
          </div>
        )}
      </div>
      {!affordable && (
        <div className={classes.buyerContainer}>
          <img
            className="buyerContainer-iconArrow"
            src={ArrowCircleDown}
            alt="buy credits"
          />
          <div className="buyerContainer-title">Comprar Créditos</div>
          <div className="buyerContainer-list">
            {config.cart?.buyOptions?.map((opt) => {
              return (
                <div
                  key={opt.creditsValue}
                  className={classnames("buyerContainer-listItem", {
                    "--selected":
                      selectedBuyOption?.creditsValue === opt.creditsValue,
                  })}
                  onClick={() => setSeletedBuyOption(opt)}
                >
                  {opt.creditsValue}
                </div>
              );
            })}
          </div>
          <div className="buyerContainer-footer">
            <div className="buyerContainer-footerMoneyCount">
              {selectedBuyOption?.creditsValue > 0 && (
                <>$ {selectedBuyOption?.moneyValue} USD</>
              )}
            </div>
            <div className="buyerContainer-footerButton">
              <Button
                variant="contained"
                color="primary"
                endIcon={<NavigateNextIcon />}
                size="small"
              >
                COMPRAR
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartDetailBuyCredits;
