//@ts-check
/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album").Track} Track */
/** @typedef {import("../../store").StateStore} StateStore */
/** @typedef {import("../../store").AlbumState} AlbumState */

/**
 * @param {StateStore | AlbumState} state
 * @return {Track | null}
 */
export const getTrackOpened = (state) => {
  /** @type {AlbumState} */
  const albumState = "albumState" in state ? state.albumState : state;

  if (!albumState.openAlbum || !albumState.albums) {
    return null;
  }

  const album = albumState.albums[albumState.openAlbum];

  if (!albumState.openTrack || !album.tracks) {
    return null;
  }

  return album.tracks[albumState.openTrack];
};

export default getTrackOpened;
