// @ts-check
import produce from "immer";

/** @typedef {import('../../store').AlbumState} AlbumState */

const TYPE = "SET_ALBUM_SHOW_LEGALES";

/**
 * @param {boolean} show
 * @return {Object}
 */
export const albumSetShowLegalesAction = (show) => {
  return {
    type: TYPE,
    show,
  };
};

/**
 * @param {AlbumState} state
 * @param {object} action
 * @return {object}
 */
export const albumSetShowLegalesReducer = (state, action) => {
  const { show } = action;

  return produce(state, (draft) => {
    draft.showLegales = show;
    if (state.albums[state.openAlbum]) {
      draft.albums[state.openAlbum].release_digital = "";
    }
  });
};

export default {
  action: albumSetShowLegalesAction,
  reducer: albumSetShowLegalesReducer,
  TYPE,
};
