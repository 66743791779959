// @ts-check
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useDropzone } from "react-dropzone";
import classnames from "classnames";
import Beforeunload from "react-beforeunload";
import { toast } from "react-toastify";

import wizardActions from "../../../../../reducers/wizardNewAlbum/wizardActions";

/** @typedef {import('../../../../../reducers/store').StateStore} StateStore */
/** @typedef {import('../../../../../reducers/wizardNewAlbum/wizardStore').NewAlbumTrack} NewAlbumTrack */

import TracksList from "./StepTracks.TracksList";

const useStyles = makeStyles((theme) => ({
  container: {},
  zoneUpload: {
    cursor: "pointer",
    width: "100%",
    height: "100px",
    marginBottom: "1rem",
    border: "1px dashed rgba(0, 0, 0, 0.23)",
    borderRadius: "0.5rem",
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& p": {
      textAlign: "center",
    },
    "&.--zoneActive": {
      borderWidth: "2px",
    },
    "&.--zoneAccept": {
      color: theme.palette.success.dark,
      borderColor: theme.palette.success.dark,
    },
    "&.--zoneReject": {
      color: theme.palette.error.light,
      borderColor: theme.palette.error.light,
    },
  },
}));

const ZoneUpload = () => {
  const dispatch = useDispatch();

  const onDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      dispatch(wizardActions.audioTrackUpload([file]));
    });
  };

  const onDropRejected = (files) => {
    toast.error("Archivos no validos");
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    onDropRejected,
    accept: ["audio/*", ".ogg", ".mp3", ".wav"],
  });
  const classes = useStyles();

  const computedClasses = classnames(classes.zoneUpload, {
    "--zoneActive": isDragActive,
    "--zoneAccept": isDragAccept,
    "--zoneReject": isDragReject,
  });

  return (
    <>
      <div {...getRootProps({ className: computedClasses })}>
        <input {...getInputProps()} />
        <p>
          Añade tus archivos de audio aquí <br />
          Arrastra o haz click
        </p>
      </div>
    </>
  );
};

const StepTracksUploader = () => {
  const classes = useStyles();
  const isUploadingTracks = useSelector(
    /**
     * @param {StateStore} state;
     * @return {boolean}
     */
    (state) => state.wizardNewAlbumState.isUploadingTracks || false
  );

  return (
    <div className={classes.container}>
      <Beforeunload
        onBeforeunload={() =>
          "Si recargas la página se van a perder todos los tracks que no estén completamente subidos."
        }
      />

      <ZoneUpload />

      {isUploadingTracks && <LinearProgress />}

      <TracksList />
    </div>
  );
};

export default StepTracksUploader;
