import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

const IconDeleteConfirmButton = ({ onClick }) => {
  const [showConfirm, setShowConfirm] = useState(false);

  const handleOnClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowConfirm(true);
  };

  const handleOnConfirm = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (onClick) {
      onClick(e);
    }
    setShowConfirm(false);
  };

  const handleOnCancel = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowConfirm(false);
  };

  return showConfirm ? (
    <div style={{ backgroundColor: "#FFF" }}>
      <Button
        aria-label="confirmar"
        onClick={handleOnConfirm}
        color="secondary"
        size="small"
      >
        Confirmar Borrar
      </Button>
      <Button aria-label="cancelar" onClick={handleOnCancel} size="small">
        Cancelar
      </Button>
    </div>
  ) : (
    <IconButton aria-label="borrar" onClick={handleOnClick}>
      <DeleteIcon fontSize="small" />
    </IconButton>
  );
};

export default IconDeleteConfirmButton;
