// @ts-check
import React, { useMemo } from "react";
import { makeStyles, Button, useMediaQuery, Box } from "@material-ui/core";
import AlbumCover from "../../../../../components-cross/AlbumCover";

import {
  getAlbumTitle,
  getArtistsPrimaries,
} from "../../../../../business-objects";
import DateRender from "../../../../../components-cross/DateRender";

import AlbumStatusChip from "../../../components/AlbumStatusChip";
import ToolbarAlbumPreview from "./ToolbarAlbumPreview";
import AlbumRestrictions from '../../../components/AlbumRestrictions';

/** @typedef {import('../../../../../business-objects/album').Album} Album */
/** @typedef {import('../../../../../business-objects/album').Artist} Artist */
/** @typedef {import('../../../../../business-objects/album').Track} Track */

const BACKGROUND =
  "linear-gradient(180deg, #F6C358 0%, rgba(246, 195, 88, 0.38) 100%)";

const useStyles = makeStyles((theme) => ({
  headerRoot: {},
  headerAlbum: {
    background: BACKGROUND,
    padding: "7vh 1.5rem 6vh",
    // paddingBottom: "2rem",

    "& .headerAlbum__inner": {
      maxWidth: "1100px",
      display: "flex",
      // flexWrap: "wrap",
      position: "relative",
      margin: "auto",
      gap: theme.spacing(3),
    },

    "& .headerAlbum__cover": {
      width: "180px",
      maxWidth: "180px",
      height: "180px",
      cursor: "pointer",
      borderRadius: "0.2rem",
      boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.25)",
      marginRight: "1.1rem",
      "& > img": {
        borderRadius: "0.2rem",
        width: "180px",
        height: "180px",
      },
    },

    "& .headerAlbum__detail": {
      flex: "1 1 auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      position: "relative",
    },
    "& .headerAlbum__toolbar": {
      position: "absolute",
      right: 0,
      top: 0,
    },

    "& .headerAlbum__toolbar_mini": {
      display: "none",
      position: "absolute",
      right: 0,
      top: 0,
    },

    "& .headerAlbum__format_and_status": {
      display: "flex",
      gap: "0.5rem",
      alignItems: "center",
    },
    "& .headerAlbum__format": {
      textTransform: "uppercase",
    },
    "& .headerAlbum__status": {
      padding: "0.1rem 0.3rem",
      backgroundColor: "rgb(204, 170, 102) !important",
    },
    "& .headerAlbum__albumTitle": {
      fontSize: "2rem",
      marginTop: "0.5rem",
      marginBottom: "0.75rem",
    },
    "& .headerAlbum__rowDetail": {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      fontSize: "0.9rem",
      color: theme.palette.text.secondary,
    },
    "& .headerAlbum__moreDetail": {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      fontSize: "0.9rem",
      color: theme.palette.text.secondary,
    },
    "& .headerAlbum__artists": {
      display: "inline-flex",
      alignItems: "center",
    },
    "& .fieldSeparator": {
      display: "block",
      paddingLeft: "0.4rem",
      paddingRight: "0.4rem",
    },
    "& .artistsList__artist": {
      display: "flex",
      alignItems: "center",
      color: theme.palette.text.primary,
      fontWeight: "bold",
    },

    [theme.breakpoints.down("xs")]: {
      "& .headerAlbum__inner": {
        flexDirection: "column",
      },

      "& .headerAlbum__cover": {
        width: "100%",
        height: "180px",
        marginBottom: theme.spacing(2),
        marginRight: "0",
        "& > img": {
          width: "100% !important",
          height: "180px !important",
        },
      },
      "& .headerAlbum__rowDetail": {
        flexDirection: "column",
        alignItems: "flex-start",
      },
      "& .headerAlbum__artists": {
        marginBottom: "0.75rem",
      },
      "& .headerAlbum__artists > .fieldSeparator": {
        display: "none",
      },
      "& .headerAlbum__toolbar": {
        display: "none",
      },
      "& .headerAlbum__toolbar_mini": {
        display: "block",
      },
    },
  },

  editButton: {
    marginLeft: theme.spacing(3),
  },

  avatarImage: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    marginRight: "0.25rem",
  },
}));

/**
 * @param {{album: Album}} props
 */
const ArtistsList = ({ album }) => {
  const classes = useStyles();
  const artists = useMemo(() => {
    return getArtistsPrimaries(album.artists);
  }, [album.artists]);

  if (artists.length === 1) {
    const artist = artists[0];
    const { imageUrl } = artist;

    return (
      <span className="artistsList__artist">
        {imageUrl && (
          <img
            src={imageUrl}
            className={classes.avatarImage}
            alt={`Avatar ${artist.name}`}
          />
        )}
        <span className="artistsList__artistName">{artist.name}</span>
      </span>
    );
  }

  return (
    <span className="artistsList__artist">
      {artists.map((artist) => artist.name).join(", ")}
    </span>
  );
};

/**
 * @param {{album: Album, toolbar: ToolbarAlbumPreview}} props
 * @returns
 */
const HeaderAlbum = ({ album, toolbar }) => {
  const classes = useStyles();

  const countTracks = useMemo(() => {
    return album.tracks ? Object.values(album.tracks).length : 0;
  }, [album.tracks]);

  return (
    <div className={classes.headerRoot}>
      <div className={classes.headerAlbum}>
        <div className="headerAlbum__inner">
          <div className="headerAlbum__cover_and_toolbar">
            <AlbumCover className="headerAlbum__cover" album={album} />

            <div className="headerAlbum__toolbar_mini">
              <ToolbarAlbumPreview album={album} vertical />
            </div>
          </div>

          <div className="headerAlbum__detail">
            <Box className="headerAlbum__format_and_status">
              <Box className="headerAlbum__format">{album.format}</Box>

              <AlbumStatusChip
                statusKey={album.status}
                className="headerAlbum__status"
              />
              <AlbumRestrictions
              className="headerAlbum__status"
              restrictions={album.restrictions}
            />
            </Box>

            <h2 className="headerAlbum__albumTitle">{getAlbumTitle(album)}</h2>

            <div className="headerAlbum__rowDetail">
              <div className="field headerAlbum__artists">
                {album && <ArtistsList album={album} />}
                <div className="fieldSeparator">·</div>
              </div>
              <div className="headerAlbum__moreDetail">
                <span className="field">
                  <DateRender value={album.release} pattern="yyyy" />
                </span>
                {album.genreSubgenre && (
                  <>
                    <div className="fieldSeparator">·</div>
                    <span className="field">
                      {album.genreSubgenre.genre}/{album.genreSubgenre.subgenre}
                    </span>
                  </>
                )}
                <div className="fieldSeparator">·</div>
                <span className="field">
                  {countTracks} track{countTracks > 1 && "s"}
                </span>
              </div>
            </div>

            <div className="headerAlbum__toolbar">
              <ToolbarAlbumPreview album={album} vertical={false} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderAlbum;
