//@ts-check
import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core";

import getArtistMain from "../../../../../business-objects/getArtistMain";
import DateRender from "../../../../../components-cross/DateRender";

/** @typedef {import('../../../../../business-objects/album').Album} Album */
/** @typedef {import('../../../../../business-objects/album').Artist} Artist */
/** @typedef {import('../../../../../business-objects/album').Track} Track */

const useStyle = makeStyles((theme) => ({
  footerAlbum: {
    fontSize: "0.85rem",
    [theme.breakpoints.down("md")]: {
      position: "static",
    },
  },

  copyrightContainer: {
    maxWidth: "1100px",
    margin: "auto",
    color: theme.palette.text.secondary,
    display: "flex",
    gap: theme.spacing(1),
    padding: theme.spacing(2, 0),
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 3),
    },
    // [theme.breakpoints.down("sm")]: {
    //   // flexDirection: "column",
    //   flexWrap: "wrap",
    // },
  },

  footerBar: {
    marginTop: theme.spacing(1),
    background: "rgba(246, 195, 88, 0.4)",
    padding: theme.spacing(2, 0),
    "& .footerField": {
      display: "flex",
      marginRight: "2rem",

      "& .footerField__label": {
        color: theme.palette.text.secondary,
        marginRight: "0.5rem",
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      "& .footerField": {
        marginRight: "initial",
        marginBottom: "0.5rem",
        "& .footerField__label": {
          // flex: "1 0 50%",
        },
        "& .footerField__value": {
          // flex: "1 0 50%",
        },
      },
    },
  },

  footerBarInner: {
    maxWidth: "1100px",
    margin: "auto",
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 3),
    },
  },
}));

/**
 *
 * @param {{label: string, value: import("react").ReactNode}} props
 */
const FooterField = ({ label, value }) => {
  return (
    <div className="footerField">
      <div className="footerField__label">{label}</div>
      <div className="footerField__value">{value}</div>
    </div>
  );
};

/**
 *  @param {{album: Album}} props
 */
const FooterAlbum = ({ album }) => {
  const classes = useStyle();
  const mainArtist = useMemo(() => {
    return getArtistMain(album?.artists);
  }, [album]);

  if (!album) {
    return <></>;
  }

  const {
    upc,
    subaccount_user,
    subaccount,
    release,
    release_digital,
    country,
  } = album;

  return (
    <div className={classes.footerAlbum}>
      <div className={classes.copyrightContainer}>
        <span className="copyRightField">
          <DateRender value={release_digital} pattern="yyyy" />
        </span>
        <span className="copyRightField">© {mainArtist?.name}</span>
        <span className="copyRightField">℗ {mainArtist?.name}</span>
        <span className="releaseDigital">
          Fecha de aparición en plataformas:{" "}
          <DateRender value={release_digital} />
        </span>
      </div>
      <div className={classes.footerBar}>
        <div className={classes.footerBarInner}>
          <FooterField label="UPC" value={upc} />
          <FooterField label="Sello" value={subaccount} />
          <FooterField label="Usuario" value={subaccount_user} />
          <FooterField label="Pais" value={country} />
        </div>
      </div>
    </div>
  );
};

export default FooterAlbum;
