// @ts-check
import { db } from "../../../firebase";
import storageService from "../../../services/storageService.js";
import { loadAlbumUnedited } from "../../cart/actionsAsync/loadAlbumUnedited";
import getTrackOriginalFilename from "../../../business-objects/getTrackOriginalFilename";
import { getAlbum } from "./getAlbum";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album").Track} Track */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldActionType} FieldActionType */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */

/**
 * @param {Track} track
 * @return {object}
 */
const makeUpdateToRestoreAudio = (track) => {
  const { isrc } = track;
  // update track metadata
  const originalFilename =
    track.originalFilename || getTrackOriginalFilename(track);

  const updates = {
    [`tracks/${isrc}/storageLocation`]: track.storageLocation || null,
    [`tracks/${isrc}/filename`]: track.filename || null,
    [`tracks/${isrc}/extension`]: track.extension || null,
    [`tracks/${isrc}/originalFilename`]: originalFilename || null,
    [`tracks/${isrc}/fileChanged`]: null,
  };
  return updates;
};

/**
 *
 * @param {function} dispatch
 * @param {string} upc
 * @param {FieldEditable} field
 */
const restoreMetadataTrack = async (dispatch, upc, field) => {
  const albumUnedited = await dispatch(loadAlbumUnedited(upc));

  if (!field?.isrcTrack || !(field.isrcTrack in albumUnedited.tracks)) {
    throw new Error("No se encontro el track para restaurar");
  }

  const trackUnedited = albumUnedited.tracks[field.isrcTrack];
  const updates = makeUpdateToRestoreAudio(trackUnedited);
  await db.updateAlbumFields(upc, updates);
};

/**
 *
 * @param {function} dispatch
 * @param {StateGetter} getState
 * @param {string} upc
 * @param {FieldEditable} field
 */
const removeUploadedAudioTrack = async (dispatch, getState, upc, field) => {
  const album = await dispatch(getAlbum(upc));

  if (!field?.isrcTrack || !(field.isrcTrack in album.tracks)) {
    console.log(field, album);
    return;
  }

  /** @type {StateStore} */
  const reduxState = getState();
  const config = reduxState.configState.config;
  const bucketName = config.buckets.uploading.name;

  try {
    await storageService.removeFile(
      album.tracks[field.isrcTrack].storageLocation,
      bucketName
    );
  } catch (e) {
    console.warn("Error on remove old audio file", e);
  }
};

/**
 * Restore an audio track
 * @param {string} upc
 * @param {FieldEditable} field
 * @return {Function}
 */
export const trackRestoreAudio = (upc, field) => {
  /**
   * @param {function} dispatch
   * @param {StateGetter} getState
   */
  return async function thunk(dispatch, getState) {
    // REMOVE TEMPORAL UPLOADED FILE AUIDO TRACK
    await removeUploadedAudioTrack(dispatch, getState, upc, field);

    // RESTORE METADATA RELATED TO THE FILE
    await restoreMetadataTrack(dispatch, upc, field);
  };
};

export default trackRestoreAudio;
