// @ts-check
/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */

const PRICE_DEFAULT = 1;

/**
 *
 * @param {CartItem} cartItem
 * @return {string}
 */
const _determineKey = (cartItem) => {
  if (!cartItem) {
    return "";
  }

  let key;
  const path = cartItem.path;

  if (cartItem.typeField === "artist" || path.indexOf("artists") > -1) {
    key = "artists";
  } else if (cartItem.level === "track") {
    const pathParts = path.split("/");
    key = pathParts[pathParts.length - 1];
  } else if (path.indexOf("cover") > -1) {
    key = "cover";
  } else {
    key = path;
  }

  return key;
};

// eslint-disable-next-line valid-jsdoc
/**
 * @param {CartItem} cartItem
 * @param {{[key:string]: number}} pricesChanges
 * @return {number}
 */
export const calculateChangeCost = (cartItem, pricesChanges) => {
  const key = _determineKey(cartItem);
  return pricesChanges && key in pricesChanges
    ? pricesChanges[key]
    : PRICE_DEFAULT;
};

export default calculateChangeCost;
