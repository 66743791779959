import React from "react";
import Chip from "@material-ui/core/Chip";
import useConfig from "../../hooks/useConfig";

const PaymentStatusChip = ({ statusKey }) => {
  const key = statusKey.toLowerCase();
  const config = useConfig();
  const status = config.paymentStatuses[key];

  if (!status) {
    return null;
  }

  return (
    <Chip
      label={status.label}
      style={{
        backgroundColor: status.color,
        color: "white",
        textTransform: "uppercase",
        fontSize: ".8em",
      }}
    />
  );
};

export default PaymentStatusChip;
