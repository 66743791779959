//@ts-check
import { toast } from "react-toastify";

/**
 *
 * @param {{
 *  message: string,
 *  details?: {
 *    isMessageUserFriendly?: boolean
 *  }
 * }} e
 * @param {string} defaultMessage
 */
export const toastAServiceError = (e, defaultMessage) => {
  let msg = defaultMessage;
  if (e.message && e.details?.isMessageUserFriendly) {
    msg = e.message;
  }
  toast.error(msg);
};

export default toastAServiceError;
