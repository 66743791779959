// @ts-check
import { useState, useMemo } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { ListItemTextTrack } from "../../../../../components-cross/TrackTitle";
import { getTracksSorted } from "../../../../../business-objects";
import TrackCreditsDialog from "./TrackCreditsDialog";
import { useHistory } from "react-router-dom";
import * as routes from "../../../../../constants/routes";
import { trackOpenAction } from "../../../../../reducers/albumActions";
import { useDispatch } from "react-redux";
import { red } from "@material-ui/core/colors";

/** @typedef {import('../../../../../business-objects/album').Album} Album */
/** @typedef {import('../../../../../business-objects/album').Track} Track */

const useStyles = makeStyles((theme) => ({
  tracksList: {
    maxWidth: "1100px",
    margin: "auto",
    // padding: theme.spacing(0, 2),
    "& .trackList__list": {
      padding: theme.spacing(6, 2, 15),
      "& .MuiListItemIcon-root": {
        minWidth: "2rem",
      },
    },
    "& .trackList__listHeader": {
      color: theme.palette.text.secondary,
      fontSize: "1rem",
      "& .MuiListItemText-primary": {
        fontSize: "1rem",
      },
    },
    "& .trackList__trackItem": {
      marginBottom: theme.spacing(2),
      border: "1px solid #eee",
      borderRadius: "1rem",
      // background: "rgba(255, 255, 255, 0.3)",
      // boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.15)",
    },

    [theme.breakpoints.up("lg")]: {
      "& .trackList__list": {
        padding: theme.spacing(6, 0),
      },
    },
  },
  trackListEmpty: {},
  fileType: {
    color: "white",
    fontSize: "0.8rem",
    padding: "0 1em",
    backgroundColor: "rgb(204, 170, 102)",
    fontWeight: "bold",
    textTransform: "lowercase",
    borderRadius: "4px",
    marginRight: "0.5rem",
  },
  errorAudio: {
    color: "white",
    backgroundColor: red[700],
    fontSize: "0.8rem",
    padding: "0 .5em",
    fontWeight: "bold",
    textTransform: "uppercase",
    borderRadius: "4px",
    marginRight: "0.5rem",
    display: "flex",
    alignItems: "center",
  },
}));

/**
 * @param {{album: Album, showDelete?:boolean}} props
 * @returns
 */
const TracksList = ({ album, showDelete }) => {
  const classes = useStyles();
  const [trackSelected, setTrackSelected] = useState(null);
  const tracks = getTracksSorted(album);
  const dispatch = useDispatch();

  const history = useHistory();

  if (!tracks) {
    return <div className={classes.trackListEmpty}>Sin tracks</div>;
  }

  const handleOnClickTrack = (track) => {
    setTrackSelected(track);
  };

  const handleOnClickTrackSecondary = (track) => {
    dispatch(trackOpenAction(track.isrc));
    history.push(routes.ALBUM_EDIT_TRACKS.replace(":upc", album.upc));
  };

  return (
    <div className={classes.tracksList}>
      <List className="trackList__list">
        {tracks.map((track, index) => {
          return (
            <ListItem
              button
              className="trackList__trackItem"
              key={track.isrc}
              onClick={() => handleOnClickTrack(track)}
            >
              <ListItemIcon>{track.order}</ListItemIcon>

              <ListItemTextTrack track={track} album={album} />

              {album.status === "draft" && (
                <Box>
                  <Typography
                    className={
                      track.extension ? classes.fileType : classes.errorAudio
                    }
                  >
                    {track.extension || "Falta audio"}
                  </Typography>
                </Box>
              )}
            </ListItem>
          );
        })}
      </List>
      {trackSelected && (
        <TrackCreditsDialog
          // @ts-ignore
          track={trackSelected}
          album={album}
          onClose={() => setTrackSelected(null)}
        />
      )}
    </div>
  );
};

export default TracksList;
