import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ScrollToTop from "../ScrollToTop";
import ReportingDashboard from "./ReportingDashboard";
import { Box, Typography } from "@material-ui/core";
import { DisplayFlashMessages } from "../flash-messages";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import Payments from "./Payments";
import BuildIcon from "@material-ui/icons/Build";
import { Card, CardActionArea } from "@material-ui/core";
import isAdmin from "../../utils/isAdmin";
import isSafeAdmin from "../../utils/isSafeAdmin";
import Disabled from "./Disabled";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "95vw",
    height: "100%",
    width: "100%",
    margin: "auto",
    marginTop: theme.spacing(14),
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(8),
    },
  },
  video: {
    height: "100%",
    width: "80vw",
    maxWidth: "700px",
  },
  tabs: {
    borderBottomColor: lighten(theme.palette.primary.light, 0.7),
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    [theme.breakpoints.up("sm")]: {
      border: "none",
    },
  },
  tabContainer: {
    paddingBottom: theme.spacing(4),
  },
  updating: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  text: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  text2: {
    marginBottom: theme.spacing(4),
    textAlign: "center",
    color: "green",
  },
  [theme.breakpoints.down("sm")]: {
    text: {
      fontSize: "3rem",
    },
  },
}));

const TabContainer = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const ReportingApp = ({ tab }) => {
  const [value, setValue] = useState(tab);

  useEffect(() => {
    if (!tab) tab = 0;
    setValue(tab);
  }, [tab]);

  const classes = useStyles();

  const handleChange = (_, newVal) => {
    setValue(newVal);
  };

  return (
    <div className={classes.root}>
      <ScrollToTop />

      <DisplayFlashMessages />

      {/* <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
        className={classes.tabs}
      >
        <Tab label="Regalías" />
        <Tab label="Pagos" />
      </Tabs> */}

      <TabContainer value={value} index={0}>
        {isAdmin() || isSafeAdmin() ? <ReportingDashboard /> : <Disabled />}
      </TabContainer>

      <TabContainer value={value} index={1}>
        {isAdmin() || isSafeAdmin() ? <Payments /> : <Disabled />}
      </TabContainer>
    </div>
  );
};

export default ReportingApp;
