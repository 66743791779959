import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Typography, makeStyles } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

const useStyles = makeStyles((theme) => ({
  hideOnMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const Sorting = ({ selected, onSelect, filterCount }) => {
  const [open, setOpen] = useState(false);
  const [choices, setChoices] = useState([]);
  const anchorRef = React.useRef(null);
  const classes = useStyles();

  useEffect(() => {
    const setFilterOptions = async () => {
      const _opts = [
        { key: "date", label: "por Fecha" },
        { key: "title", label: "por Título" },
        { key: "artist", label: "por Artista" },
      ];

      setChoices(_opts);
    };

    setFilterOptions();
  }, [filterCount]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef?.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleItemClick = (key) => {
    onSelect(key);
    setOpen(false);
  };

  if (!choices) {
    return <Typography>Cargando...</Typography>;
  }

  return (
    <React.Fragment>
      <Typography className={classes.hideOnMobile} variant="body2">
        Ordenar
      </Typography>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        color="primary"
        aria-label="split button"
      >
        <Button onClick={handleToggle}>
          {choices.find((x) => x.key === selected)?.label}
        </Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>

      <Popper
        sx={{
          zIndex: 2,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {choices.map((c) => {
                    const { key, label, count } = c;
                    return (
                      <MenuItem
                        key={key}
                        selected={selected === key}
                        onClick={() => handleItemClick(key)}
                      >
                        {label}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default Sorting;
