import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { functions } from "../../firebase";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import SwapHorizontalCircle from "@material-ui/icons/SwapHorizontalCircle";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import Divider from "@material-ui/core/Divider";
import PaymentStatusChip from "./PaymentStatusChip";
import localizerFactory from "../../utils/localizerFactory";
import {
  CircularProgress,
  TableContainer,
  Box,
  Slide,
} from "@material-ui/core";
import { PaymentForm } from "./payment-form";
import { addNotice, addError } from "../flash-messages";
import AppConfig from "../AppConfig";
import { values, orderBy, keys } from "lodash";
import { Link } from "@material-ui/core";
import isAdmin from "../../utils/isAdmin";
import isSafeAdmin from "../../utils/isSafeAdmin";
import AdminUserSearch from "./AdminUserSearch";

const localMoney = localizerFactory();

const MIN_PAYMENT = 10;

let isPRODUCTION;

switch (window.location.hostname) {
  case "subidalibre.musik.digital":
  case "therecordingconsort.musik.digital":
  case "musik.digital":
  case "www.musik.digital":
    isPRODUCTION = true;
    break;

  default:
    isPRODUCTION = false;
    break;
}

const PAYMENTS_LINK_PROD =
  "https://console.firebase.google.com/u/0/project/the-recording-consort/database/the-recording-consort/data/payments";
const PAYMENTS_LINK_DEV =
  "https://console.firebase.google.com/u/0/project/musik-digital/database/musik-digital/data/payments";
const PAYMENTS_LINK = isPRODUCTION ? PAYMENTS_LINK_PROD : PAYMENTS_LINK_DEV;

const reqPay = functions.httpsCallable("requestPayment");
const getUserPayments = functions.httpsCallable("getUserPayments");

const useStyles = makeStyles((theme) => ({
  root: {
    color: "blue",
    minWidth: "50vw",
    minHeight: "50vh",
  },
  highlight: {
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.9),
  },
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    gap: 0,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: theme.spacing(1),
    },
  },
  tableHeaderTitles: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(1),
    },
  },
  arrowWay: {
    position: "absolute",
    left: 0,
    top: "65%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    fontSize: theme.spacing(5),
    opacity: 0.7,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  arrow: {
    color: "rgb(204, 170, 102)",
  },
  hideOnMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

/* table head column config */
const colHeads = [
  { id: "period", numeric: false, disablePadding: true, label: "Período" },
  {
    id: "streams",
    numeric: true,
    disablePadding: true,
    label: "Reproducciones",
    hide: true,
  },
  {
    id: "downloads",
    numeric: true,
    disablePadding: true,
    label: "Descargas",
    hide: true,
  },
  { id: "total", numeric: true, disablePadding: true, label: "Regalías" },
  {
    id: "request ID",
    numeric: false,
    disablePadding: true,
    label: "Request ID",
    adminOnly: true,
  },
  {
    id: "requested at",
    numeric: false,
    disablePadding: true,
    label: "Request time",
    adminOnly: true,
  },
  { id: "status", numeric: false, disablePadding: true, label: "Estado" },
];

const AdminPayments = () => {
  const [selected, setSelected] = useState([]);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [working, setWorking] = useState(true);
  const [data, setData] = useState({});
  const [numSelected, setNumSelected] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [startSwipeArrow, setStartSwipeArrow] = useState(false);
  const [swipeDirection, setSwipeDirection] = useState("right");
  const [reloadData, setReloadData] = useState(0);
  const [adminMode, setAdminMode] = useState(true);
  const [colNames, setColnames] = useState(colHeads);
  const [query, setQuery] = useState("");

  const classes = useStyles();

  useEffect(() => {
    setAdminMode(isAdmin() || isSafeAdmin());
  }, []);

  useEffect(() => {
    if (adminMode) {
      setColnames(colHeads);
    } else {
      setColnames(colHeads.filter((h) => !h.adminOnly));
    }
  }, [adminMode]);

  useEffect(() => {
    (async () => {
      let result;

      try {
        if (adminMode) {
          result = await getUserPayments(query);
        } else {
          result = await getUserPayments();
        }
      } catch (e) {
        console.log(e);
        return;
      }
      setData(result.data);
      setWorking(false);
    })();
  }, [reloadData]);

  useEffect(() => {
    if (keys(data).length) {
      setTimeout(() => setStartSwipeArrow(true), 2000);
      setTimeout(() => {
        setSwipeDirection("left");
        setStartSwipeArrow(false);
      }, 5000);
    }
  }, [data]);

  useEffect(() => {
    setNumSelected(selected.length);
  }, [selected]);

  useEffect(() => {
    setRowCount(values(data).filter((x) => x.status === "unpaid").length);
  }, [data]);

  const requestPayment = async () => {
    setWorking(true);

    const payments = values(data).filter((x) => selected.indexOf(x.id) > -1);
    const result = await reqPay(payments);

    if (result.data === "ok") {
      setSelected([]);
      setSelectedAmount(0);
      setWorking(false);
      setReloadData(reloadData + 1);

      addNotice({
        text: "Pago solicitado correctamente.",
      });
    } else {
      addError({
        text: "Error en la solicitud, por favor ponte en contacto con nosotros.",
      });
    }

    setWorking(false);
  };

  const handleSelectAllClick = (event) => {
    const unpaid = values(data).filter((x) => x.status === "unpaid");

    if (event.target.checked) {
      setSelected(unpaid.map((n) => n.id));
      setSelectedAmount(unpaid.reduce((t, n) => t + n.total_raw, 0));
    } else {
      setSelected([]);
      setSelectedAmount(0);
    }
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    const newSelectedAmount = values(data)
      .filter((x) => newSelected.indexOf(x.id) > -1)
      .reduce((t, x) => t + x.total_raw, 0);

    setSelected(newSelected);
    setSelectedAmount(newSelectedAmount);
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSearch = () => {
    setReloadData(reloadData + 1);
  };

  const clearSearch = () => {
    setQuery("");
    setData({});
  };

  return (
    <Paper className={classes.root}>
      <Box className={classes.arrowWay}>
        <Slide
          direction={swipeDirection}
          in={startSwipeArrow}
          mountOnEnter
          unmountOnExit
        >
          <SwapHorizontalCircle fontSize="inherit" className={classes.arrow} />
        </Slide>
      </Box>

      <Toolbar
        variant="regular"
        // className={classNames(classes.root, {
        //   [classes.highlight]: selected.length > 0,
        // })}
      >
        <div className={classes.tableHeader}>
          {adminMode ? (
            <div className={classes.tableHeaderTitles}>
              <AdminUserSearch
                handleChange={handleChange}
                handleSearch={handleSearch}
                clearSearch={clearSearch}
                q={query}
              />
            </div>
          ) : (
            <div className={classes.tableHeaderTitles}>
              <Typography variant="h6" id="tableTitle" color="textPrimary">
                Resumen mensual de ingresos
              </Typography>

              <Typography variant="subtitle1" color="textPrimary">
                Selecciona los períodos que quieres incluir en el pago.
              </Typography>
            </div>
          )}
          <Tooltip
            title={`El pago mínimo es $${MIN_PAYMENT}`}
            placement="top-start"
            enterDelay={100}
          >
            <div>
              {working ? (
                <CircularProgress variant="indeterminate" />
              ) : (
                <AppConfig>
                  <PaymentForm
                    requestPayment={requestPayment}
                    working={working}
                    numSelected={selected.length}
                    selectedAmount={selectedAmount}
                    MIN_PAYMENT={MIN_PAYMENT}
                    localMoney={localMoney}
                    aria-label="Payment"
                  ></PaymentForm>
                </AppConfig>
              )}
            </div>
          </Tooltip>
        </div>
      </Toolbar>

      <Divider />

      <TableContainer className={classes.tableWrapper}>
        <Table
          stickyHeader
          className={classes.table}
          aria-labelledby="tableTitle"
        >
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={numSelected > 0 && numSelected === rowCount}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              {colNames.map((row) => (
                <TableCell
                  key={row.id}
                  align="left"
                  padding="normal"
                  className={row.hide ? classes.hideOnMobile : ""}
                >
                  {row.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {orderBy(values(data), ["sortByMe"], ["desc"])
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((n) => {
                const isThisSelected = isSelected(n.id);
                const isPayable = n.status === "unpaid";

                return (
                  <TableRow
                    hover={isPayable}
                    onClick={
                      isPayable ? (event) => handleClick(event, n.id) : null
                    }
                    role="checkbox"
                    aria-checked={isThisSelected}
                    tabIndex={-1}
                    key={n.id}
                    selected={isThisSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isThisSelected}
                        disabled={!isPayable}
                      />
                    </TableCell>
                    <TableCell padding="normal" align="left">
                      <Typography noWrap>{n.date}</Typography>
                    </TableCell>
                    <TableCell
                      padding="normal"
                      className={classes.hideOnMobile}
                    >
                      {isNaN(n.streams) ? 0 : n.streams}
                    </TableCell>
                    <TableCell
                      padding="normal"
                      align="left"
                      className={classes.hideOnMobile}
                    >
                      {isNaN(n.downloads) ? 0 : n.downloads}
                    </TableCell>
                    {adminMode ? (
                      <>
                        <TableCell padding="normal" align="left">
                          <Link
                            href={`${PAYMENTS_LINK}/${n.request_id}`}
                            target="_blank"
                            rel="noopener"
                          >
                            {n.request_id || "-"}
                          </Link>
                        </TableCell>
                        <TableCell padding="normal" align="left">
                          {n.requested_at}
                        </TableCell>
                      </>
                    ) : null}
                    <TableCell padding="normal" align="left">
                      {n.total_usd}
                    </TableCell>
                    <TableCell padding="normal" align="left">
                      <PaymentStatusChip statusKey={n.status} />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[15]}
        component="div"
        count={values(data).length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          "aria-label": "Previous Page",
        }}
        nextIconButtonProps={{
          "aria-label": "Next Page",
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default AdminPayments;
