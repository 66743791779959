// @ts-check
import produce from "immer";

/** @typedef {import('../../store').AlbumState} AlbumState */

const TYPE = "OPEN_ALBUM_TRACK";

/**
 * @param {string} isrc
 * @return {Object}
 */
export const trackOpenAction = (isrc) => {
  return {
    type: TYPE,
    isrc,
  };
};

/**
 * @param {AlbumState} state
 * @param {object} action
 * @return {object}
 */
export const trackOpenReducer = (state, action) => {
  return produce(state, (draft) => {
    draft.openTrack = action.isrc;
  });
};

export default {
  action: trackOpenAction,
  reducer: trackOpenReducer,
  TYPE,
};
