import React, { Fragment, Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { compose } from "recompose";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import ButtonBase from "@material-ui/core/ButtonBase";
import Money from "../Money";
import { Doughnut } from "react-chartjs-2";
import grey from "@material-ui/core/colors/grey";
import { truncate, slice } from "lodash";

const DEFAULT_ROWS_ALWAYS_VISIBLE = 5;

const Row = ({ name, i, label, amount, isrc, width }) => {
  return (
    <TableRow key={`${name}${i}`}>
      <TableCell
        component="th"
        padding={width === "xs" ? "dense" : "normal"}
        scope="row"
      >
        {label}
      </TableCell>
      <TableCell align="right">
        <Money amount={amount} />
      </TableCell>
    </TableRow>
  );
};

const Rows = ({ data, topOnly }) => {
  return data
    .filter((row, i) => {
      if (topOnly) {
        return i < DEFAULT_ROWS_ALWAYS_VISIBLE;
      } else {
        return i >= DEFAULT_ROWS_ALWAYS_VISIBLE;
      }
    })
    .map((row, i) => <Row key={i} {...row} />);
};

const Chart = ({ data, showTop, width }) => {
  const getColor = (i) => {
    const colors = [
      "rgba(242, 95, 92, 1)",
      "rgba(255, 224, 102, 1)",
      "rgba(36, 123, 160, 1)",
      "#c152a3",
      "rgba(112, 193, 179, 1)",
      "#3f6900",
      "#8b5db9",
      "rgba(80, 81, 79, 1)",
    ];

    return colors[i];
  };

  const topRows = slice(data, 0, showTop);

  const dataset = {
    data: topRows.map((row) => row.amount),
    backgroundColor: data.map((r, i) => getColor(i)),
  };

  const chart_data = {
    labels: topRows.map((row) => truncate(row.label, { length: 25 })),
    datasets: [dataset],
  };

  const options = {
    legend: {
      display: width === "xs" ? false : true,
      position: "right",
    },
  };

  return <Doughnut data={chart_data} options={options} />;
};

const styles = (theme) => ({
  title: {
    color: grey[900],
    paddingBottom: 0,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  chartWrap: {
    padding: theme.spacing(2),
  },
  table: {
    width: "100%",
  },
  openButton: {
    width: "100%",
  },
  openButtonInner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loading: {
    backgroundColor: grey[100],
    padding: theme.spacing(5),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

class ReportMini extends Component {
  state = {
    open: false,
  };

  toggleOpenHandler = () => {
    this.setState((prev) => ({ open: !prev.open }));
  };

  render() {
    const { classes, title, data, width } = this.props;
    const { open } = this.state;

    if (!data) {
      return (
        <Paper elevation={0} className={classes.loading}>
          No hay datos
        </Paper>
      );
    }

    const chartData = slice(data, 0, 5);
    const tableData = data;

    return (
      <Paper className={classes.paper}>
        <div className={classes.chartWrap}>
          {<Chart showTop={5} data={chartData} width={width} />}
        </div>

        <Typography variant="h6" gutterBottom className={classes.title}>
          {title}
        </Typography>

        <Table className={classes.table}>
          <TableBody>
            <Rows topOnly={true} data={tableData} />
          </TableBody>
        </Table>

        <Collapse hidden={!open} in={open}>
          <Table className={classes.table}>
            <TableBody>
              <Rows topOnly={false} data={tableData} />
            </TableBody>
          </Table>
        </Collapse>

        {tableData.length > DEFAULT_ROWS_ALWAYS_VISIBLE && (
          <ButtonBase
            focusRipple
            className={classes.openButton}
            onClick={this.toggleOpenHandler}
          >
            <Table className={classes.table}>
              <TableBody>
                <TableRow hover>
                  <TableCell component="th" scope="row" align="center">
                    <Typography
                      variant="subtitle2"
                      component="div"
                      className={classes.openButtonInner}
                    >
                      <Fragment>
                        {open || (
                          <span>
                            {tableData.length - DEFAULT_ROWS_ALWAYS_VISIBLE} más
                          </span>
                        )}
                        <Icon>{open ? "expand_less" : "expand_more"}</Icon>
                      </Fragment>
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </ButtonBase>
        )}
      </Paper>
    );
  }
}

export default compose(withWidth(), withStyles(styles))(ReportMini);
