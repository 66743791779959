import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  DialogActions,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "600px",
  },
  title: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  [theme.breakpoints.down("sm")]: {
    root: {
      width: "100vw",
    },
  },
}));

export default function HelpDialog({ open, handleClose }) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle className={classes.title}>
        <Typography variant="h5">¿Qué es esto?</Typography>
      </DialogTitle>
      <DialogContent className={classes.root}>
        Estos indicadores comparan el desempeño de tu música en el último
        período con tu mejor período pasado.
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>cerrar</Button>
      </DialogActions>
    </Dialog>
  );
}
