// @ts-check
/** @typedef {import('../wizardStore').WizardNewAlbumState} WizardNewAlbumState */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */

const TYPE = "WIZARD_SET_INIT_NEW_ALBUM";

/**
 * @param {string} uploadingKey
 * @param {string} account
 * @return {object}
 */
export const setInitNewAlbumAction = (uploadingKey, account) => {
  return {
    type: TYPE,
    payload: {
      uploadingKey,
      account
    }
  };
};

/**
 * @param {WizardNewAlbumState} state
 * @param {object} action
 * @return {object}
 */
export const setInitNewAlbumReducer = (state, action) => {
  const newState = {
    ...state, 
    ...action.payload
  }
  return newState;
};

export default {
  action: setInitNewAlbumAction,
  reducer: setInitNewAlbumReducer,
  TYPE,
};
