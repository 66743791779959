import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const LIGHT_SIZE = "30px";
const BOX_SIZE = "40px";
const OFF = 0.15;

const useStyles = makeStyles((theme) => ({
  box: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    minWidth: BOX_SIZE,
    padding: "3px",
    gap: "5px",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: "#333",
  },
  bulb: {
    width: LIGHT_SIZE,
    height: LIGHT_SIZE,
    borderRadius: "50%",
    margin: "auto",
  },
}));

const Semaforo = ({ color }) => {
  const classes = useStyles();

  return (
    <div className={classes.box}>
      <div
        className={classes.bulb}
        style={{
          backgroundColor: "#ff0000",
          opacity: color === "red" ? 1 : OFF,
        }}
      ></div>
      <div
        className={classes.bulb}
        style={{
          backgroundColor: "yellow",
          opacity: color === "yellow" ? 1 : OFF,
        }}
      ></div>
      <div
        className={classes.bulb}
        style={{
          backgroundColor: "#00ff00",
          opacity: color === "green" ? 1 : OFF,
        }}
      ></div>
    </div>
  );
};

export default Semaforo;
