import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { firebase } from "../firebase";
import parse from "html-react-parser";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme) => ({
  formPaper: {
    maxWidth: 1100,
    minHeight: "100vh",
    margin: "10vh auto",
    padding: theme.spacing(5),
  },
});

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      body: "",
    };
  }

  componentDidMount = async () => {
    const { slug, account = "" } = this.props;
    const domain = account.replace(".firebaseapp.com", "").replace(/\./g, "-");

    // possible keys
    const keyDefault = slug;
    const keyDomainSpecific = [slug, domain].join("-");

    // try to get domain specific content
    const htmlDomainRef = firebase.db.ref(`pages/${keyDomainSpecific}`);
    const snapshot = await htmlDomainRef.once("value");

    // save to state
    if (snapshot.exists()) {
      this.setState({
        ...snapshot.val(),
      });
    } else {
      // if none, use default content for page
      const htmlDefaultRef = firebase.db.ref(`pages/${keyDefault}`);
      const snapDefault = await htmlDefaultRef.once("value");

      this.setState({
        ...snapDefault.val(),
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { title, body } = this.state;

    if (!title || !body) {
      return (
        <CircularProgress variant="indeterminate" className={classes.loading} />
      );
    }

    return (
      <Paper elevation={1} className={classes.formPaper}>
        {parse(title)}

        <div className={classes.body}>{parse(body)}</div>
      </Paper>
    );
  }
}

export default withStyles(styles)(Page);
