// @ts-check
import produce from "immer";
import { trim } from "lodash";

const _shape = (cnf = {}) => {
  const genres = [];
  cnf.genres.forEach((g) => {
    const [gen, subgen] = g.label.split("|");
    if (!genres[gen]) {
      genres[gen] = [];
    }
    genres[gen].push(trim(subgen));
  });

  // roles
  const newConfig = Object.assign(cnf, {
    genres,
    roles: cnf.roles.filter((x) => !!x),
    subgenres: cnf.genres?.map((g) => {
      let [gen, subgen] = g.label.split("|");

      gen = trim(gen);
      subgen = trim(subgen);

      return {
        subgenre: subgen,
        genre: gen,
      };
    }),
  });

  return newConfig;
};

const TYPE = "CONFIG_SET";

/**
 *
 * @param {Object} config
 * @return {Object}
 */
export const setConfigAction = (config) => {
  return {
    type: "CONFIG_SET",
    config,
  };
};

export const setConfigReducer = (state, action) => {
  const newConfig = _shape(action.config);
  return produce(state, (draft) => {
    draft.config = newConfig;
    draft.loaded = true;
  });
};

export default {
  action: setConfigAction,
  reducer: setConfigReducer,
  TYPE,
};
