// @ts-check
import produce from "immer";

/** @typedef {import('../wizardStore').WizardNewAlbumState} WizardNewAlbumState */
/** @typedef {import('../wizardStore').NewAlbumTrack} NewAlbumTrack */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */

const TYPE = "WIZARD_TRACK_UPLOAD_DONE";

/**
 * @param {NewAlbumTrack} track
 * @param {string} storageLocation
 * @return {Object}
 */
export const trackUploadDoneAction = (track, storageLocation) => {
  return {
    type: TYPE,
    track,
    storageLocation,
  };
};

/**
 * @param {WizardNewAlbumState} state
 * @param {{track: NewAlbumTrack, storageLocation: string}} action
 * @return {object}
 */
export const trackUploadDoneReducer = (state, action) => {
  const { track, storageLocation } = action;

  return produce(state, (draft) => {
    const index = draft.tracks.findIndex((t) => t.id === track.id);
    if (index !== -1) {
      draft.tracks[index].progress = 100;
      draft.tracks[index].uploading = false;
      draft.tracks[index].busy = false;
      draft.tracks[index].storageLocation = storageLocation;
    }
  });
};

export default {
  action: trackUploadDoneAction,
  reducer: trackUploadDoneReducer,
  TYPE,
};
