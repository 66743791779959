import React, { useEffect, useState } from "react";
import { Paper, Divider, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Donut from "./DonutChart";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import MainBoard from "./LatestPeriodMainBoard";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1),
    borderTopLeftRadius: theme.spacing(20),
    borderTopRightRadius: theme.spacing(20),
    borderBottomLeftRadius: theme.spacing(20),
    borderBottomRightRadius: theme.spacing(20),
    backgroundColor: "rgba(204, 210, 102, 0.1)",
    border: "9px solid rgba(204, 170, 102, 0.1)",
    [theme.breakpoints.down("md")]: {
      borderRadius: theme.spacing(0),
    },
  },

  rootExpanded: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0),
    paddingBottom: theme.spacing(15),
    borderRadius: theme.spacing(10),
    backgroundColor: "rgba(204, 210, 102, 0.1)",
    border: "9px solid rgba(204, 170, 102, 0.1)",
    [theme.breakpoints.down("md")]: {
      borderRadius: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    },
  },
  bigmoney: {
    fontWeight: "bold",
    color: "green",
  },
  topPanel: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: theme.spacing(1),
    width: "100%",
  },

  minitotal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(1),
  },

  subtitle: {
    maxWidth: "300px",
    borderTop: "1px solid #ccc",
    paddingTop: theme.spacing(1),
    marginTop: theme.spacing(1),
    textAlign: "center",
  },
  exploreButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(-2),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
    },
  },
  button: {
    marginBottom: theme.spacing(2),
  },
}));

const LatestPeriod = ({ data }) => {
  const [streams, setStreams] = useState({});
  const [rrss, setRrss] = useState({});
  const [other, setOther] = useState({});
  const [fraud, setFraud] = useState(0);
  const [total, setTotal] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [metrics, setMetrics] = useState({});
  const [messages, setMessages] = useState({});

  useEffect(() => {
    if (data) {
      setStreams(data.streams);
      setRrss(data.rrss);
      setOther(data.other);
      setFraud(data.fraudulent);
      setTotal(data.royalties);
      setMetrics(data.metrics);
      setMessages(data.messages);
    }
  }, [data]);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  const classes = useStyles();

  return (
    <>
      <Paper className={expanded ? classes.rootExpanded : classes.root}>
        <MainBoard
          streams={streams}
          rrss={rrss}
          total={total}
          fraud={fraud}
          other={other}
          messages={messages}
        />

        <div className={classes.exploreButtonContainer}>
          <Button
            onClick={handleClick}
            color="primary"
            variant="contained"
            className={classes.button}
            startIcon={expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          >
            {expanded ? "Cerrar" : "Explorar"}
          </Button>
        </div>

        {expanded && (
          <div className={classes.metrics}>
            <Divider />
            <Donut data={metrics?.PLATAFORMA} title="Plataformas" />
            <Divider />
            <Donut data={metrics?.ARTISTA} title="Artistas" />
            <Divider />
            <Donut data={metrics?.TRACK} title="Tracks" />
            <Divider />
            <Donut data={metrics?.ALBUM} title="Albums" />
          </div>
        )}
      </Paper>
    </>
  );
};

export default LatestPeriod;
