import React from "react";
import { Grid } from "@material-ui/core";
import { isPrimaryArtist } from "../../../../../business-objects";
import useAlbumOpened from "../../../../../reducers/album/hooks/useAlbumOpened";
import EditableField from "../../../components/EditableField";

const ArtistsFields = () => {
  const album = useAlbumOpened();

  if (!album || !album.artists) {
    return <></>;
  }

  const artistsKeys = Object.keys(album.artists);

  return (
    <Grid container spacing={3}>
      {artistsKeys?.map((key, index) => {
        const artist = album.artists[key];

        return (
          <Grid key={key} item xs={12} sm={12} md={6}>
            <EditableField
              label={artist.role}
              name={`artists/${key}`}
              type="artist"
              level="album"
              canDelete={!isPrimaryArtist(artist)}
              required
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ArtistsFields;
