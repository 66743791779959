import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { firebase, functions, fbroot } from "../firebase";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import withAuthorization from "./withAuthorization";
import { RecaptchaVerifier, PhoneAuthProvider } from "firebase/auth";
import Snackbar from "@material-ui/core/Snackbar";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import phone from "phone";
import translateError from "../utils/translateError";
import PrefixSelect from "./PhonePrefixSelect";
import AppConfig from "./AppConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    left: 0,
    top: 0,
    zIndex: 1101,
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.primary.main,
    overflow: "hidden",
  },
  verifyCard: {
    minWidth: 275,
    maxWidth: "95vw",
    margin: "auto",
    padding: theme.spacing(1),
    "& strong": {
      color: theme.palette.secondary.main,
      display: "block",
    },
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  prefixedPhone: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  selectEmpty: {
    minWidth: 150,
  },
  prefix: {
    marginRight: theme.spacing(3),
  },
  prefixLabel: {
    marginBottom: theme.spacing(2),
  },
}));

// cloud function to link accounts
const linkAccountsByPhone = functions.httpsCallable("linkAccountByPhone");

const PhoneVerification = () => {
  const [snack, setSnack] = useState({});
  const [mobileWithPrefix, setMobileWithPrefix] = useState("");
  const [countryISOCode, setCountryISOCode] = useState("");
  const [phonePrefix, setPhonePrefix] = useState("");
  const [phoneNumber, _setPhoneNumber] = useState("");
  const [formNotValid, setFormNotValid] = useState(true);
  const [needVerify, setNeedVerify] = useState(false);
  const [working, setWorking] = useState(false);

  const classes = useStyles();

  /**
   * Calculate if user needs to verify phone or not
   */
  useEffect(() => {
    const isNeedVerify = async () => {
      const userHasPhoneProvider = firebase.auth.currentUser.providerData?.find((p) => p.providerId === "phone");

      // If user DOES NOT have phone provider...
      if (!userHasPhoneProvider) {
        const { uid } = firebase.auth.currentUser;
        const snap = await fbroot.database().ref("users").child(uid).child("parentAccount").once("value");
        const isGrouped = snap.exists();
        const groupParentId = snap.val();
        const isGroupParent = uid === groupParentId;

        // ...AND...
        isGrouped && isGroupParent && setNeedVerify(true); // is grouped (linked to other accounts) AND is the parent of the group...
        !isGrouped && setNeedVerify(true); // is grouped (linked to other accounts) AND is the parent of the group...
      }
    };

    isNeedVerify();
  }, []);

  /**
   * Try to pre-populate phone number
   */
  useEffect(() => {
    const fetchPhone = async () => {
      if (firebase.auth.currentUser !== null) {
        const { uid } = firebase.auth.currentUser;
        const [phoneSnap, countryISOCodeSnap] = await Promise.all([
          firebase.db.ref("users").child(uid).child("phone").once("value"),
          firebase.db.ref("users").child(uid).child("countryISOCode").once("value"),
        ]);
        const phone = phoneSnap.val();
        const countryISOCode = countryISOCodeSnap.val();

        if (countryISOCode) setCountryISOCode(countryISOCode);

        if (!phone) return;

        if (phone.includes("+") && phone.includes(" ")) {
          const [prefix, num] = phone.split(" ");
          prefix && setPhonePrefix(prefix);
          num && setPhoneNumber(num);
        } else {
          setPhoneNumber(phone);
        }
      }
    };

    setTimeout(fetchPhone, 1000);
  }, []);

  /**
   * Set phone number
   */
  useEffect(() => {
    setMobileWithPrefix(`${phonePrefix} ${phoneNumber}`);
  }, [phoneNumber, phonePrefix]);

  /**
   * Phone format validation
   */
  useEffect(() => {
    const validateMobileFormat = () => {
      const result = phone(mobileWithPrefix, { country: countryISOCode, validateMobilePrefix: false });
      return result.isValid;
    };

    setFormNotValid(!validateMobileFormat());
  }, [mobileWithPrefix, countryISOCode]);

  /**
   * Captcha preparation
   */
  useEffect(() => {
    const createRecaptcha = () => {
      // Create a Recaptcha verifier instance globally
      window.recaptcha = new RecaptchaVerifier(
        "musik-digital-signin",
        {
          size: "invisible",
          callback: (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // console.log(response);
          },
        },
        firebase.auth
      );

      // setRecaptchaVerifier(recaptcha);
    };
    if (needVerify) {
      setTimeout(createRecaptcha, 300);
    }
  }, [needVerify]);

  // --------------------------------------- API ------------------------

  /**
   * Set phoneNumber (removing leading zeroes)
   */
  const setPhoneNumber = (num) => _setPhoneNumber(num.replace(/^\s*0+/, ""));

  /**
   * Show snack on errors
   */
  const errorSnack = ({ message }) => setSnack({ open: true, message });

  /**
   * Close snack
   */
  const handleCloseSnack = () => {
    setSnack({ open: false });
  };

  /**
   * Take the value from the 'phoneNumber' input and sends SMS to that phone number
   */
  const submitPhoneNumberAuth = async () => {
    setWorking(true);
    try {
      const provider = new PhoneAuthProvider(firebase.auth);
      const verificationId = await provider.verifyPhoneNumber(mobileWithPrefix, window.recaptcha);

      // prompt user for SMS code
      const verificationCode = window.prompt("Escribe el código que te hemos enviado.");

      // create phoneCredential and attach it to the users' account
      const phoneCredential = PhoneAuthProvider.credential(verificationId, verificationCode);
      await firebase.auth.currentUser.linkWithCredential(phoneCredential);

      // save user's phone number
      await firebase.db.ref("users").child(firebase.auth.currentUser.uid).child("phone").set(mobileWithPrefix);

      setNeedVerify(false);
    } catch (_error) {
      const error = translateError(_error);

      if (error.code !== "auth/account-exists-with-different-credential") {
        errorSnack(error);
      } else {
        const linkAccounts = window.confirm(
          `El número ${mobileWithPrefix} está asociado a otra cuenta. 
          \n¿Quieres enlazar tus cuentas?`
        );
        if (linkAccounts) {
          const result = await linkAccountsByPhone(mobileWithPrefix);

          if (result.data === "ok") {
            setNeedVerify(false);
          }
        }
      }
    } finally {
      setWorking(false);
    }
  };

  const hanldePhoneChange = (event, child) => {
    setPhonePrefix(child.props.prefix);
    setCountryISOCode(child.props.code);
  };

  //////////////////////////////////////////////////////////////////////////////////////// RENDER

  if (!needVerify) return null;

  return (
    <AppConfig>
      <div className={classes.root}>
        <Card className={classes.verifyCard}>
          <CardContent>
            <Typography variant="h5" paragraph>
              Verificación SMS
            </Typography>

            <Typography variant="body2" paragraph>
              Recibirás un código en tu celular para completar la verificación.
            </Typography>
            <div id="musik-digital-signin"></div>

            <PrefixSelect
              onChangePrefix={hanldePhoneChange}
              onChangePhoneNumber={(event) => setPhoneNumber(event.target.value)}
              phonePrefix={phonePrefix}
              phoneNumber={phoneNumber}
              countryISOCode={countryISOCode}
            />
          </CardContent>
          <CardActions className={classes.actions}>
            <Button color="primary" variant="contained" disabled={formNotValid || working} onClick={submitPhoneNumberAuth}>
              Envíar SMS a {phonePrefix} {phoneNumber}
            </Button>
            {working && <CircularProgress variant="indeterminate" size="2em" />}
          </CardActions>
        </Card>

        <Snackbar
          {...snack}
          onClose={handleCloseSnack}
          ContentProps={{ "aria-describedby": "message-id" }}
          message={<span id="message-id">{snack.message}</span>}
        />
      </div>
    </AppConfig>
  );
};

const authCondition = (authUser) => !!authUser;
export default withAuthorization(authCondition)(PhoneVerification);
