//@ts-check

/**
 * Determine if the version field of a track can be editable.
 *
 * The restriction is when the album is not live and it has only a track.
 * In this case the track.version must be equal than album.version
 * => so the track.version could not be editable by the user
 *
 * @param {import("./album").Track} track
 * @param {import("./album").Album} album
 * @return {boolean}
 */
const canEditTrackVersion = (track, album) => {
  if (!track || !album) {
    return false;
  }

  const isSingle = Object.keys(album?.tracks || []).length === 1;

  const canEdit = !isSingle || album.live;
  return canEdit;
};

export default canEditTrackVersion;
