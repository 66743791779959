// @ts-check
import { getCurrentUser } from "../../../firebase/auth";
import newAlbumService from "../../../services/newAlbumService";
import { setSavedAlbumAction } from "../actions/setSavedAlbum";
import { setSubmitingAction } from "../actions/setSubmiting";
import { prepareAlbumToSubmit } from "../wizardUtils";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/user").User} User */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../wizardStore").WizardNewAlbumState} WizardNewAlbumState */
/** @typedef {import("../wizardStore").NewAlbumPayload} NewAlbumPayload*/

/**
 * Busca y retorna la config del sistema
 *
 * @return {Function}
 */
export function submitNewAlbum() {
  /**
   * @param {function} dispatch
   * @param {StateGetter} getState
   */
  return async function thunk(dispatch, getState) {
    /** @type {StateStore} */
    const state = getState();

    dispatch(setSubmitingAction(true));

    /** @type {User} */
    const user = getCurrentUser(); // TODO: mover esto de firebase a store de redux

    try {
      const payload = prepareAlbumToSubmit(state.wizardNewAlbumState, user);
      const album = await newAlbumService.submitNewAlbum(
        payload,
        state.wizardNewAlbumState.uploadingKey
      );
      dispatch(setSavedAlbumAction(album));
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      dispatch(setSubmitingAction(false));
    }
  };
}

export default submitNewAlbum;
