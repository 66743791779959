// @ts-check
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { addTrackArtist } from "../../../../../reducers/album/actionsAsync/addTrackArtist";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    gap: theme.spacing(2),
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "100%",
  },
}));

const ArtistsButtons = () => {
  /** @type {Array<import("../../../../../business-objects/album").ArtistRole>} */
  const roles = useSelector((state) => state.albumState.rolesArtistTrack);
  /** @type {string} the upc */
  const openAlbum = useSelector((state) => state.albumState.openAlbum);
  /** @type {string} the isrc */
  const openTrack = useSelector((state) => state.albumState.openTrack);
  const dispatch = useDispatch();

  const onAdd = (role) => {
    dispatch(addTrackArtist(openAlbum, openTrack, role));
  };

  const classes = useStyles();

  return (
    // <Grid container item xs={12} spacing={3}>
    <Box className={classes.root}>
      {roles?.map((role) => (
        <Button
          key={role.value}
          startIcon={<AddIcon />}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => onAdd(role)}
        >
          {role.label}
        </Button>
      ))}
    </Box>
  );
};

export default ArtistsButtons;
