import { Chart } from "react-google-charts";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonBase } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/HelpOutline";
import { useState } from "react";
import HelpDialog from "./HelpGaugeDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },
  money: {
    backgroundColor: "darkgreen",
    color: "white",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: "3px",
    marginTop: theme.spacing(-2),
    zIndex: 1,
  },
  helpIcon: {
    position: "absolute",
    top: 0,
    zIndex: 2,
  },
}));

const Relojito = ({ data, label, big, infoLeft }) => {
  const [openHelp, setOpenHelp] = useState(false);

  const classes = useStyles();

  if (!data) {
    return null;
  }

  const { best, latest } = data;

  if (!best || !latest) {
    return null;
  }

  const options = {
    width: big ? 175 : 140,
    height: big ? 175 : 140,
    max: best.count,
    redFrom: Math.round(best.count * 0.9),
    redTo: best.count,
    yellowFrom: Math.round(best.count * 0.75),
    yellowTo: Math.round(best.count * 0.9),
    minorTicks: 5,
  };

  const handleClick = () => {
    setOpenHelp(true);
  };

  const handleClose = () => {
    setOpenHelp(false);
  };

  return (
    <div className={classes.root}>
      <Chart
        chartType="Gauge"
        options={options}
        data={[
          ["Label", "Value"],
          [label, latest.count],
        ]}
      />
      {/* <Typography variant="body2" className={classes.money}>
      {localMoney.format(latest.total)}
    </Typography> */}
      <ButtonBase
        onClick={handleClick}
        className={classes.helpIcon}
        style={infoLeft ? { left: 0 } : { right: 0 }}
      >
        <InfoIcon fontSize="small" />
      </ButtonBase>
      <HelpDialog open={openHelp} handleClose={handleClose} />
    </div>
  );
};

export default Relojito;
