// @ts-check
import cartService from "../../../services/cartService";
import { getAlbum } from "../../albumActions";
import { setSaving } from "../cartActions";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album").ALBUM_STATUSES} ALBUM_STATUSES */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldTypes} FieldTypes */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */

/**
 * Publica los cambios de un carrito album
 *
 * @param {string} upc;
 * @return {Function}
 */
export function publishCart(upc) {
  /**
   * @param {function} dispatch
   * @param {StateGetter} getState
   */
  return async function thunk(dispatch, getState) {
    /** @type {StateStore} */
    const state = getState();

    /** @type {Album} */
    const album = await dispatch(getAlbum(upc));

    if (album?.status !== "live_updating") {
      return;
    }

    let result;
    dispatch(setSaving(true));
    try {
      const totalCostCredit = state.cartState.totalCostCredit;

      result = await cartService.publish({ upc, totalCostCredit });
    } catch (e) {
      dispatch(setSaving(false));
      throw e;
    }

    dispatch(setSaving(false));
    return result;
  };
}

export default publishCart;
