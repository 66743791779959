// @ts-check
import { useSelector } from "react-redux";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../store").StateStore} StateStore */

/**
 * @param {StateStore} state
 * @return {Album}
 */
export const selectAlbumOpened = (state) => {
  return state.albumState.albums[state.albumState.openAlbum];
};

/**
 * @return {Album}
 */
export const useAlbumOpened = () => {
  const album = useSelector(selectAlbumOpened);

  return album;
};

export default useAlbumOpened;
