// @ts-check
import produce from "immer";

/** @typedef {import('../wizardStore').WizardNewAlbumState} WizardNewAlbumState */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */

const TYPE = "WIZARD_SET_UPLODING_KEY";

/**
 * @param {string} uploadingKey
 * @return {Object}
 */
export const setUploadKeyAction = (uploadingKey) => {
  return {
    type: TYPE,
    uploadingKey,
  };
};

/**
 * @param {WizardNewAlbumState} state
 * @param {object} action
 * @return {object}
 */
export const setUploadKeyReducer = (state, action) => {
  // console.log("setUploadKeyReducer", state, action);
  return produce(state, (draft) => {
    draft.uploadingKey = action.uploadingKey;
  });
};

export default {
  action: setUploadKeyAction,
  reducer: setUploadKeyReducer,
  TYPE,
};
