// @ts-check
import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

/** @typedef {import('react').ReactElement} ReactElement*/
/** @typedef {import("../../../../../../business-objects/cart").CartItem} CartItem*/
/** @typedef {import("../../../../../../business-objects/album.fieldEditable").FieldActionType} FieldActionType */

/**
 * @param {string} path
 * @return {boolean}
 */
const _isCover = (path) => {
  return !!path && path.indexOf("photos/cover") > -1;
};

/**
 * @param {string} path
 * @return {boolean}
 */
const _isGenre = (path) => {
  return path.indexOf("genre") > -1;
};

const useStyle = makeStyles((theme) => ({
  cartItemValue: {
    display: "block",
    "&.--new": {
      color: theme.palette.success.dark,
    },
    "&.--unedited": {
      color: theme.palette.error.dark,
      textDecoration: "line-through",
    },
  },
}));

/**
 *
 * @param {{change: CartItem, variant: ("new" | "unedited")}} param0
 * @return {ReactElement}
 */
export const ChangeNewValueRender = ({ change, variant }) => {
  const classes = useStyle();
  if (!change) {
    return <></>;
  }
  const isNewVariant = variant === "new";
  const value = isNewVariant ? change.newValue : change.uneditedValue;

  if (!value) {
    return <></>;
  }

  if (_isCover(change.path)) {
    return (
      <a
        className={classnames(classes.cartItemValue, {
          "--new": isNewVariant,
          "--unedited": !isNewVariant,
        })}
        href={value}
        target="_blank"
        rel="noopener noreferrer"
      >
        ver
      </a>
    );
  }

  let valueString = "";
  const type = typeof value;

  if (_isGenre(change.path) && value.genre) {
    valueString = `${value.genre}/${value.subgenre}`;
  } else if (type === "boolean") {
    valueString = value ? "si" : "no";
  } else {
    valueString = value.toString();
  }

  return (
    <Typography
      className={classnames(classes.cartItemValue, {
        "--new": isNewVariant,
        "--unedited": !isNewVariant,
      })}
      variant="caption"
    >
      {valueString}
    </Typography>
  );
};

export default ChangeNewValueRender;
