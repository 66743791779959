import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  Divider,
} from "@material-ui/core";
import { keys, uniq } from "lodash";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    // color: grey[400],
    fontSize: "1em",
  },
  dialog: {
    textDecoration: "line-through",
  },
}));

const IndicatorButton = ({ lists, children, disabled }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const classes = useStyles();

  return (
    <>
      <IconButton
        aria-label="open-list"
        onClick={() => setOpen(true)}
        disabled={disabled}
        className={classes.root}
      >
        <Box style={{ width: "1.5em", height: "1.5em" }}>{children}</Box>
      </IconButton>

      {open && (
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="md"
        >
          <DialogContent>
            <Box display="flex" justifyContent="space-between">
              {keys(lists).map((type) => {
                const list = lists[type];

                return (
                  <Box key={type} style={{ margin: 30 }}>
                    <Typography gutterBottom variant="h6">
                      {type.toUpperCase()}
                    </Typography>
                    <List dense>
                      {uniq(list).map((item) => (
                        <ListItem key={item} style={{ paddingLeft: 0 }}>
                          <ListItemText primary={item} />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                );
              })}
            </Box>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default IndicatorButton;
