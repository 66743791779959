// @ts-check
import React from "react";
import { Grid } from "@material-ui/core";

import useTrackOpened from "../../../../../reducers/album/hooks/useTrackOpened";
import EditableField from "../../../components/EditableField";

const FormTrackForm = () => {
  const track = useTrackOpened();

  if (!track) {
    return <></>;
  }

  const keys = track.artists ? Object.keys(track.artists) : [];

  return (
    <Grid container item xs={12} spacing={3}>
      {keys?.map((key) => {
        const artist = track.artists[key];

        return (
          <Grid item xs={12} sm={6} md={6} key={key}>
            <EditableField
              label={artist.role}
              name={`artists/${key}`}
              type="artist"
              level="track"
              required
              canDelete
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default FormTrackForm;
