// @ts-check
import produce from "immer";

/** @typedef {import('../../store').AlbumState} AlbumState */

const TYPE = "SET_ALBUM_ERROR";

/**
 * @param {string} nameField
 * @param {string | null} error
 * @return {Object}
 */
export const albumSetErrorAction = (nameField, error) => {
  return {
    type: TYPE,
    nameField,
    error,
  };
};

/**
 * @param {AlbumState} state
 * @param {object} action
 * @return {object}
 */
export const albumSetErrorReducer = (state, action) => {
  const { nameField, error } = action;

  return produce(state, (draft) => {
    if (!draft.openAlbumErrors) {
      draft.openAlbumErrors = {};
    }
    if (error) {
      draft.openAlbumErrors[nameField] = error;
    } else if (nameField in draft.openAlbumErrors) {
      delete draft.openAlbumErrors[nameField];
    }
  });
};

export default {
  action: albumSetErrorAction,
  reducer: albumSetErrorReducer,
  TYPE,
};
