// @ts-check
import _get from "lodash/get";
import { getAlbum } from "../../albumActions";
import cartService from "../../../services/cartService";
import loadCart from "./loadCart";
import { getCartFieldChange } from "./getCartFieldChange";
import utils from "./utils";
import { loadAlbumUnedited } from "./loadAlbumUnedited";
import getTrackOriginalFilename from "../../../business-objects/getTrackOriginalFilename";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldActionType} FieldActionType */
/** @typedef {import("../../../business-objects/cart").CartItem} CartItem */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */

/**
 * PROCESS an ARTIST CHANGE
 *
 * CASO A:
 * paso 1. agrega artista
 * paso 2. modifica el nombre de artista
 * => no registrar la modificacion del nombre del artista => queda como add
 *
 *
 * CASO B:
 * paso 1. modifica el nombre de artista
 * paso 2. elimina el artista
 * => reemplazar el cambio de modificacion a eliminacion
 *
 *
 * CASO C:
 * paso 1. agrega artista
 * paso 2. modifica el nombre de artista (puede no existir este paso)
 * paso 3. elimina artista
 * => indirectamente cancela el cambio => borrar el cambio
 *

 * CASO D.1: (valido para artistas tambien)
 *  paso 1: modifica un campo
 *  paso 2: lo vuelve a editar dejando el valor original
 *
 * CASO D.2: (valido para artistas tambien)
 *  paso 1: modifica un campo pero llega el mismo valor old y new
 *  => descartar el cambio
 * 
 * 
 * CASO D.3:  CADO D pero para artistas => NO SOPORTADO (NO SE PUEDE IMPLEMENTAR)
 * paso 1. elimina artista rol 1
 * paso 2. agrega artista rol 1
 * => ¿seria modificacion de artista?
 * => por ahora seria otro key artista no se daria este caso
 *
 *
 * caso ? - cambio envivo con side effect => NO SOPORTADO (TODAVIA)
 * cambia a envivo => se modifican todos los tracks.envivo
 * elmina el cambio
 * => habria que restaurar todos los tracks
 *
 * 
 */

/**
 * @param {function} dispatch
 * @param {Album} album
 * @param {FieldEditable} field
 * @param {FieldActionType} action
 * @param {any=} uneditedValue
 * @param {any=} newValue
 */
const _processOnAdd = async (
  dispatch,
  album,
  field,
  action,
  uneditedValue = null,
  newValue = null
) => {
  const cartItem = utils.makeCartItem(album, field, action, "", newValue);
  return cartService.saveCartItem(album.upc, cartItem);
};

/**
 * @param {function} dispatch
 * @param {Album} album
 * @param {FieldEditable} field
 * @param {FieldActionType} action
 * @param {any=} uneditedValue
 * @param {any=} newValue
 */
const _processOnUpdate = async (
  dispatch,
  album,
  field,
  action,
  uneditedValue = null,
  newValue = null
) => {
  // check if exists previous changes for the field
  let cartItem = await dispatch(getCartFieldChange(album.upc, field));

  if (cartItem) {
    if (uneditedValue === newValue) {
      // CASO D.1 - cancel the change for same value as original
      return await cartService.removeCartItem(album.upc, cartItem);
    }

    // CASO A - use the previous cart change and set the newValue that should be the Artit's name
    cartItem = {
      ...cartItem,
      uneditedValue,
      newValue,
    };
  } else if (uneditedValue !== newValue) {
    // only create a changeitem for different values
    cartItem = utils.makeCartItem(
      album,
      field,
      action,
      uneditedValue,
      newValue
    );
  }

  if (cartItem) {
    return cartService.saveCartItem(album.upc, cartItem);
  }
};

/**
 * @param {function} dispatch
 * @param {Album} album
 * @param {FieldEditable} field
 * @param {FieldActionType} action
 * @param {any=} uneditedValue
 * @param {any=} newValue
 */
const _processOnDelete = async (
  dispatch,
  album,
  field,
  action,
  uneditedValue = null,
  newValue = null
) => {
  // check if exists previous changes for the field
  /** @type {CartItem} */
  let cartItem = await dispatch(getCartFieldChange(album.upc, field));
  if (cartItem) {
    if (cartItem.action === "add") {
      // CASO C - cancel the change
      return await cartService.removeCartItem(album.upc, cartItem);
    } else {
      // CASO B - transform change to remove
      cartItem = {
        ...cartItem,
        action,
      };
    }
  } else {
    cartItem = utils.makeCartItem(album, field, action, uneditedValue, "");
  }

  if (cartItem) {
    if (cartItem.typeField === "artist") {
      // Transform the path to artist/xxxx/name to artist/xxxx
      // This way is needed to restore / cancel the field
      cartItem.path = cartService.fieldPathToCartItemPath(
        "artist",
        cartItem.path
      );
    }

    return cartService.saveCartItem(album.upc, cartItem);
  }
};

/**
 * @param {function} dispatch
 * @param {Album} album
 * @param {FieldEditable} field
 * @param {FieldActionType} action
 * @param {any=} uneditedValue
 * @param {any=} newValue
 */
const _processChange = async (
  dispatch,
  album,
  field,
  action,
  uneditedValue = null,
  newValue = null
) => {
  let actionHandler;

  if (action === "add") {
    actionHandler = _processOnAdd;
  } else if (action === "update") {
    actionHandler = _processOnUpdate;
  } else if (action === "delete") {
    actionHandler = _processOnDelete;
  }

  if (actionHandler) {
    return actionHandler(
      dispatch,
      album,
      field,
      action,
      uneditedValue,
      newValue
    );
  }
};

/**
 * @param {function} dispatch
 * @param {string} upc
 * @param {FieldEditable} field
 * @return {Promise<any>}
 */
const _findUneditedValue = async (dispatch, upc, field) => {
  if (!upc || !field) {
    return null;
  }

  /** @type {Album} */
  const albumUnedited = await dispatch(loadAlbumUnedited(upc));

  if (albumUnedited) {
    const path = field.path;
    let value = _get(albumUnedited, path.replace(/\//gi, "."), null);

    if (!value && field.type === "genre") {
      value = {
        genre: albumUnedited.genre,
        subgenre: albumUnedited.subgenre,
      };
    }

    if (
      !value &&
      field.type === "audioTrack" &&
      field.isrcTrack &&
      field.isrcTrack in albumUnedited.tracks
    ) {
      // this means that the track on albumUnedited doesn't have the originalFilename field
      // so we use the getTrackOriginalFilename to detect the filename
      value = getTrackOriginalFilename(albumUnedited.tracks[field.isrcTrack]);
    }

    return value;
  }

  return null;
};

/**
 * Process an Album change and determine if it's a chargeable change.
 * Also management the cart change (find and update cart changes if it's needed)
 *
 * @param {string} upc
 * @param {FieldEditable} field
 * @param {FieldActionType} action
 * @param {any=} newValue
 * @return {Function}
 */
export const processAlbumChange = (upc, field, action, newValue = null) => {
  /**
   * @param {function} dispatch
   * @param {StateGetter} getState
   */
  return async function processAlbumChangeThunk(dispatch, getState) {
    // load album
    /** @type {Album} */
    const album = await dispatch(getAlbum(upc));

    if (album?.status !== "live_updating") {
      // the cart only is actived on "live_updating"
      return;
    }

    const uneditedValue = await _findUneditedValue(dispatch, upc, field);

    await _processChange(
      dispatch,
      album,
      field,
      action,
      uneditedValue,
      newValue
    );

    await dispatch(loadCart(upc, true));
  };
};

export default processAlbumChange;
