// @ts-check
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import "../../../../yupConfigLocale";

import Wizard from "./components/Wizard";
import Thanks from "./components/Thanks";
import StepIsSingle from "./components/StepIsSingle";
import StepAlbum from "./components/StepAlbum";
import StepAlbumArtists from "./components/StepAlbumArtists";
import wizardActions from "../../../../reducers/wizardNewAlbum/wizardActions";
import StepTracks from "./components/StepTracks";
import { getTracksSorted } from "../../../../business-objects";

/** @typedef {import("../../../../business-objects/album").Album} Album */
/** @typedef {import('../../../../reducers/wizardNewAlbum/wizardStore').WizardNewAlbumState} WizardNewAlbumState */
/** @typedef {import('../../../../reducers/store').StateStore} StateStore */

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1100,
    margin: "auto",
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(5),
    },
  },
}));

export const WizardNewAlbum = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const showThanks = useSelector(
    /**
     * @param {StateStore} state;
     * @return {boolean}
     */
    (state) => state.wizardNewAlbumState.showThanks || false
  );
  const savedAlbum = useSelector(
    /**
     * @param {StateStore} state;
     * @return {Album | null}
     */
    (state) => state.wizardNewAlbumState.savedAlbum || null
  );

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    if (showThanks && savedAlbum) {
      dispatch(wizardActions.clearAction());

      history.push(`/albums/${savedAlbum?.upc}/preview`, {
        fromWizard: true,
      });
    }
  }, [showThanks, savedAlbum]);

  return (
    <div className={classes.root}>
      <Wizard steps={[StepTracks, StepAlbum, StepAlbumArtists]} />
    </div>
  );
};

export default WizardNewAlbum;
