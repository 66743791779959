// @ts-check
import React from "react";
import classnames from "classnames";
import { makeStyles, IconButton, Box } from "@material-ui/core";
import { TrackTitleHeader } from "../../../../../components-cross/TrackTitle";
import AlbumCover from "../../../../../components-cross/AlbumCover";
import HeaderTrackTrackAudio from "./HeaderTrack.TrackAudio";
import EditISRC from "./EditISRC";

/** @typedef {import('../../../../../business-objects/album').Album} Album */
/** @typedef {import('../../../../../business-objects/album').Track} Track */

const useStyles = makeStyles((theme) => ({
  headerTrack: {
    maxWidth: "1100px",
    position: "sticky",
    top: theme.spacing(8),
    zIndex: 2,
    // background: "linear-gradient(180deg, #F2EAA9 0%, #FCF9E5 100%)",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: "flex",
    margin: "auto",

    "& .headerTrack__cover": {
      width: "100px",
      height: "100px",
      border: "1px solid #CCC",
      borderRadius: "0.25rem",
      background:
        "radial-gradient(50% 50% at 50% 50%, #BB86FC 14.06%, #603299 100%)",
      boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.25)",
      marginRight: "1.1rem",
    },
    "& .headerTrack__detail": {
      flex: "1 1 auto",
    },
    "& .headerTrack__trackMetaContainer": {
      display: "flex",
    },
    "& .headerTrack__trackNumber": {
      color: "#F50057",
      fontSize: ".8rem",
    },
    "& .headerTrack__metaDetail": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    "& .headerTrack__originalFilename, .headerTrack__isrc": {
      marginRight: "0.5rem",
      color: theme.palette.text.secondary,
    },
    "& .headerTrack__isrc": {
      display: "flex",
      justifyContent: "flex-end",
      gap: "0.5rem",
      alignItems: "center",
      marginBottom: "0.5rem",
      textAlign: "right",
      "&-label": {
        fontSize: "0.8rem",
      },
      "&-value": {
        textTransform: "uppercase",
      },
    },
  },
  [theme.breakpoints.down("sm")]: {
    headerTrack: {
      position: "initial",
      flexWrap: "wrap",
      padding: theme.spacing(2),

      "& .headerTrack__trackMetaContainer": {
        marginTop: "1rem",
      },

      "& .headerTrack__isrc": {
        marginTop: "0.2rem",
        textAlign: "left",
      },
    },
  },
}));

const HeaderEmpty = ({ className }) => {
  const computed = classnames("-empty", className);
  return (
    <div className={computed}>
      <div className="headerTrack__cover"></div>
      <div className="headerTrack__detail">Track desconocido</div>
    </div>
  );
};

/**
 *
 * @param {{album: Album, track: Track}} props
 * @returns
 */
const HeaderTrack = ({ album, track }) => {
  const classes = useStyles();

  if (!album || !track) {
    return <HeaderEmpty className={classes.headerTrack} />;
  }

  const tracksLen = album.tracks ? Object.keys(album.tracks).length : 0;

  return (
    <div className={classes.headerTrack}>
      <AlbumCover className="headerTrack__cover" album={album} />

      <div className="headerTrack__detail">
        <div className="headerTrack__trackMetaContainer">
          <div className="headerTrack__trackNumber">
            {track?.order} de {tracksLen}
          </div>
        </div>
        <TrackTitleHeader album={album} track={track} />
      </div>

      <div className="headerTrack__metaDetail">
        <div className="headerTrack__originalFilename">
          <HeaderTrackTrackAudio album={album} track={track} />
        </div>
        <EditISRC album={album} track={track} />
      </div>
    </div>
  );
};

export default HeaderTrack;
