// @ts-check
import { firebase } from "../../../firebase";
import { setAlbum } from "../../albumActions";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldActionType} FieldActionType */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */

/**
 * Find the album on the local store or fetch from server
 * @param {string} upc
 * @return {Function}
 */
export const getAlbum = (upc) => {
  /**
   * @param {function} dispatch
   * @param {StateGetter} getState
   */
  return async function thunk(dispatch, getState) {
    /** @type {StateStore} */
    const state = getState();

    let album = state.albumState.albums[upc];

    if (!album) {
      album = await firebase.db
        .ref(`catalog/${upc}`)
        .once("value")
        .then((snap) => {
          const albumSnap = snap.val();
          if (!albumSnap) {
            throw new Error(`No se encontró el album ${upc}`);
          }
          albumSnap.live = albumSnap.live ? albumSnap.live : false;
          return albumSnap;
        });
      dispatch(setAlbum(upc, album));
    }

    return album;
  };
};
