// @ts-check

import _cartSetItems from "./actions/cartSetItems";
import _setShowDetailPanel from "./actions/setShowDetailPanel";
import _setShowConfirmCheckout from "./actions/setShowConfirmCheckout";
import _setAlbumUnedited from "./actions/setAlbumUnedited";
import _setSaving from "./actions/setSaving";

import discardCartItem from "./actionsAsync/discardCartItem";
import getCartFieldChange from "./actionsAsync/getCartFieldChange";
import processAlbumChange from "./actionsAsync/processAlbumChange";
import publishCart from "./actionsAsync/publishCart";
import loadCart from "./actionsAsync/loadCart";

const cartSetItemsAction = _cartSetItems.action;
const setShowDetailPanel = _setShowDetailPanel.action;
const setShowConfirmCheckout = _setShowConfirmCheckout.action;
const setAlbumUnedited = _setAlbumUnedited.action;
const setSaving = _setSaving.action;

export {
  cartSetItemsAction,
  setShowDetailPanel,
  setShowConfirmCheckout,
  setAlbumUnedited,
  setSaving,
  processAlbumChange,
  publishCart,
  loadCart,
  getCartFieldChange,
  discardCartItem,
};

export default {
  cartSetItemsAction,
  setShowDetailPanel,
  setShowConfirmCheckout,
  setAlbumUnedited,
  setSaving,
  processAlbumChange,
  loadCart,
  publishCart,
  getCartFieldChange,
  discardCartItem,
};
