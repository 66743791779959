// @ts-check

/** @typedef {import("./album").ArtistRole} ArtistRole */

/** @type {Array<ArtistRole>} */
const rolesAlbumBase = [
  {
    label: "Artista principal",
    value: "primary_artist",
  },
  {
    label: "Feat. (Artista Colaborador)",
    value: "featuring",
  },
  {
    label: "Producer",
    value: "producer",
  },
  {
    label: "Remixer",
    value: "remixer",
  },
  {
    label: "Compositor (Música)",
    value: "composer_music",
    // deprecated: true,
  },
  {
    label: "Compositor (letra)",
    value: "composer_lyrics",
    // deprecated: true,
  },
  {
    label: "Arreglista",
    value: "arreglista",
    // deprecated: true,
  },
];

/** @type {Array<ArtistRole>} */
const rolesAlbumClassicalPlus = [
  {
    label: "Director de Orquesta",
    value: "director",
  },
  {
    label: "Ensamble",
    value: "ensemble",
  },
  {
    label: "Orquesta",
    value: "orchestra",
  },
];

/** @type {Array<ArtistRole>} */
const rolesTrackBase = [
  {
    label: "Artista principal",
    value: "primary_artist",
  },
  {
    label: "Feat. (Artista Colaborador)",
    value: "featuring",
  },
  {
    label: "Producer",
    value: "producer",
  },
  {
    label: "Remixer",
    value: "remixer",
  },
  {
    label: "Compositor (letra)",
    value: "composer_lyrics",
  },
  {
    label: "Compositor (Música)",
    value: "composer_music",
    deprecated: true,
  },
  {
    label: "Arreglista",
    value: "arreglista",
    deprecated: true,
  },
];

/** @type {Array<ArtistRole>} */
const rolesTrackClassicalPlus = [
  {
    label: "Director de Orquesta",
    value: "director",
  },
  {
    label: "Ensamble",
    value: "ensemble",
  },
  {
    label: "Orquesta",
    value: "orchestra",
  },
];

const _getRolesAlbum = (genre, subgenre) => {
  let result = rolesAlbumBase;

  if (genre === "Classical") {
    result = result.concat(rolesAlbumClassicalPlus);
  }
  return result;
};
const _getRolesTracks = (genre, subgenre) => {
  let result = rolesTrackBase;

  if (genre === "Classical") {
    result = result.concat(rolesTrackClassicalPlus);
  }
  return result;
};

const _skipDeprecated = (roles) => roles.filter((role) => !role.deprecated);

/**
 * Determine the roles availables based on genre and level (album or track)
 * @param {string} genre
 * @param {string} subgenre
 * @param {('track' | 'album')?} level
 * @param {boolean?} includeDeprecated
 * @return {Array<ArtistRole>}
 */
export const getArtistRolesForGenre = (
  genre,
  subgenre,
  level = "album",
  includeDeprecated = false
) => {
  if (!genre || !subgenre) {
    return [];
  }

  let result =
    level === "album"
      ? _getRolesAlbum(genre, subgenre)
      : _getRolesTracks(genre, subgenre);

  if (!includeDeprecated) {
    result = _skipDeprecated(result);
  }

  return result;
};

export default getArtistRolesForGenre;
