import { isArray } from "lodash";

/** @typedef {import('./album').Track} Track */

/**
 * @param {{tracks: Array<Track> | {[key:string]: Track}}} album
 * @return {'Single' | 'EP' | 'Album'}
 */
export const getAlbumFormatName = (album) => {
  const result = "Album";

  if (!album || !album.tracks) {
    return result;
  }

  const { tracks } = album;

  const len = isArray(tracks) ? tracks.length : Object.keys(tracks).length;

  if (len === 1) {
    return "Single";
  } else if (len < 5) {
    return "EP";
  } else {
    return "Album";
  }
};

export default getAlbumFormatName;
