import React, { useEffect, useRef, useState } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  useLocation,
} from "react-router-dom";
import {
  Slide,
  makeStyles,
  CircularProgress,
  Paper,
  Tabs,
  Tab,
  Box,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import Result from "../../../../components-cross/Result";
import UploadingAnimation from "../../../../components/UploadingAnimation";
import * as routes from "../../../../constants/routes";

// import AlbumStatusChip from "../../components/AlbumStatusChip";

import TrackEditor from "../TrackEditor";

import AlbumFieldsForm from "./components/AlbumFieldsForm";
import ArtistsFields from "./components/ArtistsFields";
import ArtistsButtons from "./components/ArtistsButtons";
import Tracks from "./components/Tracks";

// temporalmente se usa estos:
import useEditor from "../Edit/useEditor";
import AlbumToolbar from "../Edit/components/AlbumToolbar";
import CartDetail from "../Edit/components/shoppingCart/CartDetail";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    // backgroundColor: "rgba(204, 170, 102, 0.2)",

    width: "100%",
    marginTop: "69px", // spacing(8) + 5px del borderBottom del Navigation
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  root: {
    maxWidth: 1100,
    margin: "auto",
    marginBottom: theme.spacing(5),
    "& .MuiPaper-root": {
      padding: "2rem",
      marginBottom: "1rem",
      "& h1": {
        marginTop: 0,
        marginBottom: "1rem",
      },
    },
    "& .MuiAlert-root": {
      padding: "1rem",
    },
    "& .formTrack__creditsLine": {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(3),
      marginBottom: "1rem",
      // paddingLeft: "2rem",
      // paddingRight: "2rem",
      position: "sticky",
      top: "194px",
      zIndex: 2,
      width: "100%",
      height: "2rem",
      color: "#C4C4C4",
      backgroundColor: theme.palette.background.paper,

      "&-label": {
        backgroundColor: theme.palette.background.paper,
        display: "inline-block",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
      },
      "&-line": {
        display: "inline-block",
        width: "100%",
        borderTop: `1px solid #eee`,
        transform: "translateY(50%)",
      },
      [theme.breakpoints.down("sm")]: {
        top: "69px",
      },
    },
  },
  rolesButtonsContainer: {
    width: "100%",
    display: "flex",
    paddingTop: "2rem",
    paddingBottom: "2rem",
  },
  albumFieldsFormContainer: {
    marginTop: "0 !important",
    marginBottom: "1rem",
    position: "relative",
  },
  sectionButtonGroup: {
    marginTop: "0",
    marginBottom: "0",
    position: "sticky",
    top: "137px",
    left: "50%",
    transform: "translateX(-30%)",
    zIndex: "10",
  },
  albumStatus: {
    position: "absolute",
    top: "0",
    left: "50%",
    transform: "translateX(-50%)",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: "0.6rem",
    borderBottomRightRadius: "0.6rem",
  },
  uploadingAnimationContainer: {
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.tooltip + 1,
    "& > *": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  artistsBlock: {
    marginTop: theme.spacing(5),
  },

  artistsHeader: {
    marginBottom: theme.spacing(2),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const AlbumEditor = (props) => {
  const [tabValue, setTabValue] = useState(0);

  const upc = props.match.params.upc;
  const {
    loading,
    saving,
    album,
    trackOpened,
    canAddRemoveTracks,
    hasErrors,
    blockedit,
  } = useEditor(upc);

  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const sectionAlbumRef = useRef(null);
  const sectionArtistsRef = useRef(null);
  const sectionTracksRef = useRef(null);
  let { path } = useRouteMatch();

  useEffect(() => {
    if (sectionTracksRef?.current && location.state?.fromTrackEditor) {
      scrollTo(sectionTracksRef, false);
    } else {
      window.scrollTo({ top: 0 });
    }
  }, [location.state, sectionTracksRef]);

  const handleOnGoToPreview = () => {
    history.replace(routes.ALBUM_VIEW.replace(":upc", upc));
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const scrollTo = (ref, animated = true) => {
    window.scrollTo({
      top: ref.current.offsetTop - 50,
      behavior: animated ? "smooth" : "auto",
    });
  };

  if (!album || loading) {
    return (
      <div className={classes.root}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Switch>
      <Route path={routes.ALBUM_EDIT_TRACKS.replace(":upc", upc)}>
        <TrackEditor album={album} track={trackOpened} />
      </Route>

      <Route exact path={path}>
        <Box className={classes.background}>
          <AlbumToolbar album={album} />
        </Box>

        <div className={classes.root}>
          {saving && (
            <div className={classes.uploadingAnimationContainer}>
              <div>
                <UploadingAnimation genre={album.genre} />
              </div>
            </div>
          )}

          {hasErrors && (
            <Alert severity="error">
              Corrige los errores e inténtalo otra vez
            </Alert>
          )}

          {blockedit && (
            <Result
              status="warning"
              title="Este álbum no puede ser editado"
              backLabel="Ir a la vista de preview"
              onBack={handleOnGoToPreview}
            />
          )}

          {!blockedit && (
            <Slide direction="down" in={true} mountOnEnter unmountOnExit>
              <div>
                <CartDetail album={album} />

                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab label="Album" />
                  <Tab label="Tracks" />
                </Tabs>

                <TabPanel value={tabValue} index={0}>
                  <Paper
                    ref={sectionAlbumRef}
                    className={classes.albumFieldsFormContainer}
                  >
                    <AlbumFieldsForm />

                    <div className="formTrack__creditsLine">
                      <hr className="formTrack__creditsLine-line" size="1" />
                      <span className="formTrack__creditsLine-label">
                        ARTISTAS
                      </span>
                    </div>

                    <div className={classes.artistsBlock}>
                      <ArtistsFields />

                      <div className={classes.rolesButtonsContainer}>
                        <ArtistsButtons />
                      </div>
                    </div>
                  </Paper>
                </TabPanel>

                <TabPanel value={tabValue} index={1}>
                  <Paper ref={sectionTracksRef}>
                    <Tracks canAddRemoveTracks={canAddRemoveTracks} />
                  </Paper>
                </TabPanel>
              </div>
            </Slide>
          )}
        </div>
      </Route>
    </Switch>
  );
};

export default AlbumEditor;
