// @ts-check
import { db } from "../../../firebase";
import { getAlbum } from "./getAlbum";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album").ArtistRole} ArtistRole */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldActionType} FieldActionType */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */
/** @typedef {import("../../../reducers/store").ThunkActionFunction} ThunkActionFunction */

/**
 * Add a track to an album
 * @param {string} upc
 * @return {Function}
 */
export const trackAdd = (upc) => {
  /**
   * @param {function} dispatch
   */
  return async function thunk(dispatch) {
    /** @type {Album} */
    const album = await dispatch(getAlbum(upc));

    if (!album || album.status !== "draft") {
      throw new Error("Solo se puede agregar un track a un album en borrador");
    }

    const isrc = await db.addAlbumTrack(upc);
    return isrc;
  };
};
