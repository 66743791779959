import { useState, useEffect } from "react";
import storageService from "../services/storageService";

export const useStorageFile = (storageLocation, customBucket = null) => {
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const doIt = async () => {
      setLoading(true);
      try {
        const _url = await storageService.getDownloadURL(
          storageLocation,
          customBucket
        );
        setUrl(_url);
      } catch (e) {
        setUrl("");
      } finally {
        setLoading(false);
      }
    };

    if (storageLocation) {
      doIt();
    } else {
      setUrl("");
    }
  }, [storageLocation, customBucket]);

  return [url, loading];
};

export default useStorageFile;
