import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { firebase, auth, fbroot } from "../firebase";
import Grid from "@material-ui/core/Grid";
import grey from "@material-ui/core/colors/grey";
import indigo from "@material-ui/core/colors/indigo";
import teal from "@material-ui/core/colors/teal";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import dateFormat from "dateformat";
import { Typography } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    width: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alginItems: "center",
  },
  dropzone: {
    width: "25vw",
    height: "13vw",
    position: "absolute",
    opacity: "-1",
    cursor: "pointer",
    padding: theme.spacing(1),
    borderRadius: "10px",
    backgroundColor: grey[100],
  },
  dropzoneInner: {
    borderWidth: 1,
    borderStyle: "dashed",
    borderColor: indigo[100],
    backgroundColor: grey[100],
    borderRadius: "10px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    maxWidth: "100%",
    height: "66vw",
    maxHeight: "250px",
  },
  uploadIcon: {
    fontSize: 40,
    color: theme.palette.primary.main,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  progress: {
    position: "relative",
    zIndex: 1,
  },
  doneIcon: {
    color: teal[100],
    fontSize: 40,
  },
  deleteButton: {
    position: "absolute",
    right: theme.spacing(-2),
    bottom: theme.spacing(-2),
    color: theme.palette.primary.main,
  },
  card: {
    borderRadius: "10px",
  },
  media: {
    // minWidth: '100%',
    height: "100%",
    width: "100vw",
    maxWidth: "100%",
    // height: '66vw',
    // maxHeight: '250px'
  },
  verifyText: {
    marginBottom: theme.spacing(2),
  },
});

class VerifyAccount extends React.Component {
  state = {
    uploading: false,
    progress: 0,
    error: false,
    url: false,
  };

  requestVerification = () => {
    auth.doUpdateUserInfo({ verified: "pending", verified_when: Date.now() });
  };

  getExtension = (file) => {
    switch (file.type) {
      case "image/jpeg":
        return "jpg";
      case "image/gif":
        return "gif";
      case "image/png":
        return "png";
      default:
        const extension = file.name.split(".").pop();
        return extension ? extension : "";
    }
  };

  reset = () => {
    const uid = firebase.auth.currentUser.uid;
    const payload = {
      verified: null,
      photoID: null,
      verified_when: null,
    };

    firebase.db.ref(`users/${uid}`).update(payload);
  };

  handlePhotoDrop = (event) => {
    if (!event) {
      return false;
    }

    // set local state quick to show feedback
    this.setState({ uploading: true });

    const { config } = this.props;
    const uid = firebase.auth.currentUser.uid;
    const avatarsBucket = fbroot.app().storage(config.buckets.avatars.url);
    const file = event.target.files[0];
    const extension = this.getExtension(file);
    const filename = `${uid}-photoID.${extension}`;
    const itemStorageRef = avatarsBucket.ref(filename);

    // upload
    const uploadTask = itemStorageRef.put(file);

    // progress handler
    uploadTask.on(
      "state_changed",
      (taskSnap) => {
        this.setState({
          progress: (taskSnap.bytesTransferred / taskSnap.totalBytes) * 100,
        });
      },

      (error) => {
        this.setState({ error: true });
        console.log(error);
      },

      // set download url on complete callback
      () => {
        itemStorageRef.getDownloadURL().then((url) => {
          auth.doUpdateUserInfo({ photoID: url });
        });
      }
    );

    // no preview
    window.URL.revokeObjectURL(file.preview); // avoid memmory leaks
  };

  render() {
    const { classes, url, verified = "", verified_when = "", allFieldsOK } = this.props;

    if (!allFieldsOK) {
      return (
        <Typography variant="h6">
          Antes de iniciar la verificación de tu cuenta es necesario que todos los campos obligatorios de tu perfil (más arriba) estén rellenados y
          guardados.
        </Typography>
      );
    }

    // account verification info
    let message = "";
    let card = "";

    if (!url) {
      message = (
        <ol>
          <li>Sube una foto de tu documento de identidad.</li>
          <li>
            Una vez subida, pulsa el botón <strong>VERIFICAR CUENTA</strong> para iniciar el proceso de verificación.
          </li>
        </ol>
      );

      card = <input type="file" onChange={(e) => this.handlePhotoDrop(e)} accept={"image/*"} />;
    } else {
      if (!verified && verified !== false) {
        message = (
          <span>
            Pulsa el botón <strong>VERIFICAR CUENTA</strong> para iniciar el proceso de verificación con esta identificación.
          </span>
        );
        card = (
          <Card className={classes.card}>
            <CardMedia
              component="img"
              alt="Documento de identidad"
              className={classes.media}
              height="140"
              image={url}
              title="Documento de identidad"
            />
            <CardActions>
              <Button size="small" color="primary" onClick={this.requestVerification}>
                Verificar cuenta
              </Button>
              <Button size="small" color="primary" onClick={this.reset}>
                Eliminar
              </Button>
            </CardActions>
          </Card>
        );
      } else if (verified === "pending") {
        message = (
          <span>
            Tu cuenta está en proceso de verificación desde el <strong>{dateFormat(verified_when, "d/m/yyyy")}</strong> a las{" "}
            <strong>{dateFormat(verified_when, "shortTime")}</strong>. Te avisamos por email cuando esté verificada.
          </span>
        );
        card = (
          <Card className={classes.card}>
            <CardMedia
              component="img"
              alt="Documento de identidad"
              className={classes.media}
              height="140"
              image={url}
              title="Documento de identidad"
            />
            <CardActions>
              <Button size="small" color="primary" onClick={this.reset}>
                Cancelar
              </Button>
            </CardActions>
          </Card>
        );
      } else if (verified === false) {
        message = (
          <>
            <Typography>
              <strong>No aprobada.</strong> No hemos podido verificar tu cuenta, te hemos enviado un email.
            </Typography>

            <Button style={{ marginTop: "1em" }} variant="contained" size="small" color="primary" onClick={this.reset}>
              Re-intentar virificación
            </Button>
          </>
        );
      } else if (verified === true) {
        message = (
          <span>
            Cuenta verificada el <strong>{dateFormat(verified_when, "d/m/yyyy")}</strong>a las{" "}
            <strong>{dateFormat(verified_when, "shortTime")}</strong>.
          </span>
        ); // if there's a url AND verified is a date (should be)
      }
    }

    return (
      <Grid container spacing={5} style={{ display: "block" }}>
        {!!card ? (
          <Grid item xs={12} sm={6}>
            {card}
          </Grid>
        ) : null}
        <Grid item xs={12} md={!!card ? 6 : 12}>
          {" "}
          {message}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(VerifyAccount);
