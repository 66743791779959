module.exports = (config = {}) => {
  const {
    style = "currency",
    currency = "USD",
    maximumFractionDigits = 2,
    currencyDisplay = "symbol",
  } = config;

  return new Intl.NumberFormat("es-MX", {
    style,
    currency,
    maximumFractionDigits,
    currencyDisplay,
  });
};
