import React, { Component } from "react";
import withAuthorization from "../withAuthorization";
import { firebase, functions } from "../../firebase";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import withWidth from "@material-ui/core/withWidth";
import ScrollToTop from "../ScrollToTop";
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { keys, get, has } from "lodash";
import SpacesMenu from "./SpacesMenu";
import ReportMini from "./ReportMini";
import ReportMiniDisplay from "./ReportMiniDisplay";
import ReportBigChart from "./ReportBigChart";
import PeriodRangeMenu from "./PeriodRangeMenu";
import dateFormat from "dateformat";
import isAdmin from "../../utils/isAdmin";
import isSafeAdmin from "../../utils/isSafeAdmin";
import Search from "./Search";

const styles = (theme) => ({
  root: {
    maxWidth: 1100,
    width: "100%",
    margin: "auto",
  },
  loading: {
    width: "100%",
    minHeight: "70vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  titleBox: {
    // position: 'relative',
  },
  spaceMenu: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
      paddingBottom: theme.spacing(2),
    },
  },
  heading: {
    marginBottom: theme.spacing(1),
  },
});

// BAKCEND CALLS
const requestDashboard = functions.httpsCallable("getDashboard");

const HISTORY_YEAR_START = 2020;

class ReportingDashboard extends Component {
  state = {
    loading: true,
    spaceActive: "",
    spaces: {},
    info: "Desde el inicio",
    from: dateFormat(new Date(HISTORY_YEAR_START, 0), "yyyymm"),
    until: dateFormat(new Date(), "yyyymm"),
    showNoDataMessage: false,
  };

  /**
   * Get all possible reporting SPACES.
   * (i.e user-space + any account-spaces for accounts this user is an admin of)
   */
  loadSpaces = async () => {
    // const { spaceActive } = this.state
    const { uid, displayName } = firebase.auth.currentUser;

    const reportingSpaces = {};
    let selectedSpace = false;

    // SUPER USER
    if (isAdmin() || isSafeAdmin()) {
      reportingSpaces.all = {
        label: "Absolutamente todo",
        type: "superuser",
        key: "all",
      };
      selectedSpace = "all";
    }

    // ACCOUNTS
    // const userAccountsSnap = await firebase.db
    //   .ref("accounts")
    //   .orderByChild("owner")
    //   .equalTo(uid)
    //   .once("value");

    // if (userAccountsSnap.exists()) {
    //   userAccountsSnap.forEach((accSnap) => {
    //     const spaceKey = accSnap.key;
    //     const account = accSnap.val();

    //     // set the first account-type space as selected for now
    //     if (!selectedSpace) {
    //       selectedSpace = spaceKey;
    //     }

    //     reportingSpaces[spaceKey] = {
    //       label: account.name,
    //       type: "account",
    //       key: spaceKey,
    //     };
    //   });
    // }

    // PERSONAL
    reportingSpaces[uid] = {
      label: displayName,
      type: "user",
      key: uid,
    };

    // save spaces to state and Select an ACTIVE space
    this.setState({ spaces: reportingSpaces }, () => {
      this.activateSpace(selectedSpace);
    });
  };

  appendSpace = (space) => {
    this.setState(
      (prev) => {
        prev.spaces[space.key] = {
          label: space.label,
          type: space.type,
          key: space.key,
        };
      },
      () => {
        this.activateSpace(space.key);
      }
    );
  };

  loadData = async () => {
    const { spaceActive } = this.state;

    const snap = await firebase.db
      .ref(`reporting/${spaceActive}/metricsKeys`)
      .once("value");

    this.setState({
      loadingKeys: !snap.exists(),
    });
  };

  /**
   * return a Reporting Subject key (account id or uid)
   */
  activateSpace = (key) => {
    const { spaces } = this.state;
    const { uid } = firebase.auth.currentUser;
    const spaceActive = key && spaces[key] ? key : uid;

    this.setState({ spaceActive }, this.loadData);
  };

  setBQUpdated = () => {
    firebase.db.ref("reporting/bq_updated").on("value", (snap) => {
      const bq_updated = snap.val();

      if (bq_updated) {
        this.setState({ bq_updated });
      }
    });
  };

  /**
   * Get Metrics
   */

  handleMetricDataAdd = (snap) => {
    const { from, until, spaceActive } = this.state;
    const result = snap.val();

    if (!result) {
      this.setState({
        loading: false,
        showNoDataMessage: true,
      });
    } else {
      this.setState((prevState) => {
        if (!prevState.dashboards) prevState.dashboards = {};
        if (!prevState.dashboards[`${spaceActive}-${from}-${until}`])
          prevState.dashboards[`${spaceActive}-${from}-${until}`] = {};
        prevState.dashboards[`${spaceActive}-${from}-${until}`][result.name] =
          result.data;
        prevState.loading = false;
        prevState.loadingKeys = false;
        return prevState;
      });
    }
  };

  requestDashboardDataRefresh = () => {
    const { spaces, spaceActive, from, until } = this.state;
    const space = spaces[spaceActive];

    requestDashboard({
      space,
      from,
      until,
    });
  };

  suscribeToDashboard = () => {
    const { spaceActive, from, until } = this.state;

    this.setState({ loading: true }, () => {
      firebase.db
        .ref(`reporting/${spaceActive}/dashboards/${from}-${until}`)
        .on("child_added", this.handleMetricDataAdd);
    });
  };

  unsuscribeFromDashboard = (spaceActive, from, until) => {
    firebase.db
      .ref(`reporting/${spaceActive}/dashboards/${from}-${until}`)
      .off();
  };

  /**
   * start getting realtime data cached in Firebase
   */

  async componentDidMount() {
    // GET all possible spaces for this user
    await this.loadSpaces();

    // suscribe to BigQuery updates
    this.setBQUpdated();
  }

  async componentWillUnmount() {
    // <------------------------------- ARREGLAR AQUÍ!!!!!!!!!!!!!
    const { spaces } = this.state;

    await Promise.all(
      keys(spaces).map((spaceKey) =>
        firebase.db.ref(`reporting/${spaceKey}`).off()
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.spaceActive !== this.state.spaceActive ||
      prevState.from !== this.state.from ||
      prevState.until !== this.state.until
    ) {
      // unsuscribe from previous dashboard
      this.unsuscribeFromDashboard(
        prevState.spaceActive,
        prevState.from,
        prevState.until
      );

      // get new space/from/until data
      const { from, until, spaceActive } = this.state;
      const dashPath = `dashboards.${spaceActive}-${from}-${until}`;

      if (!has(this.state, dashPath)) {
        this.suscribeToDashboard();
        this.requestDashboardDataRefresh();
      } else {
        this.setState({ loading: false });
      }
    }
  }

  setPeriod = (period) => {
    this.setState({
      from: period.from,
      until: period.until,
      info: period.label,
    });
  };

  getGridSpacing = () => {
    const { width } = this.props;

    if (width === "xs") return 2;
    if (width === "ss") return 3;
    if (width === "sm") return 4;
    if (width === "md") return 5;
    if (width === "lg") return 5;
    if (width === "xl") return 5;
  };

  render() {
    const { classes } = this.props;
    const {
      loading,
      loadingKeys,
      spaces,
      spaceActive,
      from,
      until,
      info,
      dashboards = {},
    } = this.state;

    // current space information (like type, name, etc)
    const space = spaces[spaceActive];

    const dashboardName = `${spaceActive}-${from}-${until}`;

    const dashboard = get(dashboards, dashboardName, false);

    if (!space || loading) {
      return (
        <div className={classes.loading}>
          <CircularProgress variant="indeterminate" />
          {loadingKeys ? (
            <div style={{ marginTop: "1em" }}>
              <Typography variant="h5" style={{ marginBottom: ".2em" }}>
                Estamos generando un informe para tí.
              </Typography>
              <Typography variant="body1">
                El proceso puede tomar hasta un minuto, por favor ten paciencia.
              </Typography>
            </div>
          ) : null}
        </div>
      );
    }

    const {
      miniDisplayStreams,
      miniDisplayRoyalties,
      miniDisplayDownloads,
      bigChartTop,
      miniByAlbum,
      miniByTrack,
      miniByPlatform,
      miniByCountry,
      miniByArtist,
    } = dashboard;

    const there_is_no_data = !dashboard;

    const grid_spacing = this.getGridSpacing();

    return (
      <div className={classes.root}>
        <ScrollToTop />

        <Grid container>
          <Grid item xs={12} className={classes.spaceMenu}>
            {/* {(isAdmin() || isSafeAdmin()) && (
              <Search
                q={spaceActive}
                setSpace={this.activateSpace}
                // appendSpace={this.appendSpace}
              />
            )} */}
            <PeriodRangeMenu
              info={info}
              from={from}
              until={until}
              setPeriod={this.setPeriod}
            />
            <SpacesMenu
              spaces={spaces}
              active={spaceActive}
              setSpace={this.activateSpace}
              appendSpace={this.appendSpace}
            />
          </Grid>
        </Grid>

        {there_is_no_data ? (
          <Typography>
            Aún no hay datos para este período. Selecciona otro, por favor.
          </Typography>
        ) : (
          <Grid container spacing={grid_spacing}>
            <Grid item xs={12} md={4}>
              <ReportMiniDisplay
                title="Regalías"
                numberFormat="currency"
                data={miniDisplayRoyalties}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <ReportMiniDisplay title="Escuchas" data={miniDisplayStreams} />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <ReportMiniDisplay
                title="Descargas"
                data={miniDisplayDownloads}
              />
            </Grid>

            <Grid item xs={12}>
              <ReportBigChart
                title={
                  from === until ? `Regalías en ${info}` : "Regalías x mes"
                }
                name="totalRoyaltiesByMonths"
                data={{ ...bigChartTop }}
                chartType={from === until ? "bar" : "line"}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <ReportMini
                title="Por plataforma"
                name="royaltiesByPlatform"
                data={miniByPlatform}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <ReportMini
                title="Por álbum"
                name="royaltiesByAlbum"
                data={miniByAlbum}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <ReportMini
                title="Por track"
                name="royaltiesByTrack"
                data={miniByTrack}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <ReportMini
                title="Por país"
                name="royaltiesByCountry"
                data={miniByCountry}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <ReportMini
                title="Por artista"
                name="royaltiesByArtist"
                data={miniByArtist}
              />
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

const authCondition = (authUser) => !!authUser;

export default compose(
  withAuthorization(authCondition),
  withWidth(),
  withStyles(styles)
)(withRouter(ReportingDashboard));
