import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { auth } from "../../firebase";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";

const styles = (theme) => ({
  title: {
    fontSize: "20px",
    marginBottom: theme.spacing(2),
  },
  centeredPanel: {
    position: "fixed",
    top: "50%",
    left: 0,
    right: 0,
    transform: "translateY(-50%)",
    maxWidth: 400,
    margin: "auto",
    "& a": {
      textDecoration: "none",
    },
  },
  form: {
    padding: theme.spacing(8),
    borderRadius: 10,
    marginBottom: theme.spacing(3),
  },
  footer: {
    position: "absolute",
    bottom: theme.spacing(5),
    left: theme.spacing(5),
  },
  button: {
    marginTop: theme.spacing(5),
  },
});

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class ForgotPasswordPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      error: null,
      emailSent: false,
    };
  }

  handleCloseSnack = () => {
    this.setState({ emailSent: false });
  };

  onSubmit = (event) => {
    const { email } = this.state;

    auth
      .doPasswordReset(email)
      .then(() => this.setState({ emailSent: true }))
      .catch((error) => {
        this.setState(byPropKey("error", error));
      });

    event.preventDefault();
  };

  render() {
    const { classes } = this.props;
    const { email, error } = this.state;

    const isInvalid = email === "";

    const action = (
      <Button color="secondary" size="small" onClick={this.handleCloseSnack}>
        OK
      </Button>
    );

    return (
      <div>
        <div className={classes.centeredPanel}>
          <Paper elevation={1} className={classes.form}>
            <form onSubmit={this.onSubmit}>
              <Typography variant="h5" className={classes.title}>
                Restaurar contraseña
              </Typography>

              <TextField
                value={this.state.email}
                label="Email"
                onChange={(event) => this.setState(byPropKey("email", event.target.value))}
                fullWidth
              />
              <br />

              <Typography variant="h5" align="right">
                <Button color="primary" variant="contained" disabled={isInvalid} type="submit" className={classes.button}>
                  Enviar
                </Button>
              </Typography>

              {error && <p>{error.message}</p>}
            </form>
          </Paper>
        </div>

        <footer className={classes.footer}>
          <span>&copy; 2018 Musik.digital</span>
        </footer>

        <Snackbar
          open={this.state.emailSent}
          onClose={this.handleCloseSnack}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={
            <span id="message-id">
              Te hemos enviado un email a <strong>{email}</strong>.
            </span>
          }
          action={action}
        />
      </div>
    );
  }
}

const ForgotPasswordLink = () => (
  <Typography align="center">
    <Link to="/pw-forget">¿Olvidaste tu contraseña?</Link>
  </Typography>
);

export default withStyles(styles)(withRouter(ForgotPasswordPage));

export { ForgotPasswordLink };
