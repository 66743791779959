import React from "react";
import { useEffect } from "react";
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";
import { db } from "../../firebase";

const Fingerprint = () => {
  useEffect(() => {
    const saveFingerprint = async () => {
      const fingerprint = await getCurrentBrowserFingerPrint();
      await db.saveFingerprint(fingerprint);
    };

    saveFingerprint();
  }, []);

  return null;
};

export default Fingerprint;
