import { Button, IconButton } from "@material-ui/core";
import { Icon, TextField } from "@material-ui/core";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { trackReplaceISRC } from "../../../../../reducers/album/actionsAsync/trackReplaceISRC";
import { makeStyles } from "@material-ui/core";
import * as routes from "../../../../../constants/routes";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}));

/** @typedef {import('../../../../../business-objects/album').Album} Album */
/** @typedef {import('../../../../../business-objects/album').Track} Track */

/**
 *
 * @param {{track: Track, album: Album}} props
 * @returns
 */
const EditISRC = ({ track, album }) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [newISRC, setNewISRC] = useState(track.isrc);

  const classes = useStyles();
  const history = useHistory();

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleExit = () => {
    setIsEditing(false);
    setNewISRC(track.isrc);
  };

  const handleOnChnage = (e) => {
    setNewISRC(e.target.value);
  };

  const handleSave = async () => {
    try {
      setIsEditing(false);
      dispatch(trackReplaceISRC(album, track.isrc, newISRC));
      history.push(routes.ALBUM_EDIT.replace(":upc", album.upc)); // go back to editor
    } catch (e) {
      console.error(e);
    }
  };

  if (isEditing) {
    return (
      <div className={classes.root}>
        <TextField
          label="ISRC"
          value={newISRC}
          onChange={handleOnChnage}
          variant="outlined"
          size="small"
        />
        <IconButton
          size="small"
          onClick={handleSave}
          className={classes.button}
        >
          <Icon>check</Icon>
        </IconButton>
        <IconButton
          size="small"
          onClick={handleExit}
          className={classes.button}
        >
          <Icon>close</Icon>
        </IconButton>
      </div>
    );
  }

  return (
    <div className="headerTrack__isrc">
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={handleEdit}
      >
        ISRC {track.isrc}
      </Button>
    </div>
  );
};

export default EditISRC;
// Path: src/scenes/Albums/scenes/TrackEditor/components/EditISRC.jsx
