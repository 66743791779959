// @ts-check
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormikContext } from "formik";
import Wizard from "./Wizard";
import { setIsSingleAction } from "../../../../../reducers/wizardNewAlbum/actions/setIsSingle";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "center",
    // flexDirection: "column",
    gap: theme.spacing(2),
    margin: theme.spacing(8),
  },
}));

export const StepIsSingle = (props) => {
  const [isSingle, setIsSingle] = useState(false);
  const dispatch = useDispatch();
  // const { errors } = useFormikContext();

  const classes = useStyles();

  useEffect(() => {
    dispatch(setIsSingleAction(isSingle));
  }, [isSingle]);

  return (
    <Wizard.Step>
      <Wizard.Heading
        title="Single o Album"
        description="¿Vas a subir uno o más tracks?"
      />

      <Box className={classes.buttons}>
        <Button
          variant="contained"
          className="toolbar__discardButton"
          onClick={() => setIsSingle(true)}
          color={isSingle ? "primary" : "default"}
        >
          Single
        </Button>
        <Button
          variant="contained"
          className="toolbar__discardButton"
          onClick={() => setIsSingle(false)}
          color={isSingle ? "default" : "primary"}
        >
          Album
        </Button>
      </Box>
    </Wizard.Step>
  );
};

// StepIsSingle.label = "isSingle";

// StepIsSingle.validationSchema = yup.object({
//   isSingle: yup.bool().required(),
// });

export default StepIsSingle;
