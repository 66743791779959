// @ts-check
import React from "react";
import { useDispatch } from "react-redux";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { makeStyles } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

import { ListItemTextTrack } from "../../../../../components-cross/TrackTitle";
import { getTracksSorted } from "../../../../../business-objects";

import { trackOrder } from "../../../../../reducers/albumActions";

/** @typedef {import('../../../../../business-objects/album').Album} Album */
/** @typedef {import('../../../../../business-objects/album').Track} Track */

const useStyles = makeStyles((theme) => ({
  tracksList: {
    marginLeft: "1rem",
  },
  tracksListItem: {
    border: "1px solid #CCC",
    borderRadius: "0.3rem",
    padding: "0.4rem",
    marginBottom: "0.6rem",
    backgroundColor: "#FFF",
    "& .MuiListItemIcon-root": {
      minWidth: "1.5rem",
    },
    "& .tracksList-trackOrderNum": {
      color: theme.palette.text.secondary,
      marginLeft: "0.5rem",
      marginRight: "0.5rem",
    },
  },
}));

const SortableItem = SortableElement(({ track, album, className }) => (
  <ListItem key={track.isrc} className={className}>
    <ListItemIcon>
      <DragIndicatorIcon />
    </ListItemIcon>
    <div className="tracksList-trackOrderNum">{track.order}.</div>
    <ListItemTextTrack track={track} album={album} />
  </ListItem>
));

/**
 *
 * @param {{tracks: Array<Track>, album: Album}} props
 * @returns
 */
const TracksList = SortableContainer(({ tracks, album }) => {
  const classes = useStyles();

  return (
    <List className={classes.tracksList}>
      {tracks?.map((track, index) => {
        return (
          <SortableItem
            key={track.isrc}
            index={index}
            className={classes.tracksListItem}
            track={track}
            album={album}
          />
        );
      })}
    </List>
  );
});

/**
 * @param {{album: Album}} props
 * @returns
 */
const TracksListSortable = ({ album }) => {
  const dispatch = useDispatch();
  const tracks = getTracksSorted(album);

  const handleOnSortEnd = ({ oldIndex, newIndex }) => {
    dispatch(trackOrder(album.upc, oldIndex, newIndex));
  };

  return (
    <TracksList
      tracks={tracks}
      album={album}
      distance={1}
      onSortEnd={handleOnSortEnd}
    />
  );
};

export default TracksListSortable;
