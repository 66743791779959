import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { Box, Button, Typography } from "@material-ui/core";
import { compose } from "recompose";
import withAuthorization from "../components/withAuthorization";
import { suscribeAndHandle, deleteBulletinByRecipient } from "../utils/bulletin";
import { firebase } from "../firebase";

const styles = (theme) => ({
  root: {
    position: "fixed",
    left: 0,
    top: 0,
    zIndex: 1101,
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.primary.main,
  },
  verifyCard: {
    minWidth: 275,
    width: "50vw",
    margin: "auto",
    padding: "1rem",
    "& strong": {
      color: theme.palette.secondary.main,
      display: "block",
    },
  },
});

const Bulletin = ({ classes }) => {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [leido, setLeido] = useState(false);

  useEffect(() => {
    if (firebase.auth.currentUser?.uid) {
      const unsuscribe = suscribeAndHandle((snap) => {
        const data = snap.val();
        const { title, body } = data;
        setTitle(title);
        setBody(body);
      });

      if (typeof unsuscribe === "function") {
        return () => unsuscribe();
      }
    }
  }, []);

  const dismiss = async () => {
    await deleteBulletinByRecipient();
    setLeido(true);
  };

  if (leido || !body || !title) {
    return null;
  }

  // show bulletin
  return (
    <Box className={classes.root} open={leido} onClose={dismiss}>
      <Card className={classes.verifyCard}>
        <CardContent>
          <Typography variant="h5" paragraph>
            {title}
          </Typography>
          <Typography variant="body2" paragraph>
            {body}
          </Typography>
        </CardContent>

        <CardActions>
          <Button color="primary" variant="contained" onClick={dismiss}>
            OK
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

const authCondition = (authUser) => !!authUser;
export default compose(withAuthorization(authCondition), withStyles(styles))(Bulletin);
