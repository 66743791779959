import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import PublishIcon from "@material-ui/icons/Publish";
import CircularProgress from "@material-ui/core/CircularProgress";

import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

/** @typedef {import('../business-objects/album').Photo} Photo */

registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginImageTransform,
  FilePondPluginImageCrop,
  FilePondPluginFileValidateType,
  FilePondPluginImageValidateSize
);

const labelsEs = {
  labelIdle:
    "<div class='PreviewEmpty-title'>Portada </div> " +
    " <div class='PreviewEmpty-description'>" +
    "   Arrastra o haz click" +
    "   <br />" +
    "   (jpg, png o gif)" +
    " </div>",
  labelFileProcessing: "Subiendo",
  labelTapToRetry: "",
  labelTapToUndo: "",
  labelFileProcessingComplete: "",
  labelTapToCancel: "",
  labelFileTypeNotAllowed: "Tipo de archivo no valido",
  fileValidateTypeLabelExpectedTypes: "Suba archivos de imagenes",
  imageValidateSizeLabelImageSizeTooSmall: "La imagen es muy chica",
  imageValidateSizeLabelExpectedMinSize:
    "Tamaño minimo {minWidth} × {minHeight} px",
};

const useStyles = makeStyles((theme) => {
  const imageSize = "150px";

  return {
    container: {
      width: imageSize,
      height: imageSize,
      position: "relative",
      "& .filepond--wrapper": {
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 2,
        width: imageSize,
        height: imageSize,
      },
      "& .filepond--drop-label": {
        bottom: 0,
        height: "inherit",
        color: "inherit",
        borderRadius: "0.2rem",
        // borderWidth: 1,
        // borderStyle: "solid",
        // borderColor: "rgba(0, 0, 0, 0.23)",
        cursor: "pointer",
        "& label": {
          cursor: "pointer",
        },
      },
      "& .filepond--panel-root": {
        backgroundColor: theme.palette.action.hover,
      },
      "& .PreviewEmpty-title": {
        width: "100%",
        fontSize: "1.3rem",
        display: "none",
      },
      "& .PreviewEmpty-description": {
        width: "100%",
        color: theme.palette.text.secondary,
        textAlign: "center",
        display: "none",
      },
      "& .filepond--file-info": {
        transform: "inherit !important",
      },
      "& .filepond--file-status": {
        transform: "translate(-50%, -50%) !important",
        position: "absolute",
        top: "50%",
        left: "50%",
        alignItems: "center",
        willChange: "inherit",
        "& > .filepond--file-status-main": {
          opacity: "inherit",
          fontSize: "0.9rem",
        },
        "& > .filepond--file-status-sub": {
          opacity: "inherit",
          fontSize: "0.8rem",
        },
      },
    },

    previewContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 2,
      width: imageSize,
      height: imageSize,
      "& img": {
        borderRadius: "0.2rem",
        // borderWidth: 1,
        // borderStyle: "solid",
        // borderColor: "rgba(0, 0, 0, 0.23)",
        width: imageSize,
        height: imageSize,
      },
      "& .PreviewContainer-deleteButton": {
        position: "absolute",
        right: "0.5rem",
        bottom: "0.5rem",
        color: "white",
        backgroundColor: "rgba(0,0,0,0.25)",
      },
      "& .PreviewContainer-loading": {
        transform: "translate(-50%, -50%) !important",
        position: "absolute",
        top: "50%",
        left: "50%",
        color: "white",
        backgroundColor: "rgba(0,0,0,0.25)",
        padding: "1rem",
        borderRadius: "0.5rem",
        "& .MuiCircularProgress-svg": {
          color: "white",
        },
      },
    },
  };
});

/**
 * @param {{
 *  photo?: Photo
 *  serverProcess: Function // Ref: https://pqina.nl/filepond/docs/patterns/api/server/#advanced
 * }} props
 * @returns
 */
export const AlbumPhotoEditor = ({ photo, serverProcess }) => {
  const classes = useStyles();
  const [previewDone, setPreviewDone] = useState(false);
  const filePondRef = useRef(null);

  const photoUrl = photo?.url;

  const handleOnProcessFile = (error, file) => {
    // this is for reset the filePond after a file is uploaded
    if (filePondRef && filePondRef.current) {
      // @ts-ignore
      filePondRef.current.removeFiles();
    }
  };

  return (
    <div className={classes.container}>
      {photoUrl && (
        <div className={classes.previewContainer}>
          {!previewDone && (
            <div className="PreviewContainer-loading">
              <CircularProgress />
            </div>
          )}
          {photoUrl && (
            <img
              // @ts-ignore
              src={photoUrl}
              alt="cover"
              onLoad={() => setPreviewDone(true)}
            />
          )}
          {previewDone && (
            <IconButton
              className="PreviewContainer-deleteButton"
              aria-label="Reemplazar Imagen"
            >
              <PublishIcon />
            </IconButton>
          )}
        </div>
      )}

      <FilePond
        name="cover"
        ref={filePondRef}
        {...labelsEs}
        onprocessfile={handleOnProcessFile}
        server={{
          // @ts-ignore
          process: serverProcess,
        }}
        status={3}
        credits={null}
        allowRevert={true}
        allowMultiple={false}
        allowImageCrop
        imageResizeTargetWidth={600}
        imageCropAspectRatio="1:1"
        stylePanelAspectRatio="1:1"
        stylePanelLayout="compact"
        allowImageValidateSize
        imageValidateSizeMinWidth="500"
        imageValidateSizeMinHeight="500"
        allowFileTypeValidation
        acceptedFileTypes={["image/*"]}
        styleButtonRemoveItemPosition="center bottom"
        styleButtonProcessItemPosition="right bottom"
        styleLoadIndicatorPosition="right bottom"
        styleProgressIndicatorPosition="right bottom"
      />
    </div>
  );
};

export default AlbumPhotoEditor;
