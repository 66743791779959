// @ts-check
import { format } from "date-fns";

/**
 * Make the default album's fields for release and release_digital
 *
 * - use the current moment.
 * - return dates as string with format yyyy-MM-dd
 *
 *
 * @return {{release: string, release_digital:string}}
 */
export const makeReleaseDates = () => {
  const today = format(new Date(), "yyyy-MM-dd");
  // const forwardFiveDays = format(addDays(new Date(), 5), "yyyy-MM-dd");

  return {
    release: today,
    // release_digital: forwardFiveDays
  };
};

export default makeReleaseDates;
