// @ts-check
import { db } from "../../../firebase";
import { openAlbumField, getAlbum } from "../../albumActions";
import { processAlbumChange } from "../../cart/cartActions";

import _sideEffectCommon from "./_sideEffectCommon";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldActionType} FieldActionType */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */

/**
 * @param {object} updates
 * @param {Album} album
 * @param {FieldEditable} field
 * @param {FieldActionType} action
 * @param {any=} newValue
 * @return {object}
 */
const _sideEffectChanges = (updates, album, field, action, newValue) => {
  const path = field.path;

  if (!album.tracks) {
    album = {
      ...album,
      tracks: {},
    };
  }

  // SIDE EFFECTS
  // console.log("SIDE EFFECTS", field, path, action, newValue, updates);

  // TODO [TechDebt]: refactor the switch to if
  switch (true) {
    case album.format === "Single" &&
      (!!updates.title || path.includes("/title")):
      updates.title = newValue;

      Object.keys(album.tracks).forEach((key) => {
        updates[`tracks/${key}/title`] = newValue;
      });
      break;

    // Changing VERSION (album.version or track/12313412452/version)
    case updates.version != null:
    case Object.keys(updates).filter(
      (updateKey) => updateKey.indexOf("version") > -1
    ).length > 0:
      // Changing version when album is En Vivo
      if (album.live === true) {
        // album level
        updates.version = "En Vivo";

        // track level
        Object.keys(album.tracks).forEach((key) => {
          updates[`tracks/${key}/version`] = "En Vivo";
        });
      }

      // version on singles que no son discos En Vivo (coincide con album.version)
      else if (album.format === "Single") {
        Object.keys(album.tracks).forEach((key) => {
          updates[`tracks/${key}/version`] =
            updates.version || album.version || "";
        });
      }
      break;

    // Changing album.live
    case updates.live != null:
      if (updates.live) {
        updates.version = "";
        Object.keys(album.tracks).forEach((key) => {
          updates[`tracks/${key}/version`] = "";
        });
      }
      break;

    // Changing TRACK VERSION (tracks/__ISRC__/version) on singles
    case album.format === "Single" && path.includes("/version"):
      updates.version = newValue;
      break;

    default:
      break;
  }

  if (path === "genre") {
    updates.subgenre = "";
  } else if (path === "genreSubgenre") {
    // keep updated the deprecated version genre field
    updates.genre = newValue?.genre || "";
    updates.subgenre = newValue?.subgenre || "";
  }

  return updates;
};

/**
 * @param {Album} album
 * @param {FieldEditable} field
 * @param {FieldActionType} action
 * @param {any=} newValue
 * @return {object}
 */
const _prepareUpdates = (album, field, action, newValue) => {
  const path = field.path;

  let updates = {
    [path]: newValue,
  };
  updates = _sideEffectChanges(updates, album, field, action, newValue);
  updates = _sideEffectCommon(updates, album, field, newValue);

  return updates;
};

/**
 * Under Construction
 * @param {string} upc
 * @param {FieldEditable} field
 * @param {FieldActionType} action
 * @param {any=} newValue
 * @param {boolean=} skipCartChange
 * @return {Function}
 */
export const saveFieldChange = (
  upc,
  field,
  action,
  newValue,
  skipCartChange
) => {
  /**
   * @param {function} dispatch
   */
  return async function saveFieldChangeThunk(dispatch) {
    // load album
    /** @type {Album} */
    const album = await dispatch(getAlbum(upc));

    // persist the change
    const updates = _prepareUpdates(album, field, action, newValue);

    await db.updateAlbumFields(upc, updates);

    // send to cart
    if (!skipCartChange) {
      await dispatch(processAlbumChange(upc, field, "update", newValue));
    }

    dispatch(openAlbumField(null));
  };
};
