// @ts-check
import React from "react";
import { Grid, Tooltip } from "@material-ui/core";

import EditableField, {
  EditableSwitch,
} from "../../../components/EditableField";
import VersionField from "./FormTrack.VersionField";
import useAlbumOpened from "../../../../../reducers/album/hooks/useAlbumOpened";

const FormTrackForm = () => {
  const album = useAlbumOpened();

  const isAlbumLive = album?.live || false;
  const tooltipSwitch = isAlbumLive
    ? "No se puede cambiar cuando el album es En Vivo"
    : "";

  return (
    <Grid container item xs={12} sm={12} md={10} spacing={3}>
      <Grid item xs={12} sm={12}>
        <EditableField
          label="Título"
          name="title"
          type="input"
          level="track"
          required
        />
      </Grid>

      <Grid item xs={12} sm={4} md={4}>
        <Tooltip
          placement="right"
          title={tooltipSwitch}
          aria-label={tooltipSwitch}
        >
          <span>
            <EditableSwitch
              label="En vivo"
              name="live"
              type="switch"
              level="track"
              forceCheck={isAlbumLive}
              disabled={isAlbumLive}
            />
          </span>
        </Tooltip>
      </Grid>

      <Grid item xs={12} sm={4} md={4}>
        <EditableField
          label="Lenguaje explícito"
          name="explicit"
          type="switch"
          level="track"
        />
      </Grid>

      <Grid item xs={12} sm={4} md={4}>
        {!isAlbumLive && <VersionField />}
      </Grid>
    </Grid>
  );
};

export default FormTrackForm;
