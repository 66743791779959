//@ts-check
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import Fade from "@material-ui/core/Fade";
// import ReactHtmlParser from "react-html-parser";
import parse from "html-react-parser";

import * as routes from "../../../../../constants/routes";
import useConfig from "../../../../../hooks/useConfig";
import StepHeading from "../../../../../components/StepHeading";
import albumActions from "../../../../../reducers/albumActions";
import getRangeReleaseDigital from "../../../../../business-objects/getRangeReleaseDigital";

import { EditableDate } from "../../../components/EditableField";
import useAlbumErrors from "../../../../../reducers/album/hooks/useAlbumErrors";
import cartActions from "../../../../../reducers/cart/cartActions";
import UploadingAnimation from "../../../../../components/UploadingAnimation";
import { isAfter } from "date-fns";
import dateFormat from "dateformat";

/** @typedef {import("../../../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable*/
/** @typedef {import("../../../../../business-objects/album.fieldEditable").FieldLevelType} FieldLevelType*/
/** @typedef {import("../../../../../business-objects/album.fieldEditable").FieldTypes} FieldTypes*/

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1100,
    margin: "auto",
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  textField: {
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  heading: {
    color: "black",
  },
  rule: {
    "& strong": {
      color: "black",
    },
  },
  check: {
    marginBottom: theme.spacing(5),
    "& label > *": {
      fontSize: "1em",
      color: theme.palette.primary.main,
    },
  },
  checkLast: {
    marginBottom: 0,
  },
  uploadingAnimationContainer: {
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.tooltip + 1,
    "& > *": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

const StepLegal = ({ album }) => {
  const classes = useStyles();
  const config = useConfig();
  const dispatch = useDispatch();
  const history = useHistory();

  const { errors, hasErrors, setError } = useAlbumErrors();

  // release en plataformas
  const propsForReleaseDigital = getRangeReleaseDigital(album, config);

  const releaseDate = new Date(album.release);
  const noEarlierThan = new Date(propsForReleaseDigital.minDate);

  const releaseDigitalDefault = isAfter(releaseDate, noEarlierThan)
    ? releaseDate
    : noEarlierThan;

  const [isProcessing, setIsProcessing] = useState(false);

  const [legal, setLegal] = useState({
    agreement1: false,
    agreement2: false,
    agreement3: false,
    agreement4: false,
    agreement5: false,
  });

  const { agreement1, agreement2, agreement3, agreement4, agreement5 } = legal;

  const allAccepted = useMemo(
    () => Object.values(legal).every((item) => item === true),
    [legal]
  );

  useEffect(() => {
    (async () => {
      /** @type {FieldEditable} */
      const field = {
        // label: "Avatar",
        path: "release_digital",
        level: "album",
      };

      await dispatch(
        albumActions.saveFieldChange(
          album.upc,
          field,
          "update",
          dateFormat(releaseDigitalDefault, "yyyy-mm-dd")
        )
      );
    })();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const handleChangeLegal = (event) => {
    const name = event.target.name;
    const checked = event.target.checked;
    setLegal({
      ...legal,
      [name]: checked,
    });
  };

  const handleOnBack = () => {
    if (errors["release_digital"]) {
      setError("release_digital", null);
    }
    window.scrollTo({ top: 0 });
    history.push(routes.ALBUM_VIEW.replace(":upc", album.upc));
  };

  const handleOnFinish = async () => {
    setIsProcessing(true);
    try {
      if (album.status === "live_updating") {
        await dispatch(cartActions.publishCart(album.upc));
      } else {
        await dispatch(albumActions.albumPrice(album.upc));
        await dispatch(albumActions.publishAlbum(album.upc));
      }

      history.push(routes.ALBUMS);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Fade in={true}>
      <div className={classes.root}>
        {isProcessing && (
          <div className={classes.uploadingAnimationContainer}>
            <div>
              <UploadingAnimation genre={album.genre} />
            </div>
          </div>
        )}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <StepHeading
              title="Derechos y propiedad del contenido"
              description="Lea atentamente cada punto, usted asume la responsabilidad de su respuesta en cada caso."
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <EditableDate
              label="Fecha de aparición en plataformas"
              name="release_digital"
              type="date"
              level="album"
              required
              {...propsForReleaseDigital}
              defaultValue={releaseDigitalDefault}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography color="textSecondary" className={classes.rule}>
              {parse(config.copy.legal1)}
            </Typography>

            <Typography color="textSecondary" className={classes.check}>
              <FormControlLabel
                label="Asumo la responsabilidad"
                control={
                  <Switch
                    name="agreement1"
                    checked={agreement1}
                    color="primary"
                    onChange={handleChangeLegal}
                  />
                }
              />
            </Typography>

            <Typography color="textSecondary" className={classes.rule}>
              {parse(config.copy.legal2)}
            </Typography>

            <Typography color="textSecondary" className={classes.check}>
              <FormControlLabel
                label="Entiendo y acepto"
                control={
                  <Switch
                    name="agreement2"
                    checked={agreement2}
                    color="primary"
                    onChange={handleChangeLegal}
                  />
                }
              />
            </Typography>

            <Typography color="textSecondary" className={classes.rule}>
              {parse(config.copy.legal3)}
            </Typography>

            <Typography color="textSecondary" className={classes.check}>
              <FormControlLabel
                label="Confirmo que estoy de acuerdo"
                control={
                  <Switch
                    name="agreement3"
                    checked={agreement3}
                    color="primary"
                    onChange={handleChangeLegal}
                  />
                }
              />
            </Typography>

            <Typography color="textSecondary" className={classes.rule}>
              {parse(config.copy.legal4)}
            </Typography>

            <Typography color="textSecondary" className={classes.check}>
              <FormControlLabel
                label="Confirmo"
                control={
                  <Switch
                    name="agreement4"
                    checked={agreement4}
                    color="primary"
                    onChange={handleChangeLegal}
                  />
                }
              />
            </Typography>

            <Typography color="textSecondary" className={classes.rule}>
              {parse(config.copy.legal5)}
            </Typography>

            <Typography
              color="textSecondary"
              className={classes.check + " " + classes.checkLast}
            >
              <FormControlLabel
                label="Acepto"
                control={
                  <Switch
                    name="agreement5"
                    checked={agreement5}
                    color="primary"
                    onChange={handleChangeLegal}
                  />
                }
              />
            </Typography>
          </Grid>

          <Grid container item xs={12} spacing={2} justify="flex-end">
            <Grid item>
              <Button onClick={handleOnBack} disabled={isProcessing}>
                Volver
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="button"
                variant="contained"
                color="primary"
                disabled={!allAccepted || isProcessing || hasErrors}
                onClick={handleOnFinish}
              >
                Finalizar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

export default StepLegal;
