import React from "react";
import { firebase } from "../firebase";
import AuthUserContext from "./AuthUserContext";
import ExtendedUserContext from "../contexts/ExtendedUserContext";
import { getExtendedUserAsync } from "../firebase/db";
import { db, auth } from "../firebase/firebase";

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: null,
        extendedUser: null,
      };
    }

    suscribeToExtendedUser = () => {
      db.ref("users")
        .child(auth.currentUser.uid)
        .on("value", (extendedUser) => {
          this.setState({ extendedUser: extendedUser.val() });
        });

      db.ref("users")
        .child(auth.currentUser.uid)
        .on("child_changed", (property) => {
          console.log(property.val());
          // this.setState({ extendedUser: extendedUser.val() });
        });
    };

    componentDidMount() {
      // authUser
      firebase.auth.onAuthStateChanged((authUser) => {
        if (authUser) {
          this.setState({ authUser });
          this.suscribeToExtendedUser();
        } else {
          this.setState({ authUser: null });
        }
      });
    }

    // componentWillUnmount() {
    //   db.ref("users").off();
    // }

    render() {
      const { authUser, extendedUser } = this.state;

      return (
        <AuthUserContext.Provider value={authUser}>
          <ExtendedUserContext.Provider value={extendedUser}>
            <Component />
          </ExtendedUserContext.Provider>
        </AuthUserContext.Provider>
      );
    }
  }

  return WithAuthentication;
};

export default withAuthentication;
