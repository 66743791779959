// @ts-check
import React from "react";
import { isObject, useFormikContext } from "formik";
import { AutocompleteArtistField } from "../../../../../components-cross/AutocompleteArtists";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";

const ArtistField = ({ artist, patchArtist, canRemove = false }) => {
  const { values, setFieldValue } = useFormikContext();

  const onRemoveArtist = () => {
    const newArtists = values.artists.filter((item) => item !== artist);
    setFieldValue("artists", newArtists);
  };

  /**
   *
   * @param {object | string | null} spotyArtist
   */
  const onChangedArtist = (spotyArtist) => {
    if (isObject(spotyArtist) && spotyArtist?.images) {
      const images = spotyArtist.images;
      // the lastone is the smaller
      const artitsImageUrl = images[images.length - 1]?.url || "";
      setFieldValue(patchArtist + ".imageUrl", artitsImageUrl);
    } else {
      setFieldValue(patchArtist + ".imageUrl", null);
    }

    if (isObject(spotyArtist) && spotyArtist?.id) {
      const spotifyId = spotyArtist?.id || "";
      setFieldValue(patchArtist + ".spotifyId", spotifyId);
    } else {
      setFieldValue(patchArtist + ".spotifyId", null);
    }

    if (isObject(spotyArtist) && spotyArtist?.name) {
      console.log("spotyArtist", spotyArtist);
    }
  };

  return (
    <AutocompleteArtistField
      label={artist.role}
      name={patchArtist + ".name"}
      fullWidth
      onChange={onChangedArtist}
      InputProps={
        !canRemove
          ? null
          : {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="borrar"
                    onClick={() => onRemoveArtist()}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }
      }
    />
  );
};

export const ArtistPrimaryField = (props) => {
  const { values } = useFormikContext();
  const artistPrimary = values?.artists?.[0];

  return !artistPrimary ? (
    <></>
  ) : (
    <ArtistField
      artist={artistPrimary}
      patchArtist="artists.0"
      canRemove={false}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  artistsBlock: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: theme.spacing(5),
  },
}));

export const ArtistsFields = () => {
  const { values } = useFormikContext();

  const classes = useStyles();

  return (
    <>
      {values.artists?.slice(1)?.map((artist, index) => {
        index = index + 1;

        return (
          <Grid item xs={12}>
            <ArtistField
              key={index}
              artist={artist}
              patchArtist={`artists.${index}`}
              canRemove={true}
            />
          </Grid>
        );
      })}
    </>
  );
};

export default ArtistsFields;
