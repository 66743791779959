import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
  Typography,
} from "@material-ui/core";

import shieldSvg from "../../../../../images/shield.svg";
import useEditableField from "../../../components/EditableField/hooks/useEditableField";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      width: "436px",
      padding: theme.spacing(5),
      fontSize: "0.9rem",
    },
    "& .MuiFormControlLabel-label": {
      fontSize: "0.9rem",
    },
  },
  header: {
    textAlign: "center",
  },
  footer: {
    marginTop: "3rem",
    display: "flex",
    "& > *": {
      flex: "1 1 auto",
    },
    "& > .footer-right": {
      textAlign: "right",
    },
  },
}));

const AutoFormatTitleDialog = ({ onClose }) => {
  const field = {
    label: "Auto Formato Título",
    name: "disabledAutoTitle",
    type: "switch",
    level: "album",
  };
  const classes = useStyles();
  const { value, setValue } = useEditableField(field);
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value === true ? "OFF" : "ON");
  }, [value]);

  const handleOnClose = (e) => {
    if (onClose) {
      onClose(e);
    }
  };

  const handleChange = (e) => {
    e.stopPropagation();
    const newValue = e.target.value;
    setLocalValue(newValue);
  };

  const handleOnSubmit = () => {
    setValue(localValue === "OFF");
    if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog
      className={classes.root}
      onClose={handleOnClose}
      aria-labelledby="simple-dialog-title"
      open={true}
    >
      <div className={classes.header}>
        <img src={shieldSvg} alt="escudo auto formato" width="60" height="60" />
        <Typography variant="h5">IMPORTANTE</Typography>
      </div>
      <p>
        El auto-formato de títulos aplica las reglas de formato que exigen
        Spotify y otras plataformas.
      </p>
      <p>
        Si decides desactivar esta protección, es porque entiendes que tu álbum
        puede ser rechazado hasta que realices las correcciones necesarias en
        cada plataforma.
      </p>
      <div>
        <RadioGroup
          aria-label="autoFormat"
          name="autoFormat"
          value={localValue}
          onChange={handleChange}
        >
          <FormControlLabel
            value="ON"
            control={<Radio />}
            label="Protección activa (recomendado)"
          />
          <FormControlLabel
            value="OFF"
            control={<Radio />}
            label="Desactivar, sé lo que hago."
          />
        </RadioGroup>
      </div>
      <div className={classes.footer}>
        <div>
          <Button variant="contained" onClick={handleOnClose} size="small">
            CANCELAR
          </Button>
        </div>
        <div className="footer-right">
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleOnSubmit}
          >
            ACEPTAR
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default AutoFormatTitleDialog;
