// @ts-check

/** @typedef {import('../../../business-objects/album').Track} Track */

/**
 *
 * @param {{[key: string]: Track}} tracks
 * @return {Track | null}
 */
export const findTrackByOrder = (tracks, orderNum) => {
  const keys = Object.keys(tracks);
  const key = keys.find((k) => tracks[k].order === orderNum);
  if (!key) {
    return null;
  }
  return tracks[key];
};

export default findTrackByOrder;
